import { utils_api } from 'generics/common.js';
import { getStartArgs, resetQuery } from 'generics/queryArgs.js';

const ROUTE_KEY = [ "MarketApp", "route" ];
const ROUTE_EXPIRATION = 60*60*1000;

export function loadRoute()  {
  const stored = utils_api.getStored( ROUTE_KEY ) || {};
  const { route, stamp, params } = stored;
  const expired = Date.now()-ROUTE_EXPIRATION;
  if ( route && stamp && (stamp > expired)  )  {
    return { route, stamp, params: Object.assign({}, params) };
  }

  return {};
}


export function storeRoute( route, params )  {
  const stamp = Date.now();
  utils_api.setStored( ROUTE_KEY, {  route, stamp, params  } );
  console.info( "storeRoute", {  route, stamp, params  } )
}


export function resetRoute( )  {
  utils_api.setStored( ROUTE_KEY, {} );
}


const CURRENT_APP_KEY = [ "MarketApp", "currentAppId" ];
const CURRENT_APP_EXPIRATION = 60*60*1000;

export function storeCurrentApp( appId )  {
  const stored = { appId, stamp: Date.now() };
  utils_api.setStored( CURRENT_APP_KEY, stored );
  console.info( "storeCurrentApp", stored )
}


export function loadCurrentApp()  {
  const { appId, stamp } = (utils_api.getStored( CURRENT_APP_KEY ) || {});
  const expired = Date.now()-CURRENT_APP_EXPIRATION;
  if ( stamp && (stamp > expired)  )  {
    return appId;
  }
}


export function processArgsAndRoutes()  {
  const args = getStartArgs();
  const { a,  n  }  = args;

  if ( a && (+a > 0) )  {
    storeCurrentApp( a );
  }

  if ( ("viewitem" in args) && a && n )  {
    storeRoute( "viewitem", {  app:a,  market_hash_name:n });
  }

  resetQuery();
}


