import MoneyUtil from 'components/moneyUtil';
import { _I_ } from 'components/translate';
import { LogStream } from 'generics/common';
import { SettingsInjections } from 'generics/settingsInjections';
import React from 'react';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

// LogStream.enable("VIEWORDERSGRAPH");

function makePlottable(orders, total, fmt) {
  let volume = 0;

  const result = orders.map((order) => {
    volume += order.count;

    return {
      "format": fmt,
      "price": +order.price,
      volume
    };
  });

  return result;
}

export default class ViewOrdersGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      "plotData": [[], []],
      "plotOpt": null,
      "minPrice": 0,
      "maxPrice": 0,
      "maxOrders": 0
    };
  }

  componentDidMount() {
    this.getPlotData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.getPlotData(nextProps);
  }


  makeRanges(buyGraph, sellGraph) {
    const prices = [];

    if (buyGraph.length) {
      const { price: priceA } = buyGraph[0];
      const { price: priceB } = buyGraph[buyGraph.length - 1];
      prices.push(priceA, priceB);
    }

    if (sellGraph.length) {
      const { price: priceA } = sellGraph[0];
      const { price: priceB } = sellGraph[sellGraph.length - 1];
      prices.push(priceA, priceB);
    }

    const fPrices = (!prices.length) ? [0] : prices.filter((a) => (a > 0));

    const minPrice = Math.min(...fPrices);
    const maxPrice = Math.max(...fPrices);

    return {
      "minPrice": minPrice,
      "maxPrice": maxPrice
    };
  }

  graphTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      let { price, volume, format } = payload[0].payload;

      const market = " " + MoneyUtil.formatCurr(SettingsInjections.getUsedCurrency());

      const starting = _I_(
        (format == "buy") ? "MarketDepthTotals.starting.buy" : "MarketDepthTotals.starting.sell"
      );
      const andMore = _I_(
        (format == "buy") ? "MarketDepthTotals.andMore.buy" : "MarketDepthTotals.andMore.sell"
      );

      const prevew = _I_(
        (format == "buy") ? "MarketDepthTotals.prevew.sell" : "MarketDepthTotals.prevew.buy"
      );

      return (
        <div className="graphTooltip">
          <p className="label">{`${prevew} ${volume} ${starting} ${price} ${market} ${andMore}`}</p>
        </div>
      );
    }

    return null;
  };


  getPlotData(props) {
    let sellGraph = makePlottable(props.sellOrders, props.totalSell, "sell");
    let buyGraph = makePlottable(props.buyOrders, props.totalBuy, "buy");

    const ranges = this.makeRanges(buyGraph, sellGraph);

    this.setState({
      "plotData": [sellGraph, buyGraph.reverse()],
      ...ranges
    });
  }

  render() {
    let { plotData } = this.state;

    LogStream("VIEWORDERSGRAPH").log('render', plotData);

    return (
      <React.Fragment>
        <div className="graphContent">
          <div className="graphTitle">{_I_("OrdersGraph.plot.title")}</div>
        </div>

        <ResponsiveContainer
          width="100%"
          height={310}
        >
          <AreaChart
            margin={{
              top: 5, right: 35, bottom: 5, left: 5
            }}
          >
            <CartesianGrid
              stroke="rgba(255, 255, 255, 0.2)"
            />

            <Tooltip
              content={this.graphTooltip}
            />

            <XAxis
              dataKey="price"
              type="category"
              tickLine={false}
              allowDuplicatedCategory={false}
              stroke="rgba(255, 255, 255, 0.5)"
            />

            <YAxis
              dataKey="volume"
              type="number"
              stroke="rgba(255, 255, 255, 0.5)"
            />

            <Area
              dataKey="volume"
              data={plotData[1]}
              stroke="#21A48C"
              fill="#0A5D44"
              legendType="line"
            />

            <Area
              dataKey="volume"
              data={plotData[0]}
              stroke="#FC2C38"
              fill="#FC2C38"
              fillOpacity="0.26"
              legendType="line"
            />
          </AreaChart>
        </ResponsiveContainer>
      </React.Fragment>
    );
  }
}
