import { MarketConfigLoader } from 'components/app';
import { AuthenticationProcessor } from 'components/authentication';
import { StaticConfigLoader } from 'components/setup/staticConfigsLoader';
import { getStartArgs, getStartPath } from 'generics/queryArgs.js';
import { processArgsAndRoutes } from 'generics/routed.js';
import ReactDOM from 'react-dom';

console.info( "getStartPath", getStartPath() );
console.info( "getStartArgs", getStartArgs() );
processArgsAndRoutes();

ReactDOM.render(
  <StaticConfigLoader>{
    (staticConfigs) =>  <AuthenticationProcessor {...staticConfigs} >{
                          (authStatus) => <MarketConfigLoader  {...staticConfigs} {...authStatus} />
                        }</AuthenticationProcessor>
  }</StaticConfigLoader>,
  document.getElementById('appRoot')
);