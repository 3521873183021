import { MarketBooksBrief } from 'api/tradeAPICall';
import ApplicationEvents from 'components/applicationEvents';
import MoneyUtil from 'components/moneyUtil';
import sha1 from "crypto-js/sha1";
import { LogStream } from 'generics/common';
import { PromiseDelay } from 'generics/utils';
import React from 'react';
import G_marketConfigStorage from "./globalMarketConfigStorage";

// LogStream.enable("BRIEFBOOKLOADERLOGGER")

const logger = LogStream("BRIEFBOOKLOADERLOGGER");

export function BriefBookLoaderAsync(appid, market_name) {
  const processBriefBook = (resp) => {
    const { BUY = [], SELL = [], depth = {} } = (resp || {});
    const auction = resp ? (resp.auction || {}) : null;
    const type = resp ? (resp.type || "") : null;
    const tradeDelaySec = resp?.tradeDelaySec || 0;

    const totalSellDepth = (depth || {}).SELL || 0;
    const totalBuyDepth = (depth || {}).BUY || 0;

    const ordersSell = (SELL || []).map(([price, count]) => ({ price: MoneyUtil.formatMoney(price), rawPrice: price, count }));
    const ordersBuy = (BUY || []).map(([price, count, date]) => ({ price: MoneyUtil.formatMoney(price), rawPrice: price, count, date }));

    if (market_name && resp !== null)
      ApplicationEvents.emit_async("market.briefUpdated", market_name);

    return { totalSellDepth, totalBuyDepth, ordersSell, ordersBuy, auction, type, tradeDelaySec };
  }

  if (!market_name) {
    logger.info("skipped due to empty market_hash_name");
    return Promise.resolve(processBriefBook({}));
  }

  return MarketBooksBrief(appid, market_name).then((resp) => {
    logger.info("Result -> ", resp);
    return processBriefBook(resp);
  }).catch((reason) => {
    const message = `Failed to load market depth data:\n${reason.message}`;
    reason = Object.assign({}, reason, { message });
    logger.error("Failed -> ", reason);
    return processBriefBook({});
  });
}

export function isOwnBid(salt, hash) {
  const hashToken = G_marketConfigStorage.getUserToken();
  return hash == sha1(salt + hashToken);
}

export function getBidIndex(bids, salt) {
  return bids.findIndex(bid => isOwnBid(salt, bid.count));
}

export class BriefBookLoader extends React.Component {
  constructor(props) {
    super(props);

    this.onLoadOrdersList = () => this.loadOrdersList();
    this.tickerEventHandler = (...rest) => this.onTickerEvent(...rest);
    this.pending = null;
    this.scheduled = false;
  }

  componentDidMount() {
    ApplicationEvents.on("market.ordersUpdate", this.onLoadOrdersList);
    ApplicationEvents.on("market.notify.received", this.tickerEventHandler);
    this.loadOrdersList();
  }

  componentWillUnmount() {
    ApplicationEvents.off("market.ordersUpdate", this.onLoadOrdersList);
    ApplicationEvents.off("market.notify.received", this.tickerEventHandler);
  }

  componentDidUpdate(prevProps) {
    const { market_name, appid } = this.props;
    if (prevProps.market_name != market_name || prevProps.appid != appid)
      this.loadOrdersList();
  }

  onTickerEvent(args, eventParams, details) {
    const topic = details.decodedTopic;
    if (!topic) return;
    if (topic.eventType != "book") return;

    const { market_name, appid } = this.props;
    if (market_name != topic.hashname) return;
    if (appid != topic.appId) return;

    logger.info(`BriefBookLoader update due to ${details.topic}`);
    this.loadOrdersList();
  }

  loadOrdersList() {
    const { market_name, appid, onUpdate } = this.props;

    if (this.pending) {
      this.scheduled = true; // need reschedule
      return;
    }

    this.scheduled = false;
    this.pending = BriefBookLoaderAsync(appid, market_name).then((resp) => {
      if (onUpdate)
        onUpdate(resp);
    });

    PromiseDelay(1000, this.pending).then(() => {
      this.pending = null;
      if (this.scheduled)
        this.loadOrdersList();
    });
  }

  render() {
    return null;
  }
}

