import { Dropdown, DropdownAction } from 'components/generics';
import { _I_ } from 'components/translate';
import React from 'react';

export class ViewMarketSortOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.setFields();
  }

  setFields() {
    this.SORT_FIELDS = [
      { "key": "u", "name": _I_("MarketSortOrder.label.popular"), "start": "" },
      //{ "key": "ca", "name": _I_("MarketSortOrder.hint.newAsc"), "start": "created_asc" },
      { "key": "cd", "name": _I_("MarketSortOrder.hint.newDesc"), "start": "created_desc" },
      { "key": "pa", "name": _I_("MarketSortOrder.hint.priceAsc"), "start": "price_asc" },
      { "key": "pd", "name": _I_("MarketSortOrder.hint.priceDesc"), "start": "price_desc" },
      //{ "key": "qa", "name": _I_("MarketSortOrder.hint.amountAsc"), "start": "depth_asc" },
      //{ "key": "qd", "name": _I_("MarketSortOrder.hint.amountDesc"), "start": "depth_desc" },
    ];
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.locale != this.props.locale) {
      this.setFields();
    }
  }

  render() {
    const sortings = this.SORT_FIELDS;
    const order = this.props.sortOrder;
    const sortingDefaultOption = sortings.find(item => item.start === order);

    const sortingOptions = sortings.map((item) =>
      <DropdownAction
        key={`app_${item.key}`}
        onClick={() => this.props.onChange(item.start)}
        active={order == item.start}
      >
        {item.name}
      </DropdownAction>
    );

    return (
      <Dropdown
        mixClasses="controlItem sorting"
        caption={sortingDefaultOption.name}
      >
        {sortingOptions}
      </Dropdown>
    );
  }
}

