export const plural = (number, words) => {
  return Math.abs(parseInt(number, 10)) === 1 ? words[0] : words[1];
};

export const locale = {
  "ViewItemPreviewSidePane.quantity.inventory": " ",
  "ViewItemPreviewSidePane.quantity.ordersInventory": " szt. przedmiotów w ekwipunku",
  "ViewItemPreviewSidePane.quantity.count": " na sprzedaż",
  "ViewItemPreviewSidePane.quantity.countBuy": " zlecenia kupna",
  "ViewItemPreviewSidePane.quantity.items": " szt. przedmiotów",
  "ViewMarketedItem.SoldOutText": "wyprzedano",
  "ViewMarketedItem.SoldOutTip": "Brak poleceń kupna",
  "ViewMarketedItem.NoBuyText": "brak ofert kupna",
  "ViewMarketedItem.SellingFrom": "Sprzedawane od...",
  "ViewMarketedItem.SellingMore": " lub więcej",
  "ViewMarketedItem.BuyingMore": " lub poniżej",
  "ViewMarketedItem.tradableAfter": "będą dostępne w sprzedaży po",
  "ViewItemSearch.heading.showAll": "Wyświetlane wszystkie oferty",
  "ViewItemSearch.heading.orders": "Moje zlecenia",
  "ViewItemSearch.heading.marketSell": "Zlecenia sprzedaży",
  "ViewItemSearch.heading.marketBuy": "Zlecenia kupna",
  "ViewItemSearch.heading.marketAuctions": "Aukcje",
  "ViewItemSearch.title.marketAuction": "Aukcja",
  "ViewItemSearch.title.ending": "Do końca",
  "ViewItemSearch.title.finished": "Aukcja zakończona",
  "ViewItemSearch.heading.settings": "Ustawienia",
  "ViewMarketedItem.button.more": "Więcej szczegółów",
  "ViewItemSearch.heading.resultsFor": "Wyświetlane wyniki dla:",
  "ViewItemSearch.stub.empty": "Pusty wynik wyszukowania",
  "ViewItemSearch.heading.tooltip": "Twoja oferta została przelicytowana",
  "ViewItemSearch.heading.tooltip.win": "Twoja oferta wygrała",
  "ViewItemPopup.committed.SELL": "Sprzedaż zakończona",
  "ViewItemPopup.committed.BUY": "Zakup zakończony",
  "ViewItemPopup.committed.BID": "Oferta złożona",
  "ViewItemPopup.application.BUY": "Złożono zamówienie na zakup",
  "ViewItemPopup.application.SELL": "Utworzono wniosek o sprzedaż",
  "ViewItemPopup.application.BID": "Złożono ofertę",
  "ViewItemPopup.canceled.BUY": "Anulowano zamówienie na zakup",
  "ViewItemPopup.canceled.SELL": "Anulowano wniosek o sprzedaż",
  "ViewItemPopup.canceled.BID": "Anulowano ofertę",
  "ViewItemPopup.showAll.button": "Pokaż wszystko",
  "ViewItemAssets.readAll.button": "Oznacz wszystko jako obejrzane",
  "ViewItemPopup.heading.noHistory": "Brak powiadomień",
  "ViewItemPopup.heading.unseenHistory": "Brak nowych powiadomień",
  "ViewItemSearch.termsPlaceholder": "Warunki wyszukiwania",
  "ViewItemSearch.label.search": "Szukaj przedmiotów",
  "ViewMarketedItem.lovestPrice": "od ",
  "ViewMarketedItem.beforePrice": "wcześniej ",
  "ViewMarketedItem.yourBid": "twoja oferta ",
  "ViewMarketedItem.bid": "oferta ",
  "ViewItemSearch.heading.exchange": "Home",
  "ViewItemSearch.heading.light": "Jasny",
  "ViewItemSearch.heading.dark": "Ciemny",
  "ViewItemSearch.filteringAnnounce": "Aby filtrować zamówienia musisz wybrać grę",
  "ViewItemSearch.ordersBuy": "KUP",
  "ViewItemSearch.ordersSell": "SPRZEDAJ",
  "ViewItemSearch.ordersOpen": "Zobacz pełną historię",
  "MarketApp.Navbar.inventoryLink": "Ekwipunek",
  "MarketApp.Navbar.ordersLink": "Moje transakcje",
  "MarketApp.Navbar.marketLink": "Rynek",
  "MarketApp.Navbar.settingsLink": "Ustawienia",
  "MarketApp.Navbar.replenishLink": "Uzupełnij konto",
  "MarketApp.Navbar.balanceHistoryLink": "Historia konta",
  "MarketApp.Navbar.buyCoinsLink": "Kup GaijinCoins",
  "MarketApp.Navbar.profileLink": "Profil",
  "MarketApp.Navbar.reloginLink": "Zaloguj ponownie lub zmień konto",
  "MarketApp.Navbar.toOldVersion": "Przejdź do starej wersji",
  "MarketApp.Navbar.linkFeedback": "Zostaw opinię",
  "ViewTradeDialog.caption": "Wymiana",
  "ViewTradeDialog.button.back": "Wstecz",
  "NotAllowedMessage.caption": "Niedozwolone",
  "NotAllowedMessage.body.h1": "Dostęp do rynku",
  "NotAllowedMessage.body.h3": "obecnie niedostępne dla Twojego konta",
  "NotAllowedMessage.relogin": "Zmień konto",
  "CountryDeniedMessage.caption": "Niedozwolone",
  "CountryDeniedMessage.body.notAllowed": "Dostęp do rynku niedostępny",
  "CountryDeniedMessage.body.unclearStatus": "Z uwagi na niejasny status prawny transakcji w twoim kraju, dostęp do Rynku jest wyłączony",
  "LandingMessage.caption": "Autoryzacja",
  "LandingMessage.body": "Kończenie lądowania...",
  "TokenExpiredMessage.caption": "Autoryzacja wygasła",
  "TokenExpiredMessage.body.h1": "Autoryzacja wygasła",
  "TokenExpiredMessage.body.h3": "Nie możemy wiele na to poradzić, ale ty możesz nadal",
  "TokenExpiredMessage.relogin": "Zaloguj ponownie...",
  "AuthErrorMessage.caption": "Autoryzacja nie powiodła się",
  "AuthErrorMessage.body.h1": "Nie udało się autoryzować",
  "AuthErrorMessage.body.code": "Kod:",
  "AuthErrorMessage.body.desc": "Nie możemy wiele na to poradzić, ale ty możesz nadal",
  "AuthErrorMessage.relogin": "Zaloguj ponownie...",
  "AuthorizationRequiredMessage.caption": "Wymagana autoryzacja",
  "AuthorizationRequiredMessage.body.h3.1": "Zaraz wejdziesz",
  "AuthorizationRequiredMessage.body.h3.2": "Rynek Gaijin",
  "AuthorizationRequiredMessage.body.h3.3": "By przejść dalej musisz autoryzować proces",
  "AuthorizationRequiredMessage.login": "Zaloguj się za pomocą Gaijin.Net",
  "CheckingAuthMessage.caption": "Autoryzacja",
  "CheckingAuthMessage.body": "Sprawdzanie autoryzacji, prosimy czekać",
  "CancelOrdersPanel.cancelError": "\nNie udało się anulować polecenia ",
  "CancelOrdersPanel.loading": "Sprawdzanie aktywnych transakcji",
  "CancelOrdersPanel.done": "Operacja zakończona.",
  "CancelOrdersPanel.errors": "Pojawiły się błędy: \n",
  "CancelOrdersPanel.loaded.youHave": "Obecnie posiadasz ",
  "CancelOrdersPanel.loaded.sellOrders": " polecenia sprzedaży sumują się do ",
  "CancelOrdersPanel.loaded.buyOrders": " polecenia kupna sumują się do ",
  "CancelOrdersPanel.loaded.and": " i ",
  "CancelOrdersPanel.loaded.shouldCancel": " i musisz anulować je wszystkie. ",
  "CancelOrdersPanel.loaded.doCancel": "Anuluj polecenia",
  "CancelOrdersPanel.cancelling.processing": "Przetwarzanie",
  "CancelOrdersPanel.cancelling.outOf": " brak ",
  "CancelOrdersPanel.cancelling.sellOrders": " polecenia sprzedaży",
  "CancelOrdersPanel.cancelling.buyOrders": " polecenia kupna",
  "ClassInfoDescriptions.dropped_item.block_label": "Zawiera jeden z następujących:",
  "ClassInfoDescriptions.dropped_item.stage-unknown": "Nieznany",
  "ClassInfoDescriptions.dropped_item.stage-pending": "...ładowanie",
  "ClassInfoDescriptions.text.block_label": "",
  "ClassInfoDescriptions.dropped_bundle.block_label": " ",
  "ClassInfoDescriptions.buttonLabel.top": "Pokaż",
  "ClassInfoDescriptions.buttonLabel.hide": "Ukryj",
  "ClassInfoDescriptions.buttonLabel.bottom": "wszystkie",
  "ClassInfoDescriptions.dropped_text.description": "Description",
  "ClassInfoUtil.fake_name.pending": " ",
  "ClassInfoUtil.fake_name.broken": " ",
  "ClassInfoUtil.SynthTag.marketable1": " ",
  "ClassInfoUtil.SynthTag.marketable0": " ",
  "ClassInfoUtil.SynthTag.tradable1": " ",
  "ClassInfoUtil.SynthTag.tradable0": " ",
  "ClassInfoUtil.SynthTag.commodity1": " ",
  "ClassInfoUtil.SynthTag.commodity0": " ",
  "ConfirmBuyDialog.title": "Złóż zlecenie kupna",
  "BuyProcessingDialog.title": "Przetwarzanie...",
  "BuyProcessingDialog.pleaseWait": "Przetwarzanie, prosimy czekać",
  "ProcessingDialog.notItem": "Żądany przedmiot nie został znaleziony na rynku lub nie jest dostępny do handlu",
  "SellDialog.title": "Umieść przedmiot na sprzedaż",
  "SellProcessingDialog.title": "Przetwarzanie...",
  "SellProcessingDialog.pleaseWait": "Przetwarzanie, prosimy czekać",
  "LimitedAccountPanel.2step.reason": "Musisz skonfigurować uwierzytelnianie dwuetapowe",
  "LimitedAccountPanel.2step.link": "Więcej informacji na temat uwierzytelniania dwuetapowego",
  "LimitedAccountPanel.2step.href": "https://support.gaijin.net/hc/en-us/articles/203623622-How-to-set-up-the-Two-Step-Authorization-Google-Authenticator-",
  "LimitedAccountPanel.email.reason": "Musisz potwierdzić swój adres e-mail",
  "LimitedAccountPanel.email.link": "Przejdź do profilu",
  "LimitedAccountPanel.2step.toPlug": "Aktywuj",
  "LimitedAccountPanel.email.href": "https://store.gaijin.net/user.php",
  "LimitedAccountPanel.title": "Na twoje konto nałożono ograniczenia z powodu:",
  "LimitedAccountPanel.conclusion": "Po zmianie ustawień bezpieczeństwa musisz się wylogować, a następnie ponownie zalogować, by zmiany zaczęły obowiązywać",
  "LimitedAccountPanel.relogin": "Zaloguj ponownie...",
  "EmailVerificationStateIcon.success": "Konto potwierdzone, adres e-mail zweryfikowany",
  "EmailVerificationStateIcon.warning": "Konto niepotwierdzone, email niezweryfikowany",
  "EmailVerificationStateIcon.danger": "Niepotwierdzone konto, wymagana weryfikacja email",
  "TwoFactorStateIcon.success": "Konto zabezpieczone za pomocą uwierzytelniania dwuetapowego",
  "TwoFactorStateIcon.warning": "Niezabezpieczone konto, autoryzacja dwuetapowa wyłączona",
  "TwoFactorStateIcon.danger": "Autoryzacja dwuetapowa WYMAGANA",
  "ViewInventoryTextFilter.common.label": "By przefiltrować ekwipunek, musisz wybrać grę",
  "ItemBuyEditor.label.wheelAmountInput": "Możesz użyć kółka myszy do regulacji liczby,\nalt+kółko = prędkość 10x",
  "ItemBuyEditor.label.wheelInput": "Możesz użyć kółka myszy do regulacji ceny,\nalt+kółko = prędkość 10x\nshift+kółko=prędkość 0.1x",
  "ItemBuyEditor.note.posAmount": "Ilość powinna być wartością dodatnią",
  "ItemBuyEditor.note.maxAmount": "Wartość przekracza maksimum na 1000 przedmiotów",
  "ItemBuyEditor.note.invalidAmount": "Nieprawidłowa ilość",
  "ItemBuyEditor.note.minPrice": "Minimalna cena to",
  "ItemBuyEditor.note.maxTotal": "Zamówienie całkowite przekracza maksimum",
  "ItemBuyEditor.note.invalidPrice": "Nieprawidłowa cena",
  "ItemBuyEditor.note.mustAgree": "Należy zaakceptować umowę Terms Of Use",
  "ItemBuyEditor.note.insufficentFunds0": "Za mało funduszy, potrzebujesz więcej",
  "ItemBuyEditor.note.insufficentFunds1": "dla tego polecenia",
  "ItemBuyEditor.label.buyCouns": "Kup",
  "ItemBuyEditor.label.submit": "Umieść polecenie",
  "ItemBuyEditor.label.price": "Jaką cenę chcesz zapłacić (na jeden przedmiot):",
  "ItemBuyEditor.label.amount": "Ile chcesz kupić:",
  "ItemBuyEditor.label.totalPrice": "Cena końcowa (wliczając opłaty):",
  "ItemBuyEditor.label.agree": "Zgadzam się na Gaijin Store",
  "ItemBuyEditor.label.ToU": "Warunki korzystania z serwisu",
  "MarketDepthTotals.noOrders.sell": "Brak poleceń sprzedaży",
  "MarketDepthTotals.noOrders.buy": "Brak poleceń kupna",
  "MarketDepthTotals.starting.sell": " za ",
  "MarketDepthTotals.starting.buy": " od ",
  "MarketDepthTotals.andMore.sell": " i poniżej",
  "MarketDepthTotals.andMore.buy": " i powyżej",
  "MarketDepthTotals.prevew.sell": "Kupujesz: ",
  "MarketDepthTotals.prevew.buy": "Na sprzedaż: ",
  "MarketDepthOwnWarning.sell": "Masz oczekujące polecenia sprzedaży",
  "MarketDepthOwnWarning.buy": "Masz oczekujące polecenia kupna",
  "MarketDepthTable.caption.price": "Cena",
  "MarketDepthTable.caption.quantity": "Ilość",
  "MarketDepthTable.ellipsis.sell": "i więcej",
  "MarketDepthTable.ellipsis.buy": "i mniej",
  "ItemMarketDetails.buyButton": "KUP",
  "ItemMarketDetails.sellButton": "SPRZEDAJ",
  "ItemMarketDetails.nothingToSellButton": "Brak przedmiotów do sprzedania",
  "ItemMarketDetails.makeAuction": "Utwórz aukcję",
  "ItemMarketDetails.makeBid": "Złóż ofertę",
  "ItemMarketDetails.bid": "Oferta",
  "ItemMarketDetails.yourBid": "Twoja oferta:",
  "ItemMarketDetails.notifications.yourBid": "Twoja oferta",
  "ItemMarketDetails.yourBid.short": "twoja",
  "ItemMarketDetails.yourBid.win": "wygrywa",
  "ItemMarketDetails.yourBid.loose": "przegrywa",
  "ItemSellEditor.label.priceStep": "Krok ceny",
  "ItemSellEditor.label.endDate": "Data zakończenia",
  "limitedAccountMessage.requires": "Wymaga:",
  "limitedAccountMessage.2step": "dwuetapowa ochrona",
  "limitedAccountMessage.verifiedEmail": "potwierdzony email",
  "limitedAccountMessage.and": " i ",
  "ItemSellEditor.label.wheelInput": "Możesz użyć kółka myszy do regulacji ceny,\nalt+kółko = prędkość 10x\nshift+kółko=prędkość 0.1x",
  "ItemSellEditor.note.minPrice": "Minimalna cena to",
  "ItemSellEditor.note.maxPrice": "Maksymalna dozwolona cena sprzedaży",
  "ItemSellEditor.note.maxTotals": "Zamówienie całkowite przekracza maksimum",
  "ItemSellEditor.note.maxBalance": "Saldo nie powinno przekraczać",
  "ItemSellEditor.note.mustAgree": "Należy zaakceptować Warunki Korzystania",
  "ItemSellEditor.label.price": "Kupujący płaci (włączając opłaty dodatkowe):",
  "ItemSellEditor.label.wished": "Otrzymasz, po naliczeniu opłat dodatkowych:",
  "ItemSellEditor.label.agree": "Zgadzam się na Gaijin Store",
  "ItemSellEditor.label.ToU": "Terms of Use",
  "ItemSellEditor.label.submit": "OK, wystaw na sprzedaż",
  "ItemSellEditor.currentBalance.before": "Obecnie posiadasz",
  "ItemSellEditor.currentBalance.after": "na Twoim koncie",
  "ItemSellEditor.sellOrdersTotal.before": "plus",
  "ItemSellEditor.sellOrdersTotal.after": "nagromadzone w poleceniach sprzedaży",
  "ItemSellEditor.buyOrdersTotal.before": "i",
  "ItemSellEditor.buyOrdersTotal.after": "nagromadzone w poleceniach kupna",
  "ItemSellEditor.label.amount": "Ile chcesz sprzedać:",
  "AppFilterEditor.label.byTags": "Filter",
  "AppFilterEditor.label.clearFilter": "Wyczyść filtr",
  "AppFilterEditor.label.byTitles": "Filtruj po tytułach",
  "AppFilterEditor.label.allFilter": "Wszystkie gry",
  "AppFilterEditor.label.authenticity": "Autentyczność",
  "AppFilterEditor.message.filter": "Filtry są unikatowe dla każdej gry.",
  "AppFilterEditor.message.game": "Wybierz grę:",
  "ViewItemDetailed.restriction.amount.items": " items",
  "ViewItemDetailed.restriction.nonMarketable": "Nie można sprzedać",
  "ViewItemDetailed.restriction.blockedAfter": "Zostanie zablokowane za",
  "ViewItemDetailed.restriction.blockedPair": "Wymiana zablokowana",
  "ViewItemDetailed.restriction.expiresAt1": "Zniknie za",
  "ViewItemDetailed.restriction.expiresAt2": "",
  "ViewItemDetailed.restriction.endedAt": "End of auction",
  "ViewItemDetailed.restriction.amount": "Lot contains",
  "ViewItemDetailed.restriction.pieces": "pcs.",
  "ViewItemDetailed.restriction.startPrice": "Starting price",
  "ViewItemDetailed.restriction.days": "d",
  "ViewItemDetailed.restriction.hours": "hrs",
  "ViewItemDetailed.restriction.minutes": "min",
  "ViewItemDetailed.restriction.leadingBids": "leading bids",
  "ViewItemDetailed.restriction.noBids": "There are no bids yet. Place your first bid in this auction!",
  "ViewItemDetailed.restriction.delayTrade": "Once purchased, the item will be available for sale through",
  "ViewItemDetailed.restriction.unclearStatus": "Z uwagi na niejasny status prawny skrzynek z trofeami w twoim kraju, transakcje związane ze skrzynkami i kluczami są zablokowane na twoim koncie.",
  "MarketSortOrder.label.name": "Nazwa",
  "MarketSortOrder.hint.newDesc": "Najpierw najnowsze",
  "MarketSortOrder.hint.newAsc": "Najpierw najstarsze",
  "MarketSortOrder.label.price": "Cena",
  "MarketSortOrder.hint.priceAsc": "Koszt: od niskiego do wysokiego",
  "MarketSortOrder.hint.priceDesc": "Od najdroższego do najtańszego",
  "MarketSortOrder.label.amount": "Ilość",
  "MarketSortOrder.hint.amountAsc": "Cena: od niskiej do wysokiej",
  "MarketSortOrder.hint.amountDesc": "Od najliczniejszych do najrzadszych",
  "MarketSortOrder.label.popular": "Popularne",
  "MarketSortOrder.label.popularItems": "Popularne przedmioty",
  "DoubleSellErrorModal.heading": "Błąd",
  "DoubleSellErrorModal.prompt": "Nie udało się sprzedać tego przedmiotu",
  "DoubleSellErrorModal.explanation": "Nie udało się wystawić polecenia sprzedaży, jedna z możliwych przyczyn to sprzedanie lub skonsumowanie w grze\nSpróbuj odświeżyć stronę",
  "DoubleSellErrorModal.okButton": "OK",
  "OnBuyReserveFailModal.heading": "Błąd",
  "OnBuyReserveFailModal.prompt": "Nie udało się wystawić polecenia zakupu",
  "OnBuyReserveFailModal.explanation": "Nie udało się wystawić polecenia zakupu, nie udało się zarezerwować pieniędzy z portfela.",
  "OnBuyReserveFailModal.youNeed1": "Potrzebujesz przynajmniej",
  "OnBuyReserveFailModal.youNeed2": "dla tej operacji",
  "OnBuyReserveFailModal.tryRefresh": "Spróbuj odświeżyć stronę i upewnij się, ze masz wystarczające fundusze",
  "OnBuyReserveFailModal.okButton": "OK",
  "DoubleRateErrorModal.heading": "Error",
  "DoubleRateErrorModal.prompt": "An error occurred while placing a bid",
  "DoubleRateErrorModal.explanation": "Please try to bid later",
  "DoubleRateErrorModal.okButton": "OK",
  "AuctionFinishedErrorModal.heading": "Error",
  "AuctionFinishedErrorModal.prompt": "An error occurred while placing a bid",
  "AuctionFinishedErrorModal.explanation": "The auction was completed",
  "AuctionFinishedErrorModal.okButton": "OK",
  "CancelOrderErrorModal.heading": "Błąd",
  "CancelOrderErrorModal.prompt": "Nie udało się anulować polecenia",
  "CancelOrderErrorModal.explanation": "Możliwe przyczyny to problemy sieciowe albo sprawdzenie w czasie odświeżania polecenia.\nSpróbuj ponownie za chwilę.",
  "CancelOrderErrorModal.cancelButton": "Mam to",
  "OrdersListContainer.Tab.sellOrders": "Polecenia sprzedaży",
  "OrdersListContainer.Tab.buyOrders": "Polecenia kupna",
  "OrdersListContainer.Tab.historyList": "Historia zakupów",
  "ViewOrdersEntry.amounts.remain": "Pozostało",
  "ViewOrdersEntry.amounts.done": "Gotowe",
  "ViewOrdersEntry.order.order": "Zamów",
  "ViewOrdersEntry.order.issued": "Wystawione",
  "ViewOrdersEntry.order.executed": "Wykonane",
  "ViewOrdersEntry.order.pairIsBlocked": "Wymiana zablokowana",
  "ViewOrdersEntry.order.pairWillBlockAfter": "Zostanie zablokowane za",
  "ViewOrdersEntry.order.BUY": "KUP",
  "ViewOrdersEntry.order.SELL": "SPRZEDAJ",
  "ViewOrdersEntry.order.view": "Zobacz",
  "ViewOrdersEntry.order.cancel": "Anuluj",
  "ViewOrdersList.empty.label": "Nic tu nie ma",
  "CancelOrderDialog.heading.youSure": "Jesteś pewien?",
  "CancelOrderDialog.body.youSure": "Czy na pewno chcesz anulować polecenie?",
  "CancelOrderDialog.button.cancel": "Tak, chcę anulować polecenie",
  "CancelAllOrderDialog.button.cancel": "Yes for everyone",
  "CancelOrderDialog.button.noCancel": "Nie",
  "CancelOrderDialog.heading.processing": "Przetwarzanie...",
  "CancelOrderDialog.body.processing": "Przetwarzanie, prosimy czekać",
  "ProfileMenu.MenuItem.Settings": "Ustawienia",
  "ProfileMenu.MenuItem.GaijinAccount": "Profil Gaijin.net",
  "ProfileMenu.MenuItem.AccountSwitch": "Zmień konto",
  "ProfileMenu.MenuItem.Signout": "Wyjście",
  "ProfileMenu.MenuItem.nick": "Twój nick",
  "WalletBlock.balance.text": "Stan konta:",
  "WalletBlock.balance.buyCurrency": "Kup GJN",
  "SecurityBlock.message.title": "Uwierzytelnianie dwuetapowe",
  "SecurityBlock.emailVerified.title": "Email",
  "OrdersHistory.title.buy": "Zlecenia kupna",
  "OrdersHistory.title.sell": "Zlecenia sprzedaży",
  "NotificationsAssets.title": "Powiadomienia",
  "NotificationsAssets.title.today": "Dziś",
  "NotificationsAssets.title.historyFromLastWeek": "W ciągu ostatnich siedmiu dni",
  "NotificationsAssets.title.historyOlder": "Wcześniej",
  "LimitedAccountPanel.2step.pleas": "Zabezpiecz swoje konto, aby uzyskać dostęp do handlu na rynku.",
  "LimitedAccountPanel.2step.protected": "Twoje konto jest chronione. Masz dostęp do handlu na giełdzie.",
  "LimitedAccountPanel.email.pleas": "Potwierdź, aby uzyskać dostęp do handlu na rynku.",
  "LimitedAccountPanel.email.protected": "Twój adres e-mail został potwierdzony, masz dostęp do handlu na rynku.",
  "OrdersListContainer.Opened.Orders": "Otwarte",
  "OrdersListContainer.Closed.Orders": "Zamknięte",
  "ViewOrdersList.empty.labelCollapse": "Zwiń",
  "ViewOrdersList.empty.labelExpand": "Rozwiń",
  "OrdersGraph.plot.title": "Zlecenia kupna/sprzedaży",
  "OrdersGraph.fmt.offers.sell": "oferty kupna\n<br/>cena",
  "OrdersGraph.fmt.andMore.sell": "lub poniżej",
  "OrdersGraph.fmt.offers.buy": "oferty kupna\n<br/>cena",
  "Orders.title.header": "zamówienia",
  "OrdersGraph.fmt.andMore.buy": "i powyżej",
  "PriceHistoryGraph.plot.title": "Historia cen:",
  "PriceHistoryGraph.highlighter.format": "Dnia <strong>%s</strong><br /> cena <strong>%s</strong><br /> ilość <strong>%s</strong><br />",
  "PriceHistoryGraph.plot.shortRange": "Niewielki zakres",
  "PriceHistoryGraph.plot.wholeRange": "Cały czas",
  "DealEventTickerEntry.purchasedFrom": "kupiono przedmiot od",
  "DealEventTickerEntry.for": "dla",
  "BookSellTickerEntry.listedItem": "sale request",
  "BookBuyTickerEntry.created": "stworzono",
  "BookBuyTickerEntry.for": "purchase order",
  "BookAuctionTickerEntry.created": "made a bet",
  "ViewTradeTickerLog.waiting": "Oczekiwanie na nową aktywność...",
  "ViewTradeTickerLog.ticker": "Real time trading...",
  "ViewUserInventory.common.emptyInventory": "W tym momencie inwentarz jest pusty",
  "ViewItemPreviewSidePane.UnselectButton.label": "Filtry",
  "ViewItemPreviewSidePane.TradeButton.label": "Sprzedaj/Kup",
  "ViewItemPreviewSidePane.ViewButton.label": "Widok",
  "ViewItemPreviewSidePane.QuickSellButton.label": "Szybka sprzedaż",
  "ViewInventoryTagCloud.tagTitle.itemsOf": "przedmioty z",
  "ViewInventoryTagCloud.tagTitle.varietiesMarkedAs": "odmiany oznaczone jako",
  "ViewItemSearch.heading.inventory": "Moje przedmioty",
  "WalletBlock.circleSmall.settings": "Ustawienia",
  "WalletBlock.circleSmall.exit": "Wyjście",
  "UserSettingsDialog.language": "Język",
  "UserSettingsDialog.user_settings": "Ustawienia użytkownika",
  "UserSettingsDialog.settings": "Ustawienia",
  "UserSettingsDialog.save_settings": "Zapisz ustawienia",
  "UserSettingsDialog.privacySettings": "Handel",
  "UserSettingsDialog.privacySettingsPublic": "Publiczny",
  "UserSettingsDialog.privacySettingsPrivate": "Prywatny",
  "UserSettingsDialog.interface": "Interface",
  "UserSettingsDialog.interfaceOld": "Legacy",
  "UserSettingsDialog.interfaceNew": "Modern",
  "WalletLimitsIcon.soft_limit": "Limit GJN Coins przekroczony.\nFunkcje odnawiania są ograniczone.",
  "WalletLimitsIcon.hard_limit": "Limit GJN Coins przekroczony.\nFunkcje sprzedawania i odnawiania są ograniczone.",
  "ActionBar.action.ingamePreview": "Obejrzyj w grze",
  "ActionBar.action.previewCopied": "Link skopiowany!",
  "ActionBar.action.previewShare": "Udostępnij",
  "IngamePreview.ask.heading": "Obejrzeć w grze?",
  "IngamePreview.ask.prompt": "Zaraz zobaczysz ten przedmiot w grze",
  "IngamePreview.ask.explanation": "Upewnij się, że klient gry jest uruchomiony z Twoim kontem",
  "IngamePreview.ask.cancelButton": "Nie",
  "IngamePreview.done.heading": "Zakończono",
  "IngamePreview.done.prompt": "",
  "IngamePreview.done.explanation": "Pomyślnie wysłano",
  "IngamePreview.done.okButton": "Ok",
  "IngamePreview.fail.heading": "Niepowodzenie",
  "IngamePreview.fail.prompt": "",
  "IngamePreview.fail.explanation": "Nie można połączyć z klientem gry",
  "IngamePreview.fail.cancelButton": "Ok",
  "IngamePreview.pending.heading": "Jeszcze chwila...",
  "IngamePreview.pending.prompt": "Powiadamianie twojego klienta gry",
  "IngamePreview.pending.explanation": "",
  "IngamePreview.ask.okButton": "Tak, pokaż mi",
  "StaticConfigLoader.caption.error": "Błąd inicjalizacji",
  "StaticConfigLoader.caption.init": "Inicjalizacja",
  "StaticConfigLoader.text.init": "Inicjalizowanie aplikacji...",
  "StaticConfigLoader.error.settings": "Nie udało się wczytać ustawień strony",
  "StaticConfigLoader.error.circuits": "Nie udało się wczytać konfiguracji serwisów",
  "ControlButton.close": "Close",
  "Exit": " ",
  "Legal.TermsConditions": "Regulamin",
  "Legal.TermsService": "Warunki świadczenia usług",
  "Legal.PrivacyPolicy": "Polityka prywatności",
  "Legal.CookiesPolicy": "Pliki cookie – zasady",
  "Legal.TradePolicy": "Polityka handlowa",
  "Legal.TermsConditions.linck": "https://legal.gaijin.net/pl/",
  "Legal.TermsService.linck": "https://legal.gaijin.net/pl/termsofservice/",
  "Legal.PrivacyPolicy.linck": "https://legal.gaijin.net/pl/privacypolicy/",
  "Legal.CookiesPolicy.linck": "https://legal.gaijin.net/pl/cookie/",
  "Legal.TradePolicy.linck": "https://legal.gaijin.net/pl/tradepolicy/",
  "Legal.Copyright": "Gaijin Network Ltd. Wszystkie znaki towarowe, loga i nazwy handlowe są własnością odpowiednich właścicieli.",
  "MarketApp.Navbar.HeadTitle": "Gaijin.Net",
  "MarketApp.Navbar.HeadSubTitle": "Market",
};