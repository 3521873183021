
import { blockedPairsKey } from 'api/rpcAPICall';
import ApplicationEvents from 'components/applicationEvents';
import { BriefBookLoader } from 'components/briefBookLoader';
import { LogStream } from 'generics/common';
import React from 'react';
import Scrollbar from "react-scrollbars-custom";
import ViewLegalFooter from "./legalFooter";
import AuctionAssetPage from "./auctions/auctionAssetPage";
import ItemAssetPage from "./itemAssetPage";

LogStream.enable("VIEWTRADEDIALOG");

export default class ViewTradeDialog extends React.Component {
  constructor(props) {
    super(props);
    this.closeAssetPage = () => ApplicationEvents.emit_async("market.setCurrentAssetByHash");
    this.closeAssetPageOnEsc = (e) => {
      if ((e.key == "Esc") || (e.key == "Escape")) this.closeAssetPage();
    }
    this.stopPropagation = (e) => e.stopPropagation();
    this.state = {
      brief: {
        totalSellDepth: 0,
        totalBuyDepth: 0,
        ordersSell: [],
        ordersBuy: [],
        auction: null
      }
    };
    this.onUpdateBriefBook = (resp) => this.setState({ 'brief': resp });
  }
  componentDidMount() {
    document.addEventListener("keydown", this.closeAssetPageOnEsc);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.closeAssetPageOnEsc);
  }

  render() {
    const { brief } = this.state;
    const { appid, classInfo, ctxid, totalAmount, instances, amount } = this.props;
    const pairKey = ((classInfo.marketable || classInfo.auctionable) && classInfo.market_hash_name) ?
      blockedPairsKey({ appId: appid, marketName: classInfo.market_hash_name }) : "";

    const exchangeWith = classInfo.descriptions.filter(item => item?.type == "exchange_with");
    const droppedItem = classInfo.descriptions.filter(item => item?.type == "dropped_item");
    const isAuction = !!(classInfo?.auctionable && brief.auction?.end_time);

    return (
      <React.Fragment>
        <Scrollbar
          id="content"
          ref={this.props.scrollbar}
          style={{ display: this.props.hidden ? "none" : "" }}
          defaultStyles={false}
          scrollX={false}
        >
          <div key={(classInfo || {}).classid}
               className="appScreen"
               screen="commodity"
               onClick={this.stopPropagation}
          >
            { isAuction &&
              <AuctionAssetPage
                pairKey={pairKey}
                classInfo={classInfo}
                appid={appid}
                brief={brief}
                exchangeWith={exchangeWith}
                droppedItem={droppedItem}
                amount={amount}
                ctxid={ctxid}
                totalAmount={totalAmount}
                instances={instances}
              />
            }

            { !isAuction &&
              <ItemAssetPage
                pairKey={pairKey}
                classInfo={classInfo}
                appid={appid}
                brief={brief}
                exchangeWith={exchangeWith}
                droppedItem={droppedItem}
                amount={amount}
                ctxid={ctxid}
                totalAmount={totalAmount}
                instances={instances}
              />
            }

            <ViewLegalFooter />
          </div>
        </Scrollbar>
        <BriefBookLoader
          onUpdate={this.onUpdateBriefBook}
          market_name={classInfo.market_hash_name}
          appid={appid}
        />
      </React.Fragment>
    );
  }
}