import { _I_ } from 'components/translate';
import { IconFontAwesome } from 'generics/icons';
import { CloseSquareIcon } from 'generics/marketIcons';
import React from 'react';

export function TrivialDialog({
    prefixL10N,
    panelType="default",
    mixClasses=[],
    bodyIcon,

    onCloseButton,

    onOkButton,
    okButtonIcon="check",

    onDefaultButton,
    defaultButtonIcon="info",

    onCancelButton,
    cancelButtonIcon="times",

    children,
  })   {
    return (
[
          <div className="header">
              <div className="title">
            {_I_(`${prefixL10N}.heading`)}
            {" "}
              </div>
            {!!onCloseButton &&
            <div className="controls">
            <div className="controlButton close baloon baloonLeft baloonSide"
                 onClick={onCloseButton}
                 data-baloon={_I_("ControlButton.close")}
                 type="button">
                <CloseSquareIcon />
            </div>
            </div>}
          </div>,

          <div className="body-panel">
              <div className="text-center">
                <div className="settingsName"><b>{_I_(`${prefixL10N}.prompt`)}</b></div>
              </div>

                {!!bodyIcon && <div className="text-center">{bodyIcon}</div>}

              <div className="text-center">
                <div className="settingsName"><b>{_I_(`${prefixL10N}.explanation`)}</b></div>
              </div>
              <div className="text-center">
                  <div className="settingsName"><b>{children}</b></div>
              </div>

            <div className="text-center">
              <div className="well">
                {!!onOkButton &&
                  <div className="buy"  onClick={onOkButton}  >
                    <IconFontAwesome type={okButtonIcon} />
                    {" "}
                    {_I_(`${prefixL10N}.okButton`)}
                  </div>}

                {!!onDefaultButton &&
                  <div className="buy"  onClick={onDefaultButton}  >
                    <IconFontAwesome type={defaultButtonIcon} />
                    {" "}
                    {_I_(`${prefixL10N}.defaultButton`)}
                  </div>}

                {!!onCancelButton &&
                  <div className="refuse"  onClick={onCancelButton}  >
                    <IconFontAwesome type={cancelButtonIcon} />
                    {" "}
                    {_I_(`${prefixL10N}.cancelButton`)}
                  </div>}

              </div>
            </div>
          </div>
]
      );
}

