
import { _I_ } from 'components/translate';
import { CloseIcon } from './marketIcons';
import React from 'react';



export class CloseButton extends React.Component {
  render() {
    return (
      <div className="controls">
        <div
          className="controlButton close baloon baloonLeft baloonSide"
          data-baloon={_I_("ControlButton.close")}
          type="button"
          onClick={this.props.onClick}
        >
          <CloseIcon />
        </div>
      </div>
    );
  }
}


export class CommonButton extends React.Component {
  render() {
    const classes = this.props.classes.filter(Boolean).join(' ');
    return (
      <button
        className={classes}
        disabled={this.props.disabled}
        title={this.props.title}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </button>
    );
  }
}


/*const BootButtonVariants = {
      "default" : "btn-default",
      "primary" : "btn-primary",
      "success" : "btn-success",
      "info"    : "btn-info",
      "warning" : "btn-warning",
      "danger"  : "btn-danger",
    };

function getBootButtonVariantClass( variant )  {
  if ( !variant )    return "btn-default";

  const variantClass = BootButtonVariants[ variant ];
  if ( !variantClass )  {
    console.error("invalid BootButton variant ", variant );
    return "btn-default";
  }

  return variantClass;
}

const BootButtonSizes =  {
      "large"   : "btn-lg",
      "small"   : "btn-sm",
      "xsmall"  : "btn-xs",
    };
function getBootButtonSizeClass( size )  {
  if ( !size )    return "";

  const sizeClass = BootButtonSizes[ size ];
  if ( !sizeClass )  {
    console.error("invalid BootButton size ", size );
    return "";
  }

  return sizeClass;
}*/


function getBootButtonActiveClass(active) {
  if (!active) return "";

  return "active";
}


function getBootButtonLinkClass(link) {
  if (!link) return "";

  return "btn-link";
}


function getBootButtonBlockClass(block) {
  if (!block) return "";

  return "btn-block";
}


function getBootButtonHiddenClass(hidden) {
  if (!hidden) return "";

  return "hidden";
}



export class BootButton extends React.Component {

  render() {
    let classes = ["button cancel",
      getBootButtonActiveClass(this.props.active),
      getBootButtonLinkClass(this.props.link),
      getBootButtonBlockClass(this.props.block),
      getBootButtonHiddenClass(this.props.hidden),
      this.props.mixClasses
    ];

    return (
      <CommonButton
        classes={classes}
        disabled={this.props.disabled}
        title={this.props.title}
        onClick={this.props.onClick}
      >{this.props.children}</CommonButton>
    );
  }
}

