import { AjaxCall } from 'api/basicApi';
import { LogStream } from 'generics/common';
import { SettingsInjections } from 'generics/settingsInjections';
import MarketStore from 'stores/market';

// LogStream.enable( "GAMENOTIFYDEBUG" );
const loggerDebug = LogStream( "GAMENOTIFYDEBUG" )

////////////////////////////////////////////
// class GameNotifyCall
class GameNotifyCall  extends AjaxCall  {
  constructor ( command )  {
    super( command )

    this.append( "action", command );
    this.appendHeader( "Authorization", "Bearer " + MarketStore.token);
  }

  normalizeResponse( resp )  {
    return resp.result || resp.response || resp;
  }


  getInterfaceUrl()  {
    return SettingsInjections.getUsedCircuit().game_notify_server;
  }
};


/////////
// Game Notification Call
export function MarketNotifyGame( action, appId, params )  {
  const {  game_notify_server  } = SettingsInjections.getUsedCircuit();
  if (!game_notify_server)  {
    const resp = new Error("NOT_IMPLEMENTED");
    loggerDebug.info(`MarketNotifyGame(`,action, appId, params,`) - cancelled:`, resp);
    return Promise.reject(resp);
  }

  const callNotifyGame = new GameNotifyCall( action );

  callNotifyGame.setRetryPolicy( 2, 500, 500 );
  callNotifyGame.append( "params", params );

  return callNotifyGame.RPC().then( (resp) =>  {
      loggerDebug.info(`MarketNotifyGame(`, action, appId, params ,`) - got response:`, resp);
      return resp;
    });
}


/////////
// ViewItem Game Notification Call
export function MarketNotifyGameViewItem( appId, assetClass )  {
  return MarketNotifyGame( "market_view_item", +appId, { appId, assetClass } );
}

