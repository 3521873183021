import { spawnDialogWindow } from "./dialogHolder";
import {_I_} from "./translate";
import React, {Fragment} from "react";
import {IconFontAwesome} from "../generics/icons";
import {CloseButton} from "../generics/buttons";

export class UnavailableItemModal extends React.Component {
  constructor(props) {
    super(props);

    this.removeCurrentDialog = this.removeCurrentDialog.bind(this);
  }


  static spawnProcessingModal() {
    spawnDialogWindow(UnavailableItemModal, {});
  }

  removeCurrentDialog() {
    this.props.removeModal();
  }


  render() {
    const { removeModal } = this.props;
    return (
      <Fragment>
        <div className="header">
          <CloseButton onClick={removeModal} />
        </div>

        <div className="body-panel">
          <div className="text-center">
            <div className='image'>
              <div className='settingsName'>
                <b>{_I_("ProcessingDialog.notItem")}</b>
              </div>
            </div>
          </div>

          <div className="well">
            <div className="buyOrder" onClick={removeModal}>
              <IconFontAwesome type="remove" />&nbsp;{_I_("ControlButton.close")}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

}