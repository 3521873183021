export const plural = (number, words) => {
  return Math.abs(parseInt(number, 10)) === 1 ? words[0] : words[1];
};

export const locale = {
  "ViewItemPreviewSidePane.quantity.inventory": " ",
  "ViewItemPreviewSidePane.quantity.ordersInventory": " 库存中的道具",
  "ViewItemPreviewSidePane.quantity.count": " 正在出售",
  "ViewItemPreviewSidePane.quantity.countBuy": " 购买订单",
  "ViewItemPreviewSidePane.quantity.items": " 道具",
  "ViewMarketedItem.SoldOutText": "售完",
  "ViewMarketedItem.SoldOutTip": "没有出售订单",
  "ViewMarketedItem.NoBuyText": "没有购买订单",
  "ViewMarketedItem.SellingFrom": "出售来源…",
  "ViewMarketedItem.SellingMore": " 或以上",
  "ViewMarketedItem.BuyingMore": " 或以下",
  "ViewMarketedItem.tradableAfter": "可在时间过后上架出售：",
  "ViewItemSearch.heading.showAll": "显示所有列表",
  "ViewItemSearch.heading.orders": "我的订单",
  "ViewItemSearch.heading.marketSell": "出售订单",
  "ViewItemSearch.heading.marketBuy": "购买订单",
  "ViewItemSearch.heading.marketAuctions": "拍卖",
  "ViewItemSearch.title.marketAuction": "拍卖会",
  "ViewItemSearch.title.ending": "直到结束",
  "ViewItemSearch.title.finished": "拍卖结束",
  "ViewItemSearch.heading.settings": "设置",
  "ViewMarketedItem.button.more": "详情",
  "ViewItemSearch.heading.resultsFor": "显示结果：",
  "ViewItemSearch.stub.empty": "清空搜索结果",
  "ViewItemSearch.heading.tooltip": "您的出价被超越了",
  "ViewItemSearch.heading.tooltip.win": "您的出价成功了",
  "ViewItemPopup.committed.SELL": "销售完成",
  "ViewItemPopup.committed.BUY": "购买完成",
  "ViewItemPopup.committed.BID": "出价完成",
  "ViewItemPopup.application.BUY": "创建购买订单",
  "ViewItemPopup.application.SELL": "创建销售申请",
  "ViewItemPopup.application.BID": "出价已做",
  "ViewItemPopup.canceled.BUY": "购买订单已取消",
  "ViewItemPopup.canceled.SELL": "销售请求已取消",
  "ViewItemPopup.canceled.BID": "出价已取消",
  "ViewItemPopup.showAll.button": "查看全部",
  "ViewItemAssets.readAll.button": "全部标记为已查看",
  "ViewItemPopup.heading.noHistory": "尚无通知",
  "ViewItemPopup.heading.unseenHistory": "没有新的通知",
  "ViewItemSearch.termsPlaceholder": "在此处搜索",
  "ViewItemSearch.label.search": "搜索物品",
  "ViewMarketedItem.lovestPrice": "从 ",
  "ViewMarketedItem.beforePrice": "前 ",
  "ViewMarketedItem.yourBid": "您的出价 ",
  "ViewMarketedItem.bid": "出价 ",
  "ViewItemSearch.heading.exchange": "Home",
  "ViewItemSearch.heading.light": "亮色调",
  "ViewItemSearch.heading.dark": "暗色调",
  "ViewItemSearch.filteringAnnounce": "您必须选择游戏分类才能使用筛选功能。",
  "ViewItemSearch.ordersBuy": "购买",
  "ViewItemSearch.ordersSell": "出售",
  "ViewItemSearch.ordersOpen": "查看所有记录",
  "MarketApp.Navbar.inventoryLink": "道具清单",
  "MarketApp.Navbar.ordersLink": "我的订单",
  "MarketApp.Navbar.marketLink": "市场",
  "MarketApp.Navbar.settingsLink": "设置",
  "MarketApp.Navbar.replenishLink": "充值余额",
  "MarketApp.Navbar.balanceHistoryLink": "余额历史",
  "MarketApp.Navbar.buyCoinsLink": "充值 Gaijin 币",
  "MarketApp.Navbar.profileLink": "档案",
  "MarketApp.Navbar.reloginLink": "重新登录或更改帐户",
  "MarketApp.Navbar.toOldVersion": "返回传统版",
  "MarketApp.Navbar.linkFeedback": "发送反馈",
  "ViewTradeDialog.caption": "交易",
  "ViewTradeDialog.button.back": "返回",
  "NotAllowedMessage.caption": "无法",
  "NotAllowedMessage.body.h1": "访问交易所",
  "NotAllowedMessage.body.h3": "您的帐户目前无法访问交易所",
  "NotAllowedMessage.relogin": "更改账户",
  "CountryDeniedMessage.caption": "无法",
  "CountryDeniedMessage.body.notAllowed": "无法访问交易所",
  "CountryDeniedMessage.body.unclearStatus": "由于您所在国家/地区的市场交易法律状态不明确，因此访问交易所功能已禁用",
  "LandingMessage.caption": "验证",
  "LandingMessage.body": "正在完成验证…",
  "TokenExpiredMessage.caption": "验证已失效",
  "TokenExpiredMessage.body.h1": "验证已失效",
  "TokenExpiredMessage.body.h3": "我们能做的不多，但您仍然可以",
  "TokenExpiredMessage.relogin": "重新登录…",
  "AuthErrorMessage.caption": "验证失败",
  "AuthErrorMessage.body.h1": "无法检查验证",
  "AuthErrorMessage.body.code": "代码：",
  "AuthErrorMessage.body.desc": "我们能做的不多，但您仍然可以",
  "AuthErrorMessage.relogin": "重新登录…",
  "AuthorizationRequiredMessage.caption": "需要验证",
  "AuthorizationRequiredMessage.body.h3.1": "你即将进入",
  "AuthorizationRequiredMessage.body.h3.2": "Gaijin 交易所",
  "AuthorizationRequiredMessage.body.h3.3": "但你需要验证才能进一步进行",
  "AuthorizationRequiredMessage.login": "通过 Gaijin.Net 登录",
  "CheckingAuthMessage.caption": "验证",
  "CheckingAuthMessage.body": "正在检查验证，请耐心等待",
  "CancelOrdersPanel.cancelError": "\n取消订单失败 ",
  "CancelOrdersPanel.loading": "检查有效订单",
  "CancelOrdersPanel.done": "操作已完成。",
  "CancelOrdersPanel.errors": "操作出错：\n",
  "CancelOrdersPanel.loaded.youHave": "目前拥有 ",
  "CancelOrdersPanel.loaded.sellOrders": " 出售订单总计 ",
  "CancelOrdersPanel.loaded.buyOrders": " 购买订单总计 ",
  "CancelOrdersPanel.loaded.and": " 与 ",
  "CancelOrdersPanel.loaded.shouldCancel": " 您必须全部取消。 ",
  "CancelOrdersPanel.loaded.doCancel": "取消订单",
  "CancelOrdersPanel.cancelling.processing": "处理中",
  "CancelOrdersPanel.cancelling.outOf": " / ",
  "CancelOrdersPanel.cancelling.sellOrders": " 出售订单",
  "CancelOrdersPanel.cancelling.buyOrders": " 购买订单",
  "ClassInfoDescriptions.dropped_item.block_label": "包含以下任意之一：",
  "ClassInfoDescriptions.dropped_item.stage-unknown": "未知",
  "ClassInfoDescriptions.dropped_item.stage-pending": "…加载中",
  "ClassInfoDescriptions.text.block_label": "",
  "ClassInfoDescriptions.dropped_bundle.block_label": " ",
  "ClassInfoDescriptions.buttonLabel.top": "展开",
  "ClassInfoDescriptions.buttonLabel.hide": "隐藏",
  "ClassInfoDescriptions.buttonLabel.bottom": "全部",
  "ClassInfoDescriptions.dropped_text.description": "Description",
  "ClassInfoUtil.fake_name.pending": "等待中",
  "ClassInfoUtil.fake_name.broken": "已删除物品",
  "ClassInfoUtil.SynthTag.marketable1": "可在交易所交易",
  "ClassInfoUtil.SynthTag.marketable0": "不可在交易所交易",
  "ClassInfoUtil.SynthTag.tradable1": "可交易",
  "ClassInfoUtil.SynthTag.tradable0": "不可交易",
  "ClassInfoUtil.SynthTag.commodity1": "商品",
  "ClassInfoUtil.SynthTag.commodity0": "非商品",
  "ConfirmBuyDialog.title": "下达购买订单",
  "BuyProcessingDialog.title": "处理中…",
  "BuyProcessingDialog.pleaseWait": "处理中，请稍候",
  "ProcessingDialog.notItem": "请求的物品在市场上未找到或不可交易",
  "SellDialog.title": "将物品上架待售",
  "SellProcessingDialog.title": "处理中…",
  "SellProcessingDialog.pleaseWait": "处理中，请稍候",
  "LimitedAccountPanel.2step.reason": "需要设置双重验证",
  "LimitedAccountPanel.2step.link": "有关双重验证的更多信息",
  "LimitedAccountPanel.2step.href": "https://support.gaijin.net/hc/en-us/articles/203623622-How-to-set-up-the-Two-Step-Authorization-Google-Authenticator-",
  "LimitedAccountPanel.email.reason": "需要确认电子邮件地址",
  "LimitedAccountPanel.email.link": "前往档案",
  "LimitedAccountPanel.2step.toPlug": "启用",
  "LimitedAccountPanel.email.href": "https://store.gaijin.net/user.php",
  "LimitedAccountPanel.title": "帐户受到限制，原因为：",
  "LimitedAccountPanel.conclusion": "更改安全设置后，你应该退出并重新登录以使更改生效",
  "LimitedAccountPanel.relogin": "重新登录…",
  "EmailVerificationStateIcon.success": "帐户已确认，电子邮件已验证",
  "EmailVerificationStateIcon.warning": "帐户未确认，电子邮件未验证",
  "EmailVerificationStateIcon.danger": "帐户未确认，电子邮件需要验证",
  "TwoFactorStateIcon.success": "帐户已安全进行双重验证",
  "TwoFactorStateIcon.warning": "不安全帐户，双重验证已禁用",
  "TwoFactorStateIcon.danger": "需要双重验证",
  "ViewInventoryTextFilter.common.label": "是否在查找特定物品？",
  "ItemBuyEditor.label.wheelAmountInput": "可以使用鼠标滚轮转动数量，\nalt+滚轮 = 10 倍",
  "ItemBuyEditor.label.wheelInput": "可以使用鼠标滚轮转动价格，\nalt+滚轮 = 10 倍，\nshift+滚轮 = 0.1 倍",
  "ItemBuyEditor.note.posAmount": "数量应为正数",
  "ItemBuyEditor.note.maxAmount": "数量超过 1000 个物品的上限",
  "ItemBuyEditor.note.invalidAmount": "数量值无效",
  "ItemBuyEditor.note.minPrice": "最低价格为",
  "ItemBuyEditor.note.maxTotal": "订单总数超过最大值",
  "ItemBuyEditor.note.invalidPrice": "价格值无效",
  "ItemBuyEditor.note.mustAgree": "必须同意使用条款",
  "ItemBuyEditor.note.insufficentFunds0": "余额不足，请充值足数的 Gaijin 币",
  "ItemBuyEditor.note.insufficentFunds1": "才能操作此订单",
  "ItemBuyEditor.label.buyCouns": "购买",
  "ItemBuyEditor.label.submit": "下单",
  "ItemBuyEditor.label.price": "出价（每个物品）：",
  "ItemBuyEditor.label.amount": "购买数量：",
  "ItemBuyEditor.label.totalPrice": "总价（包含费用）：",
  "ItemBuyEditor.label.agree": "我同意 Gaijin Store",
  "ItemBuyEditor.label.ToU": "使用条款",
  "MarketDepthTotals.noOrders.sell": "没有出售订单",
  "MarketDepthTotals.noOrders.buy": "没有购买订单",
  "MarketDepthTotals.starting.sell": " 为 ",
  "MarketDepthTotals.starting.buy": " 从 ",
  "MarketDepthTotals.andMore.sell": " 更低",
  "MarketDepthTotals.andMore.buy": " 更高",
  "MarketDepthTotals.prevew.sell": "正在购买： ",
  "MarketDepthTotals.prevew.buy": "正在出售： ",
  "MarketDepthOwnWarning.sell": "你有等待中的出售订单",
  "MarketDepthOwnWarning.buy": "你有等待中的购买订单",
  "MarketDepthTable.caption.price": "价格",
  "MarketDepthTable.caption.quantity": "数量",
  "MarketDepthTable.ellipsis.sell": "及以上",
  "MarketDepthTable.ellipsis.buy": "及以下",
  "ItemMarketDetails.buyButton": "购买",
  "ItemMarketDetails.sellButton": "出售",
  "ItemMarketDetails.nothingToSellButton": "没有要出售的物品",
  "ItemMarketDetails.makeAuction": "创建拍卖",
  "ItemMarketDetails.makeBid": "出价",
  "ItemMarketDetails.bid": "出价",
  "ItemMarketDetails.yourBid": "您的出价：",
  "ItemMarketDetails.notifications.yourBid": "您的出价",
  "ItemMarketDetails.yourBid.short": "你的",
  "ItemMarketDetails.yourBid.win": "赢得",
  "ItemMarketDetails.yourBid.loose": "输了",
  "ItemSellEditor.label.priceStep": "价格步长",
  "ItemSellEditor.label.endDate": "结束日期",
  "limitedAccountMessage.requires": "没有要出售的物品",
  "limitedAccountMessage.2step": "二步验证",
  "limitedAccountMessage.verifiedEmail": "电子邮件已验证",
  "limitedAccountMessage.and": " 与 ",
  "ItemSellEditor.label.wheelInput": "可以使用鼠标滚轮转动价格，\nalt+滚轮 = 10 倍，\nshift+滚轮 = 0.1 倍",
  "ItemSellEditor.note.minPrice": "最低价格为",
  "ItemSellEditor.note.maxPrice": "允许的最高售价",
  "ItemSellEditor.note.maxTotals": "订单总数超过最大值",
  "ItemSellEditor.note.maxBalance": "总余额不应超过",
  "ItemSellEditor.note.mustAgree": "必须同意使用条款",
  "ItemSellEditor.label.price": "买方支付（包括费用）：",
  "ItemSellEditor.label.wished": "在扣除费用后，您会收到：",
  "ItemSellEditor.label.agree": "我同意 Gaijin Store",
  "ItemSellEditor.label.ToU": "使用条款",
  "ItemSellEditor.label.submit": "确认上架销售",
  "ItemSellEditor.currentBalance.before": "目前拥有",
  "ItemSellEditor.currentBalance.after": "在您的余额中",
  "ItemSellEditor.sellOrdersTotal.before": "外加",
  "ItemSellEditor.sellOrdersTotal.after": "位于出售订单中",
  "ItemSellEditor.buyOrdersTotal.before": "和",
  "ItemSellEditor.buyOrdersTotal.after": "位于购买订单中",
  "ItemSellEditor.label.amount": "您想出售的物品数量：",
  "AppFilterEditor.label.byTags": "Filter",
  "AppFilterEditor.label.clearFilter": "清除筛选",
  "AppFilterEditor.label.byTitles": "按名称筛选",
  "AppFilterEditor.label.allFilter": "全部游戏",
  "AppFilterEditor.label.authenticity": "真实性",
  "AppFilterEditor.message.filter": "每款游戏均有各自的筛选器。",
  "AppFilterEditor.message.game": "选择游戏:",
  "ViewItemDetailed.restriction.amount.items": " 道具",
  "ViewItemDetailed.restriction.nonMarketable": "不可出售",
  "ViewItemDetailed.restriction.blockedAfter": "将在此后锁定",
  "ViewItemDetailed.restriction.blockedPair": "交易已锁定",
  "ViewItemDetailed.restriction.expiresAt1": "将在此后消失",
  "ViewItemDetailed.restriction.expiresAt2": "",
  "ViewItemDetailed.restriction.endedAt": "End of auction",
  "ViewItemDetailed.restriction.amount": "Lot contains",
  "ViewItemDetailed.restriction.pieces": "pcs.",
  "ViewItemDetailed.restriction.startPrice": "Starting price",
  "ViewItemDetailed.restriction.days": "d",
  "ViewItemDetailed.restriction.hours": "hrs",
  "ViewItemDetailed.restriction.minutes": "min",
  "ViewItemDetailed.restriction.leadingBids": "leading bids",
  "ViewItemDetailed.restriction.noBids": "There are no bids yet. Place your first bid in this auction!",
  "ViewItemDetailed.restriction.delayTrade": "Once purchased, the item will be available for sale through",
  "ViewItemDetailed.restriction.unclearStatus": "由于您所在国家/地区的奖励情况的法律状态不明确，因此帐户中所有带有百宝箱和钥匙的交易均被锁定。",
  "MarketSortOrder.label.name": "名称",
  "MarketSortOrder.hint.newDesc": "新的在前",
  "MarketSortOrder.hint.newAsc": "旧的在前",
  "MarketSortOrder.label.price": "价格",
  "MarketSortOrder.hint.priceAsc": "价格从低到高",
  "MarketSortOrder.hint.priceDesc": "价格从高到低",
  "MarketSortOrder.label.amount": "数量",
  "MarketSortOrder.hint.amountAsc": "数量从低到高",
  "MarketSortOrder.hint.amountDesc": "数量从高到低",
  "MarketSortOrder.label.popular": "人气",
  "MarketSortOrder.label.popularItems": "人气道具",
  "DoubleSellErrorModal.heading": "错误",
  "DoubleSellErrorModal.prompt": "出售当前物品失败",
  "DoubleSellErrorModal.explanation": "下达出售订单失败，可能的原因之一：物品已于游戏中售出或消耗\n请尝试刷新页面",
  "DoubleSellErrorModal.okButton": "确定",
  "OnBuyReserveFailModal.heading": "错误",
  "OnBuyReserveFailModal.prompt": "下单失败",
  "OnBuyReserveFailModal.explanation": "下单失败，无法使用账号余额。",
  "OnBuyReserveFailModal.youNeed1": "您需要至少",
  "OnBuyReserveFailModal.youNeed2": "才能进行此操作",
  "OnBuyReserveFailModal.tryRefresh": "请尝试刷新页面，并确保拥有足够余额",
  "OnBuyReserveFailModal.okButton": "确定",
  "DoubleRateErrorModal.heading": "Error",
  "DoubleRateErrorModal.prompt": "An error occurred while placing a bid",
  "DoubleRateErrorModal.explanation": "Please try to bid later",
  "DoubleRateErrorModal.okButton": "OK",
  "AuctionFinishedErrorModal.heading": "Error",
  "AuctionFinishedErrorModal.prompt": "An error occurred while placing a bid",
  "AuctionFinishedErrorModal.explanation": "The auction was completed",
  "AuctionFinishedErrorModal.okButton": "OK",
  "CancelOrderErrorModal.heading": "错误",
  "CancelOrderErrorModal.prompt": "取消订单失败",
  "CancelOrderErrorModal.explanation": "可能的原因是网络问题，或订单处于更新状态。\n请稍后再试。",
  "CancelOrderErrorModal.cancelButton": "了解",
  "OrdersListContainer.Tab.sellOrders": "出售订单",
  "OrdersListContainer.Tab.buyOrders": "购买订单",
  "OrdersListContainer.Tab.historyList": "订单历史",
  "ViewOrdersEntry.amounts.remain": "剩余",
  "ViewOrdersEntry.amounts.done": "完成",
  "ViewOrdersEntry.order.order": "订单",
  "ViewOrdersEntry.order.issued": "已提出",
  "ViewOrdersEntry.order.executed": "已完成",
  "ViewOrdersEntry.order.pairIsBlocked": "交易已锁定",
  "ViewOrdersEntry.order.pairWillBlockAfter": "将在此后锁定：",
  "ViewOrdersEntry.order.BUY": "购买",
  "ViewOrdersEntry.order.SELL": "出售",
  "ViewOrdersEntry.order.view": "查看",
  "ViewOrdersEntry.order.cancel": "取消",
  "ViewOrdersList.empty.label": "此处无内容",
  "CancelOrderDialog.heading.youSure": "是否确定？",
  "CancelOrderDialog.body.youSure": "是否确定取消订单？",
  "CancelOrderDialog.button.cancel": "是的，我要取消订单",
  "CancelAllOrderDialog.button.cancel": "是且不再确认",
  "CancelOrderDialog.button.noCancel": "否",
  "CancelOrderDialog.heading.processing": "处理中…",
  "CancelOrderDialog.body.processing": "处理中，请稍候",
  "ProfileMenu.MenuItem.Settings": "设置",
  "ProfileMenu.MenuItem.GaijinAccount": "Gaijin.net 账号档案",
  "ProfileMenu.MenuItem.AccountSwitch": "更换账号",
  "ProfileMenu.MenuItem.Signout": "退出",
  "ProfileMenu.MenuItem.nick": "昵称",
  "WalletBlock.balance.text": "余额：",
  "WalletBlock.balance.buyCurrency": "充值 Gaijin 币",
  "SecurityBlock.message.title": "二步验证",
  "SecurityBlock.emailVerified.title": "邮箱",
  "OrdersHistory.title.buy": "购买订单",
  "OrdersHistory.title.sell": "出售订单",
  "NotificationsAssets.title": "通知",
  "NotificationsAssets.title.today": "今天",
  "NotificationsAssets.title.historyFromLastWeek": "在过去的七天里",
  "NotificationsAssets.title.historyOlder": "更早",
  "LimitedAccountPanel.2step.pleas": "保护您的账号在交易所进行交易。",
  "LimitedAccountPanel.2step.protected": "账号状态安全，可以在交易所进行交易。",
  "LimitedAccountPanel.email.pleas": "确认以在交易所进行交易。",
  "LimitedAccountPanel.email.protected": "邮箱已确认，您现在可以可以在交易所进行交易。",
  "OrdersListContainer.Opened.Orders": "开放",
  "OrdersListContainer.Closed.Orders": "关闭",
  "ViewOrdersList.empty.labelCollapse": "折叠",
  "ViewOrdersList.empty.labelExpand": "展开",
  "OrdersGraph.plot.title": "购买/出售订单",
  "OrdersGraph.fmt.offers.sell": "出售报价\n<br/>价格",
  "OrdersGraph.fmt.andMore.sell": "或更低",
  "OrdersGraph.fmt.offers.buy": "购买报价\n<br/>价格",
  "Orders.title.header": "订单",
  "OrdersGraph.fmt.andMore.buy": "或更高",
  "PriceHistoryGraph.plot.title": "价格历史：",
  "PriceHistoryGraph.highlighter.format": "于 <strong>%s</strong><br /> 价格<strong>%s</strong><br /> 数量 <strong>%s</strong><br />",
  "PriceHistoryGraph.plot.shortRange": "短期",
  "PriceHistoryGraph.plot.wholeRange": "全程",
  "DealEventTickerEntry.purchasedFrom": "已购买此道具，来源为",
  "DealEventTickerEntry.for": "花费",
  "BookSellTickerEntry.listedItem": "sale request",
  "BookBuyTickerEntry.created": "已创建",
  "BookBuyTickerEntry.for": "purchase order",
  "BookAuctionTickerEntry.created": "made a bet",
  "ViewTradeTickerLog.waiting": "正在等待新操作…",
  "ViewTradeTickerLog.ticker": "Real time trading...",
  "ViewUserInventory.common.emptyInventory": "目前您的库存为空",
  "ViewItemPreviewSidePane.UnselectButton.label": "筛选",
  "ViewItemPreviewSidePane.TradeButton.label": "购买/出售",
  "ViewItemPreviewSidePane.ViewButton.label": "预览",
  "ViewItemPreviewSidePane.QuickSellButton.label": "快速出售",
  "ViewInventoryTagCloud.tagTitle.itemsOf": "道具",
  "ViewInventoryTagCloud.tagTitle.varietiesMarkedAs": "道具标签为",
  "ViewItemSearch.heading.inventory": "我的库存",
  "WalletBlock.circleSmall.settings": "设置",
  "WalletBlock.circleSmall.exit": "退出",
  "UserSettingsDialog.language": "语言设置",
  "UserSettingsDialog.user_settings": "用户设置",
  "UserSettingsDialog.settings": "设置",
  "UserSettingsDialog.save_settings": "保存设置",
  "UserSettingsDialog.privacySettings": "交易模式",
  "UserSettingsDialog.privacySettingsPublic": "公开",
  "UserSettingsDialog.privacySettingsPrivate": "私密",
  "UserSettingsDialog.interface": "Interface",
  "UserSettingsDialog.interfaceOld": "Legacy",
  "UserSettingsDialog.interfaceNew": "Modern",
  "WalletLimitsIcon.soft_limit": "账号 GJN 币持有数量超过限制。\n充值功能受限。",
  "WalletLimitsIcon.hard_limit": "账号 GJN 币持有数量超过限制。\n充值及出售功能受限。",
  "ActionBar.action.ingamePreview": "在游戏中查看",
  "ActionBar.action.previewCopied": "链接已复制！",
  "ActionBar.action.previewShare": "分享",
  "IngamePreview.ask.heading": "是否在游戏中查看此项目？",
  "IngamePreview.ask.prompt": "您将在游戏中查看此物品",
  "IngamePreview.ask.explanation": "请确保客户端正在使用相同账号运行",
  "IngamePreview.ask.cancelButton": "取消",
  "IngamePreview.done.heading": "完成",
  "IngamePreview.done.prompt": "",
  "IngamePreview.done.explanation": "已成功发送",
  "IngamePreview.done.okButton": "确定",
  "IngamePreview.fail.heading": "已失败",
  "IngamePreview.fail.prompt": "",
  "IngamePreview.fail.explanation": "无法联系游戏客户端",
  "IngamePreview.fail.cancelButton": "确定",
  "IngamePreview.pending.heading": "请稍候…",
  "IngamePreview.pending.prompt": "正在通知你的游戏客户端",
  "IngamePreview.pending.explanation": "",
  "IngamePreview.ask.okButton": "是的，请显示",
  "StaticConfigLoader.caption.error": "初始化错误",
  "StaticConfigLoader.caption.init": "初始化",
  "StaticConfigLoader.text.init": "正在初始化应用程序…",
  "StaticConfigLoader.error.settings": "无法加载网站设置",
  "StaticConfigLoader.error.circuits": "无法加载服务配置",
  "ControlButton.close": "关闭",
  "Exit": "Change account",
  "Legal.TermsConditions": "条款与条件",
  "Legal.TermsService": "服务条款",
  "Legal.PrivacyPolicy": "隐私政策",
  "Legal.CookiesPolicy": "Cookie 政策",
  "Legal.TradePolicy": "交易政策",
  "Legal.TermsConditions.linck": "https://legal.gaijin.net/zh/",
  "Legal.TermsService.linck": "https://legal.gaijin.net/zh/termsofservice/",
  "Legal.PrivacyPolicy.linck": "https://legal.gaijin.net/zh/privacypolicy/",
  "Legal.CookiesPolicy.linck": "https://legal.gaijin.net/zh/cookie/",
  "Legal.TradePolicy.linck": "https://legal.gaijin.net/zh/tradepolicy/",
  "Legal.Copyright": "Gaijin Network Ltd. 所有商标，标识及品名均为其各自所有者的财产。",
  "MarketApp.Navbar.HeadTitle": "Gaijin.Net",
  "MarketApp.Navbar.HeadSubTitle": "Market",
};
