
import { ItemClassInfoResolver } from 'api/assetAPICall';
import ApplicationEvents from 'components/applicationEvents';
import { _I_ } from 'components/translate';
import { classMixer } from 'generics/utils';
import React from 'react';
////////////////////////////////////////////
// single line of textual description
class ItemClassDescLineText extends React.Component {
  render() {
    const { color, value, type = "text" } = this.props.desc || {};
    if (type != "text")
      return null
    const styles = color ? { "color": `#${color}` } : null;
    return <div className="type-text" style={styles}>{`${value}`}</div>
  }
  static filter(it) {
    return ((it.type || "text") == "text") && (!!it.value);
  }
};
////////////////////////////////////////////
// single line of textual description
class ItemClassDescBundleText extends React.Component {
  render() {
    const { color, value, type = "text" } = this.props.desc || {};
    if (type != "dropped_bundle")
      return null
    const styles = color ? { "color": `#${color}` } : null;
    return <div className="type-dropped_bundle" style={styles}>{`${value}`}</div>
  }
  static filter(it) {
    return ((it.type || "text") == "dropped_bundle") && (!!it.value);
  }
};
////////////////////////////////////////////
// single entry of dropped_item type desc
class ItemClassDescLineDroppedItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      "stage": "unknown",
      "classInfo": {},
    };
  }
  UNSAFE_componentWillMount() {
    this.checkClass();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.desc != nextProps.desc)
      this.checkClass();
  }
  checkClass() {
    const { type = "text", value, } = this.props.desc || {};
    const assetclass = (value || {})["class"];
    if ((type == "dropped_item" || type == "exchange_with") && (assetclass)) {
      const { appid } = this.props;
      ItemClassInfoResolver.resolve(appid, assetclass
      ).then(classInfo => this.setState({ classInfo, "stage": "done" })
      ).catch(reason => this.setState({ "stage": "unknown" })
      )
      this.setState({ "stage": "pending", classInfo: {} });
      return <div />
    }
    this.setState({ "stage": "unknown", classInfo: {} });
  }
  render() {
    const { classInfo, stage } = this.state;
    if (stage == "done") {
      const { icon_url, name, market_hash_name, name_color } = classInfo || {};
      const { appid, parent_hashName } = this.props;
      const clickHandler = () => ApplicationEvents.emit_async("market.setCurrentAssetByHash", appid, market_hash_name, true, false, {
        appid,
        parent_hashName
      });
      const iconStyles = name_color ? {"borderColor": `#${name_color}`} : null;
      return <a className="item" style={iconStyles} >
        <img src={icon_url || "./images/bag_random.png"} />
        <span className="baloonOverride baloon baloonTop baloonCentered" data-baloon={`${name}`}
              onClick={clickHandler}>
        </span>
      </a>
    }
    if (!this.props.showUnresolved)
      return null;
    // unresolveds - invalid or pending
    return <div className="type-item" title={_I_(`ClassInfoDescriptions.dropped_item.stage-${stage}`)}>
      <img src="./images/bag_random.png" />
    </div>
  }
  static filter(it) {
    const type = it.type || "text";
    return ((type == "dropped_item") || (type == "exchange_with")) && (!!it.value) && (!!it.value["class"]);
  }
};
const TYPE_COMPONENTS = {
  "text": ItemClassDescLineText,
  "dropped_item": ItemClassDescLineDroppedItem,
  "dropped_bundle": ItemClassDescBundleText,
  "exchange_with": ItemClassDescLineDroppedItem
};
// List of various descriptions of some type
export class ClassInfoDescriptions extends React.Component {
  render() {
    const { appid, type, descriptions, owner_descriptions } = this.props;
    const DescComponent = TYPE_COMPONENTS[type];
    if (!DescComponent) {
      return null
    }
    const entries = (descriptions && descriptions.length) ? descriptions : owner_descriptions;
    const content = (entries || []).filter(DescComponent.filter);
    if (!content.length) {
      return null
    }
    return <div className={`${type}`}>
      {content.map(
        (desc, key) => <DescComponent key={key} desc={desc} appid={appid} />
      )}
    </div>;
  }
};
export class ClassInfoDropDescriptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }
  handleExpandButtonClick = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };
  render() {
    const { appid, type, descriptions, owner_descriptions, parent_hashName } = this.props;
    const { collapsed } = this.state;
    const DescComponent = TYPE_COMPONENTS[type];
    if (!DescComponent) {
      return null
    }
    const entries = (descriptions && (descriptions.length)) ? descriptions : owner_descriptions;
    let content = (entries || []).filter(DescComponent.filter);
    if (!content.length) {
      return null
    }
    let arrowIcon = collapsed ? "arrow" : "arrow_expand";
    const isLongContent = !!(content.length > 12);

    return (
      <div className="commodityContentsContainer">
        {type == "dropped_item" && (
          <React.Fragment>
            <div className="title">{_I_("ClassInfoDescriptions.dropped_bundle.block_label")}</div>

            {isLongContent && (
              <div ref={ref => this.expandButton = ref} className="expandButton"
                   onClick={this.handleExpandButtonClick.bind(this)}>
              <span className="count">
              {collapsed ?
                _I_("ClassInfoDescriptions.buttonLabel.top") :
                _I_("ClassInfoDescriptions.buttonLabel.hide")
              }</span >
                <img className="iconExpande" src={`images/icons/${arrowIcon}.png`} />
              </div>
            )}
          </React.Fragment>
        )}
        <div ref={ref => this.holder = ref}
             className={classMixer("contents", isLongContent && collapsed && "collapsed")}>
          <div className={`${type}`}>
            {content.map(
              (desc, key) => <DescComponent key={key} desc={desc} appid={appid}
                                            parent_hashName={parent_hashName} />
            )}
          </div>
        </div>
      </div>
    );
  }
};