// ISO639-1 language code plus ISO 3166-1 alpha 2 country code of the language
// https://www.softaculous.com/docs/Scripts_Language_Codes

// https://github.com/lipis/flag-icon-css
// https://github.com/usrz/bootstrap-languages
// cln_market_info


const LocalesList = Object.freeze(
([
  ["english",       "en_US",    "en",      true   ],
  ["german",        "de_DE",    "de",      true   ],
  ["russian",       "ru_RU",    "ru",      true   ],
  ["french",        "fr_FR",    "fr",      true   ],
  ["spanish",       "es_ES",    "es",      true   ],
  ["portuguese",    "pt_PT",    "pt",      true   ],
  ["polish",        "pl_PL",    "pl",      true   ],
  ["czech",         "cs_CZ",    "cs",      true   ],
  ["italian",       "it_IT",    "it",      true   ],
  ["ukrainian",     "uk_UA",    "uk",      true   ],
  ["korean",        "ko_KR",    "ko",      true   ],
  ["simplified",    "zh_CN",    "zh",      true   ],
  ["tchinese",      "zh_TW",    "tw",      true   ],
  ["arabic",        "en_AE",    "ar",      false  ],
  ["brazilian",     "pt_BR",    "br",      false  ],    // FIXME
  ["bulgarian",     "bg_BG",    "bg",      false  ],
  ["danish",        "da_DK",    "da",      false  ],
  ["dutch",         "nl_NL",    "nl",      false  ],
  ["finnish",       "fi_FI",    "fi",      false  ],
  ["greek",         "el_GR",    "el",      false  ],
  ["hungarian",     "ht_HU",    "hu",      false  ],
  ["japanese",      "ja_JP",    "ja",      false  ],
  ["norwegian",     "nb_NO",    "no",      false  ],
  ["romanian",      "ro_RO",    "ro",      false  ],
  ["swedish",       "sv_SE",    "sv",      false  ],
  ["thai",          "th_TH",    "th",      false  ],
  ["turkish",       "tr_TR",    "tr",      false  ],
]).map(
 ([ name,            code,      icon,    enabled  ])  =>  Object.freeze({ name, code, icon, enabled })
)
);


export const getEnabledLocales = () =>  {
  return LocalesList.filter( (v) => v.enabled );
}


export const findLocaleCode = ( code ) =>  {
  return getEnabledLocales().find( (v) => (v.code==code) );
}


export const findLocaleIcon = ( icon ) =>  {
  return getEnabledLocales().find( (v) => (v.icon==icon) );
}


const findLocaleByNavigatorLanguage = ( navLang ) =>  {
  const [ matched, lang, sub ] = /^(\w+)(?:-(\w+)|)$/.exec(navLang||"")||[];
  if ( !matched || !lang )  {
    return;
  }

  if ( sub )  {
    return findLocaleCode(`${lang.toLowerCase()}_${sub.toUpperCase()}`);

  }  else  {
    return findLocaleIcon(`${lang.toLowerCase()}`);

  }
}


export const getDefaultLocale = () =>  {
  let found = findLocaleByNavigatorLanguage( navigator.language );
  if ( found )  {
    return found;
  }

  for( let lang of navigator.languages||[] )  {
    found = findLocaleByNavigatorLanguage( lang );
    if ( found )  {
      return found;
    }
  }

  return findLocaleCode( "en_US" )
}

