export const plural = (number, words) => {
  return Math.abs(parseInt(number, 10)) === 1 ? words[0] : words[1];
};

export const locale = {
  "ViewItemPreviewSidePane.quantity.inventory": " ",
  "ViewItemPreviewSidePane.quantity.ordersInventory": " artículos en el inventario",
  "ViewItemPreviewSidePane.quantity.count": " en venta",
  "ViewItemPreviewSidePane.quantity.countBuy": " órdenes de compra",
  "ViewItemPreviewSidePane.quantity.items": " artículos",
  "ViewMarketedItem.SoldOutText": "agotado",
  "ViewMarketedItem.SoldOutTip": "No hay órdenes de venta",
  "ViewMarketedItem.NoBuyText": "no hay órdenes de compra",
  "ViewMarketedItem.SellingFrom": "Vendiendo desde...",
  "ViewMarketedItem.SellingMore": " o más",
  "ViewMarketedItem.BuyingMore": " o menos",
  "ViewMarketedItem.tradableAfter": "estará disponible para la venta después de",
  "ViewItemSearch.heading.showAll": "Mostrando todos los listados",
  "ViewItemSearch.heading.orders": "Mis órdenes",
  "ViewItemSearch.heading.marketSell": "Órdenes de venta",
  "ViewItemSearch.heading.marketBuy": "Órdenes de compra",
  "ViewItemSearch.heading.marketAuctions": "Subastas",
  "ViewItemSearch.title.marketAuction": "Subasta",
  "ViewItemSearch.title.ending": "Hasta el final",
  "ViewItemSearch.title.finished": "Subasta finalizada",
  "ViewItemSearch.heading.settings": "Ajustes",
  "ViewMarketedItem.button.more": "Más detalles",
  "ViewItemSearch.heading.resultsFor": "Se muestran los resultados para:",
  "ViewItemSearch.stub.empty": "Resultado de búsqueda vacío",
  "ViewItemSearch.heading.tooltip": "Su oferta ha sido superada",
  "ViewItemSearch.heading.tooltip.win": "Su oferta ganó",
  "ViewItemPopup.committed.SELL": "Venta completada",
  "ViewItemPopup.committed.BUY": "Compra completada",
  "ViewItemPopup.committed.BID": "Oferta realizada",
  "ViewItemPopup.application.BUY": "Orden de compra creada",
  "ViewItemPopup.application.SELL": "Solicitud de venta creada",
  "ViewItemPopup.application.BID": "Oferta hecha",
  "ViewItemPopup.canceled.BUY": "Orden de compra cancelada",
  "ViewItemPopup.canceled.SELL": "Solicitud de venta cancelada",
  "ViewItemPopup.canceled.BID": "Oferta cancelada",
  "ViewItemPopup.showAll.button": "Ver todo",
  "ViewItemAssets.readAll.button": "Marcar todo como visto",
  "ViewItemPopup.heading.noHistory": "Aún no hay notificaciones",
  "ViewItemPopup.heading.unseenHistory": "No hay nuevas notificaciones",
  "ViewItemSearch.termsPlaceholder": "Busca términos aquí",
  "ViewItemSearch.label.search": "Buscar artículos",
  "ViewMarketedItem.lovestPrice": "desde ",
  "ViewMarketedItem.beforePrice": "antes ",
  "ViewMarketedItem.yourBid": "su oferta ",
  "ViewMarketedItem.bid": "oferta ",
  "ViewItemSearch.heading.exchange": "Home",
  "ViewItemSearch.heading.light": "Blanco",
  "ViewItemSearch.heading.dark": "Oscuro",
  "ViewItemSearch.filteringAnnounce": "Para filtrar las órdenes hay que seleccionar un juego",
  "ViewItemSearch.ordersBuy": "COMPRAR",
  "ViewItemSearch.ordersSell": "VENDER",
  "ViewItemSearch.ordersOpen": "Ver historial completo",
  "MarketApp.Navbar.inventoryLink": "Inventario",
  "MarketApp.Navbar.ordersLink": "Mis pedidos",
  "MarketApp.Navbar.marketLink": "Mercado",
  "MarketApp.Navbar.settingsLink": "Configuración",
  "MarketApp.Navbar.replenishLink": "Reponer balance",
  "MarketApp.Navbar.balanceHistoryLink": "Historial de balance",
  "MarketApp.Navbar.buyCoinsLink": "Compra GaijinCoins",
  "MarketApp.Navbar.profileLink": "Perfil",
  "MarketApp.Navbar.reloginLink": "Inicia sesión de nuevo o cambia de cuenta",
  "MarketApp.Navbar.toOldVersion": "Ir a la versión antigua",
  "MarketApp.Navbar.linkFeedback": "Deja tu Opinión",
  "ViewTradeDialog.caption": "Comerciar",
  "ViewTradeDialog.button.back": "Volver",
  "NotAllowedMessage.caption": "No permitido",
  "NotAllowedMessage.body.h1": "Acceso al mercado",
  "NotAllowedMessage.body.h3": "actualmente no permitido para tu cuenta",
  "NotAllowedMessage.relogin": "Cambiar cuenta",
  "CountryDeniedMessage.caption": "No permitido",
  "CountryDeniedMessage.body.notAllowed": "El acceso al mercado no está permitido",
  "CountryDeniedMessage.body.unclearStatus": "Debido al estado legal poco claro de las transacciones de mercado en tu país, el acceso al mercado está desactivado",
  "LandingMessage.caption": "Autenticación",
  "LandingMessage.body": "Acabando...",
  "TokenExpiredMessage.caption": "Autenticación expirada",
  "TokenExpiredMessage.body.h1": "Autenticación expirada",
  "TokenExpiredMessage.body.h3": "No hay mucho que podamos hacer con esto, pero aún puedes",
  "TokenExpiredMessage.relogin": "Vuelve a iniciar sesión...",
  "AuthErrorMessage.caption": "Autenticación fallida",
  "AuthErrorMessage.body.h1": "Error al verificar la autenticación",
  "AuthErrorMessage.body.code": "Código:",
  "AuthErrorMessage.body.desc": "No hay mucho que podamos hacer con esto, pero aún puedes",
  "AuthErrorMessage.relogin": "Vuelve a iniciar sesión...",
  "AuthorizationRequiredMessage.caption": "Autenticacion requerida",
  "AuthorizationRequiredMessage.body.h3.1": "Estás a punto de entrar",
  "AuthorizationRequiredMessage.body.h3.2": "Mercado Gaijin",
  "AuthorizationRequiredMessage.body.h3.3": "Pero necesitas estar autorizado para continuar",
  "AuthorizationRequiredMessage.login": "Inicia sesión con Gaijin.net",
  "CheckingAuthMessage.caption": "Autenticación",
  "CheckingAuthMessage.body": "Comprobando la autenticación, por favor se paciente",
  "CancelOrdersPanel.cancelError": "\nNo se pudo cancelar el pedido ",
  "CancelOrdersPanel.loading": "Comprobando pedidos activos",
  "CancelOrdersPanel.done": "Operación terminada.",
  "CancelOrdersPanel.errors": "Hay algunos errores: \n",
  "CancelOrdersPanel.loaded.youHave": "Actualmente tienes ",
  "CancelOrdersPanel.loaded.sellOrders": " órden(es) de venta por un total de ",
  "CancelOrdersPanel.loaded.buyOrders": " orden(es) de compra por un total de ",
  "CancelOrdersPanel.loaded.and": " y ",
  "CancelOrdersPanel.loaded.shouldCancel": " y tienes que cancelarlas todas. ",
  "CancelOrdersPanel.loaded.doCancel": "Cancelar pedidos",
  "CancelOrdersPanel.cancelling.processing": "Procesando",
  "CancelOrdersPanel.cancelling.outOf": " fuera de ",
  "CancelOrdersPanel.cancelling.sellOrders": " pedidos de venta",
  "CancelOrdersPanel.cancelling.buyOrders": " pedidos de compra",
  "ClassInfoDescriptions.dropped_item.block_label": "Contiene uno de los siguiente:",
  "ClassInfoDescriptions.dropped_item.stage-unknown": "Desconocido",
  "ClassInfoDescriptions.dropped_item.stage-pending": "...cargando",
  "ClassInfoDescriptions.text.block_label": "",
  "ClassInfoDescriptions.dropped_bundle.block_label": " ",
  "ClassInfoDescriptions.buttonLabel.top": "Mostrar",
  "ClassInfoDescriptions.buttonLabel.hide": "Ocultar",
  "ClassInfoDescriptions.buttonLabel.bottom": "todos",
  "ClassInfoDescriptions.dropped_text.description": "Description",
  "ClassInfoUtil.fake_name.pending": "Pendiente",
  "ClassInfoUtil.fake_name.broken": "Articulo eliminado",
  "ClassInfoUtil.SynthTag.marketable1": "Vendible",
  "ClassInfoUtil.SynthTag.marketable0": "No vendible",
  "ClassInfoUtil.SynthTag.tradable1": "Negociable",
  "ClassInfoUtil.SynthTag.tradable0": "No Negociable",
  "ClassInfoUtil.SynthTag.commodity1": "Comodidad",
  "ClassInfoUtil.SynthTag.commodity0": "No comodidad",
  "ConfirmBuyDialog.title": "Realizar orden de compra",
  "BuyProcessingDialog.title": "Procesando...",
  "BuyProcessingDialog.pleaseWait": "Procesando, por favor espera",
  "ProcessingDialog.notItem": "El artículo solicitado no se encuentra en el mercado o no está disponible para el comercio",
  "SellDialog.title": "Poner un artículo a la venta",
  "SellProcessingDialog.title": "Procesando...",
  "SellProcessingDialog.pleaseWait": "Procesando, por favor espera",
  "LimitedAccountPanel.2step.reason": "Tienes que configurar la autorización en dos pasos",
  "LimitedAccountPanel.2step.link": "Más información sobre la autorización en dos pasos",
  "LimitedAccountPanel.2step.href": "https://support.gaijin.net/hc/en-us/articles/203623622-How-to-set-up-the-Two-Step-Authorization-Google-Authenticator-",
  "LimitedAccountPanel.email.reason": "Tienes que confirmar tu dirección de correo electrónico",
  "LimitedAccountPanel.email.link": "Pasar al perfil",
  "LimitedAccountPanel.2step.toPlug": "Activar",
  "LimitedAccountPanel.email.href": "https://store.gaijin.net/user.php",
  "LimitedAccountPanel.title": "Tu cuenta está limitada debido a:",
  "LimitedAccountPanel.conclusion": "Tras cambiar la configuración de seguridad, deberías cerrar la sesión y luego iniciar sesión para que los cambios surtan efecto",
  "LimitedAccountPanel.relogin": "Volver a iniciar sesión...",
  "EmailVerificationStateIcon.success": "Cuenta confirmada, correo electrónico verificado",
  "EmailVerificationStateIcon.warning": "Cuenta no confirmada, correo electrónico no verificado",
  "EmailVerificationStateIcon.danger": "Cuenta no confirmada, verificación de correo electrónico REQUERIDA",
  "TwoFactorStateIcon.success": "Cuenta segura con autorización en dos pasos",
  "TwoFactorStateIcon.warning": "Cuenta insegura, autorización de dos factores deshabilitada",
  "TwoFactorStateIcon.danger": "Autorización en dos pasos NECESARIA",
  "ViewInventoryTextFilter.common.label": "Para filtrar el inventario, tienes que seleccionar un juego",
  "ItemBuyEditor.label.wheelAmountInput": "Puede usar la rueda del mouse para girar la cantidad,\nalt + rueda = 10x velocidad",
  "ItemBuyEditor.label.wheelInput": "Puede usar la rueda del mouse para girar el precio,\nalt + rueda = 10x speed,\nshift + rueda = 0.1x velocidad",
  "ItemBuyEditor.note.posAmount": "El valor de importe debe ser positivo",
  "ItemBuyEditor.note.maxAmount": "El valor del importe supera el máximo en 1000 artículos",
  "ItemBuyEditor.note.invalidAmount": "Valor de la cantidad no válida",
  "ItemBuyEditor.note.minPrice": "El precio minimo es",
  "ItemBuyEditor.note.maxTotal": "El total de pedidos supera el máximo de",
  "ItemBuyEditor.note.invalidPrice": "Valor de precio inválido",
  "ItemBuyEditor.note.mustAgree": "Debe aceptar los Términos de uso",
  "ItemBuyEditor.note.insufficentFunds0": "Fondos insuficientes, necesitas más",
  "ItemBuyEditor.note.insufficentFunds1": "para esta orden",
  "ItemBuyEditor.label.buyCouns": "Comprar",
  "ItemBuyEditor.label.submit": "Realizar pedido",
  "ItemBuyEditor.label.price": "Qué precio quieres pagar (por artículo):",
  "ItemBuyEditor.label.amount": "Cuánto quieres comprar:",
  "ItemBuyEditor.label.totalPrice": "Precio total (incluye tarifas):",
  "ItemBuyEditor.label.agree": "Estoy de acuerdo con la Tienda Gaijin",
  "ItemBuyEditor.label.ToU": "Términos de Uso",
  "MarketDepthTotals.noOrders.sell": "No hay órdenes de venta",
  "MarketDepthTotals.noOrders.buy": "No hay órdenes de compra",
  "MarketDepthTotals.starting.sell": " por ",
  "MarketDepthTotals.starting.buy": " de ",
  "MarketDepthTotals.andMore.sell": " debajo de",
  "MarketDepthTotals.andMore.buy": " mas alto",
  "MarketDepthTotals.prevew.sell": "Comprando: ",
  "MarketDepthTotals.prevew.buy": "En venta: ",
  "MarketDepthOwnWarning.sell": "Tienes pedidos de venta pendientes",
  "MarketDepthOwnWarning.buy": "Tienes pedidos de compra pendientes",
  "MarketDepthTable.caption.price": "Precio",
  "MarketDepthTable.caption.quantity": "Cantidad",
  "MarketDepthTable.ellipsis.sell": "y mas",
  "MarketDepthTable.ellipsis.buy": "y menos",
  "ItemMarketDetails.buyButton": "COMPRAR",
  "ItemMarketDetails.sellButton": "VENDER",
  "ItemMarketDetails.nothingToSellButton": "Nada que vender",
  "ItemMarketDetails.makeAuction": "Hacer subasta",
  "ItemMarketDetails.makeBid": "Hacer oferta",
  "ItemMarketDetails.bid": "Oferta",
  "ItemMarketDetails.yourBid": "Su oferta:",
  "ItemMarketDetails.notifications.yourBid": "Su oferta",
  "ItemMarketDetails.yourBid.short": "su",
  "ItemMarketDetails.yourBid.win": "gana",
  "ItemMarketDetails.yourBid.loose": "pierde",
  "ItemSellEditor.label.priceStep": "Paso de precio",
  "ItemSellEditor.label.endDate": "Fecha de finalización",
  "limitedAccountMessage.requires": "Requiere:",
  "limitedAccountMessage.2step": "protección de dos pasos",
  "limitedAccountMessage.verifiedEmail": "correo electrónico verificado",
  "limitedAccountMessage.and": " y ",
  "ItemSellEditor.label.wheelInput": "Puede usar la rueda del mouse para girar el precio,\nalt + rueda = 10x speed,\nshift + rueda = 0.1x velocidad",
  "ItemSellEditor.note.minPrice": "El precio minimo es",
  "ItemSellEditor.note.maxPrice": "Precio de venta máximo permitido",
  "ItemSellEditor.note.maxTotals": "El total de pedidos supera el máximo de",
  "ItemSellEditor.note.maxBalance": "El saldo total no debe exceder",
  "ItemSellEditor.note.mustAgree": "Debe aceptar los Términos de uso",
  "ItemSellEditor.label.price": "El comprador paga (incluye tarifas):",
  "ItemSellEditor.label.wished": "Recibes, después de las tarifas cobradas:",
  "ItemSellEditor.label.agree": "Estoy de acuerdo con la Tienda Gaijin",
  "ItemSellEditor.label.ToU": "Términos de Uso",
  "ItemSellEditor.label.submit": "OK, ponlo a la venta",
  "ItemSellEditor.currentBalance.before": "Actualmente tienes",
  "ItemSellEditor.currentBalance.after": "en tu saldo",
  "ItemSellEditor.sellOrdersTotal.before": "mas",
  "ItemSellEditor.sellOrdersTotal.after": "acumulado en órdenes de venta",
  "ItemSellEditor.buyOrdersTotal.before": "y",
  "ItemSellEditor.buyOrdersTotal.after": "acumulado en órdenes de compra",
  "ItemSellEditor.label.amount": "Cuánto quieres vender:",
  "AppFilterEditor.label.byTags": "Filter",
  "AppFilterEditor.label.clearFilter": "Limpiar filtro",
  "AppFilterEditor.label.byTitles": "Filtrar por títulos",
  "AppFilterEditor.label.allFilter": "Todos los juegos",
  "AppFilterEditor.label.authenticity": "Autenticidad",
  "AppFilterEditor.message.filter": "Los filtros son únicos para cada juego.",
  "AppFilterEditor.message.game": "Escoge un juego:",
  "ViewItemDetailed.restriction.amount.items": " items",
  "ViewItemDetailed.restriction.nonMarketable": "No puede ser vendido",
  "ViewItemDetailed.restriction.blockedAfter": "Se bloqueara en",
  "ViewItemDetailed.restriction.blockedPair": "Negocio bloqueado",
  "ViewItemDetailed.restriction.expiresAt1": "Desaparecerá después",
  "ViewItemDetailed.restriction.expiresAt2": "",
  "ViewItemDetailed.restriction.endedAt": "End of auction",
  "ViewItemDetailed.restriction.amount": "Lot contains",
  "ViewItemDetailed.restriction.pieces": "pcs.",
  "ViewItemDetailed.restriction.startPrice": "Starting price",
  "ViewItemDetailed.restriction.days": "d",
  "ViewItemDetailed.restriction.hours": "hrs",
  "ViewItemDetailed.restriction.minutes": "min",
  "ViewItemDetailed.restriction.leadingBids": "leading bids",
  "ViewItemDetailed.restriction.noBids": "There are no bids yet. Place your first bid in this auction!",
  "ViewItemDetailed.restriction.delayTrade": "Once purchased, the item will be available for sale through",
  "ViewItemDetailed.restriction.unclearStatus": "Debido al estado legal poco claro de las cajas de trofeos en tu país, todas las transacciones con trofeos y llaves están bloqueadas para tu cuenta.",
  "MarketSortOrder.label.name": "Nombre",
  "MarketSortOrder.hint.newDesc": "New ones first",
  "MarketSortOrder.hint.newAsc": "Old ones first",
  "MarketSortOrder.label.price": "Precio",
  "MarketSortOrder.hint.priceAsc": "De menor a mayor coste",
  "MarketSortOrder.hint.priceDesc": "Coste alto a bajo",
  "MarketSortOrder.label.amount": "Cantidad",
  "MarketSortOrder.hint.amountAsc": "De menor a mayor cantidad",
  "MarketSortOrder.hint.amountDesc": "Cantidad alta a baja",
  "MarketSortOrder.label.popular": "Popular",
  "MarketSortOrder.label.popularItems": "Artículos populares",
  "DoubleSellErrorModal.heading": "Error",
  "DoubleSellErrorModal.prompt": "No se pudo vender el artículo actual",
  "DoubleSellErrorModal.explanation": "No se pudo realizar el pedido de venta, una de las posibles causas - el artículo se vendió o se consumió en el juego\nPrueba refrescar la página",
  "DoubleSellErrorModal.okButton": "OK",
  "OnBuyReserveFailModal.heading": "Error",
  "OnBuyReserveFailModal.prompt": "No se pudo realizar el pedido de compra",
  "OnBuyReserveFailModal.explanation": "No se pudo emitir una orden de compra, no se pudo reservar dinero de la billetera.",
  "OnBuyReserveFailModal.youNeed1": "Necesitas al menos",
  "OnBuyReserveFailModal.youNeed2": "para esta operación",
  "OnBuyReserveFailModal.tryRefresh": "Intenta actualizar la página y asegúrate de tener fondos suficientes",
  "OnBuyReserveFailModal.okButton": "OK",
  "DoubleRateErrorModal.heading": "Error",
  "DoubleRateErrorModal.prompt": "An error occurred while placing a bid",
  "DoubleRateErrorModal.explanation": "Please try to bid later",
  "DoubleRateErrorModal.okButton": "OK",
  "AuctionFinishedErrorModal.heading": "Error",
  "AuctionFinishedErrorModal.prompt": "An error occurred while placing a bid",
  "AuctionFinishedErrorModal.explanation": "The auction was completed",
  "AuctionFinishedErrorModal.okButton": "OK",
  "CancelOrderErrorModal.heading": "Error",
  "CancelOrderErrorModal.prompt": "No se pudo cancelar el pedido",
  "CancelOrderErrorModal.explanation": "Los posibles motivos son problemas de red o estabas en el momento de la actualización del pedido.\n Inténtalo de nuevo un poco más tarde.",
  "CancelOrderErrorModal.cancelButton": "Lo tengo",
  "OrdersListContainer.Tab.sellOrders": "Pedidos de venta",
  "OrdersListContainer.Tab.buyOrders": "Pedidos de compra",
  "OrdersListContainer.Tab.historyList": "Historial de pedidos",
  "ViewOrdersEntry.amounts.remain": "Quedan",
  "ViewOrdersEntry.amounts.done": "Listo",
  "ViewOrdersEntry.order.order": "Orden",
  "ViewOrdersEntry.order.issued": "Emitido",
  "ViewOrdersEntry.order.executed": "Ejecutado",
  "ViewOrdersEntry.order.pairIsBlocked": "Negocio bloqueado",
  "ViewOrdersEntry.order.pairWillBlockAfter": "Se bloqueara en",
  "ViewOrdersEntry.order.BUY": "COMPRAR",
  "ViewOrdersEntry.order.SELL": "VENDER",
  "ViewOrdersEntry.order.view": "Ver",
  "ViewOrdersEntry.order.cancel": "Cancelar",
  "ViewOrdersList.empty.label": "Nada aquí",
  "CancelOrderDialog.heading.youSure": "¿Estás seguro?",
  "CancelOrderDialog.body.youSure": "¿Estás seguro de cancelar el pedido?",
  "CancelOrderDialog.button.cancel": "Sí, quiero cancelar el pedido",
  "CancelAllOrderDialog.button.cancel": "Sí para todos",
  "CancelOrderDialog.button.noCancel": "No",
  "CancelOrderDialog.heading.processing": "Procesando...",
  "CancelOrderDialog.body.processing": "Procesando, por favor espera",
  "ProfileMenu.MenuItem.Settings": "Configuración",
  "ProfileMenu.MenuItem.GaijinAccount": "Perfil Gaijin.net",
  "ProfileMenu.MenuItem.AccountSwitch": "Cambiar de cuenta",
  "ProfileMenu.MenuItem.Signout": "Salir",
  "ProfileMenu.MenuItem.nick": "Tu apodo",
  "WalletBlock.balance.text": "Saldo:",
  "WalletBlock.balance.buyCurrency": "Comprar GJN",
  "SecurityBlock.message.title": "Autorización en dos pasos",
  "SecurityBlock.emailVerified.title": "E-mail",
  "OrdersHistory.title.buy": "Ordenes de compra",
  "OrdersHistory.title.sell": "Ordenes de venta",
  "NotificationsAssets.title": "Notificaciones",
  "NotificationsAssets.title.today": "Hoy",
  "NotificationsAssets.title.historyFromLastWeek": "En los últimos siete días",
  "NotificationsAssets.title.historyOlder": "Anterior",
  "LimitedAccountPanel.2step.pleas": "Protege tu cuenta para acceder al comercio en el mercado.",
  "LimitedAccountPanel.2step.protected": "Tu cuenta está protegida, tienes acceso al comercio en el mercado.",
  "LimitedAccountPanel.email.pleas": "Confírmalo para acceder a las operaciones en el mercado.",
  "LimitedAccountPanel.email.protected": "Tu dirección de correo electrónico está confirmada, tienes acceso a operar en el mercado.",
  "OrdersListContainer.Opened.Orders": "Abiertas",
  "OrdersListContainer.Closed.Orders": "Cerradas",
  "ViewOrdersList.empty.labelCollapse": "Colapsar",
  "ViewOrdersList.empty.labelExpand": "Expandir",
  "OrdersGraph.plot.title": "Órdenes de compra/venta",
  "OrdersGraph.fmt.offers.sell": "vender ofertas \n<br/>precio",
  "OrdersGraph.fmt.andMore.sell": "o menos",
  "OrdersGraph.fmt.offers.buy": "comprar ofertas\n<br/>precio",
  "Orders.title.header": "órdenes",
  "OrdersGraph.fmt.andMore.buy": "y superior",
  "PriceHistoryGraph.plot.title": "Historial de precios:",
  "PriceHistoryGraph.highlighter.format": "A <strong>%s</strong><br /> precio <strong>%s</strong><br /> cantidad <strong>%s</strong><br />",
  "PriceHistoryGraph.plot.shortRange": "Corto plazo",
  "PriceHistoryGraph.plot.wholeRange": "Histórico",
  "DealEventTickerEntry.purchasedFrom": "compró este artículo de",
  "DealEventTickerEntry.for": "por",
  "BookSellTickerEntry.listedItem": "sale request",
  "BookBuyTickerEntry.created": "creado",
  "BookBuyTickerEntry.for": "purchase order",
  "BookAuctionTickerEntry.created": "made a bet",
  "ViewTradeTickerLog.waiting": "Esperando actividad nueva...",
  "ViewTradeTickerLog.ticker": "Real time trading...",
  "ViewUserInventory.common.emptyInventory": "En este momento tu inventario está vacío",
  "ViewItemPreviewSidePane.UnselectButton.label": "Filtros",
  "ViewItemPreviewSidePane.TradeButton.label": "Vender/Comprar",
  "ViewItemPreviewSidePane.ViewButton.label": "Ver",
  "ViewItemPreviewSidePane.QuickSellButton.label": "Venta rápida",
  "ViewInventoryTagCloud.tagTitle.itemsOf": "artículos de",
  "ViewInventoryTagCloud.tagTitle.varietiesMarkedAs": "variedades marcadas como",
  "ViewItemSearch.heading.inventory": "Mis artículos",
  "WalletBlock.circleSmall.settings": "Configuración",
  "WalletBlock.circleSmall.exit": "Salir",
  "UserSettingsDialog.language": "Idioma",
  "UserSettingsDialog.user_settings": "Ajustes de usuario",
  "UserSettingsDialog.settings": "Ajustes",
  "UserSettingsDialog.save_settings": "Guardar ajustes",
  "UserSettingsDialog.privacySettings": "Comerciar",
  "UserSettingsDialog.privacySettingsPublic": "Público",
  "UserSettingsDialog.privacySettingsPrivate": "Privado",
  "UserSettingsDialog.interface": "Interface",
  "UserSettingsDialog.interfaceOld": "Legacy",
  "UserSettingsDialog.interfaceNew": "Modern",
  "WalletLimitsIcon.soft_limit": "Límite de monedas GJN excedido.\nLas funciones de reabastecimiento son limitadas.",
  "WalletLimitsIcon.hard_limit": "Límite de monedas GJN excedido.\nLas funciones de reabastecimiento y venta son limitadas.",
  "ActionBar.action.ingamePreview": "Ver en el juego",
  "ActionBar.action.previewCopied": "¡Enlace copiado!",
  "ActionBar.action.previewShare": "Compartir",
  "IngamePreview.ask.heading": "Ver esto en el juego?",
  "IngamePreview.ask.prompt": "Estás a punto de ver este elemento en el juego",
  "IngamePreview.ask.explanation": "Asegúrate de que el cliente del juego se ejecuta con tu cuenta",
  "IngamePreview.ask.cancelButton": "No",
  "IngamePreview.done.heading": "Listo",
  "IngamePreview.done.prompt": "",
  "IngamePreview.done.explanation": "Enviado exitosamente",
  "IngamePreview.done.okButton": "Ok",
  "IngamePreview.fail.heading": "Fallado",
  "IngamePreview.fail.prompt": "",
  "IngamePreview.fail.explanation": "No se pudo contactar con el cliente del juego",
  "IngamePreview.fail.cancelButton": "Ok",
  "IngamePreview.pending.heading": "Sólo un segundo...",
  "IngamePreview.pending.prompt": "Notificando a tu cliente de juego",
  "IngamePreview.pending.explanation": "",
  "IngamePreview.ask.okButton": "Claro, muéstrame",
  "StaticConfigLoader.caption.error": "Error de inicialización",
  "StaticConfigLoader.caption.init": "Inicialización",
  "StaticConfigLoader.text.init": "Inicializando la aplicación...",
  "StaticConfigLoader.error.settings": "No se puede cargar la configuración del sitio",
  "StaticConfigLoader.error.circuits": "No se puede cargar la configuración de servicios",
  "ControlButton.close": "Cerrar",
  "Exit": " ",
  "Legal.TermsConditions": "Términos y Condiciones",
  "Legal.TermsService": "Condiciones de Uso",
  "Legal.PrivacyPolicy": "Política de Privacidad",
  "Legal.CookiesPolicy": "Política de Cookies",
  "Legal.TradePolicy": "Política Comercial",
  "Legal.TermsConditions.linck": "https://legal.gaijin.net/es/",
  "Legal.TermsService.linck": "https://legal.gaijin.net/es/termsofservice/",
  "Legal.PrivacyPolicy.linck": "https://legal.gaijin.net/es/privacypolicy/",
  "Legal.CookiesPolicy.linck": "https://legal.gaijin.net/es/cookie/",
  "Legal.TradePolicy.linck": "https://legal.gaijin.net/es/tradepolicy/",
  "Legal.Copyright": "Gaijin Network Ltd. Todas las marcas comerciales, logotipos y nombres de marcas son propiedad de sus respectivos propietarios.",
  "MarketApp.Navbar.HeadTitle": "Gaijin.Net",
  "MarketApp.Navbar.HeadSubTitle": "Market",
};