
import { isPairBlocked } from 'api/rpcAPICall';
import { isCountryDenied, isExpired } from 'components/classInfoUtil';
import spawnConfirmDialogModal from 'components/confirmBuyDialog';
import spawnConfirmSellModal from 'components/confirmSellDialog';
import { ViewItemMarketDepthOwnWarning, ViewItemMarketDepthTable, ViewItemMarketDepthTotals } from 'components/itemMarketDepth';
import ViewOrdersGraph from 'components/ordersGraph';
import ViewPriceHistoryGraph from 'components/priceHistoryGraph';
import ViewTradeTickerLog from 'components/tradeTickerLog';
import { _I_ } from 'components/translate';
import { LogStream } from 'generics/common';
//import { SettingsInjections } from 'generics/settingsInjections';
import { classMixer } from 'generics/utils';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ContentLoader from "react-content-loader";
import { MarketStore } from 'stores';
import { useCountdownTimer } from "../generics/marketHooks";
import { GJN, GJN_GOLD, GJN_USER_BID } from "../generics/marketIcons";
import { getBidIndex } from './briefBookLoader';
import ViewNonCommodity from "./viewNonCommodity";


LogStream.enable("VIEWITEMMARKETDETAILSCONTROLLER");


function limitedAccountMessage(creds) {
  const { need2step, have2step, needEmailVerification, haveEmailVerification } = creds;
  const twoStep = (need2step && !have2step) && _I_("limitedAccountMessage.2step");
  const verified = (needEmailVerification && !haveEmailVerification) &&
    _I_("limitedAccountMessage.verifiedEmail");
  if (twoStep || verified) {
    const combined = [twoStep, verified].filter(Boolean).join(_I_("limitedAccountMessage.and"));
    return `${_I_("limitedAccountMessage.requires")} ${combined}`;
  }
}

const ViewBuyButton = ({
  expiredItem, limitedAccount, countryDenied, blockedItem, limitedDueTwoStep, warningMsg,
  clickBuy, makeBid, auctionEnded
}) => {
  const { isEnded } = useCountdownTimer(auctionEnded);
  const [isDisabled, setDisabled] = useState(limitedAccount || countryDenied);
  const [disableReason, setDisableReason] = useState("");

  useEffect(() => {
    if ((makeBid && isEnded) || limitedAccount) {
      setDisabled(true);

      if (limitedAccount)
        setDisableReason(limitedAccount);
    }
  }, [makeBid, isEnded, limitedAccount]);

  if (expiredItem || blockedItem) {
    return null;
  }

  return (
    <div
      title={limitedAccount || ""}
      className={classMixer("button buy", isDisabled && "disabled")}
      data-disablereason={disableReason}
      onClick={isDisabled ? null : () => limitedDueTwoStep ? warningMsg() : clickBuy()}
    >
      {_I_(makeBid ? "ItemMarketDetails.makeBid" : "ItemMarketDetails.buyButton")}
    </div>
  );
};

const ViewSellButton = ({ expiredItem, limitedAccount, blockedItem, limitedDueTwoStep, warningMsg,
  clickSell, haveItems, tradableAfterStr }) => {

  const [isDisabled, setDisabled] = useState(limitedAccount || !haveItems);
  const [disableReason, setDisableReason] = useState(!haveItems && (tradableAfterStr ?
    `${_I_("ViewMarketedItem.tradableAfter")} ${tradableAfterStr}` : _I_("ItemMarketDetails.nothingToSellButton")
  ));

  useEffect(() => {
    if (limitedAccount || !haveItems) {
      setDisabled(true);

      if (limitedAccount)
        setDisableReason(limitedAccount);
    }
  }, [haveItems, limitedAccount]);

  if (expiredItem || blockedItem) {
    return null;
  }

  return (
    <div
      title={limitedAccount || ""}
      className={classMixer("button sell", isDisabled && "disabled")}
      data-disablereason={disableReason}
      onClick={isDisabled ? null : () => limitedDueTwoStep ? warningMsg() : clickSell()}
    >
      {_I_("ItemMarketDetails.sellButton")}
    </div>
  );
};

const ViewAuctionOrders = ({ auction, ordersBuy }) => {
  const { end_time: auctionEnded, salt: auctionSalt } = auction;
  const { isEnded: isAuctionEnded } = useCountdownTimer(auctionEnded);

  if (isAuctionEnded)
    return <div className='noBids'>{_I_('ViewItemSearch.title.finished')}</div>

  if (!ordersBuy.length)
    return <div className='noBids'>{_I_('ViewItemDetailed.restriction.noBids')}</div>

  const endedAtStamp = (date) => (+date > 0) && moment(1000 * date).format('DD.MM.YYYY — HH:mm');
  const ownBidIndex = getBidIndex(ordersBuy, auctionSalt);
  const haveOwnBid = ownBidIndex !== -1;
  const userBidHash = haveOwnBid ? ordersBuy[ownBidIndex].count : null;
  const renderIcon = (idx, bid) => {
    if (haveOwnBid && ownBidIndex == idx)
      return <GJN_USER_BID />

    if (idx === 0)
      return <GJN_GOLD />

    return <GJN />
  }

  return ordersBuy.map((subArray, subArrayIndex) => (
    <div key={`i_${subArrayIndex}`} className={classMixer("order", (userBidHash === subArray.count && 'userBids') || ((subArrayIndex === 0) && "first"))}>
      <div className='number'>{subArrayIndex + 1}</div>
      <div className='value'>{subArray.price}
        {renderIcon(subArrayIndex, subArray)}
        <span className='date'>{endedAtStamp(subArray.date)}</span>
      </div>
    </div>
  ))
}

class ViewItemDetails extends React.Component {
  render() {
    const itemDetails = this.props.auctionable ?
      <ViewAuctionItemDetails {...this.props} /> :
      <ViewMarketableItemDetails {...this.props} />;
    return itemDetails;
  }
}

class ViewAuctionItemDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tradeBegun: false
    };
  }
  updateTradeState = (value) => {
    this.setState({ tradeBegun: value });
  };

  render() {
    const {
      expiredItem, auction, limitedAccount, market_name, appid,
      countryDenied, blockedItem, limitedDueTwoStep, ordersBuy,
      warningMsg, clickBuy, haveOwnBuyOrders
    } = this.props;

    const title = this.state.tradeBegun ? _I_('ViewTradeTickerLog.ticker') : _I_("ViewTradeTickerLog.waiting");

    //const canTrade = !(expiredItem && blockedItem);
    const auctionEnded = auction?.end_time;

    return (
      <React.Fragment>
        <div className="commodityOrders">
          {auction == null &&
            <ContentLoader
              speed={2}
              width={960}
              height={253}
              viewBox="0 0 960 253"
              backgroundColor="#272C35"
              foregroundColor="#363D46"
            >
              <rect x="340" y="0" rx="5" ry="5" width="280" height="56" />
              <rect x="355" y="73" rx="5" ry="5" width="250" height="24" />
              <rect x="440" y="117" rx="5" ry="5" width="84" height="12" />
              <rect x="440" y="137" rx="5" ry="5" width="84" height="14" />
              <circle cx="34" cy="207" r="9" />
              <rect x="65" y="200" rx="5" ry="5" width="150" height="12" />
            </ContentLoader>
          }
          {(!expiredItem && auction !== null) &&
            <React.Fragment>
              {/* Disabled until auction creation is available to users
              {!auctionEnded &&
                <div className="ordersTable sell">
                  <div className="head">
                    {haveItems && canTrade &&
                      <div className={classMixer("button sell", !haveItems && "disabled")}
                        data-disablereason={!haveItems && _I_("ItemMarketDetails.nothingToSellButton")}
                        title={limitedAccount}
                        onClick={() => {
                          limitedDueTwoStep ? warningMsg() : clickSell()
                        }}
                      >
                        {_I_("ItemMarketDetails.makeAuction")}
                      </div>
                    }
                  </div>
                </div>
              }
              */}
              {auctionEnded &&
                <div className="ordersTable buy">
                  <div className="head">
                    <ViewBuyButton
                      expiredItem={expiredItem}
                      blockedItem={blockedItem}
                      limitedAccount={limitedAccount}
                      countryDenied={countryDenied}
                      limitedDueTwoStep={limitedDueTwoStep}
                      warningMsg={warningMsg}
                      clickBuy={clickBuy}
                      makeBid
                      auctionEnded={auctionEnded}
                    />
                    <div className="summary">
                      <div className='title'>{_I_('ViewItemDetailed.restriction.leadingBids')}</div>
                      <div className='orders'>
                        <ViewAuctionOrders auction={auction} ordersBuy={ordersBuy} />
                      </div>
                    </div>
                  </div>
                  <ViewItemMarketDepthOwnWarning type="buy" haveOwnOrders={haveOwnBuyOrders} />
                </div>
              }
            </React.Fragment>
          }
        </div>
        {auctionEnded &&
          <div className="graphContainer">
            <div className='tickerTitle'>
              <img className="subtract" src="images/icons/Subtract.png" />
              <span className='title'>{title}</span>
            </div>

            <ViewTradeTickerLog
              limitTickerLog={10}
              market_name={market_name}
              appid={appid}
              auction={auction}
              updateTradeState={this.updateTradeState}
            />
          </div>
        }
      </React.Fragment>
    )
  }
}

class ViewMarketableItemDetails extends React.Component {
  render() {
    const {
      expiredItem, haveItems, limitedAccount, market_name, appid, countryDenied, blockedItem,
      limitedDueTwoStep, tradableAfterStr, totalBuyDepth, totalSellDepth, ordersBuy, ordersSell,
      bookLengthLimit, warningMsg, clickSell, clickBuy, haveOwnSellOrders, haveOwnBuyOrders, type
    } = this.props;

    const isNonCommodity = (type === "NONCOMMODITY");

    return (
      <React.Fragment>
        <div className="commodityOrders">
          {!expiredItem &&
            <React.Fragment>
              <div className="ordersTable sell">
                <div className="head">
                  <ViewSellButton
                    expiredItem={expiredItem}
                    limitedAccount={limitedAccount}
                    blockedItem={blockedItem}
                    limitedDueTwoStep={limitedDueTwoStep}
                    warningMsg={warningMsg}
                    clickSell={clickSell}
                    haveItems={haveItems}
                    tradableAfterStr={tradableAfterStr}
                  />
                  <div className="summary">
                    <ViewItemMarketDepthTotals
                      type="buy"
                      total={totalBuyDepth}
                      orders={ordersBuy}
                    />
                  </div>

                </div>
                {!isNonCommodity &&
                  <ViewItemMarketDepthTable
                    type="buy"
                    orders={ordersBuy}
                    total={totalBuyDepth}
                    lines={bookLengthLimit}
                  />
                }
                <ViewItemMarketDepthOwnWarning type="sell" haveOwnOrders={haveOwnSellOrders} />
              </div>
              <div className="ordersTable buy">
                <div className="head">
                  <ViewBuyButton
                    expiredItem={expiredItem}
                    blockedItem={blockedItem}
                    limitedAccount={limitedAccount}
                    countryDenied={countryDenied}
                    limitedDueTwoStep={limitedDueTwoStep}
                    warningMsg={warningMsg}
                    clickBuy={clickBuy}
                  />
                  <div className="summary">
                    <ViewItemMarketDepthTotals
                      type="sell"
                      total={totalSellDepth}
                      orders={ordersSell}
                    />
                  </div>
                </div>
                {!isNonCommodity &&
                  <ViewItemMarketDepthTable
                    type="sell"
                    orders={ordersSell}
                    total={totalSellDepth}
                    lines={bookLengthLimit}
                  />
                }
                <ViewItemMarketDepthOwnWarning type="buy" haveOwnOrders={haveOwnBuyOrders} />
              </div>
            </React.Fragment>
          }
        </div>
        {isNonCommodity && <ViewNonCommodity {...this.props} />}
        <div className="graphContainer">
          <ViewTradeTickerLog
            limitTickerLog={4}
            market_name={market_name}
            appid={appid}
          />
          <ViewOrdersGraph
            sellOrders={ordersSell /*grouped*/}
            buyOrders={ordersBuy /*grouped*/}
            totalSell={totalSellDepth}
            totalBuy={totalBuyDepth} />
          <ViewPriceHistoryGraph
            market_name={market_name}
            appid={appid}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default observer(
  class ViewItemMarketDetailsController extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        limitedAccount: false,
        haveOwnSellOrders: false,
        haveOwnBuyOrders: false,
        warningState: true
      };
    }
    componentDidMount() {
      const creds = MarketStore.credentials;
      const limitedAccount = limitedAccountMessage(creds);
      this.setState({ limitedAccount });
    }
    getInitialPrices() {
      let { ordersBuy, ordersSell, auction, totalSellDepth } = this.props.brief;
      const startBuyPrice = (ordersBuy || [])[0];
      const startSellPrice = (ordersSell || [])[0];
      let initialSellPrice = null;
      let initialBuyPrice = null;
      if (!!auction && auction?.priceStep >= 0) {
        const auctionStep = startBuyPrice ? parseFloat(auction?.priceStep) / 10000 : 0;
        const auctionStartBuyPrice = (ordersBuy || []).slice(-1).pop();
        const ownBidIndex = getBidIndex(ordersBuy, auction.salt);
        const haveOwnBid = ownBidIndex !== -1;

        if (ordersBuy.length < totalSellDepth && !haveOwnBid)
          initialBuyPrice = parseFloat((startSellPrice || {}).price);
        else if (haveOwnBid)
          initialBuyPrice = parseFloat((ordersBuy[ownBidIndex] || {}).price) + auctionStep;
        else
          initialBuyPrice = parseFloat((auctionStartBuyPrice || {}).price) + auctionStep;
      } else {
        initialSellPrice = parseFloat((startBuyPrice || startSellPrice || {}).price);
        initialBuyPrice = parseFloat((startSellPrice || startBuyPrice || {}).price);
      }
      return { initialSellPrice, initialBuyPrice };
    }
    canTrade() {
      if (this.state.limitedAccount)
        return false;
      const { classInfo, blockedAfter } = this.props;
      if (isExpired(classInfo))
        return false;
      if (isPairBlocked(blockedAfter))
        return false;
      return true;
    }
    clickSell() {
      if (!this.props.haveItems)
        return;
      if (!this.canTrade())
        return;
      const { initialSellPrice } = this.getInitialPrices();
      const { classInfo, market_name, asssid, ctxid, appid, totalAmount, instances } = this.props;
      const haveOwnOrders = this.state.haveOwnBuyOrders;
      spawnConfirmSellModal({
        appid, ctxid, asssid, hash_name: market_name, classInfo, initialPrice: initialSellPrice,
        haveOwnOrders, totalAmount, instances
      });
    }
    warningMsg() {
      this.props.updateData(this.state.warningState);
    }
    clickBuy() {
      if (!this.canTrade())
        return;
      const { classInfo, market_name, appid } = this.props;
      const { auction } = this.props.brief;
      const haveOwnOrders = this.state.haveOwnSellOrders;
      const countryDenied = isCountryDenied(classInfo);
      if (countryDenied)
        return;
      const { initialBuyPrice } = this.getInitialPrices();
      spawnConfirmDialogModal(initialBuyPrice, appid, market_name, classInfo, haveOwnOrders, auction);
    }
    render() {
      const bookLengthLimit = 5;
      const creds = MarketStore.credentials;
      const { haveOwnSellOrders, haveOwnBuyOrders, limitedAccount } = this.state;
      const { classInfo, blockedAfter, brief } = this.props;
      const { totalSellDepth, totalBuyDepth, ordersSell, ordersBuy, auction, type } = brief;
      const blockedItem = isPairBlocked(blockedAfter);
      const expiredItem = isExpired(classInfo);
      const countryDenied = isCountryDenied(classInfo);
      const limitedDueTwoStep = !!(creds.need2step && !creds.have2step);
      const auctionable = classInfo?.auctionable || false;
      let tradableAfter = !this.props.haveItems ? classInfo?.tradable_after : null;
      let tradableAfterDate = tradableAfter ? new Date(tradableAfter) : null;
      let tradableAfterStr = tradableAfterDate ? moment(tradableAfterDate).format("DD/MM/YYYY HH:mm") : null;

      return (
        <React.Fragment>
          <ViewItemDetails
            appid={this.props.appid}
            auction={auction}
            type={type}
            auctionable={auctionable}
            blockedItem={blockedItem}
            bookLengthLimit={bookLengthLimit}
            clickSell={() => this.clickSell()}
            clickBuy={() => this.clickBuy()}
            countryDenied={countryDenied}
            expiredItem={expiredItem}
            haveItems={this.props.haveItems}
            limitedAccount={limitedAccount}
            limitedDueTwoStep={limitedDueTwoStep}
            market_name={this.props.market_name}
            ordersBuy={ordersBuy}
            ordersSell={ordersSell}
            totalBuyDepth={totalBuyDepth}
            totalSellDepth={totalSellDepth}
            tradableAfterStr={tradableAfterStr}
            warningMsg={() => this.warningMsg()}
            haveOwnSellOrders={haveOwnSellOrders}
            haveOwnBuyOrders={haveOwnBuyOrders}
          />
        </React.Fragment>
      )
    }
  }
)