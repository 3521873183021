import { blockedPairsKey, refreshBlockedPairs } from 'api/rpcAPICall';
import { MarketBooksBrief } from 'api/tradeAPICall';
import ApplicationEvents from 'components/applicationEvents';
import { ViewMarketAppFilterEditor, ViewMarketTagsTerms } from 'components/marketAppFilterEditor';
import { ViewMarketedItem } from 'components/marketedAssets';
import { _I_ } from 'components/translate';
import { LogStream } from 'generics/common';
import { loadCurrentApp } from 'generics/routed.js';
import { mkHashMap } from 'generics/utils';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Scrollbar from "react-scrollbars-custom";
import { MarketStore } from 'stores';
import { ItemsContentPreloader } from './generics';

export function updateBlocked(stackedItems) {
  const clean = stackedItems.map(
    ({ appid, classInfo }) => ({
      appId: appid,
      marketName: classInfo.market_hash_name,
      marketable: classInfo.marketable
    })
  ).filter(
    it => (!!+it.appId) && (!!it.marketName) && (!!it.marketable)
  );

  const uniqPairs = Object.values(mkHashMap(blockedPairsKey, clean));

  uniqPairs.forEach(
    ({ appId, marketName }) => refreshBlockedPairs(appId, marketName)
  );
}

export const ViewUserInventory = observer(
  class ViewUserInventory extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        apps: {},
        tagFilter: (() => true),
        textFilter: (() => true),
        lastFound: 0,
        skip: 0,
        pagesize: 10,
        preloading: true
      };

      this.updateContexts = this.updateContexts.bind(this);
      this.handleTableScroll = (scrollValues, scrollBarRef) => this.loadPageIfNeeded(scrollValues, scrollBarRef);

      reaction(
        () => [MarketStore.currentAppId, MarketStore.filterTags],
        () => {
          this.setState({ "skip": 0 });
        }
      )

      reaction(
        () => MarketStore.filterText,
        () => {
          this.onNewSearch();
        }
      )

      reaction(
        () => MarketStore.credentials,
        () => {
          ApplicationEvents.emit_async("market.updateInventory");
        }
      )
    }

    loadPageIfNeeded(scrollValues, scrollbar) {
      scrollValues = scrollValues || scrollbar.current.getScrollValues();

      if (scrollValues.clientHeight && (scrollValues.scrollHeight - scrollValues.scrollTop < 1.5 * scrollValues.clientHeight))
        if (!this.state.lastFound || this.state.lastFound > this.state.pagesize)
          this.setState({ "skip": this.state.skip + this.state.pagesize })
    }

    async pageSize() {
      let screenWidth = this.props.scrollbar.clientWidth || 17;
      const lotWidth = Math.trunc((screenWidth * 17) / 100);
      let pagesizeContent = Math.trunc((screenWidth / lotWidth) * 3);
      await this.setState({ "pagesize": pagesizeContent });
    }

    updateContexts({ appid, contexts, stackedItems, amount }) {
      this.pageSize().then(() => {
        Promise.allSettled(stackedItems.map(
          (stackedItem) => {
            const { appid: assetAppId, classInfo } = stackedItem;

            if (classInfo.market_hash_name === undefined || classInfo.market_hash_name === "")
              return Promise.resolve().then(() => {
                return stackedItem;
              });

            return MarketBooksBrief(
              assetAppId, classInfo.market_hash_name
            ).then((brief) => {
              const { BUY = [], depth = {} } = (brief || {});
              const totalSellDepth = (depth || {}).BUY || 0;
              const order = (BUY || []).shift();
              const orderPrice = (order || []).shift();

              return Object.assign(stackedItem, { price: (orderPrice * 10000 || 0), depth: totalSellDepth });
            }).catch((reason) => {
              reason = Object.assign({}, reason,
                {
                  "caption": "Books Brief Error",
                  "message": "Failed to get books brief:\n" + reason.message
                });

              LogStream("MARKETAPP").error("MarketBooksBrief - Failed (", reason, ")");
            });
          })
        ).then(() => {
          this.setState((prevState, props) => {
            const apps = Object.assign({}, prevState.apps);
            const filteredItems = this.getStackedItems(stackedItems);

            const lastFound = (this.state.lastFound != filteredItems.length) ?
              filteredItems.length : this.state.lastFound;

            if (amount > 0)
              apps[appid] = { contexts, appid, amount, stackedItems };
            else
              delete apps[appid];

            updateBlocked(stackedItems);

            return { apps, lastFound, preloading: false };
          });
        });
      })
    }

    componentDidMount() {
      ApplicationEvents.on("market.inventoryUpdated", this.updateContexts);

      const appIds = (this.props.appIds || []).map(it => +it).filter(it => it > 0);
      const lruAppId = +(loadCurrentApp());
      const currentAppId = (appIds.length == 1) ? appIds[0] : lruAppId;

      if ((currentAppId > 0) && (appIds.indexOf(currentAppId) >= 0)) {
        MarketStore.setCurrentAppId(currentAppId);
        return;
      }
    }

    componentWillUnmount() {
      ApplicationEvents.off("market.inventoryUpdated", this.updateContexts);
    }

    // context
    filterFn(item) {
      if (!this.state.tagFilter(item))
        return false;

      if (!this.state.textFilter(item))
        return false;

      return true;
    }

    getStackedItems() {
      const apps = Object.values(this.state.apps);
      const tagsKey = Object.keys(MarketStore.filterTags);
      const appId = MarketStore.currentAppId;

      let sortingItem = [];
      let gameContexts = [];
      let stackedItems = [];

      if (apps.length) {
        apps.forEach((app) => {
          if (app.appid == appId || !appId) {
            gameContexts.push(app.appid);
            stackedItems = stackedItems.concat(app.stackedItems);
          } else if (app.appid != appId) {
            return null;
          }
        })

        if (tagsKey.length) {
          stackedItems.forEach((item) => {
            if (!item.classInfo.tags) return;

            item.classInfo.tags.forEach((tag) => {
              const itemTags = tag.category + ":" + tag.internal_name;
              if (tagsKey.includes(itemTags) && !sortingItem.includes(item)) {
                sortingItem.push(item);
              }
            });
          })
          stackedItems = sortingItem;
        }
      }

      return stackedItems;
    }

    renderContext() {
      const apps = Object.values(this.state.apps);
      const stackedItems = this.getStackedItems();

      if (this.state.preloading)
        return <ItemsContentPreloader />

      if (apps.length == 0)
        return <div className="nothingFound">{_I_("ViewUserInventory.common.emptyInventory")}</div>

      return (
        <PaginatedContextView
          stackedItems={stackedItems}
          pageSize={this.state.pagesize}
          filterFn={(item) => this.filterFn(item)}
          skip={this.state.skip}
        />
      );
    }

    textFilterFn(terms, item) {
      if (terms.length == 0)
        return true;

      const info = item.classInfo;
      if (!info)
        return true;

      const descriptions = (info.descriptions || []).map((d) => ((d || {}).value));

      const combinedText = [
        info.name, info.market_name,
        ...descriptions
      ].filter(Boolean).join(" ").toLowerCase();

      let allowed = terms.every((term) => (combinedText.indexOf(term) >= 0));
      return allowed;
    }

    noopFilterFn() {
      return true;
    }

    onNewSearch() {
      const filterText = MarketStore.filterText;
      const terms = filterText.split(" ").map(
        (term) => term.trim().toLowerCase()
      ).filter(Boolean);

      const boundFilterFunc = (item) => terms.length ? this.textFilterFn(terms, item) : this.noopFilterFn();

      this.setState({ "textFilter": boundFilterFunc, skip: 0 })
    }

    // context
    render() {
      return (
        <React.Fragment>
          <Scrollbar ref={this.props.scrollbar} id="content" defaultStyles={false} scrollX={false}
            style={{ display: this.props.hidden ? "none" : "" }}
            onScrollStop={this.handleTableScroll}>
            <div className="appScreen" screen="inventory">
              <div className="screenBody">
                <div className="controls">
                  <div className="left">
                    <ViewMarketAppFilterEditor
                      appIds={this.props.appIds}
                      onUpdate={() => this.setState({ skip: 0 })}
                    />
                  </div>
                  <div className="center">
                  </div>
                  <ViewMarketTagsTerms />
                </div>

                {this.renderContext()}
              </div>
            </div>
          </Scrollbar>
        </React.Fragment>
      );
    }
  }
)

class PaginatedContextView extends React.Component {
  constructor(props) {
    super(props);

    this.state = { "pageShift": 0 };
    this.stubClassInfo = {};

  }

  selectItem(item, type) {
    ApplicationEvents.emit_async("market.setCurrentAssetByHash", item.appid, item.classInfo.market_hash_name, type);
  }

  render() {
    const items = this.props.stackedItems.filter(
      this.props.filterFn
    ).sort(
      PaginatedContextView.sortByClassName
    );

    const pageSize = this.props.pageSize;
    const pageShift = (this.props.skip < items.length) ? this.props.skip : items.length;
    const pageItems = items.slice(0, pageShift + pageSize);
    const selectedItem = this.props.selectedItem;

    if (!items.length) {
      return (
        <div className="nothingFound">{_I_("ViewItemSearch.stub.empty")}</div>
      );
    }

    return (
      <React.Fragment>
        <div className="lots grid">
          {
            pageItems.map((item, idx) => {
              let highlight = "lot";
              if (!item)
                highlight += " disabled";
              else if (item == selectedItem) {
                highlight += " active";
              }

              const iconStyle = item.classInfo.name_color ? item.classInfo.name_color : {};
              const userItem = {
                "appid": item.appid,
                "classInfo": item.classInfo,
                "hash_name": item.classInfo.market_hash_name,
                "icon": item.classInfo.icon_url,
                "name": item.classInfo.market_name || item.classInfo.name,
                "tags": item.classInfo.tags,
                "totalAmount": item.totalAmount,
                "price": item.price,
                "depth": item.depth,
                "color": iconStyle
              };

              return (
                <ViewMarketedItem
                  key={`MarketedItem_${idx}`}
                  styleItem={highlight}
                  handler={() => this.selectItem(item, true)}
                  item={userItem}
                  style={userItem.color}
                />
              );
            })
          }
        </div>
      </React.Fragment>
    );
  }


  static sortByClassName(a, b) {
    if (a.classInfo.name < b.classInfo.name) return -1;
    if (a.classInfo.name > b.classInfo.name) return 1;
    return 0;
  }
}