import { AjaxCall, basicRetryPolicy, getHttpDetails, isHttpRetry } from 'api/basicApi';
import { WalletCheckExpiration } from 'api/walletAPICall'; // Ewww HACKY !
import MarketStore from 'stores/market';

// re export
export { basicRetryPolicy, getHttpDetails, isHttpRetry };

////////////////////////////////////////////
// class GenericAPICall
export class GenericAPICall  extends AjaxCall {
  constructor ( command )  {
    super( command )

    this.append( "action", command );
    this.append( "token", MarketStore.token );
  }

  preRequestHook()  {   //override
    return WalletCheckExpiration( false );
  }
};

