import { observer } from "mobx-react";
import moment from "moment/moment";
import { Fragment, useContext, useEffect, useState } from "react";
import { ItemClassInfoResolver } from "../../api/assetAPICall";
import { ClassByHashnameResolver } from "../../api/tradeAPICall";
import { NOTIFICATION_MESSAGES_AUCTIONS, NOTIFICATION_MESSAGES_TRADE } from "../../generics/constants";
import { NotificationContext } from "../../generics/marketHooks";
import { Arrow, EventIcon, GJN, NotificationsDisabled, NotificationsEnabled } from "../../generics/marketIcons";
import { HistoryStore, MarketStore } from "../../stores";
import ApplicationEvents from "../applicationEvents";
import { fakeClassInfo } from "../classInfoUtil";
import { ViewItemIconic } from "../commonAssets";
import { _I_ } from "../translate";
import MoneyUtil from "../moneyUtil";

const NotificationPopup = observer(() => {
  const { history } = HistoryStore;

  return (
    <div className="notificationPopup">
      <Arrow />

      <Fragment>
        {!history.length && (
          <div className="notificationMessages">
            <div className='noHistory'>
              <NotificationsDisabled />
              {_I_("ViewItemPopup.heading.noHistory")}
            </div>
          </div>
        )}

        {!!history.length && (
          <div className="notificationMessages">
            <RenderHistoryUnseen />
          </div>
        )}
      </Fragment>

      {!!history.length && <ViewButton />}
    </div>
  )
})

const RenderHistoryUnseen = observer(() => {
  const { unseenHistory } = HistoryStore;
  const { isShow, setIsShow } = useContext(NotificationContext);

  const handleClick = async (notification) => {
    const { id, appid, hashname } = notification;

    await HistoryStore.setSeenHistory(id);
    ApplicationEvents.emit_async("market.setCurrentAssetByHash", appid, hashname, true);
    setIsShow(!isShow);
  };

  const shortUnseenHistory = !!unseenHistory.length ? unseenHistory.slice(0, 5) : [];

  return (
    <Fragment>
      {!unseenHistory.length && (
        <div className="noHistory">
          <NotificationsEnabled />
          {_I_("ViewItemPopup.heading.unseenHistory")}
        </div>
      )}

      {shortUnseenHistory.map((notification, idx) => (
        <div key={idx} className="message" onClick={() => handleClick(notification)}>
          <div className="item">
            <ViewHistoryItem notification={notification} />
          </div>
        </div>
      ))}
    </Fragment>
  )
});

export const ViewHistoryItem = ({ notification }) => {
  const { appid, hashname, event, type, cancelReason, auction, ts, price } = notification;
  const [classInfo, setClassInfo] = useState(null);

  useEffect(() => {
    ClassByHashnameResolver.resolve(appid, hashname)
      .then((assetclass) => ItemClassInfoResolver.resolve(appid, assetclass))
      .then((classInfo) => setClassInfo(classInfo))
      .catch((reason) => {
        setClassInfo(fakeClassInfo("broken", hashname));
      });
  }, [appid, hashname]);

  const eventDate = (+ts > 0) && moment(1000 * ts).format('DD.MM.YYYY — HH:mm');
  const eventPrice = price ? MoneyUtil.formatMoney(price) : "";

  return <Fragment>
    {classInfo && (
      <Fragment>
        <ViewItemIconic classInfo={classInfo} />
        <div className="classInfoContent">
          <div className="itemInfo">
            <div className="name">{classInfo.market_name}</div>

            <ViewMessage
              eventType={event}
              tradeType={type}
              cancelReason={cancelReason}
              auction={auction}
            />
          </div>

          <div className="event">
            <div className="price">
              {!!price && <Fragment>{eventPrice} <GJN /></Fragment>}
            </div>

            <div className="eventDate">{eventDate}</div>
          </div>
        </div>
        <div className="viewItem">
          <EventIcon />
        </div>
      </Fragment>
    )}
  </Fragment>;
};

const ViewButton = () => {
  const { setIsShow } = useContext(NotificationContext);

  const handleClick = () => {
    HistoryStore.loadInitial();
    MarketStore.setPage("notifications");
    ApplicationEvents.emit_async("market.setCurrentAssetByHash"); // closes the item window
    setIsShow(false);
  }

  return (
    <div className="showAll">
      <div className="buttonShow" onClick={() => handleClick()}>
        {_I_("ViewItemPopup.showAll.button")}
      </div>
    </div>
  )
}

const ViewMessage = ({ eventType, tradeType, cancelReason, auction }) => {
  const getMessage = () => {
    if (auction) {
      if (cancelReason === "AUCTION_OUTBIDDED") {
        return NOTIFICATION_MESSAGES_AUCTIONS[cancelReason];
      }

      const messageKey = tradeType === "BUY" ? `${eventType}_${tradeType}` : eventType;
      if (eventType === "new" || eventType === "deal") {
        return NOTIFICATION_MESSAGES_AUCTIONS[messageKey];
      }

      return NOTIFICATION_MESSAGES_AUCTIONS[eventType];
    } else {
      return NOTIFICATION_MESSAGES_TRADE[`${eventType}_${tradeType}`];
    }
  };

  const messageData = getMessage();
  const classEvent = `eventMessage ${messageData.class_name}`;

  return <div className={classEvent}>{messageData.icon}{_I_(messageData.message)}</div>
};

export default NotificationPopup;