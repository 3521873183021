export const plural = (number, words) => {
  number = Math.abs(parseInt(number, 10));
  const remainder = number % 10;

  if (number > 10 && number < 20) { return words[2]; }
  if (remainder > 1 && remainder < 5) { return words[1]; }
  if (remainder === 1) { return words[0]; }

  return words[2];
};

export const locale = {
  "ViewItemPreviewSidePane.quantity.inventory": "В инвентаре ",
  "ViewItemPreviewSidePane.quantity.ordersInventory": " предметов",
  "ViewItemPreviewSidePane.quantity.count": " в продаже",
  "ViewItemPreviewSidePane.quantity.countBuy": " заявок на покупку",
  "ViewItemPreviewSidePane.quantity.items": " предметов",
  "ViewMarketedItem.SoldOutText": "распродано",
  "ViewMarketedItem.SoldOutTip": "Нет предложений на продажу",
  "ViewMarketedItem.NoBuyText": "нет заявок на покупку",
  "ViewMarketedItem.SellingFrom": "Предлагается от...",
  "ViewMarketedItem.SellingMore": " и выше",
  "ViewMarketedItem.BuyingMore": " и ниже",
  "ViewMarketedItem.tradableAfter": "станет доступен для продажи",
  "ViewItemSearch.heading.showAll": "Все позиции",
  "ViewItemSearch.heading.orders": "Мои заявки",
  "ViewItemSearch.heading.tooltip": "Ваша ставка перебита",
  "ViewItemSearch.heading.tooltip.win": "Ваша ставка выиграла",
  "ViewItemPopup.committed.SELL": "Совершена продажа",
  "ViewItemPopup.committed.BUY": "Совершена покупка",
  "ViewItemPopup.committed.BID": "Совершена ставка",
  "ViewItemPopup.application.BUY": "Создана заявка на покупку",
  "ViewItemPopup.application.SELL": "Создана заявка на продажу",
  "ViewItemPopup.application.BID": "Сделана ставка",
  "ViewItemPopup.canceled.BUY": "Отменена заявка на покупку",
  "ViewItemPopup.canceled.SELL": "Отменена заявка на продажу",
  "ViewItemPopup.canceled.BID": "Отменена ставка",
  "ViewItemPopup.showAll.button": "Посмотреть все",
  "ViewItemAssets.readAll.button": "Отметить все просмотренными",
  "ViewItemPopup.heading.noHistory": "Уведомлений еще нет",
  "ViewItemPopup.heading.unseenHistory": "Пока нет свежих уведомлений",
  "ViewItemSearch.heading.marketSell": "В продаже",
  "ViewItemSearch.heading.marketBuy": "Заявки на покупку",
  "ViewItemSearch.heading.marketAuctions": "Аукционы",
  "ViewItemSearch.title.marketAuction": "Аукцион",
  "ViewItemSearch.title.ending": "До окончания",
  "ViewItemSearch.title.finished": "Аукцион завершен",
  "ViewItemSearch.heading.settings": "Настройки",
  "ViewMarketedItem.button.more": "Подробнее",
  "ViewItemSearch.heading.resultsFor": "Результаты для:",
  "ViewItemSearch.stub.empty": "Ничего не найдено",
  "ViewItemSearch.termsPlaceholder": "Поиск",
  "ViewItemSearch.label.search": "Поиск",
  "ViewMarketedItem.lovestPrice": "от ",
  "ViewMarketedItem.beforePrice": "до ",
  "ViewMarketedItem.yourBid": "ваша ставка ",
  "ViewMarketedItem.bid": "ставка ",
  "ViewItemSearch.heading.exchange": "Главная",
  "ViewItemSearch.heading.light": "Светлая",
  "ViewItemSearch.heading.dark": "Темная",
  "ViewItemSearch.filteringAnnounce": "Для того чтобы фильтровать заявки надо выбрать игру",
  "ViewItemSearch.ordersBuy": "КУПИТЬ",
  "ViewItemSearch.ordersSell": "ПРОДАТЬ",
  "ViewItemSearch.ordersOpen": "Посмотреть полную историю",
  "MarketApp.Navbar.inventoryLink": "Мой инвентарь",
  "MarketApp.Navbar.ordersLink": "Мой кошелёк",
  "MarketApp.Navbar.marketLink": "Биржа",
  "MarketApp.Navbar.settingsLink": "Настройки",
  "MarketApp.Navbar.replenishLink": "Пополнить",
  "MarketApp.Navbar.balanceHistoryLink": "История баланса",
  "MarketApp.Navbar.buyCoinsLink": "Купить GaijinCoin'ы",
  "MarketApp.Navbar.profileLink": "Профиль",
  "MarketApp.Navbar.reloginLink": "Сменить аккаунт",
  "MarketApp.Navbar.toOldVersion": "Перейти к старой версии",
  "MarketApp.Navbar.linkFeedback": "Оставить отзыв",
  "ViewTradeDialog.caption": "Торговля",
  "ViewTradeDialog.button.back": "Назад",
  "NotAllowedMessage.caption": "Нет доступа",
  "NotAllowedMessage.body.h1": "Доступ к бирже",
  "NotAllowedMessage.body.h3": "закрыт для вашего аккаунта",
  "NotAllowedMessage.relogin": "Сменить аккаунт",
  "CountryDeniedMessage.caption": "Нет доступа",
  "CountryDeniedMessage.body.notAllowed": "Доступ к бирже закрыт",
  "CountryDeniedMessage.body.unclearStatus": "Из-за неясного юридического статуса биржевых транзакций в вашей стране доступ к бирже отключен",
  "LandingMessage.caption": "Аутентификация",
  "LandingMessage.body": "Финальные проверки...",
  "TokenExpiredMessage.caption": "Аутентификация истекла",
  "TokenExpiredMessage.body.h1": "Аутентификация истекла",
  "TokenExpiredMessage.body.h3": "Для продолжения необходимо повторно авторизоваться",
  "TokenExpiredMessage.relogin": "Авторизоваться",
  "AuthErrorMessage.caption": "Не удалось аутентифицироваться",
  "AuthErrorMessage.body.h1": "Не удалось аутентифицироваться",
  "AuthErrorMessage.body.code": "Код ошибки:",
  "AuthErrorMessage.body.desc": "Вы можете попробовать переавторизоваться",
  "AuthErrorMessage.relogin": "Авторизоваться",
  "AuthorizationRequiredMessage.caption": "Требуется аутентификация",
  "AuthorizationRequiredMessage.body.h3.1": "Для входа на биржу",
  "AuthorizationRequiredMessage.body.h3.2": "Биржа Gaijin",
  "AuthorizationRequiredMessage.body.h3.3": "требуется авторизация",
  "AuthorizationRequiredMessage.login": "Войти через Gaijin.Net",
  "CheckingAuthMessage.caption": "Аутентификация",
  "CheckingAuthMessage.body": "Аутентификация, пожалуйста подождите...",
  "CancelOrdersPanel.cancelError": "\nНе удалось отменить заявку ",
  "CancelOrdersPanel.loading": "Проверка списка заявок",
  "CancelOrdersPanel.done": "Операция завершена.",
  "CancelOrdersPanel.errors": "Возникли ошибки: \n",
  "CancelOrdersPanel.loaded.youHave": "На текущий момент у вас выставлены ",
  "CancelOrdersPanel.loaded.sellOrders": " заявки(заявок) на продажу на сумму ",
  "CancelOrdersPanel.loaded.buyOrders": " заявки(заявок) на покупку на сумму ",
  "CancelOrdersPanel.loaded.and": " и ",
  "CancelOrdersPanel.loaded.shouldCancel": " и вам необходимо их все отменить. ",
  "CancelOrdersPanel.loaded.doCancel": "Отменить заявки",
  "CancelOrdersPanel.cancelling.processing": "Обрабатываются",
  "CancelOrdersPanel.cancelling.outOf": " из ",
  "CancelOrdersPanel.cancelling.sellOrders": " заявок на продажу",
  "CancelOrdersPanel.cancelling.buyOrders": " заявок на покупку",
  "ClassInfoDescriptions.dropped_item.block_label": "Содержит один из:",
  "ClassInfoDescriptions.dropped_item.stage-unknown": "Неизвестно",
  "ClassInfoDescriptions.dropped_item.stage-pending": "...загружается",
  "ClassInfoDescriptions.text.block_label": "",
  "ClassInfoDescriptions.dropped_bundle.block_label": "Содержит один из перечисленных предметов:",
  "ClassInfoDescriptions.buttonLabel.top": "Показать",
  "ClassInfoDescriptions.buttonLabel.hide": "Скрыть",
  "ClassInfoDescriptions.buttonLabel.bottom": "все",
  "ClassInfoDescriptions.dropped_text.description": "Описание",
  "ClassInfoUtil.fake_name.pending": "загрузка",
  "ClassInfoUtil.fake_name.broken": "Удаленный предмет",
  "ClassInfoUtil.SynthTag.marketable1": "Можно продать",
  "ClassInfoUtil.SynthTag.marketable0": "Нельзя продать",
  "ClassInfoUtil.SynthTag.tradable1": "Можно обмениваться",
  "ClassInfoUtil.SynthTag.tradable0": "Нельзя обмениваться",
  "ClassInfoUtil.SynthTag.commodity1": "Массовый товар",
  "ClassInfoUtil.SynthTag.commodity0": "Штучный товар",
  "ConfirmBuyDialog.title": "Покупка",
  "BuyProcessingDialog.title": "В процессе...",
  "BuyProcessingDialog.pleaseWait": "Выполняется, пожалуйста подождите",
  "ProcessingDialog.notItem": "Запрошенный товар отсутствует на бирже или недоступен для торговли",
  "SellDialog.title": "Продажа",
  "SellProcessingDialog.title": "В процессе...",
  "SellProcessingDialog.pleaseWait": "Выполняется, пожалуйста подождите",
  "LimitedAccountPanel.2step.reason": "Необходимо настроить двухэтапную авторизацию, чтобы торговать на бирже",
  "LimitedAccountPanel.2step.link": "Подробнее о двухэтапной авторизации",
  "LimitedAccountPanel.2step.href": "https://support.gaijin.net/hc/ru/articles/203623622-%D0%9A%D0%B0%D0%BA-%D0%BF%D0%BE%D0%B4%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D1%8C-%D0%B8-%D0%BE%D1%82%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D1%8C-%D0%B4%D0%B2%D1%83%D1%85%D1%8D%D1%82%D0%B0%D0%BF%D0%BD%D1%83%D1%8E-%D0%B0%D0%B2%D1%82%D0%BE%D1%80%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D1%8E-Google-Authenticator-%D0%BF%D1%80%D0%B8%D0%B2%D1%8F%D0%B7%D0%BA%D0%B0-%D0%BA-%D1%82%D0%B5%D0%BB%D0%B5%D1%84%D0%BE%D0%BD%D1%83",
  "LimitedAccountPanel.email.reason": "Необходимо подтвердить ваш email",
  "LimitedAccountPanel.email.link": "Перейти к вашему профилю",
  "LimitedAccountPanel.2step.toPlug": "Подключить",
  "LimitedAccountPanel.email.href": "https://store.gaijin.net/user.php",
  "LimitedAccountPanel.title": "На аккаунт действуют ограничения по причине:",
  "LimitedAccountPanel.conclusion": "После изменения настроек безопасности необходимо полностью выйти из аккаунта и войти снова чтобы изменения вступили в силу",
  "LimitedAccountPanel.relogin": "Сменить аккаунт",
  "EmailVerificationStateIcon.success": "Подтвержденый аккаунт",
  "EmailVerificationStateIcon.warning": "Ограниченый аккаунт, не подтверждена почта",
  "EmailVerificationStateIcon.danger": "Ограниченый аккаунт, НЕОБХОДИМО подтвердить почту",
  "TwoFactorStateIcon.success": "Аккаунт защищен двухэтапной авторизацией",
  "TwoFactorStateIcon.warning": "Ограниченый аккаунт, нет двухэтапной авторизации",
  "TwoFactorStateIcon.danger": "Ограниченый аккаунт, НЕОБХОДИМА двухэтапная авторизация",
  "ViewInventoryTextFilter.common.label": "Чтобы отфильтровать инвентарь, необходимо выбрать игру",
  "ItemBuyEditor.label.wheelAmountInput": "Можно использовать колесо прокрутки для изменения,\nalt+колесо = 10x скорость,\nshift+колесо=0.1x скорость",
  "ItemBuyEditor.label.wheelInput": "Можно использовать колесо прокрутки для изменения,\nalt+колесо = 10x скорость,\nshift+колесо=0.1x скорость",
  "ItemBuyEditor.note.posAmount": "Количество должно быть положительным",
  "ItemBuyEditor.note.maxAmount": "Количество не должно превосходить 1000",
  "ItemBuyEditor.note.invalidAmount": "Количество должно быть целым положительным числом",
  "ItemBuyEditor.note.minPrice": "Минимальная цена",
  "ItemBuyEditor.note.maxTotal": "Максимальная сумма превышает",
  "ItemBuyEditor.note.invalidPrice": "Цена должна быть положительным числом",
  "ItemBuyEditor.note.mustAgree": "Необходимо принять Пользовательское соглашение",
  "ItemBuyEditor.note.insufficentFunds0": "На балансе недостаточно",
  "ItemBuyEditor.note.insufficentFunds1": "для этого заказа",
  "ItemBuyEditor.label.buyCouns": "Купить",
  "ItemBuyEditor.label.submit": "Купить",
  "ItemBuyEditor.label.price": "Цена за единицу:",
  "ItemBuyEditor.label.amount": "Количество:",
  "ItemBuyEditor.label.totalPrice": "Итого (включая комиссию):",
  "ItemBuyEditor.label.agree": "Я соглашаюсь с",
  "ItemBuyEditor.label.ToU": "Пользовательским соглашением",
  "MarketDepthTotals.noOrders.sell": "Нет заявок на продажу",
  "MarketDepthTotals.noOrders.buy": "Нет заявок на покупку",
  "MarketDepthTotals.noOrders.auction": "Нет ставок",
  "MarketDepthTotals.starting.sell": " за ",
  "MarketDepthTotals.starting.buy": " от ",
  "MarketDepthTotals.andMore.sell": " и ниже",
  "MarketDepthTotals.andMore.buy": " и выше",
  "MarketDepthTotals.prevew.sell": "Покупают: ",
  "MarketDepthTotals.prevew.buy": "В продаже: ",
  "MarketDepthOwnWarning.sell": "У вас есть ожидающие заявки на продажу",
  "MarketDepthOwnWarning.buy": "У вас есть ожидающие заявки на покупку",
  "MarketDepthTable.caption.price": "Цена",
  "MarketDepthTable.caption.quantity": "Количество",
  "MarketDepthTable.ellipsis.sell": "и выше",
  "MarketDepthTable.ellipsis.buy": "и ниже",
  "ItemMarketDetails.buyButton": "Купить",
  "ItemMarketDetails.sellButton": "Продать",
  "ItemMarketDetails.nothingToSellButton": "Нет предметов",
  "ItemMarketDetails.makeAuction": "Создать аукцион",
  "ItemMarketDetails.makeBid": "Сделать ставку",
  "ItemMarketDetails.bid": "Ставка",
  "ItemMarketDetails.yourBid": "Ваша ставка:",
  "ItemMarketDetails.notifications.yourBid": "Ваша ставка",
  "ItemMarketDetails.yourBid.short": "ваша",
  "ItemMarketDetails.yourBid.win": "выигрывает",
  "ItemMarketDetails.yourBid.loose": "проигрывает",
  "ItemSellEditor.label.priceStep": "Шаг цены",
  "ItemSellEditor.label.endDate": "Дата окончания",
  "limitedAccountMessage.requires": "Необходимо:",
  "limitedAccountMessage.2step": "двухэтапная авторизация",
  "limitedAccountMessage.verifiedEmail": "провереный email",
  "limitedAccountMessage.and": " и ",
  "ItemSellEditor.label.wheelInput": "Можно использовать колесо прокрутки для изменения,\nalt+колесо = 10x скорость,\nshift+колесо=0.1x скорость",
  "ItemSellEditor.note.minPrice": "Минимальная цена",
  "ItemSellEditor.note.maxPrice": "Максимальная цена",
  "ItemSellEditor.note.maxTotals": "Максимальная сумма",
  "ItemSellEditor.note.maxBalance": "Баланс не должен превосходить",
  "ItemSellEditor.note.mustAgree": "Необходимо принять Пользовательское соглашение",
  "ItemSellEditor.label.price": "Цена для покупателя (включая комиссию):",
  "ItemSellEditor.label.wished": "Вы получаете, после отчисления комиссии:",
  "ItemSellEditor.label.agree": "Я соглашаюсь с",
  "ItemSellEditor.label.ToU": "Пользовательским соглашением",
  "ItemSellEditor.label.submit": "Продать",
  "ItemSellEditor.currentBalance.before": "У Вас есть",
  "ItemSellEditor.currentBalance.after": "на вашем балансе",
  "ItemSellEditor.sellOrdersTotal.before": "плюс",
  "ItemSellEditor.sellOrdersTotal.after": "в заявках на продажу",
  "ItemSellEditor.buyOrdersTotal.before": "и",
  "ItemSellEditor.buyOrdersTotal.after": "в заявках на покупку",
  "ItemSellEditor.label.amount": "Сколько вы хотите продать:",
  "AppFilterEditor.label.byTags": "Фильтр",
  "AppFilterEditor.label.clearFilter": "Очистить фильтр",
  "AppFilterEditor.label.byTitles": "Фильтр по играм",
  "AppFilterEditor.label.allFilter": "Все игры",
  "AppFilterEditor.label.authenticity": "Подлинность",
  "AppFilterEditor.message.filter": "Фильтры уникальны для каждой игры.",
  "AppFilterEditor.message.game": "Выберите игру:",
  "ViewItemDetailed.restriction.amount.items": " предметов",
  "ViewItemDetailed.restriction.nonMarketable": "Не может быть продан",
  "ViewItemDetailed.restriction.blockedAfter": "Торговля остановится",
  "ViewItemDetailed.restriction.blockedPair": "Торговля остановлена",
  "ViewItemDetailed.restriction.expiresAt1": "Пропадет после",
  "ViewItemDetailed.restriction.expiresAt2": "",
  "ViewItemDetailed.restriction.endedAt": "Окончание аукциона",
  "ViewItemDetailed.restriction.amount": "Лот содержит",
  "ViewItemDetailed.restriction.pieces": "шт.",
  "ViewItemDetailed.restriction.startPrice": "Начальная цена",
  "ViewItemDetailed.restriction.days": "д.",
  "ViewItemDetailed.restriction.hours": "ч.",
  "ViewItemDetailed.restriction.minutes": "м.",
  "ViewItemDetailed.restriction.leadingBids": "лидирующие ставки",
  "ViewItemDetailed.restriction.noBids": "Ставок еще нет. Сделайте первую ставку!",
  "ViewItemDetailed.restriction.delayTrade": "После покупки, предмет будет доступен для продажи через",
  "ViewItemDetailed.restriction.unclearStatus": "Из-за неясного юридического статуса трофеев в вашей стране, все транзакции с ними и ключами заблокированы для вашего аккаунта.",
  "MarketSortOrder.label.name": "Имя",
  "MarketSortOrder.hint.newDesc": "Новинки",
  "MarketSortOrder.hint.newAsc": "Сначала старые",
  "MarketSortOrder.label.price": "Цена",
  "MarketSortOrder.hint.priceAsc": "Сначала дешевые",
  "MarketSortOrder.hint.priceDesc": "Сначала дорогие",
  "MarketSortOrder.label.amount": "Колич-во",
  "MarketSortOrder.hint.amountAsc": "По нарастанию количества",
  "MarketSortOrder.hint.amountDesc": "По убыванию количества",
  "MarketSortOrder.label.popular": "Популярные",
  "MarketSortOrder.label.popularItems": "Популярные товары",
  "DoubleSellErrorModal.heading": "Ошибка",
  "DoubleSellErrorModal.prompt": "Возникла ошибка при выставлении заявки",
  "DoubleSellErrorModal.explanation": "Не удалось выставить этот товар на продажу\nОдна из возможных причин - он уже был продан или использован в игре\nПопробуйте обновить страницу",
  "DoubleSellErrorModal.okButton": "OK",
  "OnBuyReserveFailModal.heading": "Ошибка",
  "OnBuyReserveFailModal.prompt": "Возникла ошибка при выставлении заявки",
  "OnBuyReserveFailModal.explanation": "Ваша заявка на покупку не создана - не удалось зарезервировать необходимую сумму на счету",
  "OnBuyReserveFailModal.youNeed1": "Необходимо иметь не менее",
  "OnBuyReserveFailModal.youNeed2": "на счету для этой операции",
  "OnBuyReserveFailModal.tryRefresh": "Убедитесь что в кошельке достаточно средств и обновите страницу",
  "OnBuyReserveFailModal.okButton": "OK",
  "DoubleRateErrorModal.heading": "Ошибка",
  "DoubleRateErrorModal.prompt": "Возникла ошибка при выставлении ставки",
  "DoubleRateErrorModal.explanation": "Попробуйте сделать ставку позже",
  "DoubleRateErrorModal.okButton": "OK",
  "AuctionFinishedErrorModal.heading": "Ошибка",
  "AuctionFinishedErrorModal.prompt": "Возникла ошибка при выставлении ставки",
  "AuctionFinishedErrorModal.explanation": "Аукцион был завершен",
  "AuctionFinishedErrorModal.okButton": "OK",
  "CancelOrderErrorModal.heading": "Ошибка",
  "CancelOrderErrorModal.prompt": "Возникла ошибка при отмене",
  "CancelOrderErrorModal.explanation": "Это могло произойти из-за проблем с сетью или вы попали на момент обновления заявки.\nПопробуйте повторить операцию немного позднее.",
  "CancelOrderErrorModal.cancelButton": "Окей",
  "OrdersListContainer.Tab.sellOrders": "Заявки на продажу",
  "OrdersListContainer.Tab.buyOrders": "Заявки на покупку",
  "OrdersListContainer.Tab.historyList": "История заявок",
  "ViewOrdersEntry.amounts.remain": "Остаток",
  "ViewOrdersEntry.amounts.done": "Завершено",
  "ViewOrdersEntry.order.order": "Заявка",
  "ViewOrdersEntry.order.issued": "От",
  "ViewOrdersEntry.order.executed": "Исполнен",
  "ViewOrdersEntry.order.pairIsBlocked": "Торговля остановлена",
  "ViewOrdersEntry.order.pairWillBlockAfter": "Торговля остановится",
  "ViewOrdersEntry.order.BUY": "Покупка",
  "ViewOrdersEntry.order.SELL": "Продажа",
  "ViewOrdersEntry.order.view": "Открыть",
  "ViewOrdersEntry.order.cancel": "Отменить",
  "ViewOrdersList.empty.label": "Тут нет ничего",
  "CancelOrderDialog.heading.youSure": "Уверены?",
  "CancelOrderDialog.body.youSure": "Вы уверены что хотите отменить заявку?",
  "CancelOrderDialog.button.cancel": "Да, отменить заявку",
  "CancelAllOrderDialog.button.cancel": "Да, для всех",
  "CancelOrderDialog.button.noCancel": "Нет",
  "CancelOrderDialog.heading.processing": "Выполняется...",
  "CancelOrderDialog.body.processing": "Пожалуйста подождите",
  "ProfileMenu.MenuItem.Settings": "Настройки",
  "ProfileMenu.MenuItem.GaijinAccount": "Профиль Gaijin.net",
  "ProfileMenu.MenuItem.AccountSwitch": "Сменить аккаунт",
  "ProfileMenu.MenuItem.Signout": "Выйти",
  "ProfileMenu.MenuItem.nick": "Ваш никнейм",
  "WalletBlock.balance.text": "Баланс:",
  "WalletBlock.balance.buyCurrency": "Купить GJN",
  "SecurityBlock.message.title": "Двухфакторная авторизация",
  "SecurityBlock.emailVerified.title": "Email",
  "OrdersHistory.title.buy": "Заявки на покупку",
  "OrdersHistory.title.sell": "Заявки на продажу",
  "NotificationsAssets.title": "Уведомления",
  "NotificationsAssets.title.today": "Сегодня",
  "NotificationsAssets.title.historyFromLastWeek": "За последние семь дней",
  "NotificationsAssets.title.historyOlder": "Ранее",
  "LimitedAccountPanel.2step.pleas": "Защитите ваш аккаунт, чтобы получить доступ к торговле на бирже.",
  "LimitedAccountPanel.2step.protected": "Ваш аккаунт защищен, у вас есть доступ к торговле на бирже.",
  "LimitedAccountPanel.email.pleas": "Подтвердите его, чтобы получить доступ к торговле на бирже.",
  "LimitedAccountPanel.email.protected": "Ваш e-mail подтвержден, у вас есть доступ к торговле на бирже.",
  "OrdersListContainer.Opened.Orders": "Открытые",
  "OrdersListContainer.Closed.Orders": "Закрытые",
  "ViewOrdersList.empty.labelCollapse": "Свернуть",
  "ViewOrdersList.empty.labelExpand": "Развернуть",
  "OrdersGraph.plot.title": "Покупка/Продажа",
  "OrdersGraph.fmt.offers.sell": "заявки(заявок)\n<br/>на продажу\n<br/>по",
  "OrdersGraph.fmt.andMore.sell": "и ниже",
  "OrdersGraph.fmt.offers.buy": "заявки(заявок)\n<br/>на покупку\n<br/>от",
  "OrdersGraph.fmt.andMore.buy": "и выше",
  "Orders.title.header": "заявки",
  "PriceHistoryGraph.plot.title": "История цен:",
  "PriceHistoryGraph.highlighter.format": "На <strong>%s</strong><br />цена <strong>%s</strong><br />количество <strong>%s</strong><br />",
  "PriceHistoryGraph.plot.shortRange": "Ближайшее время",
  "PriceHistoryGraph.plot.wholeRange": "За все время",
  "DealEventTickerEntry.purchasedFrom": "купил у",
  "DealEventTickerEntry.for": "за",
  "BookSellTickerEntry.listedItem": "заявка на продажу",
  "BookBuyTickerEntry.created": "выставил",
  "BookBuyTickerEntry.for": "заявка на покупку",
  "BookAuctionTickerEntry.created": "сделал ставку",
  "ViewTradeTickerLog.waiting": "Ожидание активности...",
  "ViewTradeTickerLog.ticker": "Торговля в реальном времени...",
  "ViewUserInventory.common.emptyInventory": "Инвентарь пока пуст",
  "ViewItemPreviewSidePane.UnselectButton.label": "Фильтры",
  "ViewItemPreviewSidePane.TradeButton.label": "Продать/Купить",
  "ViewItemPreviewSidePane.ViewButton.label": "Подробнее",
  "ViewItemPreviewSidePane.QuickSellButton.label": "Продать",
  "ViewInventoryTagCloud.tagTitle.itemsOf": "предмета(ов)",
  "ViewInventoryTagCloud.tagTitle.varietiesMarkedAs": "разновидности(ей), отмеченых как",
  "ViewItemSearch.heading.inventory": "Инвентарь",
  "WalletBlock.circleSmall.settings": "Настройки",
  "WalletBlock.circleSmall.exit": "Выход",
  "UserSettingsDialog.language": "Язык",
  "UserSettingsDialog.user_settings": "Настройки пользователя",
  "UserSettingsDialog.settings": "Настройки",
  "UserSettingsDialog.save_settings": "Сохранить настройки",
  "UserSettingsDialog.privacySettings": "Торговля",
  "UserSettingsDialog.privacySettingsPublic": "Публичная",
  "UserSettingsDialog.privacySettingsPrivate": "Приватная",
  "UserSettingsDialog.interface": "Интерфейс",
  "UserSettingsDialog.interfaceOld": "Старый",
  "UserSettingsDialog.interfaceNew": "Новый",
  "WalletLimitsIcon.soft_limit": "Превышен лимит GJN, функции пополнения недоступны",
  "WalletLimitsIcon.hard_limit": "Превышен лимит GJN, функции продажи и пополнения недоступны",
  "ActionBar.action.ingamePreview": "Посмотреть в игре",
  "ActionBar.action.previewCopied": "Ссылка скопирована!",
  "ActionBar.action.previewShare": "Поделиться",
  "IngamePreview.ask.heading": "Посмотреть в игре?",
  "IngamePreview.ask.prompt": "Этот предмет можно посмотреть в игре",
  "IngamePreview.ask.explanation": "Убедитесь, что игровой клиент запущен под вашей учётной записью",
  "IngamePreview.ask.cancelButton": "Нет",
  "IngamePreview.done.heading": "Готово",
  "IngamePreview.done.prompt": "",
  "IngamePreview.done.explanation": "Отправлено успешно",
  "IngamePreview.done.okButton": "Ок",
  "IngamePreview.fail.heading": "Что-то пошло не так",
  "IngamePreview.fail.prompt": "",
  "IngamePreview.fail.explanation": "Не удалось связаться с игровым клиентом",
  "IngamePreview.fail.cancelButton": "Ок",
  "IngamePreview.pending.heading": "Секундочку...",
  "IngamePreview.pending.prompt": "Отправка уведомления игровому клиенту",
  "IngamePreview.pending.explanation": "",
  "IngamePreview.ask.okButton": "Да",
  "StaticConfigLoader.caption.error": "Ошибка инициализации",
  "StaticConfigLoader.caption.init": "Инициализация",
  "StaticConfigLoader.text.init": "Инициализация приложения...",
  "StaticConfigLoader.error.settings": "Не удалось загрузить настройки приложения",
  "StaticConfigLoader.error.circuits": "Не удалось загрузить настройки контура",
  "ControlButton.close": "Закрыть",
  "Exit": " ",
  "Legal.TermsConditions": "Условия использования",
  "Legal.TermsService": "Условия предоставления сервисов",
  "Legal.PrivacyPolicy": "Политика конфиденциальности",
  "Legal.CookiesPolicy": "Политика использования cookie",
  "Legal.TradePolicy": "Политика Маркетплейса",
  "Legal.TermsConditions.linck": "https://legal.gaijin.net/ru/",
  "Legal.TermsService.linck": "https://legal.gaijin.net/ru/termsofservice/",
  "Legal.PrivacyPolicy.linck": "https://legal.gaijin.net/ru/privacypolicy/",
  "Legal.CookiesPolicy.linck": "https://legal.gaijin.net/ru/cookie/",
  "Legal.TradePolicy.linck": "https://legal.gaijin.net/ru/tradepolicy/",
  "Legal.Copyright": "Gaijin Network Ltd. Все товарные знаки, наименования и логотипы принадлежат их соответствующим правообладателям.",
  "MarketApp.Navbar.HeadTitle": "Gaijin.Net",
  "MarketApp.Navbar.HeadSubTitle": "Market"
};
