import { SettingsInjections } from 'generics/settingsInjections';
import React from 'react';
import { _I_ } from './translate';

export default class ViewLegalFooter extends React.Component {
  render() {
    const target = "_blank";
    const currentYear = new Date().getFullYear();
    const mode = SettingsInjections.getMode();

    return (
      <div className="footer">
        {mode !== 'pixstorm' &&
          <React.Fragment>
            <div className="legalLincks">
              <a className="legalTerms" target={target} href={_I_("Legal.TermsConditions.linck")}>{_I_("Legal.TermsConditions")}</a>
              <a className="legalTerms" target={target} href={_I_("Legal.TermsService.linck")}>{_I_("Legal.TermsService")}</a>
              <a className="legalTerms" target={target} href={_I_("Legal.PrivacyPolicy.linck")}>{_I_("Legal.PrivacyPolicy")}</a>
              <a className="legalTerms" target={target} href={_I_("Legal.CookiesPolicy.linck")}>{_I_("Legal.CookiesPolicy")}</a>
              <a className="legalTerms" target={target} href={_I_("Legal.TradePolicy.linck")}>{_I_("Legal.TradePolicy")}</a>
            </div>

            <div className="copyright">© {currentYear} {_I_("Legal.Copyright")}</div>
          </React.Fragment>
        }
        {mode == 'pixstorm' &&
          <div className="copyright">{_I_("Legal.Copyright")} <a className="legalTerms" target={target} href={_I_("Legal.Copyright.href")}>{_I_("Legal.Copyright.link")}</a></div>
        }
      </div>
    );
  }
}