import React, { Fragment } from "react";
import { ViewAppIdIcon } from "./commonAssets";
import { ShareActionBar } from "./hooks/itemDetailedHooks";
import ApplicationEvents from "./applicationEvents";

const ItemInfoBlock = ({appid, classInfo}) => {

  const closeAssetPage = () => ApplicationEvents.emit_async("market.setCurrentAssetByHash");

  let hex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(classInfo.name_color);
  let rgb = null;
  if (hex != null) {
    rgb = "rgb(" + parseInt(hex[1], 16) + "," + parseInt(hex[2], 16) + "," + parseInt(hex[3], 16) + ")";
  };
  const iconStyle = classInfo.name_color ?
    {
      "backgroundColor": rgb,
      "boxShadow": "0px 6px 16px " + rgb
    } : {};

  return (
    <Fragment>
      <div className="rarity" style={iconStyle} />
      <div className="close"
           type="button"
           onClick={closeAssetPage}
      >
        <img className="icon"
             onClick={closeAssetPage}
             src="images/icons/close_merged.png"
        />
      </div>
      <div className="gameInfo">
        <div className="gameBlock">
          <ViewAppIdIcon appId={appid} compact />
        </div>
        <span className="itemName">{classInfo.name}</span>
        <ShareActionBar classInfo={classInfo} appId={appid} />
      </div>
    </Fragment>
  )
}

export default ItemInfoBlock;