export const plural = (number, words) => {
  return Math.abs(parseInt(number, 10)) === 1 ? words[0] : words[1];
};

export const locale = {
  "ViewItemPreviewSidePane.quantity.inventory": " ",
  "ViewItemPreviewSidePane.quantity.ordersInventory": " articles de l'inventaire",
  "ViewItemPreviewSidePane.quantity.count": " on sale",
  "ViewItemPreviewSidePane.quantity.countBuy": " buy orders",
  "ViewItemPreviewSidePane.quantity.items": " articles",
  "ViewMarketedItem.SoldOutText": "épuisé",
  "ViewMarketedItem.SoldOutTip": "Pas d'ordre de vente",
  "ViewMarketedItem.NoBuyText": "no buy orders",
  "ViewMarketedItem.SellingFrom": "Vendre à partir de ...",
  "ViewMarketedItem.SellingMore": " or more",
  "ViewMarketedItem.BuyingMore": " ou moins",
  "ViewMarketedItem.tradableAfter": "sera disponible à la vente après le",
  "ViewItemSearch.heading.showAll": "Afficher toutes les annonces",
  "ViewItemSearch.heading.orders": "My orders",
  "ViewItemSearch.heading.marketSell": "Sell orders",
  "ViewItemSearch.heading.marketBuy": "Buy orders",
  "ViewItemSearch.heading.marketAuctions": "Enchères",
  "ViewItemSearch.title.marketAuction": "Enchère",
  "ViewItemSearch.title.ending": "Jusqu'à la fin",
  "ViewItemSearch.title.finished": "Enchère terminée",
  "ViewItemSearch.heading.settings": "Settings",
  "ViewMarketedItem.button.more": "More details",
  "ViewItemSearch.heading.resultsFor": "Montrer les résultats pour:",
  "ViewItemSearch.stub.empty": "Résultat de recherche vide",
  "ViewItemSearch.heading.tooltip": "Votre enchère a été surpassée",
  "ViewItemSearch.heading.tooltip.win": "Votre enchère a gagné",
  "ViewItemPopup.committed.SELL": "Vente terminée",
  "ViewItemPopup.committed.BUY": "Achat terminé",
  "ViewItemPopup.committed.BID": "Offre placée",
  "ViewItemPopup.application.BUY": "Ordre d'achat créé",
  "ViewItemPopup.application.SELL": "Demande de vente créée",
  "ViewItemPopup.application.BID": "Offre faite",
  "ViewItemPopup.canceled.BUY": "Ordre d'achat annulé",
  "ViewItemPopup.canceled.SELL": "Demande de vente annulée",
  "ViewItemPopup.canceled.BID": "Offre annulée",
  "ViewItemPopup.showAll.button": "Voir tout",
  "ViewItemAssets.readAll.button": "Marquer tout comme vu",
  "ViewItemPopup.heading.noHistory": "Pas encore de notifications",
  "ViewItemPopup.heading.unseenHistory": "Pas de nouvelles notifications",
  "ViewItemSearch.termsPlaceholder": "Rechercher des termes",
  "ViewItemSearch.label.search": "Rechercher des items",
  "ViewMarketedItem.lovestPrice": "starting at ",
  "ViewMarketedItem.beforePrice": "under ",
  "ViewMarketedItem.yourBid": "votre enchère ",
  "ViewMarketedItem.bid": "enchère ",
  "ViewItemSearch.heading.exchange": "Home",
  "ViewItemSearch.heading.light": "Light",
  "ViewItemSearch.heading.dark": "Dark",
  "ViewItemSearch.filteringAnnounce": "In order to filter orders you must select a game",
  "ViewItemSearch.ordersBuy": "BUY",
  "ViewItemSearch.ordersSell": "SELL",
  "ViewItemSearch.ordersOpen": "Voir l'historique complet",
  "MarketApp.Navbar.inventoryLink": "Inventaire",
  "MarketApp.Navbar.ordersLink": "Mes commandes",
  "MarketApp.Navbar.marketLink": "Marché",
  "MarketApp.Navbar.settingsLink": "Paramètres",
  "MarketApp.Navbar.replenishLink": "Créditer le compte",
  "MarketApp.Navbar.balanceHistoryLink": "Historique du compte",
  "MarketApp.Navbar.buyCoinsLink": "Acheter des GaijinCoins",
  "MarketApp.Navbar.profileLink": "Profil",
  "MarketApp.Navbar.reloginLink": "Se reconnecter ou changer de compte",
  "MarketApp.Navbar.toOldVersion": "Aller à l'ancienne version",
  "MarketApp.Navbar.linkFeedback": "Laisser un commentaire",
  "ViewTradeDialog.caption": "Commerce",
  "ViewTradeDialog.button.back": "Retour",
  "NotAllowedMessage.caption": "Non autorisé",
  "NotAllowedMessage.body.h1": "Accéder au marché",
  "NotAllowedMessage.body.h3": "actuellement non autorisé pour votre compte",
  "NotAllowedMessage.relogin": "Changer de compte",
  "CountryDeniedMessage.caption": "Non autorisé",
  "CountryDeniedMessage.body.notAllowed": "L'accès au marché n'est pas autorisé",
  "CountryDeniedMessage.body.unclearStatus": "En raison du statut juridique non clarifié des transactions au sein du marché dans votre pays, l'accès au marché est désactivé",
  "LandingMessage.caption": "Authentification",
  "LandingMessage.body": "En cours de connection...",
  "TokenExpiredMessage.caption": "Authentification expirée",
  "TokenExpiredMessage.body.h1": "Authentification expirée",
  "TokenExpiredMessage.body.h3": "Nous ne pouvons pas faire grand chose avec ça, mais vous pouvez quand même continuer",
  "TokenExpiredMessage.relogin": "Reconnexion...",
  "AuthErrorMessage.caption": "Echec de l'authentification",
  "AuthErrorMessage.body.h1": "Échec de la vérification de l'authentification",
  "AuthErrorMessage.body.code": "Code:",
  "AuthErrorMessage.body.desc": "Nous ne pouvons pas faire grand chose avec ça, mais vous pouvez quand même continuer",
  "AuthErrorMessage.relogin": "Reconnexion...",
  "AuthorizationRequiredMessage.caption": "Authentication requise",
  "AuthorizationRequiredMessage.body.h3.1": "Vous êtes sur le point d'entrer",
  "AuthorizationRequiredMessage.body.h3.2": "Marché Gaijin",
  "AuthorizationRequiredMessage.body.h3.3": "Vous devez être autorisé à continuer",
  "AuthorizationRequiredMessage.login": "Se connecter avec Gaijin.Net",
  "CheckingAuthMessage.caption": "Authentification",
  "CheckingAuthMessage.body": "Contrôle de l'authentification, soyez patients",
  "CancelOrdersPanel.cancelError": "  ",
  "CancelOrdersPanel.loading": "Vérification des commandes en cours",
  "CancelOrdersPanel.done": "Opération terminée.",
  "CancelOrdersPanel.errors": "Il y a des erreurs: \n",
  "CancelOrdersPanel.loaded.youHave": "Actuellement, vous avez ",
  "CancelOrdersPanel.loaded.sellOrders": " total d(es) ordre(s) des vente ",
  "CancelOrdersPanel.loaded.buyOrders": " total d(es) ordre(s) d'achat ",
  "CancelOrdersPanel.loaded.and": " et ",
  "CancelOrdersPanel.loaded.shouldCancel": " et vous devez tous les annuler. ",
  "CancelOrdersPanel.loaded.doCancel": "Annuler les commandes",
  "CancelOrdersPanel.cancelling.processing": "En cours",
  "CancelOrdersPanel.cancelling.outOf": " pas de ",
  "CancelOrdersPanel.cancelling.sellOrders": " ordres de vente",
  "CancelOrdersPanel.cancelling.buyOrders": " ordres d'achat",
  "ClassInfoDescriptions.dropped_item.block_label": "Contient l'un des items suivants:",
  "ClassInfoDescriptions.dropped_item.stage-unknown": "Inconnu",
  "ClassInfoDescriptions.dropped_item.stage-pending": "...chargement",
  "ClassInfoDescriptions.text.block_label": "",
  "ClassInfoDescriptions.dropped_bundle.block_label": " ",
  "ClassInfoDescriptions.buttonLabel.top": "Show",
  "ClassInfoDescriptions.buttonLabel.hide": "Hide",
  "ClassInfoDescriptions.buttonLabel.bottom": "all",
  "ClassInfoDescriptions.dropped_text.description": "Description",
  "ClassInfoUtil.fake_name.pending": "En attente",
  "ClassInfoUtil.fake_name.broken": "Item supprimé",
  "ClassInfoUtil.SynthTag.marketable1": "Commercialisable",
  "ClassInfoUtil.SynthTag.marketable0": "Non commercialisable",
  "ClassInfoUtil.SynthTag.tradable1": "Négociable",
  "ClassInfoUtil.SynthTag.tradable0": "Non négociable",
  "ClassInfoUtil.SynthTag.commodity1": "Matériel modifiable",
  "ClassInfoUtil.SynthTag.commodity0": "Matériel non modifiable",
  "ConfirmBuyDialog.title": "Envoyer un ordre d'achat",
  "BuyProcessingDialog.title": "En cours...",
  "BuyProcessingDialog.pleaseWait": "En cours, veuillez patienter",
  "ProcessingDialog.notItem": "L'article demandé n'est pas trouvé sur le marché ou n'est pas disponible pour le commerce",
  "SellDialog.title": "Mettre un objet en vente",
  "SellProcessingDialog.title": "En cours...",
  "SellProcessingDialog.pleaseWait": "En cours, veuillez patienter",
  "LimitedAccountPanel.2step.reason": "Veuillez configurer l'autorisation en deux étapes",
  "LimitedAccountPanel.2step.link": "En savoir plus sur l'autorisation en deux étapes",
  "LimitedAccountPanel.2step.href": "https://support.gaijin.net/hc/en-us/articles/203623622-How-to-set-up-the-Two-Step-Authorization-Google-Authenticator-",
  "LimitedAccountPanel.email.reason": "Veuillez confirmer votre adresse e-mail",
  "LimitedAccountPanel.email.link": "Accéder au profil",
  "LimitedAccountPanel.2step.toPlug": "Activer",
  "LimitedAccountPanel.email.href": "https://store.gaijin.net/user.php",
  "LimitedAccountPanel.title": "Votre compte est limité en raison de:",
  "LimitedAccountPanel.conclusion": "Une fois les paramètres changés, déconnectez-vous puis connectez-vous à nouveau pour les appliquer",
  "LimitedAccountPanel.relogin": "Reconnexion...",
  "EmailVerificationStateIcon.success": "Compte confirmé, e-mail vérifié",
  "EmailVerificationStateIcon.warning": "Compte non validé, email non vérifié",
  "EmailVerificationStateIcon.danger": "Compte non validé, vérification de l'email OBLIGATOIRE",
  "TwoFactorStateIcon.success": "Compte sécurisé par une autorisation en deux étapes",
  "TwoFactorStateIcon.warning": "Compte non sécurisé, double authentification désactivée",
  "TwoFactorStateIcon.danger": "Double authentification OBLIGATOIRE",
  "ViewInventoryTextFilter.common.label": "In order to filter inventory you need to select a game",
  "ItemBuyEditor.label.wheelAmountInput": "Vous pouvez utiliser la roulette de votre souris pour faire défiler,\nalt+roulette = vitesse x10",
  "ItemBuyEditor.label.wheelInput": "Vous pouvez utiliser la roulette de votre souris pour faire défiler,\nalt+roulette = vitesse x10,\nshift+roulette=vitesse x0.1",
  "ItemBuyEditor.note.posAmount": "Le montant doit être positif",
  "ItemBuyEditor.note.maxAmount": "Le montant est supérieur au maximum de 1000 items",
  "ItemBuyEditor.note.invalidAmount": "Valeur de montant invalide",
  "ItemBuyEditor.note.minPrice": "Le prix minimum est",
  "ItemBuyEditor.note.maxTotal": "Le total des commandes est supérieur au maximum de",
  "ItemBuyEditor.note.invalidPrice": "Valeur de prix invalide",
  "ItemBuyEditor.note.mustAgree": "Vous devez accepter les conditions d'utilisation",
  "ItemBuyEditor.note.insufficentFunds0": "Fonds insuffisants, vous avez besoin de plus",
  "ItemBuyEditor.note.insufficentFunds1": "pour cette commande",
  "ItemBuyEditor.label.buyCouns": "Acheter",
  "ItemBuyEditor.label.submit": "Valider votre commande",
  "ItemBuyEditor.label.price": "Quel prix voulez-vous payer (par item):",
  "ItemBuyEditor.label.amount": "Combien voulez-vous en acheter:",
  "ItemBuyEditor.label.totalPrice": "Prix total (frais inclus):",
  "ItemBuyEditor.label.agree": "J'accepte les conditions d'utilisation de la boutique Gaijin",
  "ItemBuyEditor.label.ToU": "Conditions d'utilisation",
  "MarketDepthTotals.noOrders.sell": "Pas d'ordre de vente",
  "MarketDepthTotals.noOrders.buy": "Pas d'ordre d'achat",
  "MarketDepthTotals.starting.sell": " for ",
  "MarketDepthTotals.starting.buy": " from ",
  "MarketDepthTotals.andMore.sell": " and below",
  "MarketDepthTotals.andMore.buy": " and higher",
  "MarketDepthTotals.prevew.sell": "Buying: ",
  "MarketDepthTotals.prevew.buy": "On sale: ",
  "MarketDepthOwnWarning.sell": "Vous avez des ordres de vente en attente",
  "MarketDepthOwnWarning.buy": "Vous avez des ordres d'achat en attente",
  "MarketDepthTable.caption.price": "Prix",
  "MarketDepthTable.caption.quantity": "Quantité",
  "MarketDepthTable.ellipsis.sell": "et plus",
  "MarketDepthTable.ellipsis.buy": "et moins",
  "ItemMarketDetails.buyButton": "ACHETER",
  "ItemMarketDetails.sellButton": "VENDRE",
  "ItemMarketDetails.nothingToSellButton": "Rien à vendre",
  "ItemMarketDetails.makeAuction": "Faire une enchère",
  "ItemMarketDetails.makeBid": "Faire une offre",
  "ItemMarketDetails.bid": "Enchère",
  "ItemMarketDetails.yourBid": "Votre offre:",
  "ItemMarketDetails.notifications.yourBid": "Votre offre",
  "ItemMarketDetails.yourBid.short": "votre",
  "ItemMarketDetails.yourBid.win": "gagne",
  "ItemMarketDetails.yourBid.loose": "perd",
  "ItemSellEditor.label.priceStep": "Pas de prix",
  "ItemSellEditor.label.endDate": "Date de fin",
  "limitedAccountMessage.requires": "Requis:",
  "limitedAccountMessage.2step": "protection en deux étapes",
  "limitedAccountMessage.verifiedEmail": "email vérifié",
  "limitedAccountMessage.and": " et ",
  "ItemSellEditor.label.wheelInput": "Vous pouvez utiliser la roulette de votre souris pour faire défiler,\nalt+roulette = vitesse x10,\nshift+roulette=vitesse x0.1",
  "ItemSellEditor.note.minPrice": "Le prix minimum est de",
  "ItemSellEditor.note.maxPrice": "Prix de vente maximum autorisé",
  "ItemSellEditor.note.maxTotals": "Le total des commandes est supérieur au maximum de",
  "ItemSellEditor.note.maxBalance": "Le montant total sur votre compte ne peut être supérieur à",
  "ItemSellEditor.note.mustAgree": "Vous devez accepter les conditions d'utilisation",
  "ItemSellEditor.label.price": "L'acheteur paie (frais inclus):",
  "ItemSellEditor.label.wished": "Vous recevez, après déduction des frais:",
  "ItemSellEditor.label.agree": "J'accepte les conditions d'utilisation de la boutique Gaijin",
  "ItemSellEditor.label.ToU": "Conditions d'utilisation",
  "ItemSellEditor.label.submit": "OK, mettez le en vente",
  "ItemSellEditor.currentBalance.before": "Actuellement, vous avez",
  "ItemSellEditor.currentBalance.after": "sur votre compte",
  "ItemSellEditor.sellOrdersTotal.before": "plus",
  "ItemSellEditor.sellOrdersTotal.after": "total des ordres de vente",
  "ItemSellEditor.buyOrdersTotal.before": "et",
  "ItemSellEditor.buyOrdersTotal.after": "total des ordres d'achat",
  "ItemSellEditor.label.amount": "Combien voulez-vous en vendre:",
  "AppFilterEditor.label.byTags": "Filter",
  "AppFilterEditor.label.clearFilter": "Supprimer le filtre",
  "AppFilterEditor.label.byTitles": "Filtrer par titres",
  "AppFilterEditor.label.allFilter": "Tous les jeux",
  "AppFilterEditor.label.authenticity": "Authenticité",
  "AppFilterEditor.message.filter": "Les filtres sont uniques pour chaque jeu.",
  "AppFilterEditor.message.game": "Choisissez un jeu :",
  "ViewItemDetailed.restriction.amount.items": " items",
  "ViewItemDetailed.restriction.nonMarketable": "Ne peut pas être vendu",
  "ViewItemDetailed.restriction.blockedAfter": "Sera fermé à",
  "ViewItemDetailed.restriction.blockedPair": "Marché fermé",
  "ViewItemDetailed.restriction.expiresAt1": "Disparaîtra après",
  "ViewItemDetailed.restriction.expiresAt2": "",
  "ViewItemDetailed.restriction.endedAt": "End of auction",
  "ViewItemDetailed.restriction.amount": "Lot contains",
  "ViewItemDetailed.restriction.pieces": "pcs.",
  "ViewItemDetailed.restriction.startPrice": "Starting price",
  "ViewItemDetailed.restriction.days": "d",
  "ViewItemDetailed.restriction.hours": "hrs",
  "ViewItemDetailed.restriction.minutes": "min",
  "ViewItemDetailed.restriction.leadingBids": "leading bids",
  "ViewItemDetailed.restriction.noBids": "There are no bids yet. Place your first bid in this auction!",
  "ViewItemDetailed.restriction.delayTrade": "Once purchased, the item will be available for sale through",
  "ViewItemDetailed.restriction.unclearStatus": "En raison des incertitudes du statut juridique des boites à de trophées dans votre pays, toutes les transactions avec des trophées et des clés sont bloquées pour votre compte.",
  "MarketSortOrder.label.name": "Nom",
  "MarketSortOrder.hint.newDesc": "New ones first",
  "MarketSortOrder.hint.newAsc": "Old ones first",
  "MarketSortOrder.label.price": "Prix",
  "MarketSortOrder.hint.priceAsc": "Prix faible à élevé",
  "MarketSortOrder.hint.priceDesc": "Du coût le plus élevé au plus bas",
  "MarketSortOrder.label.amount": "Quantité",
  "MarketSortOrder.hint.amountAsc": "Quantité faible à élevée",
  "MarketSortOrder.hint.amountDesc": "Du montant le plus élevé au plus bas",
  "MarketSortOrder.label.popular": "Populaire",
  "MarketSortOrder.label.popularItems": "Popular items",
  "DoubleSellErrorModal.heading": "Erreur",
  "DoubleSellErrorModal.prompt": "Échec de la vente en cours de l'item",
  "DoubleSellErrorModal.explanation": "Échec de l'émission de l'ordre de vente, une des causes possibles - l'article a été vendu ou consommé en jeu. \nEssayez d'actualiser la page",
  "DoubleSellErrorModal.okButton": "OK",
  "OnBuyReserveFailModal.heading": "Erreur",
  "OnBuyReserveFailModal.prompt": "Echec de l'émission de l'ordre d'achat",
  "OnBuyReserveFailModal.explanation": "Échec de l'émission d'un ordre d'achat, impossible de retirer de l'argent du portefeuille.",
  "OnBuyReserveFailModal.youNeed1": "Vous avez besoin d'au moins",
  "OnBuyReserveFailModal.youNeed2": "pour cette opération",
  "OnBuyReserveFailModal.tryRefresh": "Essayez d'actualiser la page et assurez-vous d'avoir suffisamment de fonds",
  "OnBuyReserveFailModal.okButton": "OK",
  "DoubleRateErrorModal.heading": "Error",
  "DoubleRateErrorModal.prompt": "An error occurred while placing a bid",
  "DoubleRateErrorModal.explanation": "Please try to bid later",
  "DoubleRateErrorModal.okButton": "OK",
  "AuctionFinishedErrorModal.heading": "Error",
  "AuctionFinishedErrorModal.prompt": "An error occurred while placing a bid",
  "AuctionFinishedErrorModal.explanation": "The auction was completed",
  "AuctionFinishedErrorModal.okButton": "OK",
  "CancelOrderErrorModal.heading": "Erreur",
  "CancelOrderErrorModal.prompt": "Echec de l'émission de l'ordre",
  "CancelOrderErrorModal.explanation": "Les raisons possibles sont des problèmes de réseau ou une mise à jour durant la commande. \nRéessayez un peu plus tard.",
  "CancelOrderErrorModal.cancelButton": "Je l'ai eu",
  "OrdersListContainer.Tab.sellOrders": "Ordres de vente",
  "OrdersListContainer.Tab.buyOrders": "Ordres d'achat",
  "OrdersListContainer.Tab.historyList": "Historique des commandes",
  "ViewOrdersEntry.amounts.remain": "Reste",
  "ViewOrdersEntry.amounts.done": "Terminé",
  "ViewOrdersEntry.order.order": "Ordre",
  "ViewOrdersEntry.order.issued": "Emis",
  "ViewOrdersEntry.order.executed": "Réalisé",
  "ViewOrdersEntry.order.pairIsBlocked": "Marché fermé",
  "ViewOrdersEntry.order.pairWillBlockAfter": "Sera fermé à",
  "ViewOrdersEntry.order.BUY": "ACHETER",
  "ViewOrdersEntry.order.SELL": "VENDRE",
  "ViewOrdersEntry.order.view": "Vue",
  "ViewOrdersEntry.order.cancel": "Annuler",
  "ViewOrdersList.empty.label": "Aucun élément",
  "CancelOrderDialog.heading.youSure": "Êtes-vous sûrs?",
  "CancelOrderDialog.body.youSure": "Êtes-vous sûr de vouloir annuler votre commande?",
  "CancelOrderDialog.button.cancel": "Oui, je veux annuler ma commande",
  "CancelAllOrderDialog.button.cancel": "Yes for everyone",
  "CancelOrderDialog.button.noCancel": "Non",
  "CancelOrderDialog.heading.processing": "En cours...",
  "CancelOrderDialog.body.processing": "En cours, veuillez patienter",
  "ProfileMenu.MenuItem.Settings": "Paramètres",
  "ProfileMenu.MenuItem.GaijinAccount": "Profile Gaijin.net",
  "ProfileMenu.MenuItem.AccountSwitch": "Change account",
  "ProfileMenu.MenuItem.Signout": "Quitter",
  "ProfileMenu.MenuItem.nick": "Your nickname",
  "WalletBlock.balance.text": "Solde:",
  "WalletBlock.balance.buyCurrency": "Buy GJN",
  "SecurityBlock.message.title": "Autorisation en deux étapes",
  "SecurityBlock.emailVerified.title": "Email",
  "OrdersHistory.title.buy": "Buy orders",
  "OrdersHistory.title.sell": "Sell orders",
  "NotificationsAssets.title": "Notifications",
  "NotificationsAssets.title.today": "Aujourd'hui",
  "NotificationsAssets.title.historyFromLastWeek": "Au cours des sept derniers jours",
  "NotificationsAssets.title.historyOlder": "Plus tôt",
  "LimitedAccountPanel.2step.pleas": "Protect your account to gain access to trading on the exchange.",
  "LimitedAccountPanel.2step.protected": "Votre compte est protégé, vous pouvez échanger.",
  "LimitedAccountPanel.email.pleas": "Confirm it to access trading on the exchange.",
  "LimitedAccountPanel.email.protected": "Your e-mail address is confirmed, you have access to trade on the exchange.",
  "OrdersListContainer.Opened.Orders": "Aktiv",
  "OrdersListContainer.Closed.Orders": "Terminées",
  "ViewOrdersList.empty.labelCollapse": "Collapse",
  "ViewOrdersList.empty.labelExpand": "Expand",
  "OrdersGraph.plot.title": "Ordres de vendre/acheter",
  "OrdersGraph.fmt.offers.sell": "offres de vente\n<br/>prix",
  "OrdersGraph.fmt.andMore.sell": "ou moins",
  "OrdersGraph.fmt.offers.buy": "offres d'achat\n<br/>prix",
  "Orders.title.header": "offres",
  "OrdersGraph.fmt.andMore.buy": "ou plus",
  "PriceHistoryGraph.plot.title": "Historique des prix:",
  "PriceHistoryGraph.highlighter.format": "A <strong>%s</strong><br /> prix <strong>%s</strong><br /> nombre <strong>%s</strong><br />",
  "PriceHistoryGraph.plot.shortRange": "Récemment",
  "PriceHistoryGraph.plot.wholeRange": "Tout le temps",
  "DealEventTickerEntry.purchasedFrom": "acheté cet article à",
  "DealEventTickerEntry.for": "pour",
  "BookSellTickerEntry.listedItem": "sale request",
  "BookBuyTickerEntry.created": "créé",
  "BookBuyTickerEntry.for": "purchase order",
  "BookAuctionTickerEntry.created": "made a bet",
  "ViewTradeTickerLog.waiting": "En attente d'activité...",
  "ViewTradeTickerLog.ticker": "Real time trading...",
  "ViewUserInventory.common.emptyInventory": "A cet instant, votre inventaire est vide",
  "ViewItemPreviewSidePane.UnselectButton.label": "Filtres",
  "ViewItemPreviewSidePane.TradeButton.label": "Vendre/Acheter",
  "ViewItemPreviewSidePane.ViewButton.label": "Vue",
  "ViewItemPreviewSidePane.QuickSellButton.label": "Vente rapide",
  "ViewInventoryTagCloud.tagTitle.itemsOf": "éléments de",
  "ViewInventoryTagCloud.tagTitle.varietiesMarkedAs": "variétés marquées comme",
  "ViewItemSearch.heading.inventory": "My items",
  "WalletBlock.circleSmall.settings": "Paramètres",
  "WalletBlock.circleSmall.exit": "Quitter",
  "UserSettingsDialog.language": "Langue",
  "UserSettingsDialog.user_settings": "Paramètres utilisateur",
  "UserSettingsDialog.settings": "Paramètres utilisateur",
  "UserSettingsDialog.save_settings": "Sauver les paramètres",
  "UserSettingsDialog.privacySettings": "Trading",
  "UserSettingsDialog.privacySettingsPublic": "Public",
  "UserSettingsDialog.privacySettingsPrivate": "Private",
  "UserSettingsDialog.interface": "Interface",
  "UserSettingsDialog.interfaceOld": "Legacy",
  "UserSettingsDialog.interfaceNew": "Modern",
  "WalletLimitsIcon.soft_limit": "Limite de GJN coins dépassée.\nLa fonction de réapprovisionnement est limitée.",
  "WalletLimitsIcon.hard_limit": "Limite de GJN coins dépassée.\nLes fonctions de réapprovisionnement et de vente sont limitées.",
  "ActionBar.action.ingamePreview": "Voir en jeu",
  "ActionBar.action.previewCopied": "Link copied!",
  "ActionBar.action.previewShare": "Share",
  "IngamePreview.ask.heading": "Voir ceci dans le jeu?",
  "IngamePreview.ask.prompt": "Vous êtes sur le point de voir cet item dans le jeu",
  "IngamePreview.ask.explanation": "Make sure the game client is running under your account",
  "IngamePreview.ask.cancelButton": "Non",
  "IngamePreview.done.heading": "Terminé",
  "IngamePreview.done.prompt": "",
  "IngamePreview.done.explanation": "Envoyé avec succès",
  "IngamePreview.done.okButton": "Ok",
  "IngamePreview.fail.heading": "Echec",
  "IngamePreview.fail.prompt": "",
  "IngamePreview.fail.explanation": "Impossible de contacter le client du jeu",
  "IngamePreview.fail.cancelButton": "Ok",
  "IngamePreview.pending.heading": "Un instant...",
  "IngamePreview.pending.prompt": "Notification de votre client de jeu",
  "IngamePreview.pending.explanation": "",
  "IngamePreview.ask.okButton": "Oui, montrez-moi",
  "StaticConfigLoader.caption.error": "Erreur d'initialisation",
  "StaticConfigLoader.caption.init": "Initialisation",
  "StaticConfigLoader.text.init": "Initialisation de l'application ...",
  "StaticConfigLoader.error.settings": "Impossible de charger les paramètres du site",
  "StaticConfigLoader.error.circuits": "Impossible de charger la configuration des services",
  "ControlButton.close": "Close",
  "Exit": " ",
  "Legal.TermsConditions": "Termes et conditions",
  "Legal.TermsService": "Conditions du service",
  "Legal.PrivacyPolicy": "Politique de confidentialité",
  "Legal.CookiesPolicy": "Politique D'utilisation Des Cookies",
  "Legal.TradePolicy": "Politique d'échanges",
  "Legal.TermsConditions.linck": "https://legal.gaijin.net/fr/",
  "Legal.TermsService.linck": "https://legal.gaijin.net/fr/termsofservice/",
  "Legal.PrivacyPolicy.linck": "https://legal.gaijin.net/fr/privacypolicy/",
  "Legal.CookiesPolicy.linck": "https://legal.gaijin.net/fr/cookie/",
  "Legal.TradePolicy.linck": "https://legal.gaijin.net/fr/tradepolicy/",
  "Legal.Copyright": "Gaijin Network Ltd. Les marques de commerce, logos et noms de marques sont la propriété de leurs détenteurs respectifs.",
  "MarketApp.Navbar.HeadTitle": "Gaijin.Net",
  "MarketApp.Navbar.HeadSubTitle": "Market",
};