import { MarketSearch } from 'api/ordersAPICall';
import ApplicationEvents from 'components/applicationEvents';
import ViewLegalFooter from 'components/legalFooter';
import { ViewMarketAppFilterEditor } from 'components/marketAppFilterEditor';
import { ViewMarketedItemsList } from 'components/marketedAssets';
import { _I_ } from 'components/translate';
import { LogStream } from 'generics/common';
import { observer } from 'mobx-react';
import React from 'react';
import Scrollbar from "react-scrollbars-custom";
import { MarketStore } from 'stores';

export class ViewStorefront extends React.Component {
  toggleAppFilter() {
    MarketStore.setPage("marketSell");
  }

  render() {
    const { currentAsset } = this.props;

    return (
      <React.Fragment>
        <Scrollbar ref={this.props.scrollbar} id="content"
          style={{ display: this.props.hidden ? "none" : "" }} defaultStyles={false}
          scrollX={false} onScrollStop={this.handleTableScroll}>
          <div className="appScreen" screen="marketStorefront">
            <div className="screenBody">
              <div className="controls">
                <div className="left">
                  <div className="gameBtns">
                    <ViewMarketAppFilterEditor
                      appIds={this.props.appIds}
                      onUpdate={() => this.toggleAppFilter()}
                      simple
                    />
                  </div>
                </div>
              </div>

              <h2 className="title">{_I_("MarketSortOrder.label.popularItems")}</h2>

              <ViewStorefrontItems
                appIds={this.props.appIds}
                currentAsset={currentAsset}
                locale={this.props.locale}
                options="sell"
              />

            </div>
            <ViewLegalFooter />
          </div>
        </Scrollbar>
      </React.Fragment>
    );
  }
}

export const ViewStorefrontItems = observer(
  class ViewStorefrontItems extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        "foundItems": [],
        "skip": 0,
        "words": "[]",
        "sortOrder": "",
        "pagesize": 20
      };
    }

    searchItems() {
      const words = JSON.parse(this.state.words);
      const apps = this.props.appIds || [];
      const fetchCount = this.state.pagesize; // Fetch one element more to decide if we have next page
      const foundItems = this.state.foundItems;
      const skip = this.state.skip;

      MarketSearch(
        words, apps, {},
        skip, fetchCount,
        this.state.sortOrder,
        this.props.options
      ).then((assets) => {
        LogStream("VIEWMARKETEDITEMSLIST").info("searchItems -> ", assets);
        const allItems = (skip > 0) ?
          foundItems.concat(assets.slice(1, assets.length - 1)) : assets;

        this.setState({ "foundItems": allItems });
        return allItems;

      }).catch((reason) => {
        this.setState({ "foundItems": [] });

        reason = Object.assign({}, reason,
          {
            "caption": "Search Item Error",
            "message": ("Failed to search market for items:\n" +
              reason.message)
          });

        LogStream("VIEWMARKETEDITEMSLIST").error("searchItems - Failed (", reason, ")");
        ApplicationEvents.emit_async("EventLog.append", reason);
      });
    }

    componentDidMount() {
      this.searchItems();
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.locale != this.props.locale)
        this.searchItems();
    }

    render() {
      const { currentAsset } = this.props;

      return (
        <ViewMarketedItemsList
          items={this.state.foundItems}
          indexShift={this.state.skip}
          pagesize={this.state.pagesize}
          currentAsset={currentAsset}
        />
      );
    }
  }
)