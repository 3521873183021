import { ViewTextFilter } from 'components/generics';
import { ViewMarketTagsFilters } from 'components/marketAppFilterEditor';
import { _I_ } from 'components/translate';
import ViewUserSettingsDialog from 'components/userSettings';
import WalletWidgetIcon from 'components/walletWidgetIcon';
import { NavbarNav, NavbarNavDropdown, NavbarNavDropdownAction, NavbarNavIconLink } from 'generics/navbar';
import { SettingsInjections } from 'generics/settingsInjections';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Scrollbar from "react-scrollbars-custom";
import { MarketStore } from 'stores';
import { FilterToggler } from "../generics/marketIcons";
import { classMixer } from "../generics/utils";
import ApplicationEvents from "./applicationEvents";
import ViewLegalFooter from "./legalFooter";
import NotificationButton from "./notifications/notificationButton";

export default function GaijinToolPanel({ children }) {
  return (
    <div id="appBody">
      <Scrollbar id="content" style={{ position: "relative", overflow: "hidden" }} scrollX={false}>
        <div className="appScreen" screen="signIn">
          <div className="centeredBlock">
            {children}
          </div>
          <ViewLegalFooter />
        </div>
      </Scrollbar>
    </div>
  )
}

export const HeadPanel = observer(
  class HeadPanel extends React.Component {
    constructor(props) {
      super(props);

      reaction(
        () => MarketStore.filterText,
        () => {
          const denySearchPage = ["market", "orders"];
          if (denySearchPage.includes(MarketStore.page))
            MarketStore.setPage("marketSell");
        }
      )
    }

    renderBalance() {
      const marketMode = SettingsInjections.getMode();

      return (
        <React.Fragment>
          <img className="navbar-link-icon" src={`images/icons/coins${marketMode == 'pixstorm' ? '_pix' : ''}.png`} /> <WalletWidgetIcon />
        </React.Fragment>
      )
    }

    renderUserInfo() {
      const creds = MarketStore.credentials;

      return (
        <React.Fragment>
          <img className="navbar-link-icon" src={`images/icons/user.png`} /> {creds.nick || `User #[ ${creds.userId} ]`}
        </React.Fragment>
      )
    }

    renderLogo(handler) {
      return (
        <div className="marketLogo" onClick={() => handler()}>
          <div>{_I_("MarketApp.Navbar.HeadTitle")}</div>
          <div>{_I_("MarketApp.Navbar.HeadSubTitle")}</div>
        </div>
      )
    }

    render() {
      const { credentials } = MarketStore;
      const { steamAppId, steamId } = credentials;

      const storeHost = SettingsInjections.getStoreHost();

      const steamArgs = (steamAppId && steamId) ? `?app_id=${steamAppId}&steam_id=${steamId}` : "";
      const replenishHref = `${storeHost}/balance.php${steamArgs}`;
      const balanceHistoryHref = `${storeHost}/balance_history.php${steamArgs}`;
      const profileHref = `${storeHost}/user.php${steamArgs}`;

      const handleMenu = (page) => MarketStore.setPage(page);
      const handleRelogin = () => ApplicationEvents.emit_async("auth.goToRelogin", "MarketApp.Navbar relogin link");
      const handleLogout = () => ApplicationEvents.emit_async("auth.AuthLogout");

      return (
        <div className="headMenu">
          {this.renderLogo(() => handleMenu("market"))}

          <ViewTextFilter />

          <NavbarNav>
            <NavbarNavIconLink
              onClick={() => handleMenu("orders")}
              mixClasses={classMixer("sidebarButton wallet", (MarketStore.page == "orders") && "active")}
              data-baloon={_I_("MarketApp.Navbar.ordersLink")}
              icon="orders"
            >
              {_I_("ViewItemSearch.heading.orders")}
            </NavbarNavIconLink>

            <NavbarNavIconLink
              children={_I_("ViewItemSearch.heading.inventory")}
              onClick={() => handleMenu("inventory")}
              mixClasses={classMixer("sidebarButton inventory", (MarketStore.page == "inventory") && "active")}
              icon="portfolio"
            />

            <NotificationButton />

            <NavbarNavDropdown
              caption={this.renderBalance()}
              mixClasses="navbar-link-balance"
            >

              <NavbarNavDropdownAction href={replenishHref} newtab  >
                {_I_("MarketApp.Navbar.replenishLink")}</NavbarNavDropdownAction>

              <NavbarNavDropdownAction href={balanceHistoryHref} newtab  >
                {_I_("MarketApp.Navbar.balanceHistoryLink")}</NavbarNavDropdownAction>

            </NavbarNavDropdown>

            <NavbarNavDropdown
              caption={this.renderUserInfo()}  >

              <NavbarNavDropdownAction href={profileHref} newtab  >
                {_I_("ProfileMenu.MenuItem.GaijinAccount")}</NavbarNavDropdownAction>

              <NavbarNavDropdownAction onClick={() => ViewUserSettingsDialog.spawnUserSettingsModal()}  >
                {_I_("MarketApp.Navbar.settingsLink")}</NavbarNavDropdownAction>

              <NavbarNavDropdownAction onClick={handleRelogin}  >
                {_I_("MarketApp.Navbar.reloginLink")}</NavbarNavDropdownAction>

              <NavbarNavDropdownAction onClick={handleLogout}  >
                {_I_("ProfileMenu.MenuItem.Signout")}</NavbarNavDropdownAction>

            </NavbarNavDropdown>

            <NavbarNavIconLink
              onClick={() => ViewUserSettingsDialog.spawnUserSettingsModal()}
              mixClasses={classMixer("sidebarButton settings", (MarketStore.page == "settings") && "active")}
              icon="settings"
            />

          </NavbarNav>
        </div>
      )
    }
  }
)

export const LeftSidePanel = observer(
  class LeftSidePanel extends React.Component {
    renderFilterToggler() {
      const setOpenState = () => MarketStore.toggleLeftPanel();

      return (
        <React.Fragment>
          <div className='togglePanel' onClick={setOpenState} />
          <div className="filterToggler" onClick={setOpenState}>
            <FilterToggler />
          </div>
        </React.Fragment>
      )
    }

    renderControls() {
      const handleMenu = (page) => MarketStore.setPage(page);

      return (
        <div className="controlsWrapper">
          <NavbarNav>
            <NavbarNavIconLink
              children={_I_("ViewItemSearch.heading.marketSell")}
              onClick={() => handleMenu("marketSell")}
              mixClasses={classMixer("sidebarButton marketSell", (MarketStore.page == "marketSell") && "active")}
              icon="order_sell"
              title={_I_("ViewItemSearch.heading.marketSell")}
            />

            <NavbarNavIconLink
              children={_I_("ViewItemSearch.heading.marketBuy")}
              onClick={() => handleMenu("marketBuy")}
              mixClasses={classMixer("sidebarButton marketBuy", (MarketStore.page == "marketBuy") && "active")}
              icon="order_buy"
              title={_I_("ViewItemSearch.heading.marketBuy")}
            />

            {SettingsInjections.getDevMode() &&
              <NavbarNavIconLink
                children={_I_("ViewItemSearch.heading.marketAuctions")}
                onClick={() => handleMenu("marketAuctions")}
                mixClasses={classMixer("sidebarButton marketAuctions", (MarketStore.page == "marketAuctions") && "active")}
                icon="auction"
                title={_I_("ViewItemSearch.heading.marketAuctions")}
              />
            }
          </NavbarNav>
        </div>
      )
    }

    render() {
      const hiddenPage = this.props.hidden && "hidden";
      const marketMode = SettingsInjections.getMode();

      return (
        <div className={classMixer("leftsidePanel", MarketStore.leftPanelOpened && "opened")}>
          <div className="leftsideControls">
            <div>
              {this.renderControls()}
            </div>
          </div>
          <div className="leftsideBody">
            {marketMode !== 'pixstorm' &&
              <div className="leftsideLinks">
                <a href="https://trade-legacy.gaijin.net/" className="service">{_I_("MarketApp.Navbar.toOldVersion")}</a>
                <a href="https://community.gaijin.net/issues/p/marketplace" className="service">{_I_("MarketApp.Navbar.linkFeedback")}</a>
              </div>
            }
            <div className="leftsideFilters">
              {this.renderFilterToggler()}
              <ViewMarketTagsFilters
                appIds={this.props.appIds}
                hiddenPage={hiddenPage}
                locale={this.props.locale}
              />
            </div>
          </div>
        </div>
      )
    }
  }
)