export const plural = (number, words) => {
  return Math.abs(parseInt(number, 10)) === 1 ? words[0] : words[1];
};

export const locale = {
  "ViewItemPreviewSidePane.quantity.inventory": " ",
  "ViewItemPreviewSidePane.quantity.ordersInventory": " items in the inventory",
  "ViewItemPreviewSidePane.quantity.count": " on sale",
  "ViewItemPreviewSidePane.quantity.countBuy": " buy orders",
  "ViewItemPreviewSidePane.quantity.items": " items",
  "ViewMarketedItem.SoldOutText": "售完",
  "ViewMarketedItem.SoldOutTip": "沒有出售訂單",
  "ViewMarketedItem.NoBuyText": "no buy orders",
  "ViewMarketedItem.SellingFrom": "銷售來源...",
  "ViewMarketedItem.SellingMore": " or more",
  "ViewMarketedItem.BuyingMore": " 或以下",
  "ViewMarketedItem.tradableAfter": "將在之後上架販售",
  "ViewItemSearch.heading.showAll": "顯示所有列表",
  "ViewItemSearch.heading.orders": "My orders",
  "ViewItemSearch.heading.marketSell": "Sell orders",
  "ViewItemSearch.heading.marketBuy": "Buy orders",
  "ViewItemSearch.heading.marketAuctions": "拍賣",
  "ViewItemSearch.title.marketAuction": "拍賣會",
  "ViewItemSearch.title.ending": "直到結束",
  "ViewItemSearch.title.finished": "拍賣結束",
  "ViewItemSearch.heading.settings": "Settings",
  "ViewMarketedItem.button.more": "More details",
  "ViewItemSearch.heading.resultsFor": "顯示結果：",
  "ViewItemSearch.stub.empty": "清空搜索結果",
  "ViewItemSearch.heading.tooltip": "您的出價被超過了",
  "ViewItemSearch.heading.tooltip.win": "您的出價成功了",
  "ViewItemPopup.committed.SELL": "銷售完成",
  "ViewItemPopup.committed.BUY": "購買完成",
  "ViewItemPopup.committed.BID": "出價完成",
  "ViewItemPopup.application.BUY": "創建購買訂單",
  "ViewItemPopup.application.SELL": "建立銷售請求",
  "ViewItemPopup.application.BID": "出價已做",
  "ViewItemPopup.canceled.BUY": "購買訂單已取消",
  "ViewItemPopup.canceled.SELL": "取消銷售申請",
  "ViewItemPopup.canceled.BID": "出價已取消",
  "ViewItemPopup.showAll.button": "查看全部",
  "ViewItemAssets.readAll.button": "全部標記為已查看",
  "ViewItemPopup.heading.noHistory": "尚無通知",
  "ViewItemPopup.heading.unseenHistory": "沒有新的通知",
  "ViewItemSearch.termsPlaceholder": "在此處搜索字詞",
  "ViewItemSearch.label.search": "搜索物品",
  "ViewMarketedItem.lovestPrice": "starting at ",
  "ViewMarketedItem.beforePrice": "under ",
  "ViewMarketedItem.yourBid": "您的出價 ",
  "ViewMarketedItem.bid": "出價 ",
  "ViewItemSearch.heading.exchange": "Home",
  "ViewItemSearch.heading.light": "Light",
  "ViewItemSearch.heading.dark": "Dark",
  "ViewItemSearch.filteringAnnounce": "In order to filter orders you must select a game",
  "ViewItemSearch.ordersBuy": "BUY",
  "ViewItemSearch.ordersSell": "SELL",
  "ViewItemSearch.ordersOpen": "View full history",
  "MarketApp.Navbar.inventoryLink": "道具清單",
  "MarketApp.Navbar.ordersLink": "我的訂單",
  "MarketApp.Navbar.marketLink": "市場",
  "MarketApp.Navbar.settingsLink": "設定",
  "MarketApp.Navbar.replenishLink": "儲值餘額",
  "MarketApp.Navbar.balanceHistoryLink": "儲值歷史",
  "MarketApp.Navbar.buyCoinsLink": "購買 Gaijin幣",
  "MarketApp.Navbar.profileLink": "檔案",
  "MarketApp.Navbar.reloginLink": "重新登入或更改帳戶",
  "MarketApp.Navbar.toOldVersion": "轉移至舊版",
  "MarketApp.Navbar.linkFeedback": "留下反饋",
  "ViewTradeDialog.caption": "交易",
  "ViewTradeDialog.button.back": "Back",
  "NotAllowedMessage.caption": "不允許",
  "NotAllowedMessage.body.h1": "訪問交易所",
  "NotAllowedMessage.body.h3": "你的帳戶目前無法訪問交易所",
  "NotAllowedMessage.relogin": " ",
  "CountryDeniedMessage.caption": "不允許",
  "CountryDeniedMessage.body.notAllowed": "不允許訪問交易所",
  "CountryDeniedMessage.body.unclearStatus": "由於你所在國家/地區的市場交易法律狀態不明確，因此訪問交易所功能已禁用",
  "LandingMessage.caption": "驗證",
  "LandingMessage.body": "正在完成著陸…",
  "TokenExpiredMessage.caption": "驗證已失效",
  "TokenExpiredMessage.body.h1": "驗證已失效",
  "TokenExpiredMessage.body.h3": "我們能做的不多，但你仍然可以",
  "TokenExpiredMessage.relogin": "重新登錄…",
  "AuthErrorMessage.caption": "驗證失敗",
  "AuthErrorMessage.body.h1": "無法檢查驗證",
  "AuthErrorMessage.body.code": "代碼：",
  "AuthErrorMessage.body.desc": "我們能做的不多，但你仍然可以",
  "AuthErrorMessage.relogin": "重新登錄…",
  "AuthorizationRequiredMessage.caption": "需要驗證",
  "AuthorizationRequiredMessage.body.h3.1": "你即將進入",
  "AuthorizationRequiredMessage.body.h3.2": "Gaijin 交易所",
  "AuthorizationRequiredMessage.body.h3.3": "但你需要驗證才能進一步進行",
  "AuthorizationRequiredMessage.login": "通過 Gaijin.Net 登入",
  "CheckingAuthMessage.caption": "驗證",
  "CheckingAuthMessage.body": "正在檢查驗證，請耐心等待",
  "CancelOrdersPanel.cancelError": "\n取消訂單失敗 ",
  "CancelOrdersPanel.loading": "檢查有效訂單",
  "CancelOrdersPanel.done": "操作已完成。",
  "CancelOrdersPanel.errors": "有一些錯誤：\n",
  "CancelOrdersPanel.loaded.youHave": "目前你擁有 ",
  "CancelOrdersPanel.loaded.sellOrders": " 賣出訂單總計 ",
  "CancelOrdersPanel.loaded.buyOrders": " 賣出訂單總計 ",
  "CancelOrdersPanel.loaded.and": " 與 ",
  "CancelOrdersPanel.loaded.shouldCancel": " 你必須全部取消。 ",
  "CancelOrdersPanel.loaded.doCancel": "取消訂單",
  "CancelOrdersPanel.cancelling.processing": "處理中",
  "CancelOrdersPanel.cancelling.outOf": " / ",
  "CancelOrdersPanel.cancelling.sellOrders": " 出售訂單",
  "CancelOrdersPanel.cancelling.buyOrders": " 購買訂單",
  "ClassInfoDescriptions.dropped_item.block_label": "包含以下任意之一：",
  "ClassInfoDescriptions.dropped_item.stage-unknown": "未知",
  "ClassInfoDescriptions.dropped_item.stage-pending": "…加載中",
  "ClassInfoDescriptions.text.block_label": "",
  "ClassInfoDescriptions.dropped_bundle.block_label": " ",
  "ClassInfoDescriptions.buttonLabel.top": "Show",
  "ClassInfoDescriptions.buttonLabel.hide": "Hide",
  "ClassInfoDescriptions.buttonLabel.bottom": "all",
  "ClassInfoDescriptions.dropped_text.description": "Description",
  "ClassInfoUtil.fake_name.pending": "等待中",
  "ClassInfoUtil.fake_name.broken": "已刪除物品",
  "ClassInfoUtil.SynthTag.marketable1": "可在交易所交易",
  "ClassInfoUtil.SynthTag.marketable0": "不可在交易所交易",
  "ClassInfoUtil.SynthTag.tradable1": "可交易",
  "ClassInfoUtil.SynthTag.tradable0": "不可交易",
  "ClassInfoUtil.SynthTag.commodity1": "商品",
  "ClassInfoUtil.SynthTag.commodity0": "非商品",
  "ConfirmBuyDialog.title": "下達購買訂單",
  "BuyProcessingDialog.title": "處理中…",
  "BuyProcessingDialog.pleaseWait": "處理中，請稍候",
  "ProcessingDialog.notItem": "所請求的物品在市場上未找到或不可交易",
  "SellDialog.title": "將物品上架待售",
  "SellProcessingDialog.title": "處理中…",
  "SellProcessingDialog.pleaseWait": "處理中，請稍候",
  "LimitedAccountPanel.2step.reason": "你需要設置雙重驗證",
  "LimitedAccountPanel.2step.link": "有關雙重驗證的更多信息",
  "LimitedAccountPanel.2step.href": "https://support.gaijin.net/hc/en-us/articles/203623622-How-to-set-up-the-Two-Step-Authorization-Google-Authenticator-",
  "LimitedAccountPanel.email.reason": "你需要確認電子信箱地址",
  "LimitedAccountPanel.email.link": "前往檔案",
  "LimitedAccountPanel.2step.toPlug": "啟用",
  "LimitedAccountPanel.email.href": "https://store.gaijin.net/user.php",
  "LimitedAccountPanel.title": "你的帳戶受到限制，原因為：",
  "LimitedAccountPanel.conclusion": "更改安全設置後，你應該登出並重新登入以使更改生效",
  "LimitedAccountPanel.relogin": "重新登入…",
  "EmailVerificationStateIcon.success": "帳戶已確認，電子信箱已驗證",
  "EmailVerificationStateIcon.warning": "帳戶未確認，電子信箱未驗證",
  "EmailVerificationStateIcon.danger": "帳戶未確認，電子信箱需要驗證",
  "TwoFactorStateIcon.success": "帳戶已安全進行雙重驗證",
  "TwoFactorStateIcon.warning": "不安全帳戶，雙重驗證已禁用",
  "TwoFactorStateIcon.danger": "需要雙重驗證",
  "ViewInventoryTextFilter.common.label": "是否在查找特定物品？",
  "ItemBuyEditor.label.wheelAmountInput": "你可以使用滑鼠滾輪調動數量，\n alt+滾輪 = 10 倍速度",
  "ItemBuyEditor.label.wheelInput": "你可以使用滑鼠滾輪調動價格，\n alt+滾輪 = 10 倍速度，\n shift+滾輪 = 0.1 倍速度",
  "ItemBuyEditor.note.posAmount": "數量應為正數",
  "ItemBuyEditor.note.maxAmount": "數量超過 1000 個物品的上限",
  "ItemBuyEditor.note.invalidAmount": "數量值無效",
  "ItemBuyEditor.note.minPrice": "最低價格為",
  "ItemBuyEditor.note.maxTotal": "訂單總數超過最大值",
  "ItemBuyEditor.note.invalidPrice": "價格值無效",
  "ItemBuyEditor.note.mustAgree": "必須同意使用條款",
  "ItemBuyEditor.note.insufficentFunds0": "餘額不足，你需要更多Gaijin幣",
  "ItemBuyEditor.note.insufficentFunds1": "才能操作此訂單",
  "ItemBuyEditor.label.buyCouns": "購買",
  "ItemBuyEditor.label.submit": "下單",
  "ItemBuyEditor.label.price": "你想付多少價格（每個物品）：",
  "ItemBuyEditor.label.amount": "你想購買多少數量：",
  "ItemBuyEditor.label.totalPrice": "總價（包含費用）：",
  "ItemBuyEditor.label.agree": "我同意 Gaijin Store",
  "ItemBuyEditor.label.ToU": "使用條款",
  "MarketDepthTotals.noOrders.sell": "沒有出售訂單",
  "MarketDepthTotals.noOrders.buy": "沒有購買訂單",
  "MarketDepthTotals.starting.sell": " for ",
  "MarketDepthTotals.starting.buy": " from ",
  "MarketDepthTotals.andMore.sell": " and below",
  "MarketDepthTotals.andMore.buy": " and higher",
  "MarketDepthTotals.prevew.sell": "Buying: ",
  "MarketDepthTotals.prevew.buy": "On sale: ",
  "MarketDepthOwnWarning.sell": "你有等待中的出售訂單",
  "MarketDepthOwnWarning.buy": "你有等待中的購買訂單",
  "MarketDepthTable.caption.price": "價格",
  "MarketDepthTable.caption.quantity": "數量",
  "MarketDepthTable.ellipsis.sell": "及以上",
  "MarketDepthTable.ellipsis.buy": "及以下",
  "ItemMarketDetails.buyButton": "購 買",
  "ItemMarketDetails.sellButton": "出 售",
  "ItemMarketDetails.nothingToSellButton": "沒有要出售的物 品",
  "ItemMarketDetails.makeAuction": "建立拍賣",
  "ItemMarketDetails.makeBid": "出價",
  "ItemMarketDetails.bid": "出價",
  "ItemMarketDetails.yourBid": "你的出價：",
  "ItemMarketDetails.notifications.yourBid": "你的出價",
  "ItemMarketDetails.yourBid.short": "你的",
  "ItemMarketDetails.yourBid.win": "赢了",
  "ItemMarketDetails.yourBid.loose": "输了",
  "ItemSellEditor.label.priceStep": "價格步驟",
  "ItemSellEditor.label.endDate": "結束日期",
  "limitedAccountMessage.requires": "需 要",
  "limitedAccountMessage.2step": "兩步防 護",
  "limitedAccountMessage.verifiedEmail": "電子信箱已驗 證",
  "limitedAccountMessage.and": " 與 ",
  "ItemSellEditor.label.wheelInput": "你可以使用滑鼠滾輪調動價格，\n alt+滾輪 = 10 倍速度，\n shift+滾輪 = 0.1 倍速度",
  "ItemSellEditor.note.minPrice": "最低價格為",
  "ItemSellEditor.note.maxPrice": "允許的最高售價",
  "ItemSellEditor.note.maxTotals": "訂單總數超過最大值",
  "ItemSellEditor.note.maxBalance": "總餘額不應超過",
  "ItemSellEditor.note.mustAgree": "必須同意使用條款",
  "ItemSellEditor.label.price": "買方支付（包括手續費）：",
  "ItemSellEditor.label.wished": "在扣除手續費後，你會收到：",
  "ItemSellEditor.label.agree": "我同意 Gaijin Store",
  "ItemSellEditor.label.ToU": "使用條款",
  "ItemSellEditor.label.submit": "好，把它上架銷售",
  "ItemSellEditor.currentBalance.before": "目前你擁有",
  "ItemSellEditor.currentBalance.after": "位於你的餘額中",
  "ItemSellEditor.sellOrdersTotal.before": "外加",
  "ItemSellEditor.sellOrdersTotal.after": "位於出售訂單中",
  "ItemSellEditor.buyOrdersTotal.before": "和",
  "ItemSellEditor.buyOrdersTotal.after": "位於購買訂單中",
  "ItemSellEditor.label.amount": "您想出售的物品数量：",
  "AppFilterEditor.label.byTags": "Filter",
  "AppFilterEditor.label.clearFilter": "清除篩選",
  "AppFilterEditor.label.byTitles": "按標題篩選",
  "AppFilterEditor.label.allFilter": "All Games",
  "AppFilterEditor.label.authenticity": "真實性",
  "AppFilterEditor.message.filter": "每個遊戲的過濾器是不一樣的",
  "AppFilterEditor.message.game": "選擇遊戲 :",
  "ViewItemDetailed.restriction.amount.items": " items",
  "ViewItemDetailed.restriction.nonMarketable": "不可出售",
  "ViewItemDetailed.restriction.blockedAfter": "將在此後鎖定",
  "ViewItemDetailed.restriction.blockedPair": "交易已鎖定",
  "ViewItemDetailed.restriction.expiresAt1": "將在此後消失",
  "ViewItemDetailed.restriction.expiresAt2": "",
  "ViewItemDetailed.restriction.endedAt": "End of auction",
  "ViewItemDetailed.restriction.amount": "Lot contains",
  "ViewItemDetailed.restriction.pieces": "pcs.",
  "ViewItemDetailed.restriction.startPrice": "Starting price",
  "ViewItemDetailed.restriction.days": "d",
  "ViewItemDetailed.restriction.hours": "hrs",
  "ViewItemDetailed.restriction.minutes": "min",
  "ViewItemDetailed.restriction.leadingBids": "leading bids",
  "ViewItemDetailed.restriction.noBids": "There are no bids yet. Place your first bid in this auction!",
  "ViewItemDetailed.restriction.delayTrade": "Once purchased, the item will be available for sale through",
  "ViewItemDetailed.restriction.unclearStatus": "將在此後消失：由於你所在國家/地區的獎勵情況的法律狀態不明確，因此你帳戶中所有帶有獎勵和鑰匙的交易均被鎖定。",
  "MarketSortOrder.label.name": "名稱",
  "MarketSortOrder.hint.newDesc": "New ones first",
  "MarketSortOrder.hint.newAsc": "Old ones first",
  "MarketSortOrder.label.price": "價格",
  "MarketSortOrder.hint.priceAsc": "價格從低到高",
  "MarketSortOrder.hint.priceDesc": "價格從高到低",
  "MarketSortOrder.label.amount": "數量",
  "MarketSortOrder.hint.amountAsc": "數量從低到高",
  "MarketSortOrder.hint.amountDesc": "數量從高到低",
  "MarketSortOrder.label.popular": "人氣",
  "MarketSortOrder.label.popularItems": "Popular items",
  "DoubleSellErrorModal.heading": "錯誤",
  "DoubleSellErrorModal.prompt": "出售當前物品失敗",
  "DoubleSellErrorModal.explanation": "下達出售訂單失敗，可能的原因之一：物品已於游戏中售出或消耗\n請嘗試重新整理頁面",
  "DoubleSellErrorModal.okButton": "確定",
  "OnBuyReserveFailModal.heading": "錯誤",
  "OnBuyReserveFailModal.prompt": "下達購買訂單失敗",
  "OnBuyReserveFailModal.explanation": "提出購買訂單失敗，無法從錢包中保留金錢。",
  "OnBuyReserveFailModal.youNeed1": "你需要至少",
  "OnBuyReserveFailModal.youNeed2": "才能進行此操作",
  "OnBuyReserveFailModal.tryRefresh": "請嘗試重新整理頁面，並確保擁有足夠資金",
  "OnBuyReserveFailModal.okButton": "確定",
  "DoubleRateErrorModal.heading": "Error",
  "DoubleRateErrorModal.prompt": "An error occurred while placing a bid",
  "DoubleRateErrorModal.explanation": "Please try to bid later",
  "DoubleRateErrorModal.okButton": "OK",
  "AuctionFinishedErrorModal.heading": "Error",
  "AuctionFinishedErrorModal.prompt": "An error occurred while placing a bid",
  "AuctionFinishedErrorModal.explanation": "The auction was completed",
  "AuctionFinishedErrorModal.okButton": "OK",
  "CancelOrderErrorModal.heading": "錯誤",
  "CancelOrderErrorModal.prompt": "取消訂單失敗",
  "CancelOrderErrorModal.explanation": "可能的原因是網路問題，或你正好遇到訂單更新。\n請稍後再試。",
  "CancelOrderErrorModal.cancelButton": "了解",
  "OrdersListContainer.Tab.sellOrders": "出售訂單",
  "OrdersListContainer.Tab.buyOrders": "購買訂單",
  "OrdersListContainer.Tab.historyList": "訂單歷史",
  "ViewOrdersEntry.amounts.remain": "剩餘",
  "ViewOrdersEntry.amounts.done": "完成",
  "ViewOrdersEntry.order.order": "訂單",
  "ViewOrdersEntry.order.issued": "已提出",
  "ViewOrdersEntry.order.executed": "已執行",
  "ViewOrdersEntry.order.pairIsBlocked": "交易已鎖定",
  "ViewOrdersEntry.order.pairWillBlockAfter": "將在之後鎖定：",
  "ViewOrdersEntry.order.BUY": "購買",
  "ViewOrdersEntry.order.SELL": "出售",
  "ViewOrdersEntry.order.view": "查看",
  "ViewOrdersEntry.order.cancel": "取消",
  "ViewOrdersList.empty.label": "此處無內容",
  "CancelOrderDialog.heading.youSure": "是否確定？",
  "CancelOrderDialog.body.youSure": "是否確定取消訂單？",
  "CancelOrderDialog.button.cancel": "是的，我要取消訂單",
  "CancelAllOrderDialog.button.cancel": "是且不再确认",
  "CancelOrderDialog.button.noCancel": "否",
  "CancelOrderDialog.heading.processing": "處理中...",
  "CancelOrderDialog.body.processing": "處理中，請稍候",
  "ProfileMenu.MenuItem.Settings": "Settings",
  "ProfileMenu.MenuItem.GaijinAccount": "Profile Gaijin.net",
  "ProfileMenu.MenuItem.AccountSwitch": "Change account",
  "ProfileMenu.MenuItem.Signout": "Exit",
  "ProfileMenu.MenuItem.nick": "Your nickname",
  "WalletBlock.balance.text": "Balance:",
  "WalletBlock.balance.buyCurrency": "Buy GJN",
  "SecurityBlock.message.title": "Two-factor authorization",
  "SecurityBlock.emailVerified.title": "Email",
  "OrdersHistory.title.buy": "Buy orders",
  "OrdersHistory.title.sell": "Sell orders",
  "NotificationsAssets.title": "通知",
  "NotificationsAssets.title.today": "今天",
  "NotificationsAssets.title.historyFromLastWeek": "在過去的七天裡",
  "NotificationsAssets.title.historyOlder": "早些時候",
  "LimitedAccountPanel.2step.pleas": "Protect your account to gain access to trading on the exchange.",
  "LimitedAccountPanel.2step.protected": "Your account is protected, you have access to trade on the exchange.",
  "LimitedAccountPanel.email.pleas": "Confirm it to access trading on the exchange.",
  "LimitedAccountPanel.email.protected": "Your e-mail address is confirmed, you have access to trade on the exchange.",
  "OrdersListContainer.Opened.Orders": "Opened",
  "OrdersListContainer.Closed.Orders": "Closed",
  "ViewOrdersList.empty.labelCollapse": "Collapse",
  "ViewOrdersList.empty.labelExpand": "Expand",
  "OrdersGraph.plot.title": "購買/出售訂單",
  "OrdersGraph.fmt.offers.sell": "出售報價\n<br/>價格",
  "OrdersGraph.fmt.andMore.sell": "或更低",
  "OrdersGraph.fmt.offers.buy": "購買報價\n<br/>價格",
  "Orders.title.header": "訂單",
  "OrdersGraph.fmt.andMore.buy": "或更高",
  "PriceHistoryGraph.plot.title": "價格歷史:",
  "PriceHistoryGraph.highlighter.format": "於 <strong>%s</strong><br /> 價格<strong>%s</strong><br /> 數量 <strong>%s</strong><br />",
  "PriceHistoryGraph.plot.shortRange": "短期",
  "PriceHistoryGraph.plot.wholeRange": "全程",
  "DealEventTickerEntry.purchasedFrom": "已購買此物品，来源為",
  "DealEventTickerEntry.for": "花費",
  "BookSellTickerEntry.listedItem": "sale request",
  "BookBuyTickerEntry.created": "已創建",
  "BookBuyTickerEntry.for": "purchase order",
  "BookAuctionTickerEntry.created": "made a bet",
  "ViewTradeTickerLog.waiting": "正在等待新的行動...",
  "ViewTradeTickerLog.ticker": "Real time trading...",
  "ViewUserInventory.common.emptyInventory": " ",
  "ViewItemPreviewSidePane.UnselectButton.label": " ",
  "ViewItemPreviewSidePane.TradeButton.label": " ",
  "ViewItemPreviewSidePane.ViewButton.label": " ",
  "ViewItemPreviewSidePane.QuickSellButton.label": " ",
  "ViewInventoryTagCloud.tagTitle.itemsOf": " ",
  "ViewInventoryTagCloud.tagTitle.varietiesMarkedAs": " ",
  "ViewItemSearch.heading.inventory": "My items",
  "WalletBlock.circleSmall.settings": "Settings",
  "WalletBlock.circleSmall.exit": "Exit",
  "UserSettingsDialog.language": "语言",
  "UserSettingsDialog.user_settings": "用户设置",
  "UserSettingsDialog.settings": "设置",
  "UserSettingsDialog.save_settings": "保存设置",
  "UserSettingsDialog.privacySettings": "Trading",
  "UserSettingsDialog.privacySettingsPublic": "Public",
  "UserSettingsDialog.privacySettingsPrivate": "Private",
  "UserSettingsDialog.interface": "Interface",
  "UserSettingsDialog.interfaceOld": "Legacy",
  "UserSettingsDialog.interfaceNew": "Modern",
  "WalletLimitsIcon.soft_limit": "超過 GJN coins 限制。\n補充功能受限。",
  "WalletLimitsIcon.hard_limit": "超過 GJN coins 限制。\n出售及補充功能受限。",
  "ActionBar.action.ingamePreview": "在遊戲中查看",
  "ActionBar.action.previewCopied": "Link copied!",
  "ActionBar.action.previewShare": "Share",
  "IngamePreview.ask.heading": "是否在遊戲中查看此項目？",
  "IngamePreview.ask.prompt": "您將在遊戲中查看此物品",
  "IngamePreview.ask.explanation": "Make sure the game client is running under your account",
  "IngamePreview.ask.cancelButton": "不用",
  "IngamePreview.done.heading": "完成",
  "IngamePreview.done.prompt": "",
  "IngamePreview.done.explanation": "已成功發送",
  "IngamePreview.done.okButton": "確定",
  "IngamePreview.fail.heading": "已失敗",
  "IngamePreview.fail.prompt": "",
  "IngamePreview.fail.explanation": "無法聯繫遊戲客戶端",
  "IngamePreview.fail.cancelButton": "確定",
  "IngamePreview.pending.heading": "請稍候…",
  "IngamePreview.pending.prompt": "正在通知你的遊戲客戶端",
  "IngamePreview.pending.explanation": "",
  "IngamePreview.ask.okButton": "是的，請顯示",
  "StaticConfigLoader.caption.error": "初始化錯誤",
  "StaticConfigLoader.caption.init": "初始化",
  "StaticConfigLoader.text.init": "正在初始化應用程序...",
  "StaticConfigLoader.error.settings": "無法載入網站設置",
  "StaticConfigLoader.error.circuits": "無法載入服務配置",
  "ControlButton.close": "Close",
  "Exit": "Change account",
  "Legal.TermsConditions": "條款及條件",
  "Legal.TermsService": "服務條款",
  "Legal.PrivacyPolicy": "隱私權政策",
  "Legal.CookiesPolicy": "Cookie 政策",
  "Legal.TradePolicy": "交易政策",
  "Legal.TermsConditions.linck": "https://legal.gaijin.net/tw/",
  "Legal.TermsService.linck": "https://legal.gaijin.net/tw/termsofservice/",
  "Legal.PrivacyPolicy.linck": "https://legal.gaijin.net/tw/privacypolicy/",
  "Legal.CookiesPolicy.linck": "https://legal.gaijin.net/tw/cookie/",
  "Legal.TradePolicy.linck": "https://legal.gaijin.net/tw/tradepolicy/",
  "Legal.Copyright": "Gaijin Network Ltd. 所有商标，标识及品名均为其各自所有者的财产。",
  "MarketApp.Navbar.HeadTitle": "Gaijin.Net",
  "MarketApp.Navbar.HeadSubTitle": "Market",
};
