export const plural = (number, words) => {
  return Math.abs(parseInt(number, 10)) === 1 ? words[0] : words[1];
};

export const locale = {
  "ViewItemPreviewSidePane.quantity.inventory": " ",
  "ViewItemPreviewSidePane.quantity.ordersInventory": " 인벤토리의 아이템",
  "ViewItemPreviewSidePane.quantity.count": " 판매 중",
  "ViewItemPreviewSidePane.quantity.countBuy": " 구매 주문",
  "ViewItemPreviewSidePane.quantity.items": " 아이템",
  "ViewMarketedItem.SoldOutText": "품절",
  "ViewMarketedItem.SoldOutTip": "판매 주문 없음",
  "ViewMarketedItem.NoBuyText": "구매 주문 없음",
  "ViewMarketedItem.SellingFrom": "다음으로부터 판매됨",
  "ViewMarketedItem.SellingMore": " 이상",
  "ViewMarketedItem.BuyingMore": " 이하",
  "ViewMarketedItem.tradableAfter": "다음 시간 경과 후 거래 가능:",
  "ViewItemSearch.heading.showAll": "전체 목록 확인하기",
  "ViewItemSearch.heading.orders": "내 주문",
  "ViewItemSearch.heading.marketSell": "판매 주문",
  "ViewItemSearch.heading.marketBuy": "구매 주문",
  "ViewItemSearch.heading.marketAuctions": "경매",
  "ViewItemSearch.title.marketAuction": "경매",
  "ViewItemSearch.title.ending": "끝까지",
  "ViewItemSearch.title.finished": "경매 종료",
  "ViewItemSearch.heading.settings": "설정",
  "ViewMarketedItem.button.more": "더 알아보기",
  "ViewItemSearch.heading.resultsFor": "다음 게임의 아이템 리스트:",
  "ViewItemSearch.stub.empty": "검색 결과 비우기",
  "ViewItemSearch.heading.tooltip": "입찰이 상회되었습니다",
  "ViewItemSearch.heading.tooltip.win": "입찰에 성공했습니다",
  "ViewItemPopup.committed.SELL": "판매 완료",
  "ViewItemPopup.committed.BUY": "구매 완료",
  "ViewItemPopup.committed.BID": "입찰 완료",
  "ViewItemPopup.application.BUY": "구매 주문 생성",
  "ViewItemPopup.application.SELL": "판매 요청이 생성되었습니다",
  "ViewItemPopup.application.BID": "입찰 완료",
  "ViewItemPopup.canceled.BUY": "구매 주문 취소",
  "ViewItemPopup.canceled.SELL": "판매 요청이 취소되었습니다",
  "ViewItemPopup.canceled.BID": "입찰 취소",
  "ViewItemPopup.showAll.button": "모두 보기",
  "ViewItemAssets.readAll.button": "모두 본 것으로 표시",
  "ViewItemPopup.heading.noHistory": "알림이 없습니다",
  "ViewItemPopup.heading.unseenHistory": "새로운 알림이 없습니다",
  "ViewItemSearch.termsPlaceholder": "여기서 약관 검색",
  "ViewItemSearch.label.search": "아이템 찾기",
  "ViewMarketedItem.lovestPrice": "부터 ",
  "ViewMarketedItem.beforePrice": "이전 ",
  "ViewMarketedItem.yourBid": "귀하의 입찰 ",
  "ViewMarketedItem.bid": "입찰 ",
  "ViewItemSearch.heading.exchange": "Home",
  "ViewItemSearch.heading.light": "라이트",
  "ViewItemSearch.heading.dark": "다크",
  "ViewItemSearch.filteringAnnounce": "필터링 기능을 사용하기 위해선 게임을 선택해야 합니다",
  "ViewItemSearch.ordersBuy": "구매",
  "ViewItemSearch.ordersSell": "판매",
  "ViewItemSearch.ordersOpen": "전체 내역 보기",
  "MarketApp.Navbar.inventoryLink": "보관함",
  "MarketApp.Navbar.ordersLink": "내 주문 목록",
  "MarketApp.Navbar.marketLink": "마켓",
  "MarketApp.Navbar.settingsLink": "설정",
  "MarketApp.Navbar.replenishLink": "코인 충전",
  "MarketApp.Navbar.balanceHistoryLink": "코인 이용 내역",
  "MarketApp.Navbar.buyCoinsLink": "가이진 코인 구매",
  "MarketApp.Navbar.profileLink": "프로필",
  "MarketApp.Navbar.reloginLink": "재로그인 혹은 계정 변경",
  "MarketApp.Navbar.toOldVersion": "과거 버전 사용",
  "MarketApp.Navbar.linkFeedback": "피드백 남기기",
  "ViewTradeDialog.caption": "거래",
  "ViewTradeDialog.button.back": "뒤로",
  "NotAllowedMessage.caption": "진행할 수 없습니다",
  "NotAllowedMessage.body.h1": "가이진 마켓으로 이동하기",
  "NotAllowedMessage.body.h3": "현재 계정으로 진행할 수 없습니다",
  "NotAllowedMessage.relogin": "계정 변경",
  "CountryDeniedMessage.caption": "진행할 수 없습니다",
  "CountryDeniedMessage.body.notAllowed": "가이진 마켓으로 접속할 수 없습니다",
  "CountryDeniedMessage.body.unclearStatus": "온라인 거래에 대한 플레이어 국가의 법적인 문제로 인하여, 가이진 마켓으로 접속할 수 없습니다",
  "LandingMessage.caption": "본인 확인",
  "LandingMessage.body": "마켓으로 접속하는 중..",
  "TokenExpiredMessage.caption": "본인 확인이 만료되었습니다",
  "TokenExpiredMessage.body.h1": "본인 확인이 만료되었습니다",
  "TokenExpiredMessage.body.h3": "더 진행하기 위해서는",
  "TokenExpiredMessage.relogin": "로그인해 주십시오.",
  "AuthErrorMessage.caption": "본인 확인이 만료되었습니다",
  "AuthErrorMessage.body.h1": "본인 확인에 실패하였습니다",
  "AuthErrorMessage.body.code": "코드:",
  "AuthErrorMessage.body.desc": "더 진행하기 위해서는",
  "AuthErrorMessage.relogin": "로그인해 주십시오.",
  "AuthorizationRequiredMessage.caption": "본인 확인이 필요합니다",
  "AuthorizationRequiredMessage.body.h3.1": "현재 접속하려는 곳은",
  "AuthorizationRequiredMessage.body.h3.2": "가이진 마켓입니다",
  "AuthorizationRequiredMessage.body.h3.3": "단, 마켓에 입장하기 위해선",
  "AuthorizationRequiredMessage.login": "Gaijin.Net 계정을 생성해야 합니다",
  "CheckingAuthMessage.caption": "본인 확인",
  "CheckingAuthMessage.body": "본인 확인 중입니다. 잠시만 기다려주십시오",
  "CancelOrdersPanel.cancelError": "\n주문 취소에 실패하였습니다 ",
  "CancelOrdersPanel.loading": "거래 주문 확인 중",
  "CancelOrdersPanel.done": "작업이 완료되었습니다.",
  "CancelOrdersPanel.errors": "오류가 발생하였습니다: \n",
  "CancelOrdersPanel.loaded.youHave": "현재 소유중 ",
  "CancelOrdersPanel.loaded.sellOrders": " 전체 판매 주문량 ",
  "CancelOrdersPanel.loaded.buyOrders": " 전체 구매 주문량 ",
  "CancelOrdersPanel.loaded.and": " 과(와) ",
  "CancelOrdersPanel.loaded.shouldCancel": " 을(를) 취소해 주셔야 합니다. ",
  "CancelOrdersPanel.loaded.doCancel": "주문 취소하기",
  "CancelOrdersPanel.cancelling.processing": "진행중",
  "CancelOrdersPanel.cancelling.outOf": " 주문량 없음 ",
  "CancelOrdersPanel.cancelling.sellOrders": " 구매",
  "CancelOrdersPanel.cancelling.buyOrders": " 판매",
  "ClassInfoDescriptions.dropped_item.block_label": "다음 중 하나가 들어 있습니다",
  "ClassInfoDescriptions.dropped_item.stage-unknown": "알 수 없음",
  "ClassInfoDescriptions.dropped_item.stage-pending": "...불러오는 중",
  "ClassInfoDescriptions.text.block_label": "",
  "ClassInfoDescriptions.dropped_bundle.block_label": " ",
  "ClassInfoDescriptions.buttonLabel.top": "보이기",
  "ClassInfoDescriptions.buttonLabel.hide": "숨기기",
  "ClassInfoDescriptions.buttonLabel.bottom": "모두 보기",
  "ClassInfoDescriptions.dropped_text.description": "Description",
  "ClassInfoUtil.fake_name.pending": "대기중",
  "ClassInfoUtil.fake_name.broken": "아이템 삭제",
  "ClassInfoUtil.SynthTag.marketable1": "판매 가능 물품",
  "ClassInfoUtil.SynthTag.marketable0": "판매 불가능 물품",
  "ClassInfoUtil.SynthTag.tradable1": "거래 가능 물품",
  "ClassInfoUtil.SynthTag.tradable0": "거래 불가능 물품",
  "ClassInfoUtil.SynthTag.commodity1": "거래 가능 물품",
  "ClassInfoUtil.SynthTag.commodity0": "거래 불가능 물품",
  "ConfirmBuyDialog.title": "구매 주문하기",
  "BuyProcessingDialog.title": "진행중...",
  "BuyProcessingDialog.pleaseWait": "진행중입니다. 잠시만 기다려 주세요",
  "ProcessingDialog.notItem": "요청된 항목이 시장에서 찾을 수 없거나 거래할 수 없습니다",
  "SellDialog.title": "판매할 아이템을 올려 주십시오",
  "SellProcessingDialog.title": "진행중...",
  "SellProcessingDialog.pleaseWait": "진행중입니다. 잠시만 기다려 주세요",
  "LimitedAccountPanel.2step.reason": "2단계 인증을 설정해야 합니다",
  "LimitedAccountPanel.2step.link": "2단계 인증 상세 정보",
  "LimitedAccountPanel.2step.href": "https://support.gaijin.net/hc/en-us/articles/203623622-How-to-set-up-the-Two-Step-Authorization-Google-Authenticator-",
  "LimitedAccountPanel.email.reason": "이메일 주소를 인증해야 합니다",
  "LimitedAccountPanel.email.link": "프로필로 이동하기",
  "LimitedAccountPanel.2step.toPlug": "활성화",
  "LimitedAccountPanel.email.href": "https://store.gaijin.net/user.php",
  "LimitedAccountPanel.title": "다음 사유로 인해 계정이 제한되어 있습니다:",
  "LimitedAccountPanel.conclusion": "보안 설정을 변경한 다음, 변경 사항을 적용하기 위해 다시 로그인해야 합니다",
  "LimitedAccountPanel.relogin": "재접속...",
  "EmailVerificationStateIcon.success": "이메일 인증 완료",
  "EmailVerificationStateIcon.warning": "이메일을 인증하지 않았습니다.",
  "EmailVerificationStateIcon.danger": "본인 확인이 되지 않은 계정입니다. 이메일을 통해 반드시 인증해야 합니다",
  "TwoFactorStateIcon.success": "계정이 2단계 인증으로 안전하게 보호되었습니다",
  "TwoFactorStateIcon.warning": "계정이 2단계 인증으로 보호받고 있지 않습니다",
  "TwoFactorStateIcon.danger": "2단계 인증이 반드시 필요합니다",
  "ViewInventoryTextFilter.common.label": "특정 아이템을 찾고 계신가요?",
  "ItemBuyEditor.label.wheelAmountInput": "마우스 휠을 이용하여 구매 수량을 결정할 수 있습니다, \nalt+휠로 10개 씩 선택할 수 있습니다.",
  "ItemBuyEditor.label.wheelInput": "마우스 휠을 이용하여 구매 가격을 결정할 수 있습니다, \nalt+휠로 10단위의 가격을, \nShift+휠로 0.1단위의 가격을 설정할 수 있습니다.",
  "ItemBuyEditor.note.posAmount": "수량은 0보다 적을 수 없습니다.",
  "ItemBuyEditor.note.maxAmount": "판매량은 1000개를 넘길 수 없습니다",
  "ItemBuyEditor.note.invalidAmount": "적절하지 않은 수량입니다",
  "ItemBuyEditor.note.minPrice": "최소 가격은 다음과 같습니다",
  "ItemBuyEditor.note.maxTotal": "최종 구매 수량은 다음과 같습니다",
  "ItemBuyEditor.note.invalidPrice": "적절하지 않은 구매 가격입니다",
  "ItemBuyEditor.note.mustAgree": "이용 약관에 동의해 주셔야 합니다",
  "ItemBuyEditor.note.insufficentFunds0": "가이진 코인이 부족합니다, 다음의 가이진 코인이",
  "ItemBuyEditor.note.insufficentFunds1": "이 아이템 구매를 위해 필요합니다",
  "ItemBuyEditor.label.buyCouns": "구매",
  "ItemBuyEditor.label.submit": "구매 요청",
  "ItemBuyEditor.label.price": "개당 가격:",
  "ItemBuyEditor.label.amount": "수량:",
  "ItemBuyEditor.label.totalPrice": "최종 가격(수수료 포함):",
  "ItemBuyEditor.label.agree": "가이진 스토어 정책에 동의합니다",
  "ItemBuyEditor.label.ToU": "이용 약관",
  "MarketDepthTotals.noOrders.sell": "판매 주문 없음",
  "MarketDepthTotals.noOrders.buy": "구매 주문 없음",
  "MarketDepthTotals.starting.sell": " 가격 ",
  "MarketDepthTotals.starting.buy": " 가격 ",
  "MarketDepthTotals.andMore.sell": " 이하",
  "MarketDepthTotals.andMore.buy": " 이상",
  "MarketDepthTotals.prevew.sell": "구매 중인 품목: ",
  "MarketDepthTotals.prevew.buy": "판매 중인 품목: ",
  "MarketDepthOwnWarning.sell": "판매 주문이 대기 중입니다",
  "MarketDepthOwnWarning.buy": "구매 주문이 대기 중입니다",
  "MarketDepthTable.caption.price": "가격",
  "MarketDepthTable.caption.quantity": "수량",
  "MarketDepthTable.ellipsis.sell": "이상",
  "MarketDepthTable.ellipsis.buy": "이하",
  "ItemMarketDetails.buyButton": "구매",
  "ItemMarketDetails.sellButton": "판매",
  "ItemMarketDetails.nothingToSellButton": "판매할 물품이 없습니다",
  "ItemMarketDetails.makeAuction": "경매 만들기",
  "ItemMarketDetails.makeBid": "입찰",
  "ItemMarketDetails.bid": "입찰",
  "ItemMarketDetails.yourBid": "귀하의 입찰:",
  "ItemMarketDetails.notifications.yourBid": "귀하의 입찰",
  "ItemMarketDetails.yourBid.short": "당신의",
  "ItemMarketDetails.yourBid.win": "이기다",
  "ItemMarketDetails.yourBid.loose": "지다",
  "ItemSellEditor.label.priceStep": "가격 단계",
  "ItemSellEditor.label.endDate": "종료 날짜",
  "limitedAccountMessage.requires": "요구 사항:",
  "limitedAccountMessage.2step": "2단계 인증",
  "limitedAccountMessage.verifiedEmail": "이메일 본인 확인",
  "limitedAccountMessage.and": " 그리고 ",
  "ItemSellEditor.label.wheelInput": "마우스 휠을 이용하여 구매 가격을 결정할 수 있습니다, \nalt+휠로 10단위의 가격을, \nShift+휠로 0.1단위의 가격을 설정할 수 있습니다.",
  "ItemSellEditor.note.minPrice": "최소 가격은 다음과 같습니다",
  "ItemSellEditor.note.maxPrice": "최대 가격은 다음과 같습니다",
  "ItemSellEditor.note.maxTotals": "최대 판매 수량은 다음과 같습니다",
  "ItemSellEditor.note.maxBalance": "가이진 코인의 총량은 다음을 넘길 수 없습니다",
  "ItemSellEditor.note.mustAgree": "이용 약관에 동의해 주셔야 합니다",
  "ItemSellEditor.label.price": "구매자가 지불할 가격(수수료 포함):",
  "ItemSellEditor.label.wished": "수수료 정산 후 받게 되는 가격:",
  "ItemSellEditor.label.agree": "가이진 스토어 정책에 동의합니다",
  "ItemSellEditor.label.ToU": "이용 약관",
  "ItemSellEditor.label.submit": "물품 판매",
  "ItemSellEditor.currentBalance.before": "현재",
  "ItemSellEditor.currentBalance.after": "을 소유하고 있습니다",
  "ItemSellEditor.sellOrdersTotal.before": "=+",
  "ItemSellEditor.sellOrdersTotal.after": "누적 판매 주문량",
  "ItemSellEditor.buyOrdersTotal.before": "그리고",
  "ItemSellEditor.buyOrdersTotal.after": "누적 구매 주문량",
  "ItemSellEditor.label.amount": "판매할 수량:",
  "AppFilterEditor.label.byTags": "Filter",
  "AppFilterEditor.label.clearFilter": "필터 초기화",
  "AppFilterEditor.label.byTitles": "게임별로 필터링",
  "AppFilterEditor.label.allFilter": "모든 게임",
  "AppFilterEditor.label.authenticity": "관련성",
  "AppFilterEditor.message.filter": "필터는 각 게임별로 적용됩니다.",
  "AppFilterEditor.message.game": "게임 선택:",
  "ViewItemDetailed.restriction.amount.items": " items",
  "ViewItemDetailed.restriction.nonMarketable": "거래할 수 없습니다.",
  "ViewItemDetailed.restriction.blockedAfter": "다음에 잠금될 것입니다",
  "ViewItemDetailed.restriction.blockedPair": "거래 잠김",
  "ViewItemDetailed.restriction.expiresAt1": "소멸 예정까지",
  "ViewItemDetailed.restriction.expiresAt2": "",
  "ViewItemDetailed.restriction.endedAt": "End of auction",
  "ViewItemDetailed.restriction.amount": "Lot contains",
  "ViewItemDetailed.restriction.pieces": "pcs.",
  "ViewItemDetailed.restriction.startPrice": "Starting price",
  "ViewItemDetailed.restriction.days": "d",
  "ViewItemDetailed.restriction.hours": "hrs",
  "ViewItemDetailed.restriction.minutes": "min",
  "ViewItemDetailed.restriction.leadingBids": "leading bids",
  "ViewItemDetailed.restriction.noBids": "There are no bids yet. Place your first bid in this auction!",
  "ViewItemDetailed.restriction.delayTrade": "Once purchased, the item will be available for sale through",
  "ViewItemDetailed.restriction.unclearStatus": "트로피 케이스에 대한 해당 국가의 법적 견해가 확실하지 않아, 계정 내 트로피와 키의 거래 기능을 이용하실 수 없습니다.",
  "MarketSortOrder.label.name": "이름",
  "MarketSortOrder.hint.newDesc": "신규 아이템 우선",
  "MarketSortOrder.hint.newAsc": "구 아이템 우선",
  "MarketSortOrder.label.price": "가격",
  "MarketSortOrder.hint.priceAsc": "오름차순 (가격)",
  "MarketSortOrder.hint.priceDesc": "내림차순 (가격)",
  "MarketSortOrder.label.amount": "수량",
  "MarketSortOrder.hint.amountAsc": "오름차순 (수량)",
  "MarketSortOrder.hint.amountDesc": "내림차순 (수량)",
  "MarketSortOrder.label.popular": "인기순",
  "MarketSortOrder.label.popularItems": "인기 아이템",
  "DoubleSellErrorModal.heading": "오류",
  "DoubleSellErrorModal.prompt": "해당 아이템을 판매하지 못했습니다",
  "DoubleSellErrorModal.explanation": "판매 주문을 만드는 데 실패하였습니다. - 아이템이 이미 팔렸거나, 게임 내에서 소모되었는지 확인해 주신 후, \n 페이지를 새로 고쳐주십시오.",
  "DoubleSellErrorModal.okButton": "확인",
  "OnBuyReserveFailModal.heading": "오류",
  "OnBuyReserveFailModal.prompt": "아이템 구매 주문에 실패하였습니다",
  "OnBuyReserveFailModal.explanation": "지갑에 남은 가이진 코인이 부족하여 구매 주문에 실패하였습니다.",
  "OnBuyReserveFailModal.youNeed1": "구매 주문을 위해선",
  "OnBuyReserveFailModal.youNeed2": "이(가) 필요합니다.",
  "OnBuyReserveFailModal.tryRefresh": "가이진 코인을 충분히 보유하고 있는지 확인한 후 페이지를 새로고침 하여 주십시오",
  "OnBuyReserveFailModal.okButton": "확인",
  "DoubleRateErrorModal.heading": "Error",
  "DoubleRateErrorModal.prompt": "An error occurred while placing a bid",
  "DoubleRateErrorModal.explanation": "Please try to bid later",
  "DoubleRateErrorModal.okButton": "OK",
  "AuctionFinishedErrorModal.heading": "Error",
  "AuctionFinishedErrorModal.prompt": "An error occurred while placing a bid",
  "AuctionFinishedErrorModal.explanation": "The auction was completed",
  "AuctionFinishedErrorModal.okButton": "OK",
  "CancelOrderErrorModal.heading": "오류",
  "CancelOrderErrorModal.prompt": "주문 취소에 실패하였습니다",
  "CancelOrderErrorModal.explanation": "네트워크 문제 혹은 주문 업데이트로 인해 작업에 실패하였습니다. \n잠시 후 다시 시도해 주십시오",
  "CancelOrderErrorModal.cancelButton": "확인",
  "OrdersListContainer.Tab.sellOrders": "판매 주문",
  "OrdersListContainer.Tab.buyOrders": "구매 주문",
  "OrdersListContainer.Tab.historyList": "주문 이력",
  "ViewOrdersEntry.amounts.remain": "수량",
  "ViewOrdersEntry.amounts.done": "완료",
  "ViewOrdersEntry.order.order": "주문",
  "ViewOrdersEntry.order.issued": "주문한 시각:",
  "ViewOrdersEntry.order.executed": "처리된 시각:",
  "ViewOrdersEntry.order.pairIsBlocked": "거래 잠김",
  "ViewOrdersEntry.order.pairWillBlockAfter": "다음 시일에 거래가 잠깁니다",
  "ViewOrdersEntry.order.BUY": "구매",
  "ViewOrdersEntry.order.SELL": "판매",
  "ViewOrdersEntry.order.view": "확인하기",
  "ViewOrdersEntry.order.cancel": "취소",
  "ViewOrdersList.empty.label": "아무것도 없습니다",
  "CancelOrderDialog.heading.youSure": "이대로 진행하시겠습니까?",
  "CancelOrderDialog.body.youSure": "구매 주문을 취소하시겠습니까?",
  "CancelOrderDialog.button.cancel": "네, 1개 상품을 취소하겠습니다",
  "CancelAllOrderDialog.button.cancel": "네, 전부 취소하겠습니다.",
  "CancelOrderDialog.button.noCancel": "아니오",
  "CancelOrderDialog.heading.processing": "진행중...",
  "CancelOrderDialog.body.processing": "진행 중입니다. 잠시만 기다려 주십시오",
  "ProfileMenu.MenuItem.Settings": "설정",
  "ProfileMenu.MenuItem.GaijinAccount": "Gaijin.net 프로필",
  "ProfileMenu.MenuItem.AccountSwitch": "계정 변경",
  "ProfileMenu.MenuItem.Signout": "종료",
  "ProfileMenu.MenuItem.nick": "귀하의 닉네임",
  "WalletBlock.balance.text": "잔액:",
  "WalletBlock.balance.buyCurrency": "GJN 구매",
  "SecurityBlock.message.title": "2단계 인증",
  "SecurityBlock.emailVerified.title": "이메일",
  "OrdersHistory.title.buy": "구매 주문",
  "OrdersHistory.title.sell": "판매 주문",
  "NotificationsAssets.title": "알림",
  "NotificationsAssets.title.today": "오늘",
  "NotificationsAssets.title.historyFromLastWeek": "지난 7일 동안",
  "NotificationsAssets.title.historyOlder": "이전 알림",
  "LimitedAccountPanel.2step.pleas": "마켓을 이용하기 위해선 계정을 보호해야 합니다.",
  "LimitedAccountPanel.2step.protected": "계정이 보호되어 있어 거래가 가능합니다.",
  "LimitedAccountPanel.email.pleas": "이메일을 인증하면 마켓을 이용할 수 있습니다",
  "LimitedAccountPanel.email.protected": "이메일 인증이 완료되어 마켓을 자유롭게 이용할 수 있습니다.",
  "OrdersListContainer.Opened.Orders": "열림",
  "OrdersListContainer.Closed.Orders": "닫힘",
  "ViewOrdersList.empty.labelCollapse": "접기",
  "ViewOrdersList.empty.labelExpand": "펼치기",
  "OrdersGraph.plot.title": "구매/판매 주문",
  "OrdersGraph.fmt.offers.sell": "개의 판매 주문\n<br/>가격:",
  "OrdersGraph.fmt.andMore.sell": "이하",
  "OrdersGraph.fmt.offers.buy": "개의 구매 주문\n<br/>가격:",
  "Orders.title.header": "주문",
  "OrdersGraph.fmt.andMore.buy": "이상",
  "PriceHistoryGraph.plot.title": "가격 내역:",
  "PriceHistoryGraph.highlighter.format": "시간<strong>%s</strong><br />가격<strong>%s</strong><br />거래량<strong>%s</strong><br />",
  "PriceHistoryGraph.plot.shortRange": "좁은 범위",
  "PriceHistoryGraph.plot.wholeRange": "전체 시간",
  "DealEventTickerEntry.purchasedFrom": "이(가)",
  "DealEventTickerEntry.for": "(으)로부터 다음 가격에 이 아이템을 구매하였습니다:",
  "BookSellTickerEntry.listedItem": "sale request",
  "BookBuyTickerEntry.created": "생성됨",
  "BookBuyTickerEntry.for": "purchase order",
  "BookAuctionTickerEntry.created": "made a bet",
  "ViewTradeTickerLog.waiting": "새 활동 기다리는 중...",
  "ViewTradeTickerLog.ticker": "Real time trading...",
  "ViewUserInventory.common.emptyInventory": "인벤토리가 비어있습니다",
  "ViewItemPreviewSidePane.UnselectButton.label": "필터로 돌아가기",
  "ViewItemPreviewSidePane.TradeButton.label": "구매/판매",
  "ViewItemPreviewSidePane.ViewButton.label": "확인하기",
  "ViewItemPreviewSidePane.QuickSellButton.label": "빠른 판매",
  "ViewInventoryTagCloud.tagTitle.itemsOf": "다음 게임의 아이템",
  "ViewInventoryTagCloud.tagTitle.varietiesMarkedAs": "다음 태그를 만족하는 품목",
  "ViewItemSearch.heading.inventory": "내 아이템",
  "WalletBlock.circleSmall.settings": "설정",
  "WalletBlock.circleSmall.exit": "종료",
  "UserSettingsDialog.language": "언어",
  "UserSettingsDialog.user_settings": "유저 설정",
  "UserSettingsDialog.settings": "설정",
  "UserSettingsDialog.save_settings": "설정 저장",
  "UserSettingsDialog.privacySettings": "거래",
  "UserSettingsDialog.privacySettingsPublic": "공개",
  "UserSettingsDialog.privacySettingsPrivate": "비공개",
  "UserSettingsDialog.interface": "Interface",
  "UserSettingsDialog.interfaceOld": "Legacy",
  "UserSettingsDialog.interfaceNew": "Modern",
  "WalletLimitsIcon.soft_limit": "가이진 코인 소유 제한을 넘어섰습니다.\n코인 충전 기능을 이용하실 수 없습니다.",
  "WalletLimitsIcon.hard_limit": "가이진 코인 소유 제한을 넘어섰습니다.\n판매 및 코인 충전 기능을 이용하실 수 없습니다.",
  "ActionBar.action.ingamePreview": "게임 내에서 확인하기",
  "ActionBar.action.previewCopied": "링크 복사 완료!",
  "ActionBar.action.previewShare": "공유",
  "IngamePreview.ask.heading": "게임 내에서 확인하시겠습니까?",
  "IngamePreview.ask.prompt": "잠시 후 게임 내에서 아이템을 확인하실 수 있습니다",
  "IngamePreview.ask.explanation": "이 계정으로 클라이언트를 가동하고 있는지 확인해 주세요.",
  "IngamePreview.ask.cancelButton": "아니오",
  "IngamePreview.done.heading": "확인",
  "IngamePreview.done.prompt": "",
  "IngamePreview.done.explanation": "전송에 성공하였습니다",
  "IngamePreview.done.okButton": "확인",
  "IngamePreview.fail.heading": "실패",
  "IngamePreview.fail.prompt": "",
  "IngamePreview.fail.explanation": "게임 클라이언트와 연동할 수 없습니다",
  "IngamePreview.fail.cancelButton": "확인",
  "IngamePreview.pending.heading": "잠시만 기다려 주세요...",
  "IngamePreview.pending.prompt": "게임 클라이언트를 확인하고 있습니다",
  "IngamePreview.pending.explanation": "",
  "IngamePreview.ask.okButton": "네, 보여주세요",
  "StaticConfigLoader.caption.error": "실행 오류",
  "StaticConfigLoader.caption.init": "실행중",
  "StaticConfigLoader.text.init": "앱 실행중",
  "StaticConfigLoader.error.settings": "웹페이지 설정을 불러올 수 없습니다",
  "StaticConfigLoader.error.circuits": "서비스 설정 파일을 불러올 수 없습니다",
  "ControlButton.close": "닫기",
  "Exit": " ",
  "Legal.TermsConditions": "이용 약관",
  "Legal.TermsService": "서비스 약관",
  "Legal.PrivacyPolicy": "개인정보 보호정책",
  "Legal.CookiesPolicy": "쿠키 정책",
  "Legal.TradePolicy": "거래 정책",
  "Legal.TermsConditions.linck": "https://legal.gaijin.net/ko/",
  "Legal.TermsService.linck": "https://legal.gaijin.net/ko/termsofservice/",
  "Legal.PrivacyPolicy.linck": "https://legal.gaijin.net/ko/privacypolicy/",
  "Legal.CookiesPolicy.linck": "https://legal.gaijin.net/ko/cookie/",
  "Legal.TradePolicy.linck": "https://legal.gaijin.net/ko/tradepolicy/",
  "Legal.Copyright": "Gaijin Network Ltd. 모든 상표, 로고 그리고 브랜드명은 각 소유자의 재산입니다.",
  "MarketApp.Navbar.HeadTitle": "Gaijin.Net",
  "MarketApp.Navbar.HeadSubTitle": "Market",
};