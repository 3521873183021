import ApplicationEvents from 'components/applicationEvents';
import { ClassInfoDescriptions, ClassInfoDropDescriptions } from 'components/classInfoDescriptions';
import { fetchExtendedTags } from 'components/classInfoUtil';
import G_marketConfigStorage from 'components/globalMarketConfigStorage';
import { ViewInGamePanel } from 'components/hooks/itemDetailedHooks';
import { _I_ } from 'components/translate';
import ViewAssetWarnings from 'generics/restriction';
import { classMixer } from 'generics/utils';
import React from 'react';
import ReactGA from 'react-ga';
import Scrollbar from "react-scrollbars-custom";
import { MarketStore } from 'stores';

export class ViewAmountBadge extends React.Component {
  render() {
    if (this.props.showLowCounts || (this.props.amount > 1)) {
      return (
        <div className="quantity">{this.props.amount}
          <span className="count">{_I_("ViewItemDetailed.restriction.amount.items")}</span>
        </div>
      );
    }

    return null
  }
}

export class ViewAppIdIcon extends React.Component {
  render() {
    const appDesc = G_marketConfigStorage.resolveAppId(this.props.appId);

    return (
      <React.Fragment>
        <img className="projectIcon" title={appDesc.desc.join("\n")} src={appDesc.icon} />
        <div className="projectName">
          {appDesc.briefName}
          {this.props.children}
        </div>
      </React.Fragment>
    );
  }
}

export class ViewItemIconic extends React.Component {
  render() {
    let classInfo = this.props.classInfo;
    let iconStyle = {};

    if (classInfo.background_color)
      iconStyle.backgroundColor = '#' + classInfo.background_color;

    let imageBorder = {};
    let overlayTextColor = {};

    if (classInfo.name_color) {
      imageBorder.borderColor = '#' + classInfo.name_color;
      overlayTextColor.color = '#' + classInfo.name_color;
    }

    const imageUrl = (this.props.large && classInfo.icon_url_large) || classInfo.icon_url;

    return (
      <div className="itemContent"
        style={iconStyle}
        title={classInfo.name}
        onClick={this.props.onClick}
      >

        <div className="itemImage">
          {(!!imageUrl) &&
            (<img style={imageBorder} className="main" src={imageUrl} />)}
        </div>

        <div className="itemOver"
          style={overlayTextColor}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}

export class ViewItemInfoIconic extends React.Component {

  render() {
    let classInfo = this.props.classInfo;

    const imageUrl = (this.props.large && classInfo.icon_url_large) || classInfo.icon_url;
    const hexRarityColor = classInfo.name_color || {};
    const rarityBlockStyle = hexRarityColor ?
      {
        "background": `radial-gradient(50% 50.00% at 50% 50.00%, rgba(39, 42, 46, 0.60) 0%,
        rgba(39, 42, 46, 0.67) 22.92%, rgba(39, 42, 46, 0.76) 45.31%,
        rgba(39, 42, 46, 0.94) 85.42%, #272A2E 100%), #${hexRarityColor}`
      } : {};

    return (
      <div className="image" title={classInfo.name}>
        {(!!imageUrl) &&
          <img src={imageUrl} onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = "images/broken_item.png";
          }} />
        }
        <div className="rarityBg" style={rarityBlockStyle} />
      </div>
    )
  }
}

export class ClassInfoTagList extends React.Component {
  render() {
    const { classInfo, appId } = this.props;

    const onTagClick = (tagDesc) => {
      const { category, color, name: loc, internal_name } = tagDesc;
      const tags = {};
      const tagName = category + ":" + internal_name;

      tags[tagName] = { loc, name: tagName, color }

      ApplicationEvents.emit_async("market.setCurrentAssetByHash");

      MarketStore.setCurrentAppId(appId);
      MarketStore.setFilterTags(tags);

      if (MarketStore.page === "market")
        MarketStore.setPage("marketSell");
    };

    const tags = fetchExtendedTags(
      classInfo
    ).filter(
      tagDesc => tagDesc && tagDesc.name
    ).map((tagDesc, idx) => {
      const tagColor = (tagDesc.color ? { "backgroundColor": '#' + tagDesc.color } : {});
      return (
        <div
          className="tag"
          style={tagColor}
          key={idx}
          onClick={() => onTagClick(tagDesc)}
        >
          {tagDesc.category_name + ": " || ""}
          {tagDesc.name || ""}
        </div>
      );
    });

    return (<div className="tags">{tags}</div>);
  }
}

export class ViewItemDetailed extends React.Component {
  componentDidMount() {
    const { classInfo, appId } = this.props;
    ReactGA.pageview('item/' + appId + '/' + encodeURI(classInfo.market_hash_name));
  }

  render() {
    const { classInfo, blockedAfter, appId } = this.props;
    const assetNameColor = (classInfo.name_color ? { "color": '#' + classInfo.name_color } : null);

    return (
      <div id="inventoryItemInfo" >
        <Scrollbar className="list" defaultStyles={false} scrollX={false}>
          <ViewItemInfoIconic classInfo={classInfo} large >
            <div className="lotMeta">
              <div className="name" style={assetNameColor}>{classInfo.name}</div>
            </div>
            <ViewAmountBadge amount={this.props.amount} showLowCounts />
          </ViewItemInfoIconic>

          <div className="gameBlock">
            <ViewAppIdIcon appId={appId} compact />
          </div>
          <div className="name">{classInfo.name}</div>
          <ViewAssetWarnings classInfo={classInfo} blockedAfter={blockedAfter} />

          <ClassInfoDescriptions
            appid={appId}
            descriptions={classInfo.descriptions}
            owner_descriptions={classInfo.owner_descriptions}
            type="text"
          />
          <div className="commodityContents">
            <ClassInfoDropDescriptions
              appid={appId}
              descriptions={classInfo.descriptions}
              owner_descriptions={classInfo.owner_descriptions}
              type="dropped_item"
              parent_hashName={classInfo.market_hash_name}
            />

            <ClassInfoDropDescriptions
              appid={appId}
              descriptions={classInfo.descriptions}
              owner_descriptions={classInfo.owner_descriptions}
              type="dropped_bundle"
              parent_hashName={classInfo.market_hash_name}
            />
          </div>
          <ViewInGamePanel classInfo={classInfo} appId={appId} />

          <ClassInfoTagList classInfo={classInfo} appId={appId} />

          {this.props.children}
        </Scrollbar>
      </div>
    );
  }
}

function getHexColor(name_color, op) {
  let hex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(name_color || null);
  let rgb = null;

  if (hex != null) {
    rgb = "rgba(" + parseInt(hex[1], 16) + "," + parseInt(hex[2], 16) + "," + parseInt(hex[3], 16) + ", " + op + ")";
  };

  return rgb;
}

export class ViewItemCard extends React.Component {
  render() {
    const { classInfo, appId } = this.props;

    let iconRgb = getHexColor(classInfo.name_color, 1);
    let cardRgb = getHexColor(classInfo.name_color, 0.28);
    let cardRgbTo = getHexColor(classInfo.name_color, 0.05);

    const iconStyle = classInfo.name_color ?
      {
        background: iconRgb,
        boxShadow: "0px 6px 16px " + iconRgb
      } : {};

    const cardStyle = classInfo.name_color ?
      {
        background: "radial-gradient(142.5% 100% at 50% 0%, " + cardRgb + " 0%, " + cardRgbTo + " 100%)"
      } : {};

    return (
      <div className="card_buy" style={cardStyle}>
        <div className="rarity">
          <div className="rarity_color" style={iconStyle} />
        </div>
        <ViewItemInfoIconic classInfo={classInfo} large />
        <div className="summary">
          <div className="projectBlock">
            <ViewAppIdIcon appId={appId} compact />
          </div>
          <div className="title">{classInfo.name}</div>
          <ClassInfoTagList classInfo={this.props.classInfo} appId={appId} />
        </div>
      </div >
    )
  }
}

export class ViewCheckbox extends React.Component {
  render() {
    const { checked } = this.props;
    const checkedClass = classMixer("checkbox-field", checked && "checked");

    return (
      <div className="checkbox-block">
        <div className={checkedClass} />
        {this.props.children}
      </div>
    );
  }
}

export class ViewAmountInput extends React.Component {
  render() {
    const { amount, buyAmount, onBlur, onChange, canBeLess, canBeMore, onAmountChange } = this.props;

    const onClickLess = () => {
      if (canBeLess)
        onAmountChange(buyAmount - 1)
    }

    const onClickMore = () => {
      if (canBeMore)
        onAmountChange(buyAmount + 1)
    }

    return (
      <div className="editor-amount-input">
        <input type="text" className="form-control input-sm"
          value={amount || 0} onBlur={onBlur || null} onChange={onChange || null} />
        <div className={classMixer("editor-amount-input-less", !canBeLess && "disabled")}
          onClick={onClickLess}>&minus;</div>
        <div className={classMixer("editor-amount-input-more", !canBeMore && "disabled")}
          onClick={onClickMore}>+</div>
      </div>
    )
  }
}