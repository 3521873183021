import { WalletLoadLimits } from 'api/walletAPICall';
import { LogStream } from 'generics/common';
import { dataBound } from 'generics/dataBound';
import { DataSource } from 'generics/utils';
import React from 'react';

// LogStream.enable("WALLETLIMITS");
const loggerWalletLimits =  LogStream("WALLETLIMITS");


const SAFE_DEFAULTS = {  "soft_limit": 2500,  "hard_limit": 5000,  "op_limit":500  };
export const walletLimits = new DataSource(SAFE_DEFAULTS);
walletLimits.on( ( limits ) => {
    loggerWalletLimits.debug( "walletLimits::emit(", limits, ")" );
  });


class WalletLimitsController extends React.Component  {

  componentDidMount()  {
    this.requestUpdate();
  }


  componentWillUnmount()  {
  }


  requestUpdate()  {
    WalletLoadLimits().then(
      ( resp ) =>  {
        const {  soft_limit,  hard_limit,  op_limit,  } = Object.assign({}, SAFE_DEFAULTS, resp);
        if ( (soft_limit >= 0) && (hard_limit >= 0) && (op_limit >= 0) )  {
          walletLimits.set({  soft_limit,  hard_limit,  op_limit,  });
        }  else  {
          loggerWalletLimits.info( "Leaving default safety config" );
          walletLimits.set(walletLimits.get());
        }
      },
      ( reason ) =>  {
        loggerWalletLimits.error( "Failed to load wallet limits:\n", reason);
      });
  }


  render() {
    return null;
  }
}


export default dataBound(WalletLimitsController, {
  "wlimits":walletLimits
});


