import React from "react";
import ViewItemMarketDetailsController from "./itemMarketDetailsController";
import { useWarningState } from "../generics/marketHooks";


const MarketDetails = ({classInfo, appid, ctxid, brief, instances, totalAmount, blockedAfter}) => {
  const { updateData } = useWarningState();

  if (!(classInfo.marketable || classInfo.auctionable))
    return;
  const haveItems = (
    (instances) &&
    (instances.length > 0) &&
    (totalAmount > 0)
  );

  const asssid = haveItems ? instances[0].id : null;

  return (
    <ViewItemMarketDetailsController
      appid={appid}
      ctxid={ctxid}
      classInfo={classInfo}
      market_name={classInfo.market_hash_name}
      haveItems={haveItems}
      asssid={asssid}
      blockedAfter={blockedAfter}
      totalAmount={totalAmount}
      instances={instances}
      updateData={updateData}
      brief={brief}
    />
  )
}

export default MarketDetails;