export const plural = (number, words) => {
  return Math.abs(parseInt(number, 10)) === 1 ? words[0] : words[1];
};

export const locale = {
  "ViewItemPreviewSidePane.quantity.inventory": "no inventário ",
  "ViewItemPreviewSidePane.quantity.ordersInventory": " itens no inventário",
  "ViewItemPreviewSidePane.quantity.count": " à venda",
  "ViewItemPreviewSidePane.quantity.countBuy": " odens de compra",
  "ViewItemPreviewSidePane.quantity.items": " itens",
  "ViewMarketedItem.SoldOutText": "esgotado",
  "ViewMarketedItem.SoldOutTip": "Sem ordens de compra",
  "ViewMarketedItem.NoBuyText": "sem ordens de compra",
  "ViewMarketedItem.SellingFrom": "Vendendo a partir de...",
  "ViewMarketedItem.SellingMore": " ou mais",
  "ViewMarketedItem.BuyingMore": " ou menos",
  "ViewMarketedItem.tradableAfter": "ficará disponível para venda após",
  "ViewItemSearch.heading.showAll": "Mostrar listagem completa",
  "ViewItemSearch.heading.orders": "As minhas ordens",
  "ViewItemSearch.heading.marketSell": "Ordens de venda",
  "ViewItemSearch.heading.marketBuy": "Ordens de compra",
  "ViewItemSearch.heading.marketAuctions": "Leilões",
  "ViewItemSearch.title.marketAuction": "Leilão",
  "ViewItemSearch.title.ending": "Até o fim",
  "ViewItemSearch.title.finished": "Leilão terminado",
  "ViewItemSearch.heading.settings": "Configurações",
  "ViewMarketedItem.button.more": "Mais detalhes",
  "ViewItemSearch.heading.resultsFor": "Mostrando resultados de:",
  "ViewItemSearch.stub.empty": "Resultado de pesquisa em branco",
  "ViewItemSearch.heading.tooltip": "Sua oferta foi superada",
  "ViewItemSearch.heading.tooltip.win": "Seu lance venceu",
  "ViewItemPopup.committed.SELL": "Venda concluída",
  "ViewItemPopup.committed.BUY": "Compra concluída",
  "ViewItemPopup.committed.BID": "Lance feito",
  "ViewItemPopup.application.BUY": "Ordem de compra criada",
  "ViewItemPopup.application.SELL": "Pedido de venda criado",
  "ViewItemPopup.application.BID": "Lance feito",
  "ViewItemPopup.canceled.BUY": "Ordem de compra cancelada",
  "ViewItemPopup.canceled.SELL": "Pedido de venda cancelado",
  "ViewItemPopup.canceled.BID": "Lance cancelado",
  "ViewItemPopup.showAll.button": "Ver tudo",
  "ViewItemAssets.readAll.button": "Marcar todos como vistos",
  "ViewItemPopup.heading.noHistory": "Ainda sem notificações",
  "ViewItemPopup.heading.unseenHistory": "Sem novas notificações",
  "ViewItemSearch.termsPlaceholder": "Termos de pesquisa aqui",
  "ViewItemSearch.label.search": "Pesquisar itens",
  "ViewMarketedItem.lovestPrice": "de ",
  "ViewMarketedItem.beforePrice": "antes ",
  "ViewMarketedItem.yourBid": "seu lance ",
  "ViewMarketedItem.bid": "lance ",
  "ViewItemSearch.heading.exchange": "Home",
  "ViewItemSearch.heading.light": "Branco",
  "ViewItemSearch.heading.dark": "Escuro",
  "ViewItemSearch.filteringAnnounce": "De forma a filtrar ordens é necessário selecionar um jogo",
  "ViewItemSearch.ordersBuy": "COMPRAR",
  "ViewItemSearch.ordersSell": "VENDER",
  "ViewItemSearch.ordersOpen": "Ver histórico completo",
  "MarketApp.Navbar.inventoryLink": "Inventário",
  "MarketApp.Navbar.ordersLink": "As minhas ordens",
  "MarketApp.Navbar.marketLink": "Mercado",
  "MarketApp.Navbar.settingsLink": "Configurações",
  "MarketApp.Navbar.replenishLink": "Adicionar fundos",
  "MarketApp.Navbar.balanceHistoryLink": "Histórico de compras",
  "MarketApp.Navbar.buyCoinsLink": "Comprar GaijinCoins",
  "MarketApp.Navbar.profileLink": "Perfil",
  "MarketApp.Navbar.reloginLink": "Reentrar ou mudar de conta",
  "MarketApp.Navbar.toOldVersion": "Ir para versão antiga",
  "MarketApp.Navbar.linkFeedback": "Deixar opinião",
  "ViewTradeDialog.caption": "Comércio",
  "ViewTradeDialog.button.back": "Voltar",
  "NotAllowedMessage.caption": "Não permitido",
  "NotAllowedMessage.body.h1": "Acesso ao mercado",
  "NotAllowedMessage.body.h3": "neste momento não é permitido a sua conta",
  "NotAllowedMessage.relogin": "Alterar conta",
  "CountryDeniedMessage.caption": "Não permitido",
  "CountryDeniedMessage.body.notAllowed": "Acesso ao mercado restringido",
  "CountryDeniedMessage.body.unclearStatus": "Devido ao estatuto legal não claro das transações no mercado em seu país, o acesso ao mercado está restrito",
  "LandingMessage.caption": "Autenticação",
  "LandingMessage.body": "Verificações finais...",
  "TokenExpiredMessage.caption": "Autenticação expirada",
  "TokenExpiredMessage.body.h1": "Autenticação expirada",
  "TokenExpiredMessage.body.h3": "Não há muito que possamos fazer, mas ainda é possível",
  "TokenExpiredMessage.relogin": "Voltar a iniciar sessão...",
  "AuthErrorMessage.caption": "Autenticação falhou",
  "AuthErrorMessage.body.h1": "Falha ao verificar autenticação",
  "AuthErrorMessage.body.code": "Código:",
  "AuthErrorMessage.body.desc": "Não há muito que possamos fazer, mas ainda é possível",
  "AuthErrorMessage.relogin": "Voltar a iniciar sessão...",
  "AuthorizationRequiredMessage.caption": "Autenticação necessária",
  "AuthorizationRequiredMessage.body.h3.1": "Você está prestes a entrar",
  "AuthorizationRequiredMessage.body.h3.2": "Gaijin Marketplace",
  "AuthorizationRequiredMessage.body.h3.3": "É necessário autorização para prosseguir",
  "AuthorizationRequiredMessage.login": "Iniciar sessão com Gaijin.Net",
  "CheckingAuthMessage.caption": "Autenticação",
  "CheckingAuthMessage.body": "Verificando autenticação, por favor aguarde",
  "CancelOrdersPanel.cancelError": "\nFalha no cancelamento da ordem ",
  "CancelOrdersPanel.loading": "Verificando ordens ativas",
  "CancelOrdersPanel.done": "Operação terminada.",
  "CancelOrdersPanel.errors": "Encontrados alguns erros: \n",
  "CancelOrdersPanel.loaded.youHave": "Neste momento tem ",
  "CancelOrdersPanel.loaded.sellOrders": " ordens de venda totalizando ",
  "CancelOrdersPanel.loaded.buyOrders": " ordens de compra totalizando ",
  "CancelOrdersPanel.loaded.and": " e ",
  "CancelOrdersPanel.loaded.shouldCancel": " e terá de cancelar todas. ",
  "CancelOrdersPanel.loaded.doCancel": "Cancelar ordens",
  "CancelOrdersPanel.cancelling.processing": "Processando",
  "CancelOrdersPanel.cancelling.outOf": " de ",
  "CancelOrdersPanel.cancelling.sellOrders": " ordens de venda",
  "CancelOrdersPanel.cancelling.buyOrders": " ordens de compra",
  "ClassInfoDescriptions.dropped_item.block_label": "Contém um dos seguintes:",
  "ClassInfoDescriptions.dropped_item.stage-unknown": "Desconhecido",
  "ClassInfoDescriptions.dropped_item.stage-pending": "...carregando",
  "ClassInfoDescriptions.text.block_label": "",
  "ClassInfoDescriptions.dropped_bundle.block_label": " ",
  "ClassInfoDescriptions.buttonLabel.top": "Mostrar",
  "ClassInfoDescriptions.buttonLabel.hide": "Esconder",
  "ClassInfoDescriptions.buttonLabel.bottom": "tudo",
  "ClassInfoDescriptions.dropped_text.description": "Description",
  "ClassInfoUtil.fake_name.pending": "Pendente",
  "ClassInfoUtil.fake_name.broken": "Itens apagados",
  "ClassInfoUtil.SynthTag.marketable1": "Transacionável",
  "ClassInfoUtil.SynthTag.marketable0": "Intransacionável",
  "ClassInfoUtil.SynthTag.tradable1": "Transacionável",
  "ClassInfoUtil.SynthTag.tradable0": "Intransacionável",
  "ClassInfoUtil.SynthTag.commodity1": "Material",
  "ClassInfoUtil.SynthTag.commodity0": "Não-material",
  "ConfirmBuyDialog.title": "Efetuar ordem de compra",
  "BuyProcessingDialog.title": "Processando...",
  "BuyProcessingDialog.pleaseWait": "Processando, por favor aguarde",
  "ProcessingDialog.notItem": "O item solicitado não foi encontrado no mercado ou não está disponível para negociação",
  "SellDialog.title": "Colocar item à venda",
  "SellProcessingDialog.title": "Processando...",
  "SellProcessingDialog.pleaseWait": "Processando, por favor aguarde",
  "LimitedAccountPanel.2step.reason": "Deverá configurar a autorização de dois passos",
  "LimitedAccountPanel.2step.link": "Mais informações sobre a autorização de dois passos",
  "LimitedAccountPanel.2step.href": "https://support.gaijin.net/hc/en-us/articles/203623622-How-to-set-up-the-Two-Step-Authorization-Google-Authenticator-",
  "LimitedAccountPanel.email.reason": "Deverá confirmar seu endereço de email",
  "LimitedAccountPanel.email.link": "Proceder para o perfil",
  "LimitedAccountPanel.2step.toPlug": "Ativar",
  "LimitedAccountPanel.email.href": "https://store.gaijin.net/user.php",
  "LimitedAccountPanel.title": "A sua conta está limitada devido a:",
  "LimitedAccountPanel.conclusion": "Depois de alterar as configurações de segurança, deverá terminar sessão e voltar a iniciar para que as alterações entrem em vigor",
  "LimitedAccountPanel.relogin": "Voltar a iniciar sessão...",
  "EmailVerificationStateIcon.success": "Conta confirmada, email verificado",
  "EmailVerificationStateIcon.warning": "Conta não confirmada, email não verificado",
  "EmailVerificationStateIcon.danger": "Conta não confirmada, a verificação de email é NECESSÁRIA",
  "TwoFactorStateIcon.success": "Conta protegida por autorização de dois passos",
  "TwoFactorStateIcon.warning": "Conta não protegida, autorização de dois fatores desativada",
  "TwoFactorStateIcon.danger": "Autorização de dois fatores NECESSÁRIA",
  "ViewInventoryTextFilter.common.label": "Para filtrar o inventário, é necessário escolher um jogo",
  "ItemBuyEditor.label.wheelAmountInput": "Agora pode rodar a roda do mouse para alterar quantidade,\nalt+wheel = 10x",
  "ItemBuyEditor.label.wheelInput": "Agora pode usar a roda do mouse para alterar preço,\nalt+wheel = 10x,\nshift+wheel=0.1x",
  "ItemBuyEditor.note.posAmount": "O valor deve ser positivo",
  "ItemBuyEditor.note.maxAmount": "O valor quantidade está acima do limite máximo de 1000 itens",
  "ItemBuyEditor.note.invalidAmount": "Valor inválido",
  "ItemBuyEditor.note.minPrice": "O preço mínimo é",
  "ItemBuyEditor.note.maxTotal": "O total de ordens está acima do máximo de",
  "ItemBuyEditor.note.invalidPrice": "Valor preço inválido",
  "ItemBuyEditor.note.mustAgree": "É necessário concordar com os Termos de Uso",
  "ItemBuyEditor.note.insufficentFunds0": "Fundos insuficientes, são necessários mais",
  "ItemBuyEditor.note.insufficentFunds1": "para esta ordem",
  "ItemBuyEditor.label.buyCouns": "Comprar",
  "ItemBuyEditor.label.submit": "Colocar ordem",
  "ItemBuyEditor.label.price": "Preço por item:",
  "ItemBuyEditor.label.amount": "Quantidade:",
  "ItemBuyEditor.label.totalPrice": "Preço final (taxa incluidas):",
  "ItemBuyEditor.label.agree": "Concordo com a Gaijin Store",
  "ItemBuyEditor.label.ToU": "Temos de Uso",
  "MarketDepthTotals.noOrders.sell": "Sem ordens de venda",
  "MarketDepthTotals.noOrders.buy": "Sem ordens de compra",
  "MarketDepthTotals.starting.sell": " por ",
  "MarketDepthTotals.starting.buy": " a partir de ",
  "MarketDepthTotals.andMore.sell": " e mais baixo",
  "MarketDepthTotals.andMore.buy": " e mais alto",
  "MarketDepthTotals.prevew.sell": "Comprando: ",
  "MarketDepthTotals.prevew.buy": "À venda: ",
  "MarketDepthOwnWarning.sell": "Ordens de venda pendentes",
  "MarketDepthOwnWarning.buy": "Ordens de compra pendentes",
  "MarketDepthTable.caption.price": "Preço",
  "MarketDepthTable.caption.quantity": "Quantidade",
  "MarketDepthTable.ellipsis.sell": "ou mais",
  "MarketDepthTable.ellipsis.buy": "ou menos",
  "ItemMarketDetails.buyButton": "COMPRAR",
  "ItemMarketDetails.sellButton": "VENDER",
  "ItemMarketDetails.nothingToSellButton": "Nada para vender",
  "ItemMarketDetails.makeAuction": "Fazer leilão",
  "ItemMarketDetails.makeBid": "Fazer uma oferta",
  "ItemMarketDetails.bid": "Lance",
  "ItemMarketDetails.yourBid": "Seu lance:",
  "ItemMarketDetails.notifications.yourBid": "Seu lance",
  "ItemMarketDetails.yourBid.short": "seu",
  "ItemMarketDetails.yourBid.win": "ganha",
  "ItemMarketDetails.yourBid.loose": "perde",
  "ItemSellEditor.label.priceStep": "Incremento de preço",
  "ItemSellEditor.label.endDate": "Data de término",
  "limitedAccountMessage.requires": "Requerimento:",
  "limitedAccountMessage.2step": "proteção em dois passos",
  "limitedAccountMessage.verifiedEmail": "email verificado",
  "limitedAccountMessage.and": " e ",
  "ItemSellEditor.label.wheelInput": "A roda do mouse pode ser usada para variar o preço,\nalt+wheel = 10x,\nshift+wheel=0.1x",
  "ItemSellEditor.note.minPrice": "Preço mínimo de",
  "ItemSellEditor.note.maxPrice": "Preço máximo de venda permitido",
  "ItemSellEditor.note.maxTotals": "Total de compras superior ao máximo de",
  "ItemSellEditor.note.maxBalance": "Total de fundos não deverá exceder",
  "ItemSellEditor.note.mustAgree": "Deverá concordar com os Termos de Uso",
  "ItemSellEditor.label.price": "O comprador paga (taxa incluída):",
  "ItemSellEditor.label.wished": "Após taxa aplicada receberá:",
  "ItemSellEditor.label.agree": "Eu concordo com a Gaijin Store",
  "ItemSellEditor.label.ToU": "Termos de Uso",
  "ItemSellEditor.label.submit": "OK, colocar para venda",
  "ItemSellEditor.currentBalance.before": "No momento tem",
  "ItemSellEditor.currentBalance.after": "em saldo",
  "ItemSellEditor.sellOrdersTotal.before": "mais",
  "ItemSellEditor.sellOrdersTotal.after": "acumulado em ordens de venda",
  "ItemSellEditor.buyOrdersTotal.before": "e",
  "ItemSellEditor.buyOrdersTotal.after": "acumulado em ordens de compra",
  "ItemSellEditor.label.amount": "Quantos quer vender:",
  "AppFilterEditor.label.byTags": "Filter",
  "AppFilterEditor.label.clearFilter": "Limpar filtros",
  "AppFilterEditor.label.byTitles": "Filtrar por títulos",
  "AppFilterEditor.label.allFilter": "Todos os jogos",
  "AppFilterEditor.label.authenticity": "Autenticidade",
  "AppFilterEditor.message.filter": "Os filtros são únicos para cada jogo.",
  "AppFilterEditor.message.game": "Escolher jogo:",
  "ViewItemDetailed.restriction.amount.items": " itens",
  "ViewItemDetailed.restriction.nonMarketable": "Não pode ser vendido",
  "ViewItemDetailed.restriction.blockedAfter": "Bloqueado após",
  "ViewItemDetailed.restriction.blockedPair": "Câmbio bloqueado",
  "ViewItemDetailed.restriction.expiresAt1": "Desaparecerá após",
  "ViewItemDetailed.restriction.expiresAt2": "",
  "ViewItemDetailed.restriction.endedAt": "End of auction",
  "ViewItemDetailed.restriction.amount": "Lot contains",
  "ViewItemDetailed.restriction.pieces": "pcs.",
  "ViewItemDetailed.restriction.startPrice": "Starting price",
  "ViewItemDetailed.restriction.days": "d",
  "ViewItemDetailed.restriction.hours": "hrs",
  "ViewItemDetailed.restriction.minutes": "min",
  "ViewItemDetailed.restriction.leadingBids": "leading bids",
  "ViewItemDetailed.restriction.noBids": "There are no bids yet. Place your first bid in this auction!",
  "ViewItemDetailed.restriction.delayTrade": "Once purchased, the item will be available for sale through",
  "ViewItemDetailed.restriction.unclearStatus": "Devido ao estatuto legal não claro dos troféus em seu país, todas as transações de troféus estão bloqueadas em sua conta.",
  "MarketSortOrder.label.name": "Nome",
  "MarketSortOrder.hint.newDesc": "Mais recentes primeiro",
  "MarketSortOrder.hint.newAsc": "Mais antigos primeiro",
  "MarketSortOrder.label.price": "Preço",
  "MarketSortOrder.hint.priceAsc": "Preço mais baixo",
  "MarketSortOrder.hint.priceDesc": "Preço mais alto",
  "MarketSortOrder.label.amount": "Quantidade",
  "MarketSortOrder.hint.amountAsc": "Menor quantidade",
  "MarketSortOrder.hint.amountDesc": "Maior quantidade",
  "MarketSortOrder.label.popular": "Popularidade",
  "MarketSortOrder.label.popularItems": "Itens populares",
  "DoubleSellErrorModal.heading": "Erro",
  "DoubleSellErrorModal.prompt": "Falha na venda do item",
  "DoubleSellErrorModal.explanation": "Falha na colocação da ordem de venda. Cauas possíveis - item vendido ou consumido no jogo\nAtualize a página",
  "DoubleSellErrorModal.okButton": "OK",
  "OnBuyReserveFailModal.heading": "Erro",
  "OnBuyReserveFailModal.prompt": "Falha na colocação de ordem de compra",
  "OnBuyReserveFailModal.explanation": "Falha na colocação de uma ordem de compra. Impossível reservar dinheiro da carteira de fundos.",
  "OnBuyReserveFailModal.youNeed1": "É necessário pelo menos",
  "OnBuyReserveFailModal.youNeed2": "para esta operação",
  "OnBuyReserveFailModal.tryRefresh": "Tente recarregar a página e assegure que tem fundos suficientes",
  "OnBuyReserveFailModal.okButton": "OK",
  "DoubleRateErrorModal.heading": "Error",
  "DoubleRateErrorModal.prompt": "An error occurred while placing a bid",
  "DoubleRateErrorModal.explanation": "Please try to bid later",
  "DoubleRateErrorModal.okButton": "OK",
  "AuctionFinishedErrorModal.heading": "Error",
  "AuctionFinishedErrorModal.prompt": "An error occurred while placing a bid",
  "AuctionFinishedErrorModal.explanation": "The auction was completed",
  "AuctionFinishedErrorModal.okButton": "OK",
  "CancelOrderErrorModal.heading": "Erro",
  "CancelOrderErrorModal.prompt": "Falha ao cancelar a ordem",
  "CancelOrderErrorModal.explanation": "Razões possíveis são problemas de ligação ou falha no momento da atualização das ordens.\nPor favor tente novamente.",
  "CancelOrderErrorModal.cancelButton": "Ok",
  "OrdersListContainer.Tab.sellOrders": "Ordens de venda",
  "OrdersListContainer.Tab.buyOrders": "Ordens de compra",
  "OrdersListContainer.Tab.historyList": "Histórico",
  "ViewOrdersEntry.amounts.remain": "Permanecer",
  "ViewOrdersEntry.amounts.done": "Terminado",
  "ViewOrdersEntry.order.order": "Ordem",
  "ViewOrdersEntry.order.issued": "Emitido",
  "ViewOrdersEntry.order.executed": "Executado",
  "ViewOrdersEntry.order.pairIsBlocked": "Câmbio bloqueado",
  "ViewOrdersEntry.order.pairWillBlockAfter": "Bloqueado após",
  "ViewOrdersEntry.order.BUY": "COMPRAR",
  "ViewOrdersEntry.order.SELL": "VENDER",
  "ViewOrdersEntry.order.view": "Ver",
  "ViewOrdersEntry.order.cancel": "Cancelar",
  "ViewOrdersList.empty.label": "Nada aqui",
  "CancelOrderDialog.heading.youSure": "Tem a certeza?",
  "CancelOrderDialog.body.youSure": "Tem a certeza que pretende cancelar a ordem?",
  "CancelOrderDialog.button.cancel": "Sim, EU quero cancelar a ordem",
  "CancelAllOrderDialog.button.cancel": "Sim para tudo",
  "CancelOrderDialog.button.noCancel": "Não",
  "CancelOrderDialog.heading.processing": "Processando...",
  "CancelOrderDialog.body.processing": "Processando, por favor aguarde",
  "ProfileMenu.MenuItem.Settings": "Configurações",
  "ProfileMenu.MenuItem.GaijinAccount": "Perfil Gaijin.net",
  "ProfileMenu.MenuItem.AccountSwitch": "Alterar conta",
  "ProfileMenu.MenuItem.Signout": "Sair",
  "ProfileMenu.MenuItem.nick": "Nome de jogo",
  "WalletBlock.balance.text": "Saldo:",
  "WalletBlock.balance.buyCurrency": "Comprar GJN",
  "SecurityBlock.message.title": "Autorização de dois fatores",
  "SecurityBlock.emailVerified.title": "Email",
  "OrdersHistory.title.buy": "Ordens de compra",
  "OrdersHistory.title.sell": "Ordens de venda",
  "NotificationsAssets.title": "Notificações",
  "NotificationsAssets.title.today": "Hoje",
  "NotificationsAssets.title.historyFromLastWeek": "Nos últimos sete dias",
  "NotificationsAssets.title.historyOlder": "Anterior",
  "LimitedAccountPanel.2step.pleas": "Proteja sua conta para ter acesso ao comércio.",
  "LimitedAccountPanel.2step.protected": "Conta protegida, acesso ao comércio permitido.",
  "LimitedAccountPanel.email.pleas": "Confime para ter acesso ao comércio.",
  "LimitedAccountPanel.email.protected": "Endereço de email confirmado, acesso ao comércio permitido.",
  "OrdersListContainer.Opened.Orders": "Aberto",
  "OrdersListContainer.Closed.Orders": "Encerrado",
  "ViewOrdersList.empty.labelCollapse": "Fechar",
  "ViewOrdersList.empty.labelExpand": "Expandir",
  "OrdersGraph.plot.title": "Ordens de Compra/Venda",
  "OrdersGraph.fmt.offers.sell": "ofertas de venda\n<br/>preço",
  "OrdersGraph.fmt.andMore.sell": "ou menos",
  "OrdersGraph.fmt.offers.buy": "ofertas de compra\n<br/>preço",
  "Orders.title.header": "ordens",
  "OrdersGraph.fmt.andMore.buy": "ou mais",
  "PriceHistoryGraph.plot.title": "Histórico de preços:",
  "PriceHistoryGraph.highlighter.format": "Em <strong>%s</strong><br /> preço <strong>%s</strong><br /> quantia <strong>%s</strong><br />",
  "PriceHistoryGraph.plot.shortRange": "Curto prazo",
  "PriceHistoryGraph.plot.wholeRange": "Longo prazo",
  "DealEventTickerEntry.purchasedFrom": "Item adquirido de",
  "DealEventTickerEntry.for": "por",
  "BookSellTickerEntry.listedItem": "sale request",
  "BookBuyTickerEntry.created": "criado",
  "BookBuyTickerEntry.for": "purchase order",
  "BookAuctionTickerEntry.created": "made a bet",
  "ViewTradeTickerLog.waiting": "Aguardando nova atividade...",
  "ViewTradeTickerLog.ticker": "Real time trading...",
  "ViewUserInventory.common.emptyInventory": "Inventário vazio no momento",
  "ViewItemPreviewSidePane.UnselectButton.label": "Filtros",
  "ViewItemPreviewSidePane.TradeButton.label": "Vender/Comprar",
  "ViewItemPreviewSidePane.ViewButton.label": "Ver",
  "ViewItemPreviewSidePane.QuickSellButton.label": "Venda rápida",
  "ViewInventoryTagCloud.tagTitle.itemsOf": "Itens de",
  "ViewInventoryTagCloud.tagTitle.varietiesMarkedAs": "variedades marcadas como",
  "ViewItemSearch.heading.inventory": "Os meus itens",
  "WalletBlock.circleSmall.settings": "Configurações",
  "WalletBlock.circleSmall.exit": "Sair",
  "UserSettingsDialog.language": "Idioma",
  "UserSettingsDialog.user_settings": "Configurações de usuário",
  "UserSettingsDialog.settings": "Configurações",
  "UserSettingsDialog.save_settings": "Salvar configurações",
  "UserSettingsDialog.privacySettings": "Comércio",
  "UserSettingsDialog.privacySettingsPublic": "Público",
  "UserSettingsDialog.privacySettingsPrivate": "Privado",
  "UserSettingsDialog.interface": "Interface",
  "UserSettingsDialog.interfaceOld": "Legacy",
  "UserSettingsDialog.interfaceNew": "Modern",
  "WalletLimitsIcon.soft_limit": "Limite de GJN excedido.\nFunções de adição de fundos limitadas.",
  "WalletLimitsIcon.hard_limit": "Limite de GJN excedido.\nFunções de venda e adição de fundos limitadas.",
  "ActionBar.action.ingamePreview": "Ver no jogo",
  "ActionBar.action.previewCopied": "Link copiado!",
  "ActionBar.action.previewShare": "Partilhar",
  "IngamePreview.ask.heading": "Ver no jogo?",
  "IngamePreview.ask.prompt": "Você está prestes a ver o item no jogo",
  "IngamePreview.ask.explanation": "Certifique-se que o jogo está correndo com sua conta",
  "IngamePreview.ask.cancelButton": "Não",
  "IngamePreview.done.heading": "Terminado",
  "IngamePreview.done.prompt": "",
  "IngamePreview.done.explanation": "Enviado com sucesso",
  "IngamePreview.done.okButton": "Ok",
  "IngamePreview.fail.heading": "Falha",
  "IngamePreview.fail.prompt": "",
  "IngamePreview.fail.explanation": "Não foi possível entrar em contacto com o cliente de jogo",
  "IngamePreview.fail.cancelButton": "Ok",
  "IngamePreview.pending.heading": "Um instante...",
  "IngamePreview.pending.prompt": "Notificando o cliente de jogo",
  "IngamePreview.pending.explanation": "",
  "IngamePreview.ask.okButton": "Com certeza, mostrar",
  "StaticConfigLoader.caption.error": "Erro de inicialização",
  "StaticConfigLoader.caption.init": "Inicialização",
  "StaticConfigLoader.text.init": "Aplicação de inicialização...",
  "StaticConfigLoader.error.settings": "Não foi possível carregar as configurações da página",
  "StaticConfigLoader.error.circuits": "Não foi possível carregar a configuração de serviços",
  "ControlButton.close": "Fechar",
  "Exit": " ",
  "Legal.TermsConditions": "Termos e condições",
  "Legal.TermsService": "Termos de Serviço",
  "Legal.PrivacyPolicy": "Política de Privacidade",
  "Legal.CookiesPolicy": "Política de Cookies",
  "Legal.TradePolicy": "Política Comercial",
  "Legal.TermsConditions.linck": "https://legal.gaijin.net/pt/",
  "Legal.TermsService.linck": "https://legal.gaijin.net/pt/termsofservice/",
  "Legal.PrivacyPolicy.linck": "https://legal.gaijin.net/pt/privacypolicy/",
  "Legal.CookiesPolicy.linck": "https://legal.gaijin.net/pt/cookie/",
  "Legal.TradePolicy.linck": "https://legal.gaijin.net/pt/tradepolicy/",
  "Legal.Copyright": "Gaijin Network Ltd. Todas as marcas registadas, logotipos e nomes de marcas são propriedade dos respetivos donos.",
  "MarketApp.Navbar.HeadTitle": "Gaijin.Net",
  "MarketApp.Navbar.HeadSubTitle": "Market",
};