import AppConfigsMenu from 'components/appConfigsMenu';
import ApplicationEvents from 'components/applicationEvents';
import { BootButton } from 'generics/buttons';
import { LogStream, utils_api } from 'generics/common';
import { IconGlyphicon } from 'generics/icons';
import React from 'react';

LogStream.enable( "EVENTLOG" );

class EventLogEntry  extends React.Component  {
  constructor(props)  {
    super(props);

    this.onExpand = () => this.setState({ "expand": !this.state.expand });
    this.state = { "expand": false };
  }


  renderDetails()  {
    const details = (this.props.details||[]).filter(Boolean);
    if (details.length == 0)
      return null;

    const detailsPadClasses = ("event-log-entry-details" + (this.state.expand? "expand":""));
    return (
        <div className={detailsPadClasses}>
        {details.map(
            ([key, val])  =>  (
                <div  className="event-log-detail" key={key}>
                  <div className="event-log-label">{key}</div>
                  <div className="event-log-value">{val}</div>
                </div>)
          )}
        </div>
      );
  }


  render()  {
    let status = "info";
    switch ( this.props.type )  {
      case "error":     status = "danger"; break;
      case "warning":   status = "warning"; break;
      case "log":       status = "success"; break;
      default:          status = "info";
    }


    return (<div className={"alert alert-"+status+" event-log-entry"} role="alert">
          <div className="event-log-entry-caption">
              {this.props.caption}
            <span className="event-log-entry-stamp">{(new Date(this.props.stamp)).toISOString()}</span>
            {(this.props.stamp > this.props.ackStamp)&&(
              <IconGlyphicon type="fire" />
            )}
          </div>
        <div className="event-log-entry-message">
          <strong>&lt;</strong>
          {this.props.message}
          <strong>&gt;</strong>
        </div>

        {this.renderDetails()}

        <div className="event-log-entry-expand-box" onClick={this.onExpand}>
          ... expand/collapse ...
        </div>
      </div>);
  }
}


export class EventLogController  extends React.Component  {
  constructor(props)  {
    super(props);

    this.appendEvent = ( ...rest ) => this.appendEvent__( ...rest );
    this.onToggleOff = () => this.setState({  "expand": false  });
    this.onToggleOn = () => this.setState({  "expand": true  });
    this.onToggleOffOnEsc = ( e ) =>  { if ( (e.key == "Esc") || (e.key == "Escape") ) this.onToggleOff(); }
    this.showAuthDialog = () =>  ApplicationEvents.emit_async("auth.showDialog");

    this.state = {
      "log": [],

      "showWarnings": false,
      "showLogs": true,
      "showInfo": false,

      "reverse":true,

      "needReload": false,
      "ackStamp":0
    };
  }


  componentDidMount()  {
    document.addEventListener("keydown", this.onToggleOffOnEsc );
    ApplicationEvents.on("EventLog.append", this.appendEvent );
  }


  componentWillUnmount()  {
    ApplicationEvents.off("EventLog.append", this.appendEvent );
    document.removeEventListener("keydown", this.onToggleOffOnEsc );
  }


  filterToken( value )  {
    const token = this.props.token||"";
    if ( token )  {
      const replacement = token.replace( /(.{5}).*(.{5})/, "$1*****$2")
      return (value||"").split( token ).join( replacement );
    }
    return (value||"");
  }


  appendEvent__({ type, message, details, caption, needReload })  {
    const stamp = Date.now();

    type = ""+type;
    message = this.filterToken(""+message);
    caption = this.filterToken(""+caption);
    needReload = !!needReload;

    details = Object.entries(details || {}).map(
            ([ key, val ]) => [key, this.filterToken(JSON.stringify(val, null, "  "))]
          );

    const eventObj = { type, message, details, caption, stamp, needReload };

    LogStream( "EVENTLOG" ).info("appendEvent", eventObj, this.state.log.length );
    this.setState( (prevState, props) =>  ({
          "log": [ ...prevState.log, eventObj ],
          "needReload": ( prevState.needReload || needReload )
        }) );
  }


  renderAckButton( errorCount, maxStamp )  {
    const canAck = ( (errorCount > 0) ||
                     (this.state.ackStamp < maxStamp) ||
                      this.state.needReload );

    if ( this.state.needReload )
      return (
          <BootButton size="xsmall"  active  disabled={!canAck}
                      onClick={() =>  utils_api.sheduleReload()}
              > Reload page ({errorCount}) </BootButton>
        );

    return (
        <BootButton size="xsmall"
                    variant="warning"
                    disabled={!canAck}
                    onClick={() =>  this.setState({  "ackStamp":maxStamp  })}
            > Set Acknowledged ({errorCount}) </BootButton>
      );


  }


  renderButtons(maxStamp,errorCount)  {
    return (
        <div className = "eventBtn">
          <BootButton size="xsmall"  active={this.state.showWarnings}
                      onClick={ () =>  this.setState({ "showWarnings": !this.state.showWarnings }) }
            > Warnings </BootButton>

          <BootButton size="xsmall"  active={this.state.showLogs}
                      onClick={ () =>  this.setState({ "showLogs": !this.state.showLogs }) }
            > Logs </BootButton>

          <BootButton size="xsmall"  active={this.state.showInfo}
                      onClick={ () =>  this.setState({ "showInfo": !this.state.showInfo }) }
            > Info </BootButton>

          { this.renderAckButton( errorCount, maxStamp ) }
        </div>
      );
  }


  renderEntryList()  {
    let errorCount = 0;
    let maxStamp = 0;
    const entryList = this.state.log.map( ( item, key ) =>  {
      switch( item.type )  {
        case "warning":
          if ( !this.state.showWarnings )
            return null;
          break;
        case "log":
          if ( !this.state.showLogs )
            return null;
          break;
        case "info":
          if ( !this.state.showInfo )
            return null;
          break;
        case "error":
          if ( item.stamp > this.state.ackStamp )
            errorCount++;
          break;
      }

      if ( maxStamp < item.stamp )
        maxStamp = item.stamp;

      return (<EventLogEntry
                    key={key}
                    type={item.type}
                    message={item.message}
                    details={item.details}
                    caption={item.caption}
                    stamp={item.stamp}
                    ackStamp={this.state.ackStamp}
                  />);

    });

    if (this.state.reverse)
      entryList.reverse();

    return {errorCount, maxStamp, entryList}
  }


  renderTouch(errorCount, onToggle)  {
    const [ evBtnVariant, evIconType,     evText ]  =  (errorCount > 0) ?
          [ "danger",     "warning-sign", errorCount ] :
          [ "default",    "tasks",        "Events"];

    return (
        <div  className="view-event-log-touch" >
          <AppConfigsMenu dropup />

          <BootButton variant={evBtnVariant} mixClasses="crb-full" onClick={onToggle}>
            <IconGlyphicon type={evIconType} />&nbsp;{evText}
          </BootButton>
{/*          { this.props.devMode ?
              (<BootButton mixClasses="crb-full" link onClick={this.showAuthDialog}>
                <IconGlyphicon type="sunglasses" />&nbsp;DEVMODE Auth
              </BootButton>)  :  null
          }*/}
        </div>
      );
  }


  renderBackdrop( isOpen, onToggle )  {
    if( !isOpen )      return null;
    return (<div className="view-event-log-backdrop" onClick={onToggle} ></div>);
  }


  render()  {
    const {errorCount, maxStamp, entryList} = this.renderEntryList();

    const isOpen = this.state.expand || (errorCount > 0);
    const rootClassName = ("view-event-log-root" + (isOpen?" expand":""));
    const onToggle =  isOpen  ?  this.onToggleOff  :  this.onToggleOn;

    return (
      <div>
        <div  className={rootClassName}>
        {this.renderBackdrop( isOpen, onToggle )}
          {this.renderTouch(errorCount, onToggle)}
          {this.renderButtons(maxStamp,errorCount)}
          <div  className="view-event-log-list">
            {entryList}
          </div>
        </div>
      </div>
    );
  }
}


