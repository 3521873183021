import G_marketConfigStorage from 'components/globalMarketConfigStorage';
import MoneyUtil from 'components/moneyUtil';
import { _I_ } from 'components/translate';
import { IconGlyphicon } from 'generics/icons';
import { SettingsInjections } from 'generics/settingsInjections';
import { classMixer } from 'generics/utils';
import React from 'react';
import { ViewAmountInput, ViewCheckbox } from "./commonAssets";

export default class ViewItemBuyEditor extends React.Component {
  constructor(props) {
    super(props);

    const { auction } = this.props;

    const initialPrice = MoneyUtil.parseMoney(this.props.initialPrice.toFixed(2));
    const buyPrice = (isFinite(initialPrice) && (initialPrice > 0)) ? initialPrice : 10000;
    const priceText = MoneyUtil.formatMoney(buyPrice);

    const userCurrStr = MoneyUtil.formatCurr(SettingsInjections.getUsedCurrency());

    let buyPriceMin = G_marketConfigStorage.getPriceMin();
    let buyPriceMax = G_marketConfigStorage.getPriceMax();

    if (auction?.end_time && this.props.initialPrice)
      buyPriceMin = initialPrice;

    this.currentLocale = SettingsInjections.getUsedLocale();

    this.state = {
      buyPrice,
      priceText,

      buyAmount: 1,
      amountText: "1",

      buyPriceMin,
      buyPriceMax,
      agree: false,
      userCurrStr
    }
  }


  handleAgreeClick = (event) => {
    const agree = this.state.agree ? false : Date.now();
    this.setState({ agree });
  }


  handleAmountBlur = (e) => {
    const amountText = `${this.state.buyAmount}`
    if (amountText == this.state.amountText) {
      return;
    }

    this.setState({ amountText });
  }


  handleAmountWheel = (e) => {
    e.preventDefault();

    const delta = Math.sign(e.deltaY) * (e.altKey ? 10 : 1);
    const newValue = this.state.buyAmount - delta;

    this.processAmountChange((newValue > 0) ? newValue : 0);
  }


  handleAmountChange = (event) => {
    this.processAmountChange(event.target.value);
  }

  processAmountChange(val) {
    const [, amountText] = (/^\s*([0-9]+)\s*$/).exec(val) || [];
    if (!amountText) {
      return;
    }

    if (amountText == this.state.amountText) {
      return;
    }

    const buyAmount = +amountText;
    if (buyAmount > 0) {
      this.setState({ buyAmount, amountText });
    }
  }


  handleBlurPrice = (e) => {
    const priceText = MoneyUtil.formatMoney(this.state.buyPrice);

    if (priceText != this.state.priceText) {
      this.setState({ priceText });
    }
  }


  handlePriceWheel = (e) => {
    e.preventDefault();

    const delta = 10000 * Math.sign(e.deltaY) * (e.altKey ? 10 : (e.shiftKey ? 0.1 : 1));
    const newValue = MoneyUtil.formatMoney(this.state.buyPrice - delta);

    if (+newValue >= 0) {
      this.processPriceChange(newValue);
    }
  }


  handlePriceChange = (event) => {
    this.processPriceChange(event.target.value);
  }


  processPriceChange(val) {
    const [, hi, dot, lo] = ((/^\s*(\d*)(?:(\.)(\d*))?\s*$/).exec(val) || []);
    if ((!hi) && (!lo))
      return;

    const priceText = `${hi || ""}${(dot && ".") || ""}${lo || ""}`;
    if (this.state.priceText === priceText)
      return;

    const buyPrice = MoneyUtil.parseMoney(val);
    if (isFinite(buyPrice) && (buyPrice >= 0)) {
      this.setState({ buyPrice, priceText });
    }
  }


  getResult() {
    const { buyAmount, buyPrice, buyPriceMin, buyPriceMax, userCurrStr, agree } = this.state;

    const notes = [];
    let isValid = true;
    let amount = Math.floor(buyAmount);
    if (isFinite(amount)) {
      if (amount < 1) {
        isValid = false;
        amount = NaN;
        notes.push(_I_("ItemBuyEditor.note.posAmount"));

      } else if (amount > 1000) {
        isValid = false;
        amount = NaN;
        notes.push(_I_("ItemBuyEditor.note.maxAmount"));
      }

    } else {
      isValid = false;
      amount = NaN;
      notes.push(_I_("ItemBuyEditor.note.invalidAmount"));
    }

    let price = Math.floor(buyPrice);
    if (isFinite(price)) {
      if (price < buyPriceMin) {
        isValid = false;
        price = NaN;
        notes.push(`${_I_("ItemBuyEditor.note.minPrice")} ${MoneyUtil.formatMoney(buyPriceMin)} ${userCurrStr}`);
      } else {
        const total = (amount * price);
        if (total > buyPriceMax) {
          isValid = false;
          price = NaN;
          notes.push(`${_I_("ItemBuyEditor.note.maxTotal")} ${MoneyUtil.formatMoney(buyPriceMax)} ${userCurrStr}`);
        }
      }

    } else {
      isValid = false;
      price = NaN;
      notes.push(_I_("ItemBuyEditor.note.invalidPrice"));

    }


    return { isValid, amount, price, agree, notes };
  }


  renderBuyButton(isValid, requiredFunds, total) {
    const { userCurrStr } = this.state;
    const { auctionable } = this.props;
    const disabled = !(isValid && this.state.agree);
    const storeHost = SettingsInjections.getStoreHost();

    if (requiredFunds) {
      const handler = () => document.location.assign(`${storeHost}/balance.php`);
      const active = classMixer("button buy baloonOverride baloon baloonTop baloonCentered", !isValid && "disabled");

      return (
        <div className={active} onClick={handler}>
          {_I_("ItemBuyEditor.label.buyCouns")} {requiredFunds} {userCurrStr}
        </div>
      );
    }

    return (
      <div className={classMixer("button buy baloonOverride baloon baloonTop baloonCentered", disabled && "disabled")}
        onClick={this.props.processBuyOrder}>
        {_I_(auctionable ? "ItemMarketDetails.bid" : "ItemBuyEditor.label.submit")} {isValid && (
          <React.Fragment>
            {!auctionable && _I_("MarketDepthTotals.starting.sell")} {MoneyUtil.formatMoney(total)} {userCurrStr}
          </React.Fragment>
        )}
      </div>
    );
  }


  render() {
    const { isValid, amount, price, notes, agree } = this.getResult();
    const total = amount * price;
    const requiredFunds = Math.ceil(Math.max(total - (this.props.balance.gjn || 0), 0) / 10000);
    const { priceText, amountText, buyAmount } = this.state;
    const { auctionable } = this.props;
    const legalHost = SettingsInjections.getLegalHost();

    if (isValid) {
      if (requiredFunds)
        notes.push(`${_I_("ItemBuyEditor.note.insufficentFunds0")} ${this.state.userCurrStr} ${_I_("ItemBuyEditor.note.insufficentFunds1")}`);

      if (!agree)
        notes.push(_I_("ItemBuyEditor.note.mustAgree"));
    }

    const danger = isValid ? null : (
      <div className="notes-row">
        {notes.map(
          (text, key) => <div key={key}><IconGlyphicon type="exclamation-sign" />&nbsp;{text}</div>
        )}
      </div>
    );

    let termsUrl = `${legalHost}/${this.currentLocale.icon}/termsofservice`;

    return (
      <React.Fragment>
        <div className="editor-block">
          <div className="editor-row">
            <span className="label-column">
              {_I_( auctionable ? "ItemMarketDetails.yourBid" : "ItemBuyEditor.label.price")}
            </span>
            <span className="input-column baloonOverride baloon baloonTop baloonCentered"
              onWheel={this.handlePriceWheel}
              data-baloon={_I_("ItemBuyEditor.label.wheelInput")}  >
              <input type="text" className="form-control input-sm"
                value={priceText}
                onBlur={this.handleBlurPrice}
                onChange={this.handlePriceChange} />
            </span>
          </div>

          {!auctionable &&
            <React.Fragment>
              <div className="editor-row">
                <span className="label-column">{_I_("ItemBuyEditor.label.amount")}</span>
                <span className="input-column baloonOverride baloon baloonTop baloonCentered"
                  onWheel={this.handleAmountWheel}
                  data-baloon={_I_("ItemBuyEditor.label.wheelAmountInput")}
                >
                  <ViewAmountInput
                    amount={amountText}
                    buyAmount={buyAmount}
                    onBlur={(e) => this.handleAmountBlur(e)}
                    onChange={(e) => this.handleAmountChange(e)}
                    canBeLess={buyAmount > 1}
                    canBeMore={true}
                    onAmountChange={(v) => this.processAmountChange(v)}
                  />
                </span>
              </div>


              <div className="editor-row">
                <span className="label-column">{_I_("ItemBuyEditor.label.totalPrice")}</span>
                <span className="input-column text-center">
                  <input type="text" className="form-control input-sm" disabled
                    value={MoneyUtil.formatMoney(total)} />
                </span>
              </div>
            </React.Fragment>
          }
        </div>

        {danger}

        <div className="editor-row">
          <div className="agree-checkbox" onClick={this.handleAgreeClick}>
            <ViewCheckbox checked={!!agree}>
              {_I_("ItemBuyEditor.label.agree")}&nbsp;
              <a href={termsUrl} className="agree-link" target="_blank" rel="noopener noreferrer">
                {_I_("ItemBuyEditor.label.ToU")}
              </a>
            </ViewCheckbox>
          </div>
        </div>

        <div className="editor-row">
          {this.renderBuyButton(isValid, requiredFunds, total)}
        </div>
      </React.Fragment>
    );
  }
}
