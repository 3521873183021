import { observer } from "mobx-react";
import React, {Fragment, useEffect, useState} from "react";
import Scrollbar from "react-scrollbars-custom";
import { classMixer } from "../../generics/utils";
import { HistoryStore } from "../../stores";
import ApplicationEvents from "../applicationEvents";
import ViewLegalFooter from "../legalFooter";
import { _I_ } from "../translate";
import { ViewHistoryItem } from "./notificationPopup";

const NotificationsAssets = observer(({ hidden, scrollbar }) => {
  const { history, todayHistory, historyFromLastWeek, historyOlder, unseenHistory } = HistoryStore;
  const [previousHistoryLength, setPreviousHistoryLength] = useState(0);

  useEffect(() => {
    HistoryStore.loadInitial();
  }, []);

  const handleScroll = (e) => {
    const historyLength = history.length;
    const maxScrollTop = 0.8;

    if (e.top > maxScrollTop && historyLength > previousHistoryLength) {
      setPreviousHistoryLength(historyLength);
      HistoryStore.loadMore();
    }
  };

  return (
    <Scrollbar
      ref={scrollbar}
      id="content"
      style={{ display: hidden ? "none" : "" }}
      defaultStyles={false}
      scrollX={false}
      onScroll={(e) => handleScroll(e)}>
      <div className="appScreen" screen="notifications">
        <div className="screenBody">
          <div className="notificationsContainer">
            <div className="headerContent">
              <div className="notificationsTitle">{_I_("NotificationsAssets.title")}</div>
              {!!unseenHistory.length && <ReadAllButton />}
            </div>

            <div className="notificationsList">
              {!!unseenHistory.length && <ViewNotificationsBlock title="" notifications={unseenHistory} />}

              {!unseenHistory.length && (
                <Fragment>
                  {!!todayHistory.length && <ViewNotificationsBlock title="today" notifications={todayHistory} />}
                  {!!historyFromLastWeek.length && <ViewNotificationsBlock title="historyFromLastWeek" notifications={historyFromLastWeek} />}
                  {!!historyOlder.length && <ViewNotificationsBlock title="historyOlder" notifications={historyOlder} />}
                </Fragment>
              )}
            </div>
          </div>
        </div>
        <ViewLegalFooter />
      </div>
    </Scrollbar>
  )
})

const ReadAllButton = () => {

  const handleClick = () => {
    HistoryStore.setSeenAllHistory();
  }

  return (
    <div className="buttonRead" onClick={() => handleClick()}>
      {_I_("ViewItemAssets.readAll.button")}
    </div>
  )
}

const ViewNotificationsBlock = observer(({ title, notifications }) => {
  const handleClick = (notification) => {
    const { appid, hashname } = notification;
    ApplicationEvents.emit_async("market.setCurrentAssetByHash", appid, hashname, true);
  };

  return (
    <div className="notificationsBlock">
      {!!title && <div className="title">{_I_(`NotificationsAssets.title.${title}`)}</div>}

      {!!notifications.length && notifications.map((notification, idx) => (
        <div
          key={idx}
          className={classMixer("message", !notification.seen && "unseen")}
          onClick={() => handleClick(notification)}
          onMouseOver={() => HistoryStore.setSeenHistory(notification.id)}
        >
          <div className="item">
            <ViewHistoryItem notification={notification} />
          </div>
        </div>
      ))}
    </div>
  )
})

export default NotificationsAssets;