import { CredentialsIcon } from 'components/credentialsIcon';
import { BootButton } from 'generics/buttons';
import { IconGlyphicon } from 'generics/icons';
import React from 'react';




export default class AuthInfoIcon extends React.Component {
  renderError()  {
    let msg = "Error";
    switch ( this.props.error )  {
      case "AUTH_RESPONSE_STATUS_IS_LOGINERROR": msg = "Session expired";          break;
      case "TOKEN_LENGTH_REJECTED":              msg = "Invalid session";          break;
      default:                                   msg = "Can't authenticate";       break;
    }

    return (
        <BootButton disabled title={this.props.error} mixClasses={this.props.mixClasses}>
          <IconGlyphicon type="ban-circle" />{msg}
        </BootButton>
      );
  }


  renderLoading() {
    return (
        <BootButton disabled title="Verifying authentication..." mixClasses={this.props.mixClasses}>
          <img src="images/loader.gif" /><i>...loading...</i>
        </BootButton>
      );
  }


  renderUser() {
    return (
        <BootButton  onClick={this.props.onClick} mixClasses={this.props.mixClasses}>
          <CredentialsIcon  creds={this.props.creds} />
        </BootButton>
      );
  }


  render() {
    if ( this.props.error )       return this.renderError();
    if ( this.props.creds )       return this.renderUser();
                                  return this.renderLoading();
  }

}

