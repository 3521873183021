import { GenericAPICall } from 'api/genericAPICall';
import { LogStream } from 'generics/common';
import { SettingsInjections } from 'generics/settingsInjections';

////////////////////////////////////////////
// class OrdersAPICall
class OrdersAPICall extends GenericAPICall {
  normalizeResponse(resp) {
    return resp.result || resp.response;
  }


  getInterfaceUrl() {
    return SettingsInjections.getUsedCircuit().trade_server;
  }
};


////////////////////////////////////////////
//  AssetAPI request  action=cln_get_user_open_orders&token=xsaffasfa&appid=666
// {  "txId",  "market",  "currency",  "price",  "amount",  "time",  "appId",  "pairId",  "id",  "type"  }
export function MarketOrders(appid) {
  let callBooks = new OrdersAPICall("cln_get_user_open_orders");
  callBooks.setRetryPolicy(3, 1000, 3000);

  return callBooks.POST().then((resp) => {
    LogStream("APICALLDEBUG").info(`MarketOrders() - got response:`, resp);
    return resp;
  });
}


// AssetAPI request action=cln_get_user_trade_history&token=xsaffasfa
// from=1498856400
// to=1501534800
export function MarketTradeHistory(appid, from, to) {
  let callTradeHistory = new OrdersAPICall("cln_get_user_trade_history");
  callTradeHistory.setRetryPolicy(3, 1000, 3000);

  if (from && to) {
    callTradeHistory.append("from", from);
    callTradeHistory.append("to", to);
  }

  return callTradeHistory.POST().then((resp) => {
    LogStream("APICALLDEBUG").info(`MarketTradeHistory() - got response:`, resp);
    return resp;
  });
}


//  AssetAPI request  action=cln_market_search&token=xsaffasfa
// tags="type:aircraft;country:gb;type:tank;country:gm"
// appid="666;777;123;5776"
// skip=500
// count=50
// text="hat horse"

//  "result"."assets".{ "appid", "hash_name", "name", "icon", "color" }
export function MarketSearch(words, apps, tagsMap, skip, count, sortOrder, options) {
  const language = SettingsInjections.getUsedLocale().code;

  let optionsVal = "";

  switch (options) {
    case 'buy':
      optionsVal = "any_buy_orders;include_marketpairs"
    break;
    case 'sell':
      optionsVal = "any_sell_orders;include_marketpairs"
    break;
    case 'auction':
      optionsVal = "include_auctions"
  }

//include_marketpairs
  let callSearch = new OrdersAPICall("cln_market_search");
  callSearch.append("skip", skip);
  callSearch.append("count", count);
  callSearch.append("text", words.join(" "));
  callSearch.append("language", language);
  callSearch.append("options", optionsVal);

  if (apps.length) {
    callSearch.append("appid_filter", apps.join(";"));
  }

  if (sortOrder) {
    if (options == "buy" && sortOrder.startsWith("price_")) {
      sortOrder = "buy_" + sortOrder
    }

    callSearch.append("sort", sortOrder);
  }

  const tags = Object.keys(tagsMap);
  if (tags.length) {
    if (apps.length != 1)
      return Promise.reject({
        "type": "fatal",
        "caption": "Assertion Failed",
        "message": "Filtering by tags available for exactly one game only",
        "details": {
          "response": { "success": false, "error": `Invalid games amount ${apps.length}` },
          "command": "cln_market_search",
          "params": []
        },
      });

    callSearch.append("tags", tags.join(";"));
  }

  return callSearch.POST().then((resp) => {
    LogStream("APICALLDEBUG").info(`MarketSearch(${JSON.stringify({ words, apps, tags, skip, count })}) - got response:`, resp);
    return resp.assets;
  });
}


//  AssetAPI request  action=cln_market_info&token=xsaffasfa&language
// response:{
//   marketFee:1
//   games:[ { appid,name,briefName,desc:[""],icon } ]
// }
export function MarketGlobalInfo() {
  const language = SettingsInjections.getUsedLocale().code;

  let callGlobalInfo = new OrdersAPICall("cln_market_info");
  callGlobalInfo.setRetryPolicy(5, 100, 500);
  callGlobalInfo.append("language", language);

  return callGlobalInfo.POST().then((resp) => {
    LogStream("APICALLDEBUG").info(`MarketGlobalInfo(${language}) - got response:`, resp);
    return resp;
  });
}


//  AssetAPI request  action=cln_get_app_tags&token=xsaffasfa&language
// language="en_US"
// appid=1067
export function MarketGetAppTags(appid) {
  const language = SettingsInjections.getUsedLocale().code;

  let callGetAppTags = new OrdersAPICall("cln_get_app_tags");
  callGetAppTags.setRetryPolicy(3, 500, 1500);
  callGetAppTags.append("language", language);
  callGetAppTags.append("appid", appid);
  callGetAppTags.append("version", 2);

  return callGetAppTags.POST().then((resp) => {
    LogStream("APICALLDEBUG").info(`MarketGetAppTags(${appid}) - got response:`, resp);
    return resp.tags;
  });
}

/* AssetAPI request action=cln_get_user_history&count=100&skip=0
{
    "success": true,
    "response": [{
        "id": "664e5f2c0954a10cba4007e6",
        "ts": 1716412204,
        "count": 1,
        "price": 5900,
        "currency": "gjn",
        "dealUid": "6218154265818251922",
        "type": "SELL",
        "orderId": "6792018138739647719",
        "event": "deal",
        "appid": 1067,
        "hashname": "__auction__63",
        "auction": true,
        "seen": false
    }]
}
 */
export function MarketGetHistory(count = 100, skip = 0) {
  let callGetHistory = new OrdersAPICall("cln_get_user_history");
  callGetHistory.setRetryPolicy(3, 500, 1500);

  callGetHistory.append("count", count);
  callGetHistory.append("skip", skip);

  return callGetHistory.POST().then((resp) => {
    LogStream("APICALLDEBUG").info(`MarketGetHistory - got response:`, resp);
    return resp.events;
  });
}

// AssetAPI request action=cln_set_seen_user_history&ids=1,2,3
export function MarketSetSeenHistory(ids) {
  let callSetSeenHistory = new OrdersAPICall("cln_set_seen_user_history");
  callSetSeenHistory.setRetryPolicy(3, 500, 1500);

  callSetSeenHistory.append("ids", ids.join(","));

  return callSetSeenHistory.POST().then((resp) => {
    LogStream("APICALLDEBUG").info(`MarketSetSeenHistory - got response:`, resp);
    return resp;
  });
}

// AssetAPI request action=cln_set_seen_all_user_history
export function MarketSetSeenAllHistory() {
  let callSetSeenAllHistory = new OrdersAPICall("cln_set_seen_all_user_history");
  callSetSeenAllHistory.setRetryPolicy(3, 500, 1500);

  return callSetSeenAllHistory.POST().then((resp) => {
    LogStream("APICALLDEBUG").info(`MarketSetSeenAllHistory - got response:`, resp);
    return resp;
  });
}