import * as Sentry from "@sentry/react";

///
///   common utils
///
export let utils_api = {


  'frozenMerge': function frozenMerge(...i) {
    return Object.freeze(Object.assign({}, ...i));
  },


  'getStored': function getStored(path, def) {
    let itemJSON = window.localStorage.getItem("" + path);
    if (!itemJSON) {
      if (def === undefined)
        def = null;
      itemJSON = JSON.stringify(def);
      window.localStorage.setItem("" + path, itemJSON);
    }

    return JSON.parse(itemJSON);
  },


  'setStored': function setStored(path, val) {
    if (val === undefined)
      val = null;
    let itemJSON = JSON.stringify(val);
    window.localStorage.setItem("" + path, itemJSON);
    return JSON.parse(itemJSON);
  },


  'sheduleReload': function sheduleReload() {
    setTimeout(() => {
      window.location.reload();
      window.location.search = "";
    }, 0);
  },


};    //    utils_api namespace



export function LogStream(stream) {
  if (!LogStream.streams[stream])
    LogStream.streams[stream] = new LogStreamHolder(stream);

  return LogStream.streams[stream];
}


LogStream.streams = {};  //  static


LogStream.enable = (...streams) => {
  streams.forEach((stream) => LogStream(stream).enabled = true);
}


LogStream.disable = (...streams) => {
  streams.forEach((stream) => LogStream(stream).enabled = false);
}

class SentryError extends Error {
  constructor(name, message) {
    super(message);
    this.name = name;
    this.stack = (new Error()).stack;
  }
}

export function sanitizeEvent(obj, depth = 0) {
  const jwtRegex = /eyJ[A-Za-z0-9-_]+\.(?:eyJ[A-Za-z0-9-_]+)?\.[A-Za-z0-9-_]{2,}(?:(?:\.[A-Za-z0-9-_]{2,}){2})?/;

  if (depth > 10 || typeof obj !== 'object' || obj === null) return;

  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      sanitizeEvent(obj[key], depth + 1);
    } else if (Array.isArray(obj[key])) {
      obj[key].forEach((item, index) => {
        if (typeof item === 'string') {
          obj[key][index] = item.replace(jwtRegex, '[token]');
        } else if (typeof item === 'object' && item !== null) {
          sanitizeEvent(item, depth + 1);
        }
      });
    } else if (typeof obj[key] === 'string') {
      obj[key] = obj[key].replace(jwtRegex, '[token]');
    }
  }
}

export class LogStreamHolder {
  constructor(stream) {
    this.stream = stream;
    this.streamMark = `[${stream}]`;
    this.enabled = false;

    // arrow-bound "methods"
    this.debug = (...rest) => {
      this.shout((...x) => console.debug(...x), ...rest);
      return this.debug;
    }
    this.log = (...rest) => {
      this.shout((...x) => console.log(...x), ...rest);
      return this.log;
    }
    this.info = (...rest) => {
      this.shout((...x) => console.info(...x), ...rest);
      return this.info;
    }
    this.error = (...rest) => {
      this.shout((...x) => console.error(...x), ...rest);

      const firstErrorObject = rest.find(item => typeof item === 'object' && item?.errorText !== undefined);
      const errorText = firstErrorObject ? firstErrorObject.errorText : null;

      const event = [...rest];
      sanitizeEvent(event);

      Sentry.captureException(new SentryError(`${stream} exception ${errorText}`, event));
      return this.error;
    }
    this.warning = (...rest) => {
      this.shout((...x) => console.warn(...x), ...rest);
      return this.warning;
    }
  }


  shout(handler, ...rest) {
    if (this.enabled)
      handler(this.streamMark, ...rest);
  }


};



