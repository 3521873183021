import { MarketGetPairStat } from 'api/tradeAPICall';
import MoneyUtil from 'components/moneyUtil';
import { _I_ } from 'components/translate';
import { LogStream } from 'generics/common';
import { SettingsInjections } from 'generics/settingsInjections';
import { classMixer } from 'generics/utils';
import moment from 'moment';
import React from 'react';
import {
  Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis
} from 'recharts';

// LogStream.enable("VIEWPRICEHISTORYGRAPH");
const loggerPriceHistory = LogStream("VIEWPRICEHISTORYGRAPH");

export default class ViewPriceHistoryGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      "shortRange": true,
      "series_1h": [],
      "series_1d": [],
    };
  }


  componentDidMount() {
    this.getPlotData();
  }

  prepareSeries(series) {
    if (!series || !series.length)
      return [];

    return series.map(([ts, price, vol]) => ({
      "time": ts * 1000,
      "volume": vol,
      "price": +MoneyUtil.formatMoney(price)
    }));
  }

  graphTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      let { price, volume } = payload[0].payload;

      const { shortRange } = this.state;
      const dateFormat = shortRange ? 'HH:mm DD.MM.YYYY' : 'DD.MM.YYYY';

      const market = " " + MoneyUtil.formatCurr(SettingsInjections.getUsedCurrency());
      const labelDate = moment(label).format(dateFormat);

      return (
        <div className="graphTooltip">
          <p className="label">{`${labelDate}`}</p>
          <p className="label">{`${_I_("MarketDepthTable.caption.quantity")}: ${volume}`}</p>
          <p className="label">{`${_I_("MarketDepthTable.caption.price")}: ${price} ${market}`}</p>
        </div>
      );
    }

    return null;
  };

  getPlotData() {
    if (!this.props.market_name) {
      this.setState({ "series_1h": [], "series_1d": [] });
      loggerPriceHistory.info("getPlotData - skipped due to empty market_hash_name");
      return;
    }

    MarketGetPairStat(
      this.props.appid, this.props.market_name

    ).then((resp) => {
      loggerPriceHistory.info('getPlotData', resp);
      this.setState({
        "series_1h": this.prepareSeries(resp["1h"]),
        "series_1d": this.prepareSeries(resp["1d"])
      });

    }).catch((reason) => {
      this.setState({ "series_1h": [], "series_1d": [] });
      reason = Object.assign({}, reason,
        {
          "message": ("Failed to load price history data:\n" +
            reason.message)
        });

      loggerPriceHistory.error("getPlotData - Failed (", reason, ")");
    });
  }

  render() {
    const { shortRange } = this.state;
    const series = this.state[shortRange ? "series_1h" : "series_1d"] || [];

    loggerPriceHistory.debug('render', series);

    return (
      <React.Fragment>
        <div className="graphContent">
          <div className="graphTitle">{_I_("PriceHistoryGraph.plot.title")}</div>
          <div className="content">
            <div className="body-panel">
              <div className="well">
                <a className={classMixer("buy", (this.state.shortRange) && "active")}
                  onClick={() => { this.setState({ "shortRange": true }) }}
                >{_I_("PriceHistoryGraph.plot.shortRange")}</a>

                <a className={classMixer("buy", (!this.state.shortRange) && "active")}
                  onClick={() => { this.setState({ "shortRange": false }) }}
                >{_I_("PriceHistoryGraph.plot.wholeRange")}</a>
              </div>
            </div>
          </div>
        </div>
        <ResponsiveContainer width="100%" height={310}>
          <AreaChart
            data={series}
            margin={{
              top: 5, right: 0, bottom: 5, left: 5
            }}
          >
            <CartesianGrid
              stroke="rgba(255, 255, 255, 0.2)"
            />

            <XAxis
              dataKey='time'
              tickFormatter={(unixTime) => moment(unixTime).format('HH:mm DD.MM')}
              type='number'
              domain={['dataMin', 'dataMax']}
              stroke="rgba(255, 255, 255, 0.5)"
            />

            <YAxis
              yAxisId="left"
              stroke="rgba(255, 255, 255, 0.5)"
            />

            <YAxis
              yAxisId="right"
              orientation="right"
              stroke="rgba(255, 255, 255, 0.5)"
            />

            <Tooltip
              content={this.graphTooltip}
            />

            <Area
              yAxisId="left"
              dataKey="price"
              stroke="#FC2C38"
              fill="#FC2C38"
              fillOpacity="0.26"
              legendType="line"
            />

            <Area
              yAxisId="right"
              dataKey="volume"
              stroke="#21A48C"
              fillOpacity={0}
              legendType="line"
            />
          </AreaChart>
        </ResponsiveContainer>
      </React.Fragment>
    );
  }
}
