import {
  OrderDone,
  Outbid,
  WinningBid,
  BidCanceled,
  AuctionEdit,
  OrderSell,
  OrderBuy,
  CancelBuy,
  CancelSell
} from "./marketIcons";


const Icons = {
  "winning_bid": <WinningBid />,
  "outbid": <Outbid />,
  "bid_canceled": <BidCanceled />,
  "committed_bid": <AuctionEdit size="16" />,
  "order_done": <OrderDone />,
  "order_buy": <OrderBuy />,
  "order_sell": <OrderSell />,
  "cancel_buy": <CancelBuy />,
  "cancel_sell": <CancelSell />
};

export const NOTIFICATION_MESSAGES_AUCTIONS = {
  "deal": {
    message: "ViewItemPopup.committed.BID",
    icon: Icons.committed_bid,
    class_name: "positive"
  },
  "deal_BUY": {
    message: "ViewItemSearch.heading.tooltip.win",
    icon: Icons.committed_bid,
    class_name: "win"
  },
  "new": {
    message: "ViewItemPopup.application.BID",
    icon: Icons.winning_bid,
    class_name: "positive"
  },
  "new_BUY": {
    message: "ItemMarketDetails.notifications.yourBid",
    icon: Icons.winning_bid,
    class_name: "positive"
  },
  "cancel": {
    message: "ViewItemPopup.canceled.BID",
    icon: Icons.bid_canceled,
    class_name: "canceled"
  },
  "AUCTION_OUTBIDDED": {
    message: "ViewItemSearch.heading.tooltip",
    icon: Icons.outbid,
    class_name: "negative"
  }
};

export const NOTIFICATION_MESSAGES_TRADE = {
  "deal_BUY": {
    message: "ViewItemPopup.committed.BUY",
    icon: Icons.order_done,
    class_name: "win"
  },
  "deal_SELL": {
    message: "ViewItemPopup.committed.SELL",
    icon: Icons.order_done,
    class_name: "win"
  },
  "new_SELL": {
    message: "ViewItemPopup.application.SELL",
    icon: Icons.order_sell,
    class_name: "positive"
  },
  "new_BUY": {
    message: "ViewItemPopup.application.BUY",
    icon: Icons.order_buy,
    class_name: "positive"
  },
  "cancel_SELL": {
    message: "ViewItemPopup.canceled.SELL",
    icon: Icons.cancel_sell,
    class_name: "canceled"
  },
  "cancel_BUY": {
    message: "ViewItemPopup.canceled.BUY",
    icon: Icons.cancel_buy,
    class_name: "canceled"
  }
}