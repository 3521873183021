
import ApplicationEvents from 'components/applicationEvents';
import { _I_ } from 'components/translate';
import { IconFontAwesome, IconGlyphicon } from 'generics/icons';
import { SettingsInjections } from 'generics/settingsInjections';
import { classMixer } from 'generics/utils';
import { observer } from 'mobx-react';
import { MarketStore } from 'stores';
export const EmailVerificationStateIcon = ({ creds }) => {
  const { haveEmailVerification, needEmailVerification, } = creds;
  const visual = haveEmailVerification ?
    "success" : (needEmailVerification ? "danger" : "warning");
  return <IconFontAwesome type="envelope" fixedWidth
                          mixClasses={[`text-${visual}`]}
                          title={_I_(`EmailVerificationStateIcon.${visual}`)} />;
}
const TwoFactorStateIcon = ({ creds }) => {
  const { have2step, need2step, } = creds;
  const visual = have2step ? "success" : (need2step ? "danger" : "warning");
  return <IconFontAwesome type="shield" fixedWidth
                          mixClasses={[`text-${visual}`]}
                          title={_I_(`TwoFactorStateIcon.${visual}`)} />;
}
export const CredentialsIcon = ({ creds }) => {
  creds = creds || {};
  return (
    <div className="credentials-icon"  >
      <IconFontAwesome type="user-circle" />
      &nbsp;{creds.nick || `User #[ ${creds.userId} ]`}
      &nbsp;<EmailVerificationStateIcon creds={creds} />
      &nbsp;<TwoFactorStateIcon creds={creds} />
    </div>
  );
}
const LimitedAccountPanelHandleRelogin = () => ApplicationEvents.emit_async("auth.goToRelogin", "LimitedAccountPanelHandleRelogin");
export const WarnLimitedDueTwoStep = ({ creds }) => {
  return <li>
    <div><TwoFactorStateIcon creds={creds} />&nbsp;
    </div>
    <div><a href={_I_("LimitedAccountPanel.2step.href")}>&nbsp;
      {_I_("LimitedAccountPanel.2step.link")}
    </a></div>
  </li>;
}
const WarnLimitedDueEmailVerification = ({ creds, steamArgs }) => {
  const href = `${_I_("LimitedAccountPanel.email.href")}${steamArgs}`;
  return <li>
    <div><EmailVerificationStateIcon creds={creds} />&nbsp;
      {_I_("LimitedAccountPanel.email.reason")}</div>
    <div><a href={href}>
      {_I_("LimitedAccountPanel.email.link")}
    </a></div>
  </li>;
}
export const LimitedAccountPanel = observer(({ warningState }) => {
  const creds = MarketStore.credentials;
  const limitedDueTwoStep = !!(creds.need2step && (!creds.have2step));
  const twoStepEnabledState = classMixer("item twoStepEnabled", warningState && "active");
  const currentLocale = SettingsInjections.getUsedLocale();
  const loginHost = SettingsInjections.getLoginHost();
  const urlToPlug = loginHost + "/" + currentLocale.icon + "/profile/security/" + creds.userId;
  var twoStepWindow = null;
  const openTwoStepEnable = () => {
    twoStepWindow = window.open(urlToPlug);
    const checkWindowInterval = setInterval(function () {
      if (twoStepWindow && twoStepWindow.closed) {
        clearInterval(checkWindowInterval);
        window.GSEA?.open();
      }
    }, 1000);
  }
  return (
    <div className="alert alert-warning">
      {limitedDueTwoStep && (
        <div className={twoStepEnabledState}>
          <img className="icon" src={`images/icons/warning.png`} />
          <div className="message">
            <div className="text">{_I_("LimitedAccountPanel.2step.reason")}
              <br />
              <div className="twoStepLink" >
                <img className="twoStepIcon" src={`images/icons/2step_icon.png`} />
                <a href={_I_("LimitedAccountPanel.2step.href")} className="link">
                  {_I_("LimitedAccountPanel.2step.link")}
                </a>
                <a href={_I_("LimitedAccountPanel.2step.href")} target="_blank">
                  <img className="newWindowIcon" src={`images/icons/new-window.png`} />
                </a>
              </div>
            </div>
            <div className="twoStepBtn" onClick={openTwoStepEnable}>{_I_("LimitedAccountPanel.2step.toPlug")}</div>
          </div>
        </div>
      )}
    </div>
  );
});
export const LimitedAccountWarningPanel = ({ creds }) => {
  creds = (creds || {});
  const limitedDueTwoStep = !!(creds.need2step && (!creds.have2step));
  const limitedDueEmailVerification = !!(creds.needEmailVerification && (!creds.haveEmailVerification));
  if (!(limitedDueTwoStep || limitedDueEmailVerification)) {
    return null;
  }
  return (<div className="alert alert-warning">
    <IconGlyphicon type="warning-sign" />&nbsp;{_I_("LimitedAccountPanel.title")}
    <ul>
      {limitedDueTwoStep && (
        <WarnLimitedDueTwoStep creds={creds} />
      )}
      {limitedDueEmailVerification && (
        <WarnLimitedDueEmailVerification creds={creds} />
      )}
      <li>
        <div>{_I_("LimitedAccountPanel.conclusion")}</div>
        <div><a onClick={LimitedAccountPanelHandleRelogin}>{_I_("LimitedAccountPanel.relogin")}</a></div>
      </li>
    </ul>
  </div>);
}