import { AjaxCall } from 'api/basicApi';
import { LogStream } from 'generics/common';
import { SettingsInjections } from 'generics/settingsInjections';

// LogStream.enable( "AUTHLOGDEBUG" )

////////////////////////////////////////////
// class RolesAPICall
class RolesAPICall  extends AjaxCall {
  constructor ( command )  {
    super( command )

    this.append( "action", command )
  }


  normalizeResponse( resp )  {
    if ( "result" in resp )    return resp.result;
    if ( "response" in resp )  return resp.response;
    return resp;
  }


  getInterfaceUrl()  {
    return SettingsInjections.getUsedCircuit().market_server;
  }
};


function isRolesAllow(roles) {
  // disable clients to close marketplace
  const allowRoles = [
  //     "ADMIN","DEVELOPER","GAIJIN","TESTER","ALPHA_TESTER"
    ];
  if ( !allowRoles.length )
    return true;

  return  !!roles.filter( (it) => allowRoles.includes(it) ).length;
}

function isCountryAllow(country) {
  const disallowCounties = [];

  if ( !disallowCounties.length )
    return true;

  return !disallowCounties.includes(country);
}


////////////////////////////////////////////
//  RolesAPI request  action=cmn_check_user_auth&token=xsaffasfa
export function AuthCheckUserAuth( token )  {

  if ( !token )
    return Promise.reject({
                "type":"error",
                "caption": "Authorization error",
                "message": "No token provided during checking authorization",
                "details": { "response":{"success": false, "error": "No token, so no call"},
                             "command":"cmn_check_user_auth",
                             "params": []    },
              });

  let callCheckAuth = new RolesAPICall("cmn_check_user_auth");
  callCheckAuth.append("token", token);

  return callCheckAuth.POST().then( (resp) =>  {
      LogStream( "AUTHLOGDEBUG" ).info(`AuthCheckUserAuth(${token}) - got response:`, resp);
      resp = (resp || {});

      const {  userId, nick, country  }  = resp;
      if ( nick && userId && userId > 0 )  {
        const policy = (resp.policy || {});
        const need2step =               !!(policy.check2factor),
              needEmailVerification =   !!(policy.checkEmailVerification),
              checkDeniedTags =           (policy.checkDeniedTags||[]).filter(Boolean);

        const have2step =               !!(resp["2step"]),
              haveEmailVerification =   !!(resp.email_verified),
              isWealthyUser         =   !!(resp.wealthy_gjn),
              deniedTags =                (resp.deniedTags||[]).filter(Boolean);

        const disallowedByRoles = !isRolesAllow( (resp.roles || "").split(";") );
        const disallowedByCountry = !isCountryAllow(country);

        return  {
            token, userId, nick,
            need2step,  have2step,
            needEmailVerification,  haveEmailVerification,
            deniedTags, checkDeniedTags,
          disallowedByRoles, disallowedByCountry, country, isWealthyUser
          };
      }

      return Promise.reject({
                  "type":"error",
                  "caption": "Authorization error",
                  "message": "Server returns error while checking authorization",
                  "details": { "response":resp,
                               "command":callCheckAuth.command,
                               "params": callCheckAuth.params     },
                });
    });
}


export function AuthByRefreshToken( refresh_token )
{
    let callAuth = new RolesAPICall("cln_authorize_by_refresh_token");
    callAuth.append("refresh_token", refresh_token);

    return callAuth.POST().then( (resp) =>  {
        LogStream( "AUTHLOGDEBUG" ).info(`AuthByRefreshToken(${refresh_token}) - got response:`, resp);
        resp = (resp || {});

        const {  jwt  }  = resp;

        if ( jwt )
        {
            return {
                jwt
            };
        }

        return Promise.reject({
            "type":"error",
            "caption": "Authorization error",
            "message": "Server returns error while checking authorization",
            "details": { "response":resp,
                "command":callAuth.command,
                "params": callAuth.params     },
        });
    });
}

