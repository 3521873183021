
const startSearch = document.location.search.replace( /^\?/, "" ).split( "&" ).filter( Boolean );
export function  getStartSearch()  {
  return startSearch;
}

const startHash   = document.location.hash.replace( /^#/, "" );
export function  getStartHash()  {
  return startHash;
}


const startPath   = document.location.pathname.split( "/" ).filter( Boolean );
export function  getStartPath()  {
  return startPath;
}


const argsReducer = (acc, arg)  =>  {
  const [name, val=""] = arg.split("=");
  if ( name )  {
    acc[name] = (val ? decodeURIComponent(val.replace(/[+]/g," ")) : "");
  }
  return acc;
}

const startArgs = startSearch.reduce(argsReducer, {});
export function  getStartArgs()  {
  return startArgs;
}


export function resetQuery()  {
  window.history.replaceState(null, document.title, window.location.href.replace( window.location.search, "" ) )
}
