export const plural = (number, words) => {
  return Math.abs(parseInt(number, 10)) === 1 ? words[0] : words[1];
};

export const locale = {
  "ViewItemPreviewSidePane.quantity.inventory": "У інвентарі",
  "ViewItemPreviewSidePane.quantity.ordersInventory": " предметів",
  "ViewItemPreviewSidePane.quantity.count": " у продажу",
  "ViewItemPreviewSidePane.quantity.countBuy": " заявок на покупку",
  "ViewItemPreviewSidePane.quantity.items": " предметів",
  "ViewMarketedItem.SoldOutText": "розпродано",
  "ViewMarketedItem.SoldOutTip": "Немає пропозицій на продаж",
  "ViewMarketedItem.NoBuyText": "немає заявок на купівлю",
  "ViewMarketedItem.SellingFrom": "Пропонується від...",
  "ViewMarketedItem.SellingMore": " і вище",
  "ViewMarketedItem.BuyingMore": " і нижче",
  "ViewMarketedItem.tradableAfter": "стане доступним для продажу",
  "ViewItemSearch.heading.showAll": "Усі позиції",
  "ViewItemSearch.heading.orders": "Мої заявки",
  "ViewItemSearch.heading.marketSell": "У продажі",
  "ViewItemSearch.heading.marketBuy": "Заявки на покупку",
  "ViewItemSearch.heading.marketAuctions": "Аукціони",
  "ViewItemSearch.title.marketAuction": "Аукціон",
  "ViewItemSearch.title.ending": "До кінця",
  "ViewItemSearch.title.finished": "Аукціон завершено",
  "ViewItemSearch.heading.settings": "Налаштування",
  "ViewMarketedItem.button.more": "Детальніше",
  "ViewItemSearch.heading.resultsFor": "Результати для:",
  "ViewItemSearch.stub.empty": "Нічого не знайдено",
  "ViewItemSearch.heading.tooltip": "Вашу ставку перебили",
  "ViewItemSearch.heading.tooltip.win": "Ваша ставка виграла",
  "ViewItemPopup.committed.SELL": "Продаж завершено",
  "ViewItemPopup.committed.BUY": "Покупку завершено",
  "ViewItemPopup.committed.BID": "Ставку зроблено",
  "ViewItemPopup.application.BUY": "Створено замовлення на покупку",
  "ViewItemPopup.application.SELL": "Створено запит на продаж",
  "ViewItemPopup.application.BID": "Ставка зроблена",
  "ViewItemPopup.canceled.BUY": "Замовлення на покупку скасовано",
  "ViewItemPopup.canceled.SELL": "Скасовано запит на продаж",
  "ViewItemPopup.canceled.BID": "Ставка скасована",
  "ViewItemPopup.showAll.button": "Переглянути все",
  "ViewItemAssets.readAll.button": "Позначити все як переглянуте",
  "ViewItemPopup.heading.noHistory": "Немає сповіщень",
  "ViewItemPopup.heading.unseenHistory": "Немає нових сповіщень",
  "ViewItemSearch.termsPlaceholder": "Пошук",
  "ViewItemSearch.label.search": "Пошук",
  "ViewMarketedItem.lovestPrice": "від ",
  "ViewMarketedItem.beforePrice": "до ",
  "ViewMarketedItem.yourBid": "ваша ставка ",
  "ViewMarketedItem.bid": "ставка ",
  "ViewItemSearch.heading.exchange": "Головна",
  "ViewItemSearch.heading.light": "Світла",
  "ViewItemSearch.heading.dark": "Темна",
  "ViewItemSearch.filteringAnnounce": "Для того, щоб фільтрувати заявки треба вибрати гру",
  "ViewItemSearch.ordersBuy": "КУПИТИ",
  "ViewItemSearch.ordersSell": "ПРОДАТИ",
  "ViewItemSearch.ordersOpen": "Переглянути повну історію",
  "MarketApp.Navbar.inventoryLink": "Мій інвентар",
  "MarketApp.Navbar.ordersLink": "Мій гаманець",
  "MarketApp.Navbar.marketLink": "Біржа",
  "MarketApp.Navbar.settingsLink": "Налаштування",
  "MarketApp.Navbar.replenishLink": "Поповнити",
  "MarketApp.Navbar.balanceHistoryLink": "Історія балансу",
  "MarketApp.Navbar.buyCoinsLink": "Купити GaijinCoin'и",
  "MarketApp.Navbar.profileLink": "Профіль",
  "MarketApp.Navbar.reloginLink": "Змінити обліковий запис",
  "MarketApp.Navbar.toOldVersion": "Перейти до старої версії",
  "MarketApp.Navbar.linkFeedback": "Залишити відгук",
  "ViewTradeDialog.caption": "Торгівля",
  "ViewTradeDialog.button.back": "Повернутись",
  "NotAllowedMessage.caption": "Немає доступу",
  "NotAllowedMessage.body.h1": "Доступ до біржі",
  "NotAllowedMessage.body.h3": "закритий для вашого облікового запису",
  "NotAllowedMessage.relogin": "Змінити обліковий запис",
  "CountryDeniedMessage.caption": "Немає доступу",
  "CountryDeniedMessage.body.notAllowed": "Доступ до біржі",
  "CountryDeniedMessage.body.unclearStatus": "Через незрозумілий юридичний статус біржових транзакцій у вашій країні доступ до біржі відключено",
  "LandingMessage.caption": "Аутентифікація",
  "LandingMessage.body": "Фінальні перевірки...",
  "TokenExpiredMessage.caption": "Аутентифікація закінчилася",
  "TokenExpiredMessage.body.h1": "Аутентифікація закінчилася",
  "TokenExpiredMessage.body.h3": "Для продовження необхідно повторно авторизуватись",
  "TokenExpiredMessage.relogin": "Авторизуватися",
  "AuthErrorMessage.caption": "Не вдалося автентифікуватись",
  "AuthErrorMessage.body.h1": "Не вдалося автентифікуватись",
  "AuthErrorMessage.body.code": "Код помилки:",
  "AuthErrorMessage.body.desc": "Ви можете спробувати переавторизуватися",
  "AuthErrorMessage.relogin": "Авторизуватися",
  "AuthorizationRequiredMessage.caption": "Потрібна автентифікація",
  "AuthorizationRequiredMessage.body.h3.1": "Для входу на біржу",
  "AuthorizationRequiredMessage.body.h3.2": "Біржа Gaijin",
  "AuthorizationRequiredMessage.body.h3.3": "потрібна авторизація",
  "AuthorizationRequiredMessage.login": "Авторизуватись на Gaijin.Net",
  "CheckingAuthMessage.caption": "Аутентифікація",
  "CheckingAuthMessage.body": "Аутентифікація, зачекайте...",
  "CancelOrdersPanel.cancelError": "\nНе вдалося скасувати заявку ",
  "CancelOrdersPanel.loading": "Перевірка списку заявок",
  "CancelOrdersPanel.done": "Операцію завершено.",
  "CancelOrdersPanel.errors": "Виникли помилки: \n",
  "CancelOrdersPanel.loaded.youHave": "На даний момент у вас виставлені ",
  "CancelOrdersPanel.loaded.sellOrders": " заявки (заявок) на продаж на суму  ",
  "CancelOrdersPanel.loaded.buyOrders": " заявки (заявок) на покупку на суму  ",
  "CancelOrdersPanel.loaded.and": " та ",
  "CancelOrdersPanel.loaded.shouldCancel": " і вам необхідно їх всі скасувати. ",
  "CancelOrdersPanel.loaded.doCancel": "Скасувати заявки",
  "CancelOrdersPanel.cancelling.processing": "Обробляються",
  "CancelOrdersPanel.cancelling.outOf": " з ",
  "CancelOrdersPanel.cancelling.sellOrders": " заявок на продаж",
  "CancelOrdersPanel.cancelling.buyOrders": " заявок на покупку",
  "ClassInfoDescriptions.dropped_item.block_label": "Містить один із:",
  "ClassInfoDescriptions.dropped_item.stage-unknown": "Невідомо",
  "ClassInfoDescriptions.dropped_item.stage-pending": "...завантажується",
  "ClassInfoDescriptions.text.block_label": "",
  "ClassInfoDescriptions.dropped_bundle.block_label": "Містить один із перерахованих предметів:",
  "ClassInfoDescriptions.buttonLabel.top": "Показати",
  "ClassInfoDescriptions.buttonLabel.hide": "Приховати",
  "ClassInfoDescriptions.buttonLabel.bottom": "всі",
  "ClassInfoDescriptions.dropped_text.description": "Description",
  "ClassInfoUtil.fake_name.pending": "завантаження",
  "ClassInfoUtil.fake_name.broken": "Вилучений предмет",
  "ClassInfoUtil.SynthTag.marketable1": "Можна продати",
  "ClassInfoUtil.SynthTag.marketable0": "Не можна продати",
  "ClassInfoUtil.SynthTag.tradable1": "Можна обмінюватися",
  "ClassInfoUtil.SynthTag.tradable0": "Не можна обмінюватися",
  "ClassInfoUtil.SynthTag.commodity1": "Масовий товар",
  "ClassInfoUtil.SynthTag.commodity0": "Штучний товар",
  "ConfirmBuyDialog.title": "Покупка",
  "BuyProcessingDialog.title": "В процесі...",
  "BuyProcessingDialog.pleaseWait": "Виконується, будь ласка, зачекайте",
  "ProcessingDialog.notItem": "Запитуваний товар не знайдено на ринку або він недоступний для торгівлі",
  "SellDialog.title": "Продаж",
  "SellProcessingDialog.title": "В процесі...",
  "SellProcessingDialog.pleaseWait": "Виконується, будь ласка, зачекайте",
  "LimitedAccountPanel.2step.reason": "Необхідно налаштувати двоетапну авторизацію, щоб торгувати на біржі",
  "LimitedAccountPanel.2step.link": "Про двоетапну авторизацію",
  "LimitedAccountPanel.2step.href": "https://support.gaijin.net/hc/en-us/articles/203623622-How-to-set-up-the-Two-Step-Authorization-Google-Authenticator-",
  "LimitedAccountPanel.email.reason": "Необхідно підтвердити ваш email",
  "LimitedAccountPanel.email.link": "Перейти до вашого профілю",
  "LimitedAccountPanel.2step.toPlug": "Підключити",
  "LimitedAccountPanel.email.href": "https://store.gaijin.net/user.php",
  "LimitedAccountPanel.title": "На обліковий запис діють обмеження через:",
  "LimitedAccountPanel.conclusion": "Після зміни налаштувань безпеки необхідно повністю вийти з облікового запису та увійти знову, щоб зміни набули чинності",
  "LimitedAccountPanel.relogin": "Змінити обліковий запис",
  "EmailVerificationStateIcon.success": "Підтверджений обліковий запис",
  "EmailVerificationStateIcon.warning": "Обмежений обліковий запис, не підтверджено пошту",
  "EmailVerificationStateIcon.danger": "Обмежений обліковий запис, НЕОБХІДНО підтвердити пошту",
  "TwoFactorStateIcon.success": "Обліковий запис захищений двоетапною авторизацією",
  "TwoFactorStateIcon.warning": "Обмежений обліковий запис, немає двоетапної авторизації",
  "TwoFactorStateIcon.danger": "Обмежений обліковий запис, НЕОБХІДНА двоетапна авторизація",
  "ViewInventoryTextFilter.common.label": "Щоб відфільтрувати інвентар, необхідно вибрати гру",
  "ItemBuyEditor.label.wheelAmountInput": "Можна використовувати колесо прокручування для зміни,\nctrl+колесо = 10x швидкість,\nshift+колесо=0.1x швидкість",
  "ItemBuyEditor.label.wheelInput": "Можна використовувати колесо прокручування для зміни,\nctrl+колесо = 10x швидкість,\nshift+колесо=0.1x швидкість",
  "ItemBuyEditor.note.posAmount": "Значення має бути додатним",
  "ItemBuyEditor.note.maxAmount": "Значення не має перевищувати 1000",
  "ItemBuyEditor.note.invalidAmount": "Значення має бути цілим додатним числом",
  "ItemBuyEditor.note.minPrice": "Мінімальна ціна",
  "ItemBuyEditor.note.maxTotal": "Максимальна сума перевищує",
  "ItemBuyEditor.note.invalidPrice": "Ціна має бути додатнім числом",
  "ItemBuyEditor.note.mustAgree": "Необхідно прийняти Угода користувача",
  "ItemBuyEditor.note.insufficentFunds0": "На балансі недостатньо",
  "ItemBuyEditor.note.insufficentFunds1": "для цього замовлення",
  "ItemBuyEditor.label.buyCouns": "Придбати",
  "ItemBuyEditor.label.submit": "Зробити замовлення",
  "ItemBuyEditor.label.price": "Ціна за одиницю:",
  "ItemBuyEditor.label.amount": "Кількість:",
  "ItemBuyEditor.label.totalPrice": "Разом (включаючи комісію):",
  "ItemBuyEditor.label.agree": "Я погоджуюсь з",
  "ItemBuyEditor.label.ToU": "Угодою користувача",
  "MarketDepthTotals.noOrders.sell": "Немає заявок на продаж",
  "MarketDepthTotals.noOrders.buy": "Немає заявок на покупку",
  "MarketDepthTotals.starting.sell": " за ",
  "MarketDepthTotals.starting.buy": " від ",
  "MarketDepthTotals.andMore.sell": " і нижче",
  "MarketDepthTotals.andMore.buy": " і вище",
  "MarketDepthTotals.prevew.sell": "Купують: ",
  "MarketDepthTotals.prevew.buy": "У продажу: ",
  "MarketDepthOwnWarning.sell": "У вас є очікування на продаж",
  "MarketDepthOwnWarning.buy": "У вас є очікування на покупку",
  "MarketDepthTable.caption.price": "Ціна",
  "MarketDepthTable.caption.quantity": "Кількість",
  "MarketDepthTable.ellipsis.sell": "і вище",
  "MarketDepthTable.ellipsis.buy": "і нижче",
  "ItemMarketDetails.buyButton": "Придбати",
  "ItemMarketDetails.sellButton": "Продати",
  "ItemMarketDetails.nothingToSellButton": "Немає предметів",
  "ItemMarketDetails.makeAuction": "Створити аукціон",
  "ItemMarketDetails.makeBid": "Зробити ставку",
  "ItemMarketDetails.bid": "Ставка",
  "ItemMarketDetails.yourBid": "Ваша ставка:",
  "ItemMarketDetails.notifications.yourBid": "Ваша ставка",
  "ItemMarketDetails.yourBid.short": "ваш",
  "ItemMarketDetails.yourBid.win": "виграє",
  "ItemMarketDetails.yourBid.loose": "програє",
  "ItemSellEditor.label.priceStep": "Крок ціни",
  "ItemSellEditor.label.endDate": "Дата завершення",
  "limitedAccountMessage.requires": "Необхідно:",
  "limitedAccountMessage.2step": "двоетапна авторизація",
  "limitedAccountMessage.verifiedEmail": "перевірений email",
  "limitedAccountMessage.and": " та ",
  "ItemSellEditor.label.wheelInput": "Можна використовувати колесо прокручування для зміни,\nctrl+колесо = 10x швидкість,\nshift+колесо=0.1x швидкість",
  "ItemSellEditor.note.minPrice": "Мінімальна ціна",
  "ItemSellEditor.note.maxPrice": "Максимальна ціна",
  "ItemSellEditor.note.maxTotals": "Максимальна сума",
  "ItemSellEditor.note.maxBalance": "Баланс не повинен перевищувати",
  "ItemSellEditor.note.mustAgree": "Необхідно прийняти Угода користувача",
  "ItemSellEditor.label.price": "Ціна для покупця (включаючи комісію):",
  "ItemSellEditor.label.wished": "Ви отримуєте, після відрахування комісії:",
  "ItemSellEditor.label.agree": "Я погоджуюсь з",
  "ItemSellEditor.label.ToU": "Угодою користувача",
  "ItemSellEditor.label.submit": "Продати",
  "ItemSellEditor.currentBalance.before": "Ваш поточний баланс",
  "ItemSellEditor.currentBalance.after": "на вашому балансі",
  "ItemSellEditor.sellOrdersTotal.before": "плюс",
  "ItemSellEditor.sellOrdersTotal.after": "у заявках на продаж",
  "ItemSellEditor.buyOrdersTotal.before": "та",
  "ItemSellEditor.buyOrdersTotal.after": "у заявках на купівлю",
  "ItemSellEditor.label.amount": "Скільки ви хочете продати:",
  "AppFilterEditor.label.byTags": "Фільтр",
  "AppFilterEditor.label.clearFilter": "Очистити фільтр",
  "AppFilterEditor.label.byTitles": "Фільтр з ігор",
  "AppFilterEditor.label.allFilter": "Усі ігри",
  "AppFilterEditor.label.authenticity": "Справжність",
  "AppFilterEditor.message.filter": "Фільтри є унікальними для кожної гри.",
  "AppFilterEditor.message.game": "Оберіть гру:",
  "ViewItemDetailed.restriction.amount.items": " предметів",
  "ViewItemDetailed.restriction.nonMarketable": "Не може бути проданий",
  "ViewItemDetailed.restriction.blockedAfter": "Торгівля зупиниться",
  "ViewItemDetailed.restriction.blockedPair": "Торгівлю зупинено",
  "ViewItemDetailed.restriction.expiresAt1": "Пропаде після",
  "ViewItemDetailed.restriction.expiresAt2": "",
  "ViewItemDetailed.restriction.endedAt": "End of auction",
  "ViewItemDetailed.restriction.amount": "Lot contains",
  "ViewItemDetailed.restriction.pieces": "pcs.",
  "ViewItemDetailed.restriction.startPrice": "Starting price",
  "ViewItemDetailed.restriction.days": "d",
  "ViewItemDetailed.restriction.hours": "hrs",
  "ViewItemDetailed.restriction.minutes": "min",
  "ViewItemDetailed.restriction.leadingBids": "leading bids",
  "ViewItemDetailed.restriction.noBids": "There are no bids yet. Place your first bid in this auction!",
  "ViewItemDetailed.restriction.delayTrade": "Once purchased, the item will be available for sale through",
  "ViewItemDetailed.restriction.unclearStatus": "Через незрозумілий юридичний статус трофеїв у вашій країні, всі транзакції з ними та ключами заблоковані для вашого облікового запису.",
  "MarketSortOrder.label.name": "Ім'я",
  "MarketSortOrder.hint.newDesc": "Новинки",
  "MarketSortOrder.hint.newAsc": "Спочатку старі",
  "MarketSortOrder.label.price": "Ціна",
  "MarketSortOrder.hint.priceAsc": "Спочатку дешеві",
  "MarketSortOrder.hint.priceDesc": "Спочатку дорогі",
  "MarketSortOrder.label.amount": "Кільк-ть",
  "MarketSortOrder.hint.amountAsc": "За наростанням кількості",
  "MarketSortOrder.hint.amountDesc": "За зменшенням кількості",
  "MarketSortOrder.label.popular": "Популярні",
  "MarketSortOrder.label.popularItems": "Популярні товари",
  "DoubleSellErrorModal.heading": "Помилка",
  "DoubleSellErrorModal.prompt": "Виникла помилка під час виставлення заявки",
  "DoubleSellErrorModal.explanation": "Не вдалося виставити цей товар на продаж\nОдна з можливих причин - він вже був проданий або використаний у грі\nСпробуйте оновити сторінку",
  "DoubleSellErrorModal.okButton": "OK",
  "OnBuyReserveFailModal.heading": "Помилка",
  "OnBuyReserveFailModal.prompt": "Виникла помилка під час виставлення заявки",
  "OnBuyReserveFailModal.explanation": "Вашу заявку на покупку не створено - не вдалося зарезервувати необхідну суму на рахунку",
  "OnBuyReserveFailModal.youNeed1": "Необхідно мати не менше",
  "OnBuyReserveFailModal.youNeed2": "на рахунку для цієї операції",
  "OnBuyReserveFailModal.tryRefresh": "Переконайтеся, що в гаманці достатньо коштів та оновіть сторінку",
  "OnBuyReserveFailModal.okButton": "OK",
  "DoubleRateErrorModal.heading": "Error",
  "DoubleRateErrorModal.prompt": "An error occurred while placing a bid",
  "DoubleRateErrorModal.explanation": "Please try to bid later",
  "DoubleRateErrorModal.okButton": "OK",
  "AuctionFinishedErrorModal.heading": "Error",
  "AuctionFinishedErrorModal.prompt": "An error occurred while placing a bid",
  "AuctionFinishedErrorModal.explanation": "The auction was completed",
  "AuctionFinishedErrorModal.okButton": "OK",
  "CancelOrderErrorModal.heading": "Помилка",
  "CancelOrderErrorModal.prompt": "Виникла помилка при скасуванні",
  "CancelOrderErrorModal.explanation": "Це могло статися через проблеми з мережею або ви потрапили на момент оновлення заявки.\nСпробуйте повторити операцію трохи пізніше.",
  "CancelOrderErrorModal.cancelButton": "Зрозумів",
  "OrdersListContainer.Tab.sellOrders": "Заявки на продаж",
  "OrdersListContainer.Tab.buyOrders": "Заявки на покупку",
  "OrdersListContainer.Tab.historyList": "Історія заявок",
  "ViewOrdersEntry.amounts.remain": "Залишок",
  "ViewOrdersEntry.amounts.done": "Завершено",
  "ViewOrdersEntry.order.order": "Заявка",
  "ViewOrdersEntry.order.issued": "Від",
  "ViewOrdersEntry.order.executed": "Виконано",
  "ViewOrdersEntry.order.pairIsBlocked": "Торгівлю зупинено",
  "ViewOrdersEntry.order.pairWillBlockAfter": "Торгівля зупиниться",
  "ViewOrdersEntry.order.BUY": "Покупка",
  "ViewOrdersEntry.order.SELL": "Продаж",
  "ViewOrdersEntry.order.view": "Відкрити",
  "ViewOrdersEntry.order.cancel": "Скасувати",
  "ViewOrdersList.empty.label": "Тут нічого немає",
  "CancelOrderDialog.heading.youSure": "Впевнені?",
  "CancelOrderDialog.body.youSure": "Ви впевнені, що хочете скасувати заявку?",
  "CancelOrderDialog.button.cancel": "Так, скасувати заявку",
  "CancelAllOrderDialog.button.cancel": "Так, для всіх",
  "CancelOrderDialog.button.noCancel": "Ні",
  "CancelOrderDialog.heading.processing": "Виконується...",
  "CancelOrderDialog.body.processing": "Будь ласка зачекайте",
  "ProfileMenu.MenuItem.Settings": "Налаштування",
  "ProfileMenu.MenuItem.GaijinAccount": "Профіль Gaijin.net",
  "ProfileMenu.MenuItem.AccountSwitch": "Змінити обліковий запис",
  "ProfileMenu.MenuItem.Signout": "Вийти",
  "ProfileMenu.MenuItem.nick": "Ваш нікнейм",
  "WalletBlock.balance.text": "Баланс:",
  "WalletBlock.balance.buyCurrency": "Купити GJN",
  "SecurityBlock.message.title": "Двофакторна авторизація",
  "SecurityBlock.emailVerified.title": "Email",
  "OrdersHistory.title.buy": "Заявки на покупку",
  "OrdersHistory.title.sell": "Заявки на продаж",
  "NotificationsAssets.title": "Сповіщення",
  "NotificationsAssets.title.today": "Сьогодні",
  "NotificationsAssets.title.historyFromLastWeek": "За останні сім днів",
  "NotificationsAssets.title.historyOlder": "Раніше",
  "LimitedAccountPanel.2step.pleas": "Захистіть ваш обліковий запис, щоб отримати доступ до торгівлі на біржі.",
  "LimitedAccountPanel.2step.protected": "Ваш обліковий запис захищений, у вас є доступ до торгівлі на біржі.",
  "LimitedAccountPanel.email.pleas": "Підтвердити його, щоб отримати доступ до торгівлі на біржі.",
  "LimitedAccountPanel.email.protected": "Ваш e-mail підтверджено, у вас є доступ до торгівлі на біржі.",
  "OrdersListContainer.Opened.Orders": "Відкриті",
  "OrdersListContainer.Closed.Orders": "Закриті",
  "ViewOrdersList.empty.labelCollapse": "Згорнути",
  "ViewOrdersList.empty.labelExpand": "Розгорнути",
  "OrdersGraph.plot.title": "Купівля/Продаж",
  "OrdersGraph.fmt.offers.sell": "заявки(заявок)\n<br/>на продаж\n<br/>по",
  "OrdersGraph.fmt.andMore.sell": "і нижче",
  "OrdersGraph.fmt.offers.buy": "заявки(заявок)\n<br/>на покупку\n<br/>від",
  "Orders.title.header": "заявки",
  "OrdersGraph.fmt.andMore.buy": "і вище",
  "PriceHistoryGraph.plot.title": "Історія цін:",
  "PriceHistoryGraph.highlighter.format": "На <strong>%s</strong><br />ціна <strong>%s</strong><br />кількість <strong>%s</strong><br />",
  "PriceHistoryGraph.plot.shortRange": "Найближчим часом",
  "PriceHistoryGraph.plot.wholeRange": "За весь час",
  "DealEventTickerEntry.purchasedFrom": "купив у",
  "DealEventTickerEntry.for": "за",
  "BookSellTickerEntry.listedItem": "sale request",
  "BookBuyTickerEntry.created": "виставив",
  "BookBuyTickerEntry.for": "purchase order",
  "BookAuctionTickerEntry.created": "made a bet",
  "ViewTradeTickerLog.waiting": "Очікування активності...",
  "ViewTradeTickerLog.ticker": "Real time trading...",
  "ViewUserInventory.common.emptyInventory": "Інвентар поки порожній",
  "ViewItemPreviewSidePane.UnselectButton.label": "Фільтри",
  "ViewItemPreviewSidePane.TradeButton.label": "Продати/Купити",
  "ViewItemPreviewSidePane.ViewButton.label": "Детальніше",
  "ViewItemPreviewSidePane.QuickSellButton.label": "Продати",
  "ViewInventoryTagCloud.tagTitle.itemsOf": "предмета(ів)",
  "ViewInventoryTagCloud.tagTitle.varietiesMarkedAs": "різновиди(ів), зазначених як",
  "ViewItemSearch.heading.inventory": "Інвентар",
  "WalletBlock.circleSmall.settings": "Налаштування",
  "WalletBlock.circleSmall.exit": "Вихід",
  "UserSettingsDialog.language": "Мова",
  "UserSettingsDialog.user_settings": "Налаштування користувача",
  "UserSettingsDialog.settings": "Налаштування",
  "UserSettingsDialog.save_settings": "Зберегти налаштування",
  "UserSettingsDialog.privacySettings": "Торгівля",
  "UserSettingsDialog.privacySettingsPublic": "Публічна",
  "UserSettingsDialog.privacySettingsPrivate": "Приватна",
  "UserSettingsDialog.interface": "Interface",
  "UserSettingsDialog.interfaceOld": "Legacy",
  "UserSettingsDialog.interfaceNew": "Modern",
  "WalletLimitsIcon.soft_limit": "Перевищено ліміт GJN, функції поповнення недоступні",
  "WalletLimitsIcon.hard_limit": "Перевищено ліміт GJN, функції продажу та поповнення недоступні",
  "ActionBar.action.ingamePreview": "Переглянути у грі",
  "ActionBar.action.previewCopied": "Посилання скопійоване!",
  "ActionBar.action.previewShare": "Поділитися",
  "IngamePreview.ask.heading": "Переглянути у грі?",
  "IngamePreview.ask.prompt": "Цей предмет можна переглянути у грі",
  "IngamePreview.ask.explanation": "Переконайтеся, що ігровий клієнт запущено під вашим обліковим записом",
  "IngamePreview.ask.cancelButton": "Ні",
  "IngamePreview.done.heading": "Готово",
  "IngamePreview.done.prompt": "",
  "IngamePreview.done.explanation": "Надіслано успішно",
  "IngamePreview.done.okButton": "Ок",
  "IngamePreview.fail.heading": "Щось пішло не так",
  "IngamePreview.fail.prompt": "",
  "IngamePreview.fail.explanation": "Не вдалося зв'язатися з ігровим клієнтом",
  "IngamePreview.fail.cancelButton": "Ок",
  "IngamePreview.pending.heading": "Секундочку...",
  "IngamePreview.pending.prompt": "Надсилання повідомлення ігровому клієнту",
  "IngamePreview.pending.explanation": "",
  "IngamePreview.ask.okButton": "Так",
  "StaticConfigLoader.caption.error": "Помилка ініціалізації",
  "StaticConfigLoader.caption.init": "Ініціалізація",
  "StaticConfigLoader.text.init": "Ініціалізація програми...",
  "StaticConfigLoader.error.settings": "Не вдалося завантажити установки програми",
  "StaticConfigLoader.error.circuits": "Не вдалося завантажити налаштування контуру",
  "ControlButton.close": "Закрити",
  "Exit": "Змінити обліковий запис",
  "Legal.TermsConditions": "Правила та умови",
  "Legal.TermsService": "Обслуговування",
  "Legal.PrivacyPolicy": "Конфіденційність",
  "Legal.CookiesPolicy": "Умови Cookie",
  "Legal.TradePolicy": "Про торгівлю",
  "Legal.TermsConditions.linck": "https://legal.gaijin.net/ua/",
  "Legal.TermsService.linck": "https://legal.gaijin.net/ua/termsofservice/",
  "Legal.PrivacyPolicy.linck": "https://legal.gaijin.net/ua/privacypolicy/",
  "Legal.CookiesPolicy.linck": "https://legal.gaijin.net/ua/cookie/",
  "Legal.TradePolicy.linck": "https://legal.gaijin.net/ua/tradepolicy/",
  "Legal.Copyright": "Gaijin Network Ltd. Усі торгові марки, логотипи й назви брендів належать їх відповідним власникам.",
  "MarketApp.Navbar.HeadTitle": "Gaijin.Net",
  "MarketApp.Navbar.HeadSubTitle": "Market",
};
