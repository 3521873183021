import { MarketGetHistory, MarketSetSeenAllHistory, MarketSetSeenHistory } from "api/ordersAPICall";
import { makeAutoObservable, runInAction } from "mobx";
class HistoryStore {
  history = [];
  loading = false;

  constructor() {
    makeAutoObservable(this)
  }

  get unseenHistory() {
    return this.history.filter(item => !item.seen) || [];
  }

  get unseenHistoryIds() {
    return this.history.filter(item => !item.seen).map(item => item.id);
  }

  get todayHistory() {
    const today = new Date();
    return this.getHistoryForPeriod(today, null);
  }

  get historyFromLastWeek() {
    const today = new Date();
    const aWeekAgo = new Date();
    aWeekAgo.setDate(today.getDate() - 7);
    today.setDate(today.getDate() - 1);
    return this.getHistoryForPeriod(aWeekAgo, today);
  }

  get historyOlder() {
    const aWeekAgo = new Date();
    aWeekAgo.setDate(aWeekAgo.getDate() - 7);
    return this.getHistoryForPeriod(null, aWeekAgo);
  }

  async fetchHistory({ count = 100, skip = 0, append = false, prepend = false } = {}) {
    this.loading = true;
    try {
      const data = await MarketGetHistory(count, skip);

      runInAction(() => {
        if (prepend || append) {
          const existingIds = new Set(this.history.map(item => item.id));
          const newHistory = data.filter(item => !existingIds.has(item.id));

          if (prepend) {
            this.history = [...newHistory, ...this.history];
          } else {
            this.history = [...this.history, ...newHistory];
          }
        } else {
          this.history = data;
        }
      })
    } catch (error) {
      console.error("Failed to fetch history:", error);
    } finally {
      this.loading = false;
    }
  }

  async setSeenHistory(ids) {

    if (typeof ids === "string")
      ids = [ids]

    try {
      await MarketSetSeenHistory(ids);
      this.setSeen(ids);
    } catch (error) {
      console.error("Failed to set history:", error);
    }
  }

  async setSeenAllHistory() {
    try {
      await MarketSetSeenAllHistory();
      this.setSeenAll();
    } catch (error) {
      console.error("Failed to set all history:", error);
    }
  }

  loadInitial(count = 100, skip = 0) {
    return this.fetchHistory({ count, skip });
  }

  loadMore(count = 100) {
    const skip = this.history.length;
    return this.fetchHistory({ count, skip, append: true });
  }

  loadUpdates(count = 100, skip = 0) {
    return this.fetchHistory({ count, skip, prepend: true });
  }

  setSeen(ids) {
    this.history.forEach(event => {
      if (ids.includes(event.id)) {
        event.seen = true;
      }
    });
  }

  setSeenAll() {
    this.history.forEach(event => {
      if (!event.seen) {
        event.seen = true;
      }
    });
  }

  getHistoryForPeriod(startDate, endDate) {
    const startTimestamp = startDate ? startDate.setHours(0, 0, 0, 0) : null;
    const endTimestamp = endDate ? endDate.setHours(23, 59, 59, 999) : null;

    return this.history.filter((message) => {
      const messageTimestamp = message.ts * 1000;

      if (startTimestamp && messageTimestamp < startTimestamp) return false;
      if (endTimestamp && messageTimestamp > endTimestamp) return false;

      return true;
    });
  }
}

export default new HistoryStore();