import { AjaxCall } from 'api/basicApi';
import ApplicationEvents from 'components/applicationEvents';
import { LogStream } from 'generics/common';
import { SettingsInjections } from 'generics/settingsInjections';
import { DataSource } from 'generics/utils';
import MarketStore from 'stores/market';

// LogStream.enable( "WALLETAPICALL" );
const loggerWalletApiCall = LogStream("WALLETAPICALL");

////////////////////////////////////////////
// class WalletAPICall
class WalletGOAPICall extends AjaxCall {
  constructor(command) {
    super(command);
    this.append("token", MarketStore.token);
  }

  normalizeResponse(resp) {
    return resp;
  }

  getInterfaceUrl() {
    const { wallet_server } = SettingsInjections.getUsedCircuit();
    return `${wallet_server}${this.command}.php`;
  }
};

////////////////////////////////////////////
// class WalletAPICall
class WalletAPICall extends AjaxCall {
  constructor(command) {
    super(command);
    this.appendHeader("Authorization", "BEARER " + MarketStore.token);
  }

  normalizeResponse(resp) {
    return resp;
  }

  getInterfaceUrl() {
    const { wallet_server } = SettingsInjections.getUsedCircuit();
    return `${wallet_server}${this.command}`;
  }
};

////////////////////////////////////////////
// class WalletStaticCall
class WalletStaticCall extends AjaxCall {
  normalizeResponse(resp) {
    return resp;
  }

  getInterfaceUrl() {
    // const {  wallet_server  }  =  SettingsInjections.getUsedCircuit();
    const wallet_server = "config/wallet_";
    return `${wallet_server}${this.command}.json`;
  }
};

////////////////////////////////////////////
// WalletAPI request  POST: GetBalance access_token=[JWT_TOKEN]
// https://wallet-gotest.gaijin.net/GetBalance
export function WalletGetBalance() {

  let callGetBalance = new WalletAPICall("GetBalance");

  return callGetBalance.GET().then((resp) => {
    loggerWalletApiCall.info(`WalletGetBalance() - got response:`, resp);
    return resp.balance;
  });
}

// WalletGOAPI request  POST: exchange_rates.php
// https://wallet-gotest.gaijin.net/exchange_rates.php
export function WalletGetExchange() {

  let callGetExchange = new WalletGOAPICall("exchange_rates");

  return callGetExchange.POST().then((resp) => {
    loggerWalletApiCall.info(`WalletGetExchange() - got response:`, resp);
    return resp.rates;
  });
}

// WalletGOAPI request  POST: buy_coins.php?currency=usd&amount=100&token=xsaffasfa
// https://wallet-gotest.gaijin.net/buy_coins.php
export function WalletBuyCoins(amount, curr, agree) {

  let callBuyCoins = new WalletGOAPICall("buy_coins");
  callBuyCoins.append("currency", curr);
  callBuyCoins.append("coins", amount);
  callBuyCoins.append("agree", agree);

  return callBuyCoins.POST().then((resp) => {
    loggerWalletApiCall.info(`WalletBuyCoins() - got response:`, resp);
    return resp;
  });
}

export const cachedBalance = new DataSource({});
cachedBalance.on((balance) => {
  // loggerWalletApiCall.debug( "cachedBalance::emit(", balance, ")" );
  ApplicationEvents.emit_async("market.balance", balance);
})

const EXPIRATION_RECHECK_INTERVAL_MS = (45 * 60 * 1000);
let walletNextCheckTime = 0;

export function WalletCheckExpiration(force) {

  if (!force && (Date.now() < walletNextCheckTime)) {
    const balance = cachedBalance.get();
    cachedBalance.set(balance);
    return Promise.resolve(balance);
  }

  walletNextCheckTime = Date.now() + EXPIRATION_RECHECK_INTERVAL_MS;
  return WalletGetBalance().then(
    (balance) => {
      loggerWalletApiCall.info("WalletCheckExpiration -> ", balance);
      const gjn = balance || 0;
      cachedBalance.set({ gjn })
      return Promise.resolve(cachedBalance.get());

    },
    (reason) => {
      ApplicationEvents.emit_async("auth.tokenExpired");
      walletNextCheckTime = 0;
      cachedBalance.set({})
      return Promise.reject(reason);
    });
}

export function WalletLoadLimits() {
  let callWalletLimits = new WalletStaticCall("limits");

  return callWalletLimits.GET().then((resp) => {
    loggerWalletApiCall.info(`WalletLoadLimits() - got response:`, resp);
    return resp;
  });
}