import {
  ItemClassInfoResolver, MarketGetContextContents, MarketGetContexts
} from 'api/assetAPICall';
import ApplicationEvents from 'components/applicationEvents';
import { LogStream } from 'generics/common';
import { objectMap } from 'generics/utils';
import { cloneDeep } from 'lodash';

// LogStream.enable( "STORAGE" );

export const makeAssetBucket = (appid, assetClass, classInfo, ctxid) => {
  return {
    classInfo, assetClass, ctxid, appid,
    classid: classInfo.classid, totalAmount: 0, instances: [],
  };
};


const mkInvalidClassinfo = (appid, ctxid, asssid, itemClass) => {
  const sid = ItemClassInfoResolver.getClassSid(appid, itemClass);
  const key = `${appid}_${ctxid}_${asssid}`;

  return {
    "classid": sid,
    "IS_UNRESOLVED": true,

    "background_color": "400000",
    "commodity": true,
    "descriptions": [
      { "value": "<UNABLE TO RESOLVE ITEM INFO>" }
    ],
    "icon_url": "images/invalid_item.png",
    "icon_url_large": "images/invalid_item_large.png",
    "market_fee": 0,
    "market_hash_name": `<INVALID_${key}>`,
    "market_name": `<INVALID_${key}>`,
    "marketable": false,
    "tradable": false,
    "name": `<Unresolved item ${key}>`,
    "name_color": "800000",
    "owner_descriptions": [
      { "value": "<UNABLE TO RESOLVE ITEM INFO>" }
    ],
    "tags": [],
    "type": ""
  }
};


const loadContextContent = (contextInfo, appid) => {
  contextInfo = Object.assign({}, contextInfo, { contents: {}, stackedContents: {}, })
  const ctxid = contextInfo.id;

  LogStream("STORAGE").info(`loadContextContent(${appid}, ${ctxid}) - requesting`);

  return MarketGetContextContents(
    appid, ctxid
  ).then((assets) => {
    assets = assets || [];
    LogStream("STORAGE").info(`loadContextContent(${ctxid}) - got response:`, assets);

    let pending = assets.map(assetInfo => {
      assetInfo = Object.assign({}, assetInfo);
      const asssid = `${assetInfo.id}`;
      const cached = { appid, ctxid, asssid, assetInfo, classInfo: {}, };
      contextInfo.contents[asssid] = cached;

      return ItemClassInfoResolver.resolve(
        appid, assetInfo["class"]
      ).catch(
        (error) => mkInvalidClassinfo(appid, ctxid, asssid, assetInfo["class"])
      ).then(
        (classInfo) => Object.assign(cached.classInfo, classInfo)
      );

    });

    return Promise.all(pending).then((res) => contextInfo);
  });
};


const stackCommodityItems = (contextInfo, appid) => {
  const ctxid = contextInfo.id;

  let items = {};
  objectMap(contextInfo.contents, (item) => {
    const classInfo = item.classInfo;
    if (classInfo.IS_UNRESOLVED ||
      classInfo.hidden ||
      classInfo.store_hidden) {
      return;
    }
    const assetClass = item.assetInfo["class"];
    const classid = ItemClassInfoResolver.getClassSid(appid, assetClass);
    const bucket = items[classid] ||
      (items[classid] = makeAssetBucket(appid, assetClass, classInfo, ctxid,));

    bucket.totalAmount += item.assetInfo["amount"] || 1;
    bucket.instances.push(item.assetInfo);
  });

  contextInfo.stackedContents = items;
  return contextInfo
};


const loadContextsList = (appid) => {
  LogStream("STORAGE").info("loadContextsList - requesting");

  return MarketGetContexts(
    appid
  ).then(
    (contexts) => (contexts || [])
  ).then(
    (contexts) => contexts.map((contextInfo) => loadContextContent(contextInfo, appid))
  ).then(
    (contexts) => Promise.all(contexts)
  ).then(
    (contexts) => contexts.map((contextInfo) => stackCommodityItems(contextInfo, appid))
  ).then(
    (contexts) => contexts.map((contextInfo) => ({ [`ctx_${contextInfo.id}`]: contextInfo }))
  ).then(
    (entries) => Object.assign({}, ...entries)
  );
}


// holds inventory (whole list of contexts and its contents) for appid
export class UserInventoryStorageProvider {
  constructor(appid) {
    this.appid = appid;
    this.contexts = {};
  }


  onUpdateInventoryReq() {
    const appid = this.appid;

    return loadContextsList(
      appid
    ).then((contexts) => {
      const stackedItems = Object.values(contexts).reduce(
        (items, ctx) => {
          items.push(...(Object.values(ctx.stackedContents || {})));
          return items;
        },
        []);

      const amount = stackedItems.reduce(((accum, it) => accum + it.totalAmount), 0);

      this.contexts = contexts;
      this.stackedItems = stackedItems;
      this.amount = amount;

      // HINT: Must be sync event, otherwise there is race condition
      ApplicationEvents.emit("market.inventoryUpdated", { contexts, appid, stackedItems, amount });

      return contexts;

    }).catch((reason) => {
      reason = Object.assign({}, reason,
        {
          "caption": "User Inventory Error",
          "message": ("Failed to refresh inventory:\n" +
            reason.message +
            "\nYOU NEED RELOAD PAGE TO CONTINUE"),
          "stack": reason.stack,
          "needReload": true
        });

      LogStream("STORAGE").error("onUpdateInventoryReq - Failed (", reason, ")");
      ApplicationEvents.emit_async("EventLog.append", reason);
      return Promise.reject(reason);
    });
  }


  findAssetByClass(assetAppId, assetClassId, assetCtxId) {
    if (assetAppId != this.appid)
      throw new Error(`findAssetByClass( ${assetAppId}, ${assetClassId}, ${assetCtxId} )`);

    // find first stacked bucket with desired assetClassId, otherwise false
    return cloneDeep(
      Object.values(this.contexts
      ).filter((context) => (!assetCtxId || context.id === assetCtxId)
      ).map((context) => context.stackedContents[assetClassId]
      )[0]
    );
  }

  findAllAssetsByClass(assetAppId, assetClassId, assetCtxId) {
    if (assetAppId != this.appid)
      throw new Error(`findAssetByClass( ${assetAppId}, ${assetClassId}, ${assetCtxId} )`);

    let assets = [];

    Object.values(this.contexts)
      .filter(
        (context) => (!assetCtxId || context.id === assetCtxId)
      )
      .forEach(
        (context) => {
          Object.keys(context.stackedContents).forEach(
            (stackedAssetClassId) => {
              if (stackedAssetClassId.startsWith(assetClassId))
                assets.push(context.stackedContents[stackedAssetClassId])
            }
          )
        }
      )

    return assets
  }

};

