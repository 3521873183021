import * as Sentry from "@sentry/browser";
import { ItemClassInfoResolver } from 'api/assetAPICall';
import { refreshBlockedPairs } from 'api/rpcAPICall';
import { ClassByHashnameResolver } from 'api/tradeAPICall';
import ApplicationEvents from 'components/applicationEvents';
import ViewTradeDialog from 'components/assetPage';
import { DialogHolder } from 'components/dialogHolder';
import { EventLogController } from 'components/eventLog';
import G_marketConfigStorage from 'components/globalMarketConfigStorage';
import { makeAssetBucket, UserInventoryStorageProvider } from 'components/internalLogic';
import { ViewItemSearch } from 'components/marketedAssets';
import { ViewStorefront } from 'components/marketStorefront';
import { ViewOrdersListContainer } from 'components/orderAssets';
import { setupUsedLocale } from 'components/setup/staticConfigsLoader';
import { RealtimeTransport } from 'components/tickerListener';
import { ViewUserInventory } from 'components/userAssets';
import ExchangeRates from 'components/walletExchangeRates';
import WalletLimitsController from 'components/walletLimitsController';
import { LogStream, utils_api } from 'generics/common';
import { loadRoute, resetRoute } from 'generics/routed.js';
import { SettingsInjections } from 'generics/settingsInjections';
import { reaction } from "mobx";
import { observer } from 'mobx-react';
import React from 'react';
import ReactGA from 'react-ga';
import { MarketStore } from 'stores';
import 'styles/style.less';
import { HeadPanel, LeftSidePanel } from "./gaijinToolPanel";
import NotificationsAssets from "./notifications/notificationsAssets";
import { UnavailableItemModal } from "./unavailableItemModal";

LogStream.enable("MARKETAPP");

const MarketApp = observer(
  class MarketApp extends React.Component {
    constructor(props) {
      super(props);

      this.appInventoryStorage = {};

      const settings = SettingsInjections.getUsedCircuit();
      if (settings.notify_server && settings.notify_realm)
        this.realtimeTransport = new RealtimeTransport(settings.notify_server,
          settings.notify_realm);

      this.setCurrentAssetByHash = (appid, hash_name, isTradeDialog) => this.setCurrentAsset(appid, hash_name, isTradeDialog);

      this.onUpdateInventoryReqBound = () => this.onUpdateInventoryReq();

      this.scrollbar = React.createRef();

      this.state = {
        "currentAsset": null,
        "isTradeDialog": true
      };
    }

    prepareStorage(appid) {
      if (this.appInventoryStorage[appid] === undefined)
        this.appInventoryStorage[appid] = new UserInventoryStorageProvider(appid);

      return this.appInventoryStorage[appid];
    }


    findAssetByClass(assetAppId, assetClassId, assetCtxId) {
      return this.prepareStorage(assetAppId).findAssetByClass(assetAppId, assetClassId, assetCtxId);
    }

    findAllAssetsByClass(assetAppId, assetClassId, assetCtxId) {
      return this.prepareStorage(assetAppId).findAllAssetsByClass(assetAppId, assetClassId, assetCtxId);
    }


    onUpdateInventoryReq() {
      Promise.all(this.props.appIds.map(
        (appid) => this.prepareStorage(appid).onUpdateInventoryReq()
      )).then(() => {
        ApplicationEvents.emit_async("market.inventoryUpdateCompleted", {});

        let { currentAsset } = this.state;
        if (currentAsset)
          ApplicationEvents.emit_async("market.setCurrentAssetByHash",
            currentAsset.appid, currentAsset.classInfo.market_hash_name);
      })
    }


    componentDidMount() {
      ApplicationEvents.on("market.setCurrentAssetByHash", this.setCurrentAssetByHash);
      ApplicationEvents.on("market.updateInventory", this.onUpdateInventoryReqBound);
      ApplicationEvents.on("market.setLocale", () => this.onUpdateInventoryReqBound());

      if (this.realtimeTransport)
        this.realtimeTransport.attach();

      ApplicationEvents.emit_async("market.updateInventory");

      const storedRoute = loadRoute();
      if (storedRoute.route && (storedRoute.route == "viewitem")) {
        const { app, market_hash_name } = (storedRoute.params || {});
        if (app && market_hash_name)
          ApplicationEvents.emit_async("market.setCurrentAssetByHash", app, market_hash_name);
        resetRoute();
      }

      reaction(
        () => [MarketStore.page],
        () => {
          this.setState({ "currentAsset": null, "previewAsset": null });
          ReactGA.pageview(MarketStore.page);
        }
      )

    }


    getTradableAfter(appid, classSid) {
      const assetList = this.findAllAssetsByClass(appid, classSid, null);
      let tradableAfter = null;

      assetList.forEach((asset) => {
        const tradableAfterValue = asset.classInfo?.tradable_after;
        const tradableAfterDate = tradableAfterValue && new Date(tradableAfterValue).getTime();

        if (tradableAfterDate && (!tradableAfter || tradableAfterDate < tradableAfter))
          tradableAfter = tradableAfterDate
      });

      return tradableAfter;
    }

    componentWillUnmount() {
      ApplicationEvents.off("market.setCurrentAssetByHash", this.setCurrentAssetByHash);
      ApplicationEvents.off("market.updateInventory", this.onUpdateInventoryReqBound);
      ApplicationEvents.off("market.setLocale", () => this.onUpdateInventoryReqBound());

      if (this.realtimeTransport)
        this.realtimeTransport.detach();
    }

    setCurrentAsset(appid, hash_name, isTradeDialog = true) {
      if (this.state.previousAsset && !hash_name) {
        hash_name = this.state.previousAsset.parent_hashName;
        appid = this.state.previousAsset.appid;
      }

      LogStream("MARKETAPP").info("setCurrentAssetByHash -> ", appid, hash_name, isTradeDialog);

      if (!appid || !hash_name || !this.props.appIds?.includes(appid.toString())) {
        this.setState({ "currentAsset": null, isTradeDialog, hash_name });
        return;
      }

      refreshBlockedPairs(appid, hash_name);

      ClassByHashnameResolver.resolve(
        appid, hash_name
      ).then((assetclass) => {
        const classSid = ItemClassInfoResolver.getClassSid(appid, assetclass);
        LogStream("MARKETAPP").info(`Resolved ${appid}:"${hash_name}" => ${classSid} : `, assetclass);

        const tradableAfter = this.getTradableAfter(appid, classSid);
        const asset = this.findAssetByClass(appid, classSid, null);

        if (asset) {
          LogStream("MARKETAPP").info("Found asset ", asset);

          if (tradableAfter)
            asset.classInfo["tradable_after"] = tradableAfter;

          if (hash_name)
            asset.classInfo["market_hash_name"] = hash_name;

          this.setState({
            currentAsset: asset,
            isTradeDialog
          });

          return;
        }

        return ItemClassInfoResolver.resolve(
          appid, assetclass, hash_name
        ).then((classInfo) => {
          let fakeAsset = makeAssetBucket(appid, assetclass, classInfo, null);
          LogStream("MARKETAPP").info("Fake asset ", fakeAsset);

          if (tradableAfter)
            fakeAsset.classInfo["tradable_after"] = tradableAfter;

          this.setState({
            currentAsset: fakeAsset,
            isTradeDialog
          });

          return;
        });

      }).catch((reason) => {

        if (reason.details?.response?.error === 'ASSET_CLASS_NOT_FOUND')
          UnavailableItemModal.spawnProcessingModal();

        this.setState({ "currentAsset": null, isTradeDialog });

        reason = Object.assign({}, reason,
          {
            "caption": "Asset Resolve Error",
            "message": ("Failed to resolve asset info:\n" +
              reason.message)
          });

        LogStream("MARKETAPP").error("setCurrentAssetByHash - Failed (", reason, ")");
        // ApplicationEvents.emit_async( "EventLog.append", reason );
      });
    }

    renderAppNavbar() {
      const filterPages = ["market", "marketSell", "marketBuy", "marketAuctions", "inventory"];
      const isHidden = !filterPages.includes(MarketStore.page);

      return (
        <LeftSidePanel
          appIds={this.props.appIds}
          hidden={isHidden}
          locale={this.props.locale}
        />
      );
    }

    renderHeadPanel() {
      return (
        <div className="screenHead">
          <HeadPanel
            locale={this.props.locale} />
        </div>
      )
    }


    renderTradeDialog() {
      let { currentAsset, isTradeDialog } = this.state;
      if (currentAsset && isTradeDialog)
        return (
          <ViewTradeDialog
            classInfo={currentAsset.classInfo}
            assetClass={currentAsset.assetClass}
            instances={currentAsset.instances}
            ctxid={currentAsset.ctxid}
            appid={currentAsset.appid}
            totalAmount={currentAsset.totalAmount}
          />
        );
    }


    renderOrders() {
      const hiddenPage = (this.state.currentAsset || (MarketStore.page != "orders")) ? "hidden" : "";

      return (
        <ViewOrdersListContainer hidden={hiddenPage} scrollbar={this.scrollbar} />
      );
    }

    renderHistory() {
      const hiddenPage = (this.state.currentAsset || (MarketStore.page != "notifications")) ? "hidden" : "";

      return (
        <NotificationsAssets hidden={hiddenPage} scrollbar={this.scrollbar} />
      );
    }

    renderStorefront() {
      let { currentAsset, isTradeDialog } = this.state;

      const hiddenPage = (currentAsset && isTradeDialog) || MarketStore.page != "market";

      return (
        <ViewStorefront appIds={this.props.appIds} hidden={hiddenPage}
          currentAsset={currentAsset} scrollbar={this.scrollbar}
          locale={this.props.locale} />
      );
    }

    renderMarket() {
      let { currentAsset, isTradeDialog } = this.state;
      let options = "";

      const hiddenPage = ((currentAsset && isTradeDialog) ||
        ((MarketStore.page != "marketSell") &&
          (MarketStore.page != "marketBuy") &&
          (MarketStore.page != "marketAuctions")));

      if (MarketStore.page == "marketSell")
        options = "sell";
      else if (MarketStore.page == "marketBuy")
        options = "buy";
      else if (MarketStore.page == "marketAuctions")
        options = "auction";

      return (
        <ViewItemSearch appIds={this.props.appIds} hidden={hiddenPage} scrollbar={this.scrollbar}
          currentAsset={currentAsset}
          options={options} locale={this.props.locale} />
      );
    }


    renderInventory() {
      const hiddenPage = (this.state.currentAsset || MarketStore.page != "inventory");

      return (
        <ViewUserInventory
          appIds={this.props.appIds}
          hidden={hiddenPage}
          scrollbar={this.scrollbar}
          locale={this.props.locale}
        />
      );
    }

    render() {
      return (
        <div id="appBody">
          <WalletLimitsController />
          {this.renderHeadPanel()}
          <div className="screenWrapper">
            {this.renderAppNavbar()}
            {this.renderTradeDialog()}
            {this.renderOrders()}
            {this.renderHistory()}
            {this.renderStorefront()}
            {this.renderMarket()}
            {this.renderInventory()}
          </div>
        </div>
      );
    }
  }
)

export class MarketConfigLoader extends React.Component {

  constructor(props) {
    super(props);

    const currentLocale = SettingsInjections.getUsedLocale();

    this.state = {
      appIds: null,
      locale: currentLocale
    };
  }

  loadAppIds() {
    return G_marketConfigStorage.load(
    ).catch((error) => {
      console.warn("loadAppIds", error);
    });
  }

  loadExchangeRates() {
    return ExchangeRates.load(
    ).catch((error) => {
      console.warn("loadExchangeRates", error);
    });
  }

  componentDidMount() {
    Sentry.setUser({ id: (this.props.credentials || {}).userId });

    Promise.all([
      this.loadAppIds(),
      this.loadExchangeRates()
    ]).then(() => {
      this.setState({ appIds: G_marketConfigStorage.getAppIdList() });
    }).catch((error) => {
      console.warn("MarketConfigLoader", error);
    });

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });

    ApplicationEvents.on("market.setLocale", (code) => this.setUsedLocale(code));
  }

  componentWillUnmount() {
    ApplicationEvents.off("market.setLocale", (code) => this.setUsedLocale(code));
  }

  setUsedLocale(code) {
    utils_api.setStored(["MarketApp", "LRU", "locale"], code);
    setupUsedLocale();

    const currentLocale = SettingsInjections.getUsedLocale();
    this.setState({ locale: currentLocale });
  }

  render() {
    if (!this.state.appIds)
      return null;

    return (
      <div>
        <MarketApp
          siteSettings={this.props.siteSettings}
          appIds={this.state.appIds}
          locale={this.state.locale}
        />

        <DialogHolder />
        <EventLogController
          token={(this.props.credentials || {}).token}
          devMode={this.props.devMode}
        />
      </div>
    );
  }

}
