import { DatCache } from 'api/datCache';
import { WalletGetExchange } from 'api/walletAPICall';
import ApplicationEvents from 'components/applicationEvents';
import { LogStream } from 'generics/common';

// LogStream.enable( "WALLETEXCHANGERATES" );

////////////////////////////////////////////
class ExchangeRatesCache extends DatCache  {
  constructor()  {
    super({name:"rates.2", timeoutMin:30});
  }


  hash()  {
    return "default";
  }


  fetch( key, req )  {
    return WalletGetExchange().then( (rates) => {
      rates = rates.map(
          (it)  =>  {
            if ( it && it["from"] && it["to"] )  {
              const r = (+it["rate"])||0;
              if ( r > 0 )  {
                const h = `${it["from"]}\0${it["to"]}`;
                return { [h] : r };
              }
            }
            return {};
          }
        );
      return Object.assign({}, ...rates);
    });
  }
}


const ratesCache = new ExchangeRatesCache();


class WalletExchangeRates {
  constructor()  {
    this.rates = {};
    this.logger = LogStream("WALLETEXCHANGERATES");
  }


  load() {
    return ratesCache.resolve().then( (rates) => {
      this.logger.info( "WalletExchangeRates::load -> ", rates );
      this.rates = rates;
    }).catch( ( reason ) =>  {
      reason = Object.assign( {}, reason,
                              { "message": ("Failed to load exchange rates data:\n"+reason.message) });

      ApplicationEvents.emit_async( "EventLog.append", reason );
    });
  }


  sellRate(source, destination, amount) {
    if (source == destination)
      return amount;

    const rate = this.rates[`${source}\0${destination}`] || 0;
    if ( rate > 0 )  {
      const result = amount * rate;
      this.logger.info( `WalletExchangeRates::sellRate -> (${amount} ${destination}*${rate}) = ${result} ${source}` );
      return result;
    }

    return 0;
  }


  buyRate(source, destination, amount) {
    if (source == destination)
      return amount;

    const rate = this.rates[`${source}\0${destination}`] || 0;
    if ( rate > 0 )  {
      const result = amount / rate;
      this.logger.info( `WalletExchangeRates::buyRate -> (${amount} ${destination}/${rate}) = ${result} ${source}` );
      return result;
    }

    return 0;
  }

};


const ExchangeRates = new WalletExchangeRates(); // FIXME, should be separate component
export default ExchangeRates;

