import isset from "isset";

const translations = {};
const plurals = {
    0: (num, words) => {return words[0];},
};
const parseStringRegex = /(?:{([\w]+)}(?:([\s]*)\[([^|]+(?:\|[^|}]+)*)\])?)|(?:{!([\w]+)!}\[([^|]+(?:\|[^|}]+)*)\])/gi;
let defaultLanguage = "en";

const LocalizedStrings = {
    get defaultLanguage() {return String(defaultLanguage);},
    set defaultLanguage(v) {
        if (typeof v !== 'string' || !v) {
            throw TypeError("parameter 1 expected to be a valuable string");
        }

        defaultLanguage = v;

        return String(defaultLanguage);
    },

    registerPlural(lang, plurationFn) {
        if (typeof  lang !== "string") {
            throw TypeError("parameter 1 expected to be of type string, got " + typeof lang);
        }
        if (typeof  plurationFn !== "function") {
            throw TypeError("parameter 2 expected to be of type function, got " + typeof plurationFn);
        }

        if (isset(plurals[lang]) && plurals[lang] !== plurationFn) {
            throw new Error(`plural for language '${lang}' has already been set`);
        }

        plurals[lang] = plurationFn;
    },

    getPlural(lang) {
        return plurals[lang] || plurals[0];
    },

    registerTranslations(lang, stringsTranslations) {
        if (!translations[lang]) {
            translations[lang] = {};
        }

        for (let string in stringsTranslations) {
            if (!stringsTranslations.hasOwnProperty(string)) {continue;}

            translations[lang][string] = stringsTranslations[string];
        }

        if (!Object.keys(translations[lang]).length) {
            delete translations[lang];
        }
    },

    registerTranslation(lang, string, translation) {
        if (!translations[lang]) {
            translations[lang] = {};
        }

        translations[lang][string] = translation;
    },

    getTranslation(string, language, fallbackLanguage = defaultLanguage) {
        if (translations.hasOwnProperty(language) && translations[language].hasOwnProperty(string)) {
            return translations[language][string];
        }
        else if (translations.hasOwnProperty(fallbackLanguage) && translations[fallbackLanguage].hasOwnProperty(string)) {
            return translations[fallbackLanguage][string];
        }

        return string;
    },

    stringProcessor(language, matches, data) {
        if (isset(matches[4]) && isset(matches[5])) { // if match is plural-only replacement
            const replacement = data && data.hasOwnProperty(matches[4]) ? data[matches[4]] : null;

            if (replacement === null) { return matches[0]; }

            const words = matches[5].split("|");

            return this.getPlural(language)(replacement, words);
        }

        const replacement = data && data.hasOwnProperty(matches[1]) ? data[matches[1]] : null;

        if (replacement === null) { return matches[0]; }

        if (isset(matches[2]) && isset(matches[3])) {
            const words = matches[3].split("|");

            return replacement + matches[2] + this.getPlural(language)(replacement, words);
        }

        return replacement;
    },

    processString(string, data, language, fallbackLanguage = defaultLanguage) {
        let str = this.getTranslation(string, language, fallbackLanguage);

        if (!string || typeof string !== 'string') {return string;}

        return str.replace(
                parseStringRegex,
                (...args) => this.stringProcessor(language, args, data),
        );
    },
};

export default LocalizedStrings;
