import moment from "moment";
import { createContext, useEffect, useState } from "react";

export const useWarningState = () => {
  const [warningState, setWarningState] = useState(false);

  const updateData = (value) => {
    setWarningState(value);
    setTimeout(() => setWarningState(false), 600);
  };

  return { warningState, updateData };
};

export const useCountdownTimer = (endDate) => {
  const [timeLeft, setTimeLeft] = useState(() => calculateTimeLeft(endDate));
  const [isEnded, setEnded] = useState(false);

  useEffect(() => {
    const endTime = endDate * 1000;
    const now = Date.now();

    if (endTime < now) {
      setEnded(true);
    } else {
      const intervalId = setInterval(() => {
        const currTimeLeft = calculateTimeLeft(endDate);
        setTimeLeft(currTimeLeft);

        if (currTimeLeft.ended) {
          setEnded(true);
          clearInterval(intervalId);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [endDate]);

  return { timeLeft, isEnded };
};

const calculateTimeLeft = (endDate) => {
  const currentDate = moment();
  const auctionEndDate = moment.unix(endDate);

  const duration = moment.duration(auctionEndDate.diff(currentDate));

  const ended = duration.asSeconds() <= 0;

  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes() + !ended;

  return { days, hours, minutes, ended };
}

export const NotificationContext = createContext(null);
