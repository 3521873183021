export const locale = {
  "MarketApp.Navbar.buyCoinsLink": "Купить PIX'ы",
  "AuthorizationRequiredMessage.body.h3.2": "Биржа Pixel Storm",
  "AuthorizationRequiredMessage.login": "Войти",
  "LimitedAccountPanel.2step.href": "",
  "LimitedAccountPanel.email.href": "https://store.pixstorm.ru/user.php",
  "ProfileMenu.MenuItem.GaijinAccount": "Профиль",
  "WalletBlock.balance.buyCurrency": "Купить PIX",
  "WalletLimitsIcon.soft_limit": "Превышен лимит PIX, функции пополнения недоступны",
  "WalletLimitsIcon.hard_limit": "Превышен лимит PIX, функции продажи и пополнения недоступны",
  "Legal.TermsConditions": "Правовая информация",
  "Legal.TermsConditions.linck": "https://legal.pixstorm.ru",
  "Legal.TermsService": "Условия использования сервисов",
  "Legal.TermsService.linck": "https://legal.pixstorm.ru/termsofservice",
  "Legal.PrivacyPolicy.linck": "https://legal.pixstorm.ru/privacypolicy",
  "MarketApp.Navbar.HeadTitle": "",
  "MarketApp.Navbar.HeadSubTitle": "Биржа",
  "Legal.Copyright": "Содержимое этого сайта предоставлено Gaijin Entertainment. Полная правовая информация доступна в",
  "Legal.Copyright.link": "Юридическом уведомлении",
  "Legal.Copyright.href": "https://legal.pixstorm.ru/legal-notice-trade"
};