import 'moment/min/locales';
import React, { useEffect, useState } from "react";
import { blockedPairsSource, getBlockedPairAffectAfter } from "../../api/rpcAPICall";
import { SourceListener } from "../../generics/dataBound";
import { useCountdownTimer, useWarningState } from "../../generics/marketHooks";
import { Amount, AuctionEdit, GJN, StartingPrice, Timelapse } from "../../generics/marketIcons";
import ViewAssetWarnings from "../../generics/restriction";
import { ClassInfoDescriptions, ClassInfoDropDescriptions } from "../classInfoDescriptions";
import { ClassInfoTagList, ViewAmountBadge, ViewItemInfoIconic } from "../commonAssets";
import { LimitedAccountPanel } from "../credentialsIcon";
import ItemInfoBlock from "../itemInfoBlock";
import MarketDetails from "../marketDetails";
import MoneyUtil from "../moneyUtil";
import { _I_ } from "../translate";

const AuctionAssetPage = ({
  pairKey,
  classInfo,
  appid,
  brief,
  amount,
  ctxid,
  totalAmount,
  instances
}) => {

  return (
    <div className="screenBody auctionable">
      <SourceListener blockedPairs={blockedPairsSource}>{
        ({ blockedPairs }) => {
          const blockedAfter = getBlockedPairAffectAfter(blockedPairs[pairKey]);

          return (
            <div className="commodityCard">

              <ItemInfoBlock appid={appid} classInfo={classInfo} />

              <div className='pageContainer'>
                <CommodityInfo
                  classInfo={classInfo}
                  amount={amount}
                  appid={appid}
                  blockedAfter={blockedAfter}
                  brief={brief}
                />

                <TradingInformation
                  classInfo={classInfo}
                  appid={appid}
                  brief={brief}
                  blockedAfter={blockedAfter}
                  instances={instances}
                  totalAmount={totalAmount}
                  ctxid={ctxid}
                />
              </div>
            </div>
          );
        }
      }
      </SourceListener>
    </div>
  )
}

const CommodityInfo = ({ classInfo, amount, appid, blockedAfter, brief }) => {
  const [stateAuctionEnded, setStateAuctionEnded] = useState(false);

  const auctionEndedAt = brief.auction?.end_time;
  const formattedStartPrice = MoneyUtil.formatMoney(brief?.auction?.startPrice);
  const { isEnded } = useCountdownTimer(auctionEndedAt);

  useEffect(() => {
    setStateAuctionEnded(isEnded);
  }, [isEnded]);

  return (
    <div className="commodityInfo">

      <div className='cardInfo'>
        <ViewItemInfoIconic classInfo={classInfo} large>
          <ViewAmountBadge amount={amount} showLowCounts />
        </ViewItemInfoIconic>

        <div className='descriptionInfo'>
          <ClassInfoTagList classInfo={classInfo} appId={appid} />

          <ClassInfoDescriptions
            appId={appid}
            descriptions={classInfo.descriptions}
            owner_descriptions={classInfo.owner_descriptions}
            type="text"
          />
        </div>
      </div>

      <div className='auctionInfo'>

        <div className='bidInformation'>
          <div className='title'>
            <AuctionEdit size="25" />
            {_I_('ViewItemSearch.title.marketAuction')}
          </div>

          <div className='dayCounter'>
            <CountdownTimer endDate={auctionEndedAt} />
          </div>

          <div className="summary">
            <ViewAssetWarnings
              classInfo={classInfo}
              blockedAfter={blockedAfter}
              brief={brief}
            />
          </div>

          <div className='numberLots'>
            {_I_("ViewItemDetailed.restriction.amount")}

            <div className='amountBlock'>
              <Amount />
              <span className='amount'>
                {brief.totalSellDepth}
              </span>

              <span className='amountText'>
                {_I_('ViewItemDetailed.restriction.pieces')}
              </span>
            </div>
          </div>

          <div className='startingPrice'>
            {_I_('ViewItemDetailed.restriction.startPrice')}

            <div className='startingPriceBlock'>
              <StartingPrice />
              <span className='price'>{formattedStartPrice}</span>
              <GJN />
            </div>
          </div>

        </div>

      </div>
    </div>
  )
}

const TradingInformation = ({ classInfo, appid, brief, blockedAfter, instances, totalAmount, ctxid }) => {
  const { warningState } = useWarningState();

  return (
    <div className='tradingInfo'>
      <div className="commodityContents">
        <ClassInfoDropDescriptions
          appid={appid}
          descriptions={classInfo.descriptions}
          owner_descriptions={classInfo.owner_descriptions}
          type="dropped_bundle"
          parent_hashName={classInfo.market_hash_name}
        />
      </div>

      <LimitedAccountPanel warningState={warningState} />

      <MarketDetails
        classInfo={classInfo}
        appid={appid}
        ctxid={ctxid}
        brief={brief}
        instances={instances}
        totalAmount={totalAmount}
        blockedAfter={blockedAfter}
      />
    </div>
  )
}

const CountdownTimer = ({ endDate }) => {
  const { timeLeft, isEnded } = useCountdownTimer(endDate);

  if (isEnded)
    return _I_('ViewItemSearch.title.finished');

  return (
    <React.Fragment>
      {_I_('ViewItemSearch.title.ending')}
      <div className='endingBlock'>
        <Timelapse />

        <span className='date'>
          <div>
            {timeLeft.days > 0 && `${timeLeft.days} ${_I_("ViewItemDetailed.restriction.days")} `}
            {timeLeft.hours > 0 && `${timeLeft.hours} ${_I_("ViewItemDetailed.restriction.hours")} `}
            {timeLeft.minutes > 0 && `${timeLeft.minutes} ${_I_("ViewItemDetailed.restriction.minutes")}`}
          </div>
        </span>
      </div>
    </React.Fragment>
  )
}

export default AuctionAssetPage;