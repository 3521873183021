import { detectOldInterface, detectSsoLanding, loadCookieTokenTuple, loadTokenTuple, resetToken, updateToken } from 'api/authentic';
import { AuthCheckUserAuth } from 'api/rolesAPICall';
import ApplicationEvents from 'components/applicationEvents';
import { CancelOrdersPanel } from 'components/cancelAllOrders';
import GaijinToolPanel from 'components/gaijinToolPanel';
import { DevAuthenticationDialog } from 'components/setup/devAuthenticationDialog';
import { _I_ } from 'components/translate';
import { LogStream } from 'generics/common';
import { SettingsInjections } from 'generics/settingsInjections';
import { classMixer } from 'generics/utils';
import React from 'react';
import { MarketStore } from 'stores';

LogStream.enable("LOGIN");
const loggerAuth = LogStream("LOGIN")

export class AuthenticationProcessor extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      //  Special case, while sso landing detected we should store token and reload
      ssoLanding: !!detectSsoLanding(),
      oldInterface: !!detectOldInterface(props.siteSettings?.legacyUrl),

      lastUsedToken: loadTokenTuple(),
      stealAdmToken: loadCookieTokenTuple(),

      creds: null,
      authError: false,
      showAuthDialog: false,
      tokenExpired: false,
      notAllowed: false,
      countryDenied: false,

      gsea: window.GSEA || undefined,
    };
  }

  onShowAuthDialog = () => {
    this.setState({ showAuthDialog: true });
  }

  onHideAuthDialog = () => {
    this.setState({ showAuthDialog: false });
  }

  onTokenExpired = () => {
    resetToken();
    this.setState({ tokenExpired: true })
  }

  onShowAuthorizationRequired = () => {
    this.setState({ authorizationRequired: true })
  }

  onAuthLogout = () => {
    loggerAuth.warning("MarketApp::onAuthLogout>>");

    const { gsea } = this.state;

    const onLogout = () => {
      resetToken();

      this.setState({
        creds: null,
        lastUsedToken: null,
        authorizationRequired: true
      })
    }

    if (gsea) {
      gsea.logout().then(() => onLogout());
      return;
    }

    onLogout();
  }

  onRelogin = (reason) => {
    const { siteSettings } = this.props;
    const { gsea } = this.state;

    if (gsea) {
      gsea.open();
    } else if (siteSettings.ssoUrl) {
      loggerAuth.info("MarketApp::onRelogin>>", reason);
      document.location.assign(siteSettings.ssoUrl);
    } else {
      loggerAuth.error("MarketApp::onRelogin failed, no SSO enabled");
    }
  };

  componentDidMount() {

    if (this.state.ssoLanding) {
      loggerAuth.info("SSO Landing detected, waiting for reloading");
      return;
    }

    const { gsea } = this.state;

    if (gsea != undefined)
      gsea.on('change', this.onGseaChanged);

    ApplicationEvents.on("auth.showDialog", this.onShowAuthDialog);
    ApplicationEvents.on("auth.tokenExpired", this.onTokenExpired);
    ApplicationEvents.on("auth.authorizationRequired", this.onShowAuthorizationRequired);
    ApplicationEvents.on("auth.goToRelogin", this.onRelogin);
    ApplicationEvents.on("auth.AuthLogout", this.onAuthLogout);

    this.resolveCreds();
  }

  componentWillUnmount() {
    ApplicationEvents.off("auth.showDialog", this.onShowAuthDialog);
    ApplicationEvents.off("auth.tokenExpired", this.onTokenExpired);
    ApplicationEvents.off("auth.authorizationRequired", this.onShowAuthorizationRequired);
    ApplicationEvents.off("auth.goToRelogin", this.onRelogin);
    ApplicationEvents.off("auth.AuthLogout", this.onAuthLogout);
  }

  checkToken = (tokenTuple) => {
    tokenTuple = (tokenTuple || {});

    return AuthCheckUserAuth(
      tokenTuple.token
    ).then(
      (creds) => ({ tokenTuple, creds })
    );
  }

  resolveCreds = () => {
    loggerAuth.info("Checking authorization");
    const { lastUsedToken, stealAdmToken } = this.state;
    this.checkToken(
      lastUsedToken
    ).catch(
      (err) => ((stealAdmToken) ? this.checkToken(stealAdmToken) : Promise.reject(err))
    ).then(
      this.onCredsResolved,
      this.onAuthError
    );
  }

  onGseaLogged = (token, gseaToken) => {
    updateToken(token, gseaToken);
    this.setState({ lastUsedToken: loadTokenTuple() });
    this.resolveCreds();
  }

  onGseaChanged = () => {
    const { lastUsedToken, gsea } = this.state;
    const { status, jwt } = this.state.gsea;
    const marketMode = SettingsInjections.getMode();

    if (status === 'ok' && jwt && jwt != lastUsedToken?.gseaToken) {
      if (marketMode == 'pixstorm') {
        gsea.secondarySession.fetch().then((secondarySession) => {
          if (secondarySession.jwt != lastUsedToken?.token)
            this.onGseaLogged(secondarySession.jwt, jwt);
        });
      } else if (jwt != lastUsedToken?.token) {
        this.onGseaLogged(jwt);
      }
    }
  }

  onCredsResolved = ({ creds, tokenTuple }) => {
    loggerAuth.info("Got credentials");

    if (creds.disallowedByRoles)
      loggerAuth.warning("MarketApp::onNotAllowed>> disallowedByRoles");

    if (creds.deniedTags.length > 0)
      loggerAuth.warning("MarketApp::onNotAllowed>> deniedTags");

    if (creds.disallowedByCountry)
      loggerAuth.warning("MarketApp::onCountryDenied");

    creds = { ...tokenTuple, ...creds };
    SettingsInjections.setCredentials(creds);
    MarketStore.setCredentials(creds);

    this.setState({
      creds,
      lastUsedToken: tokenTuple,
      authorizationRequired: false,
      authError: false,
      showAuthDialog: false,
      tokenExpired: false,
      notAllowed: !!(creds.disallowedByRoles || creds.deniedTags?.length > 0),
      countryDenied: !!creds.disallowedByCountry
    });
  }

  onAuthError = (err) => {
    if (!this.state.lastUsedToken) {
      loggerAuth.warning("onAuthError - showing AuthorizationRequired", err);
      return this.onShowAuthorizationRequired();
    }

    const code = (((err || {}).details || {}).response || {}).error || "Unknown";
    if ("AUTH_RESPONSE_STATUS_IS_LOGINERROR" == code) {
      loggerAuth.warning("onAuthError - showing TokenExpired", err);
      return this.onTokenExpired();
    }

    loggerAuth.warning("onAuthError - showing AuthError", err);
    this.setState({ authError: code });
  }

  renderDevDialog() {
    if (!this.props.siteSettings.devMode)
      return;

    return (
      <DevAuthenticationDialog
        lastUsedToken={(this.state.lastUsedToken || {}).token || ""}
        stealAdmToken={(this.state.stealAdmToken || {}).token || ""}
        onClose={this.onHideAuthDialog}
      />
    );
  }

  render() {
    const devDialog = this.renderDevDialog() || null;
    if (this.state.ssoLanding) {
      return <LandingMessage devDialog={devDialog} />;
    }

    if (this.state.tokenExpired) {
      return <TokenExpiredMessage devDialog={devDialog} />;
    }

    if (this.state.authorizationRequired) {
      return <AuthorizationRequiredMessage devDialog={devDialog} />;
    }

    if (this.state.notAllowed) {
      return <NotAllowedMessage devDialog={devDialog} />;
    }

    if (this.state.countryDenied) {
      return <CountryDeniedMessage devDialog={devDialog} />;
    }

    if (this.state.authError) {
      return <AuthErrorMessage reason={this.state.authError} devDialog={devDialog} />;
    }

    if (!this.state.creds) {
      return <CheckingAuthMessage devDialog={devDialog} />;
    }

    const inlineDevDialog = !!this.state.showAuthDialog && devDialog;
    return (
      <div>
        {inlineDevDialog}
        <div key="appHideHolder" className={classMixer(inlineDevDialog && "hidden")} >
          {this.props.children({
            credentials: this.state.creds,
            devMode: this.props.siteSettings.devMode,
          })}
        </div>
      </div>
    );
  }
}

function NotAllowedMessageHandleRelogin() {
  ApplicationEvents.emit_async("auth.goToRelogin", "NotAllowedMessage.relogin");
}

function TokenExpiredMessageHandleRelogin() {
  ApplicationEvents.emit_async("auth.goToRelogin", "TokenExpiredMessage.relogin");
}

function AuthorizationRequiredMessageHandleRelogin() {
  ApplicationEvents.emit_async("auth.goToRelogin", "AuthorizationRequiredMessage.relogin");
}

function AuthErrorMessageHandleRelogin() {
  ApplicationEvents.emit_async("auth.goToRelogin", "AuthErrorMessage.relogin");
}

function NotAllowedMessage({ devDialog }) {
  return (
    <GaijinToolPanel type="warning" caption={_I_("NotAllowedMessage.caption")}
      additional={devDialog} >
      <div className="signInRequest">
        <div className="title" >
          {_I_("NotAllowedMessage.body.h1")}
        </div>
        <div className="announce">
          {_I_("NotAllowedMessage.body.h3")}
        </div>
        <div className="links">
          <a className="loginLink" onClick={NotAllowedMessageHandleRelogin} block >
            <span>{_I_("NotAllowedMessage.relogin")}</span>
          </a>
        </div>
      </div>
    </GaijinToolPanel>
  );
}

function CountryDeniedMessage({ devDialog }) {
  return (
    <GaijinToolPanel type="warning" caption={_I_("CountryDeniedMessage.caption")}
      additional={devDialog} >
      <div className="signInRequest">
        <div className="title" >
          {_I_("CountryDeniedMessage.body.notAllowed")}
        </div>
        <div className="announce">
          {_I_("CountryDeniedMessage.body.unclearStatus")}
        </div>
        <CancelOrdersPanel />
      </div>
    </GaijinToolPanel>
  );
}

function LandingMessage() {
  return (
    <GaijinToolPanel type="success" caption={_I_("LandingMessage.caption")} >
      <div className="signInRequest" >
        <div className="title" >
          {_I_("LandingMessage.body")}
        </div>
      </div>
    </GaijinToolPanel>
  );
}

function TokenExpiredMessage({ devDialog }) {
  return (
    <GaijinToolPanel type="warning" caption={_I_("TokenExpiredMessage.caption")}
      additional={devDialog} >
      <div className="signInRequest">
        <div className="title" >
          {_I_("TokenExpiredMessage.body.h1")}
        </div>
        <div className="announce">
          {_I_("TokenExpiredMessage.body.h3")}
        </div>
        <div className="links">
          <a className="loginLink" onClick={TokenExpiredMessageHandleRelogin}>
            <span>{_I_("TokenExpiredMessage.relogin")}</span>
          </a>
        </div>
      </div>
    </GaijinToolPanel>
  );
}

function AuthorizationRequiredMessage({ devDialog }) {
  return (
    <GaijinToolPanel type="default" caption={_I_("AuthorizationRequiredMessage.caption")}
      additional={devDialog} >
      <div className="signInRequest">
        <div className="title" >
          {_I_("AuthorizationRequiredMessage.body.h3.1")}
        </div>
        <div className="announce">
          {_I_("AuthorizationRequiredMessage.body.h3.3")}
        </div>
        <div className="links">
          <a className="loginLink" onClick={AuthorizationRequiredMessageHandleRelogin}>
            <span>{_I_("AuthorizationRequiredMessage.login")}</span>
          </a>
        </div>
      </div>
    </GaijinToolPanel>
  );
}

function AuthErrorMessage({ reason, devDialog }) {
  return (
    <GaijinToolPanel type="warning" caption={_I_("AuthErrorMessage.caption")}
      additional={devDialog} >
      <div className="signInRequest">
        <div className="title" >
          {_I_("AuthErrorMessage.body.h1")}
        </div>
        <div className="announce">
          {_I_("AuthErrorMessage.body.code")} {reason}
          <br />{_I_("AuthErrorMessage.body.desc")}
        </div>
        <div className="links">
          <a className="loginLink" onClick={AuthErrorMessageHandleRelogin}>
            <span>{_I_("AuthErrorMessage.relogin")}</span>
          </a>
        </div>
      </div>
    </GaijinToolPanel>
  );
}

function CheckingAuthMessage() {
  return (
    <GaijinToolPanel caption={_I_("CheckingAuthMessage.caption")} >
      <div className="signInRequest" >
        <div className="title" >
          {_I_("CheckingAuthMessage.body")}
        </div>
        <div id="app">
          <div className="loader">
            <div className="spinner" />
          </div>
        </div>
      </div>
    </GaijinToolPanel>
  );
}
