import ApplicationEvents from 'components/applicationEvents';
import { spawnDialogWindow } from 'components/dialogHolder';
import { Dropdown, DropdownAction } from 'components/generics';
import { _I_ } from 'components/translate';
import { CloseButton } from 'generics/buttons';
import { utils_api } from 'generics/common';
import { getEnabledLocales } from 'generics/localeUtils';
import { SettingsInjections } from 'generics/settingsInjections';
import { classMixer } from 'generics/utils';
import $ from 'jquery';
import 'jquery.cookie';
import React from 'react';

class CheckboxPrivateMode extends React.Component {

  constructor(props) {
    super(props);

    const getPrivateMode = utils_api.getStored(["MarketApp", "privateTradingState"]);

    this.state = {
      isChecked: !(getPrivateMode === false),
    };
  }

  toggleChange = (privateMode) => {
    this.setState({
      isChecked: privateMode,
    });
    utils_api.setStored(["MarketApp", "privateTradingState"], privateMode);
  }

  render() {
    return (
      <div className="settings-row">
        <div className="label">
          {_I_("UserSettingsDialog.privacySettings")}
        </div>
        <div className="field">
          <div className="switcher-box">
            <div onClick={() => this.toggleChange(false)} className={classMixer("switcher-button", (!this.state.isChecked) && "active")}>
              {_I_("UserSettingsDialog.privacySettingsPublic")}
            </div>
            <div onClick={() => this.toggleChange(true)} className={classMixer("switcher-button", (this.state.isChecked) && "active")}>
              {_I_("UserSettingsDialog.privacySettingsPrivate")}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class CheckboxInterface extends React.Component {

  constructor(props) {
    super(props);

    const interfaceOldVersion = $.cookie('interfaceOldVersion');

    this.state = {
      isChecked: interfaceOldVersion === "true",
    };
  }

  toggleChange = (interfaceOldVersion) => {
    this.setState({
      isChecked: interfaceOldVersion,
    });

    const domainParts = window.location.hostname.split('.');
    const domain = domainParts.slice(-2).join('.');

    $.cookie('interfaceOldVersion', interfaceOldVersion, { expires: 365, path: '/', domain });
  }

  render() {
    return (
      <div className="settings-row">
        <div className="label">
          {_I_("UserSettingsDialog.interface")}
        </div>
        <div className="field">
          <div className="switcher-box">
            <div onClick={() => this.toggleChange(true)} className={classMixer("switcher-button", (this.state.isChecked) && "active")}>
              {_I_("UserSettingsDialog.interfaceOld")}
            </div>
            <div onClick={() => this.toggleChange(false)} className={classMixer("switcher-button", (!this.state.isChecked) && "active")}>
              {_I_("UserSettingsDialog.interfaceNew")}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class UserLocale extends React.Component {
  constructor(props) {
    super(props);

    const currentLocale = SettingsInjections.getUsedLocale();

    this.state = {
      localeCode: currentLocale.code
    }
  }

  setUsedLocale(localeCode) {
    this.setState({ localeCode }, () => {
      const currentLocale = SettingsInjections.getUsedLocale();
      if (currentLocale.code != localeCode)
        ApplicationEvents.emit_async("market.setLocale", localeCode);
    });
  }

  render() {
    const currentLocale = SettingsInjections.getUsedLocale();
    const currentLocaleCaption = <span className="lang-xs lang-lbl-full" lang={currentLocale.icon} />;

    const items = getEnabledLocales().map((item) =>
      <DropdownAction
        key={`lang_${item.code}`}
        onClick={() => this.setUsedLocale(item.code)}
        active={currentLocale.code == item.code}
      >
        <span className="lang-xs lang-lbl-full" lang={item.icon} />
      </DropdownAction>
    );

    return (
      <div className="settings-row">
        <div className="label">
          {_I_("UserSettingsDialog.language")}
        </div>
        <div className="field">
          <Dropdown caption={currentLocaleCaption}>
            {items}
          </Dropdown>
        </div>
      </div>
    );
  }
}

class ViewUserSettingsContainer extends React.Component {
  render() {
    const marketMode = SettingsInjections.getMode();

    return (
      <div className="appSettings">
        <div className="settingsBlock">
          {marketMode !== 'pixstorm' && <UserLocale />}
          <CheckboxPrivateMode />
          {marketMode !== 'pixstorm' && <CheckboxInterface />}
        </div>
      </div>
    );
  }
}

export default class ViewUserSettingsDialog extends React.Component {
  constructor(props) {
    super(props);

    const currentLocale = SettingsInjections.getUsedLocale();

    this.state = {
      localeCode: currentLocale.code
    }
  }

  static spawnUserSettingsModal() {
    spawnDialogWindow(ViewUserSettingsDialog, { class: "settings" });
  }

  /*onBackdropTouch() {
    this.props.removeModal();
  }*/

  render() {
    return (
      <React.Fragment>
        <div className="header">
          <div className="title">{_I_("UserSettingsDialog.settings")}</div>
          <CloseButton onClick={this.props.removeModal} />
        </div>
        <div className="body">
          <ViewUserSettingsContainer />
        </div>
      </React.Fragment>
    )
  }
}