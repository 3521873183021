import LocalizedStrings from 'components/localizedStrings';
import * as cs from "./cs";
import * as de from "./de";
import * as en from "./en";
import * as es from "./es";
import * as fr from "./fr";
import * as it from "./it";
import * as ko from "./ko";
import * as pl from "./pl";
import * as pt from "./pt";
import * as ru from "./ru";
import * as tw from "./tw";
import * as uk from "./uk";
import * as zh from "./zh";

import * as ru_pixstorm from "./ru_pixstorm";

const marketMode = process.env.REACT_APP_MARKET_MODE;

export default function registerTranslations()
{
  if (marketMode == "pixstorm")
  {
    LocalizedStrings.registerTranslations("ru", ru.locale);
    LocalizedStrings.registerTranslations("ru", ru_pixstorm.locale);
    LocalizedStrings.registerPlural("ru", ru.plural);
  }
  else
  {
    LocalizedStrings.registerTranslations("en", en.locale);
    LocalizedStrings.registerPlural("en", en.plural);

    LocalizedStrings.registerTranslations("ru", ru.locale);
    LocalizedStrings.registerPlural("ru", ru.plural);

    LocalizedStrings.registerTranslations("cs", cs.locale);
    LocalizedStrings.registerPlural("cs", cs.plural);

    LocalizedStrings.registerTranslations("fr", fr.locale);
    LocalizedStrings.registerPlural("fr", fr.plural);

    LocalizedStrings.registerTranslations("de", de.locale);
    LocalizedStrings.registerPlural("de", de.plural);

    LocalizedStrings.registerTranslations("it", it.locale);
    LocalizedStrings.registerPlural("it", it.plural);

    LocalizedStrings.registerTranslations("ko", ko.locale);
    LocalizedStrings.registerPlural("ko", ko.plural);

    LocalizedStrings.registerTranslations("pl", pl.locale);
    LocalizedStrings.registerPlural("pl", pl.plural);

    LocalizedStrings.registerTranslations("pt", pt.locale);
    LocalizedStrings.registerPlural("pt", pt.plural);

    LocalizedStrings.registerTranslations("es", es.locale);
    LocalizedStrings.registerPlural("es", es.plural);

    LocalizedStrings.registerTranslations("zh", zh.locale);
    LocalizedStrings.registerPlural("zh", zh.plural);

    LocalizedStrings.registerTranslations("tw", tw.locale);
    LocalizedStrings.registerPlural("tw", tw.plural);

    LocalizedStrings.registerTranslations("uk", uk.locale);
    LocalizedStrings.registerPlural("uk", uk.plural);
  }
}
