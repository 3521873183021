import { DatCache } from 'api/datCache';
import { GenericAPICall } from 'api/genericAPICall';
import { LogStream } from 'generics/common';
import { SettingsInjections } from 'generics/settingsInjections';
import { DataSource, mkHashMap } from 'generics/utils';

// LogStream.enable( "APICALLDEBUG" );


////////////////////////////////////////////
// class MarketRPCall
class MarketRPCall  extends GenericAPICall {
  normalizeResponse( resp )  {
    return resp.result || resp.response || resp;
  }


  getInterfaceUrl()  {
    return SettingsInjections.getUsedCircuit().market_rpc_server;
  }
};


/////////
// RPC API Call
// {
//   "action":"cln_filter_blocked_pairs",
//   "token":"***********************",
//   "list":[
//     {"appId":1067, "marketName" : "Typhoon Mk.IB (Rank 3 Great Britain)"},
//   ]
// }
function MarketFilterBlockedPairs( list )  {
  if (SettingsInjections.getUsedCircuit().blockedPairsUnsupported)  {
    const resp = list.map(
        ({appId,marketName}) => ({appId,marketName, "mode" : 0, "affectAfterSec" : 0})
      );
    LogStream( "APICALLDEBUG" ).info(`MarketFilterBlockedPairs(`,list,`) - fake response:`, resp);
    return Promise.resolve(resp);
  }

  const callFilterBlockedPairs = new MarketRPCall("cln_filter_blocked_pairs");

  callFilterBlockedPairs.setRetryPolicy( 5, 100, 500 );
  callFilterBlockedPairs.appendTransactId();
  callFilterBlockedPairs.append( "list", list );

  return callFilterBlockedPairs.RPC().then( (resp) =>  {
      LogStream( "APICALLDEBUG" ).info(`MarketFilterBlockedPairs(`,list,`) - got response:`, resp);
      return resp.list||[];
    });
}


const hashid = ({appId,marketName})  =>  `${appId}#${marketName}`


function clearParams( items )  {
  return items.map(  ({appId,marketName})  =>  ({appId,marketName})  );
}


// pool requests here
const requestAccumulator = [];

setInterval(()  =>  {
    const len = requestAccumulator.length;
    if (len == 0)
      return;
    const current = requestAccumulator.splice(0,len);

    MarketFilterBlockedPairs(
        Object.values( mkHashMap(hashid, clearParams( current )) )
      ).then(
      (resp)  =>  {
        const hashed = mkHashMap(hashid,resp);

        current.forEach( (it) => {
          const found = hashed[hashid(it)];
          if ( found )  {
            it.resolve(found);
          }  else  {
            it.reject(new Error("not found"));
          }
        });
      },
      (err)  =>  {
        current.forEach( ({reject}) => reject(err) );
        return Promise.reject(err);
      });
  },
  100);


////////////////////////////////////////////
class FilterBlockedPairsCache extends DatCache  {
  constructor()  {
    super({name:"blockedpairs", timeoutMin:5});
  }


  fetch( key, [  appId, marketName  ] )  {
    return new Promise( (resolve, reject) => requestAccumulator.push({appId,marketName,resolve,reject}) );
  }
}


export const blockedPairsResolver = new FilterBlockedPairsCache();
export const blockedPairsSource = new DataSource({});


export const blockedPairsKey = hashid;


export const getBlockedPairAffectAfter = (blockedState)  =>  {
    const { mode=0, affectAfterSec=0 }  = (blockedState||{});
    if (mode == 0)  {
      return 0;
    }

    if (affectAfterSec > 0)  {
      // return Date.parse("2018-04-12T18:15:24.384Z");
      return 1000*affectAfterSec;
    }

    return -1;
  };


export const isPairBlocked = (blockedAfter, now)  =>  (
    ( (blockedAfter != 0) && ((blockedAfter) < (now||Date.now())) )
  );


export function refreshBlockedPairs(appId, marketName)  {
  appId = +appId
  if (!appId)  {
    return Promise.reject(new Error("invalid appid"));
  }

  if (!marketName)  {
    return Promise.reject(new Error("invalid marketName"));
  }

  return blockedPairsResolver.resolve(appId, marketName).then( (blockState) => {
    blockedPairsSource.update({
      [hashid({appId,marketName})]:blockState,
    });
    return blockState;
  },
  (err) => {
    blockedPairsSource.update({
      [hashid({appId,marketName})]:null,
    });
    return Promise.reject(err);
  });

}

