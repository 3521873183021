import { DatCache } from 'api/datCache';
import { GenericAPICall } from 'api/genericAPICall';
import { LogStream, utils_api } from 'generics/common';
import { SettingsInjections } from 'generics/settingsInjections';

////////////////////////////////////////////
// class TradeAPICall
class TradeAPICall extends GenericAPICall {
  normalizeResponse(resp) {
    return resp.result || resp.response || resp;
  }


  getInterfaceUrl() {
    return SettingsInjections.getUsedCircuit().trade_server;
  }
};

////////////////////////////////////////////
//  AssetAPI request  action=cln_market_sell&token=xsaffasfa&appid=666
export function MarketSellItem(appid, contextid, assetid, amount, price, wished, agree_stamp) {
  let callSellItem = new TradeAPICall("cln_market_sell");
  const privateModeState = utils_api.getStored(["MarketApp", "privateTradingState"]);

  callSellItem.setRetryPolicy(3, 1000, 2000);
  callSellItem.appendTransactId();

  callSellItem.append("appid", appid);

  callSellItem.append("contextid", contextid);
  callSellItem.append("assetid", assetid);
  callSellItem.append("amount", amount);

  callSellItem.append("currencyid", SettingsInjections.getUsedCurrency());
  callSellItem.append("price", price);
  callSellItem.append("seller_should_get", wished);
  callSellItem.append("agree_stamp", agree_stamp);
  callSellItem.append("privateMode", !(privateModeState === false));

  return callSellItem.POST().then((resp) => {
    LogStream("APICALLDEBUG").info(`MarketSellItem(appid:${appid}, (ctx:${contextid}/asset:${assetid} * amount:${amount}) <=> (price:${price}%wished:${wished})) - got response:`, resp);
    return resp;
  });
}


//  AssetAPI request  action=cln_market_buy&token=xsaffasfa&appid=666
export function MarketBuyItem(appid, market_name, amount, price, agree_stamp) {
  let callBuyItem = new TradeAPICall("cln_market_buy");
  const privateModeState = utils_api.getStored(["MarketApp", "privateTradingState"]);

  callBuyItem.setRetryPolicy(3, 1000, 2000);
  callBuyItem.appendTransactId();
  callBuyItem.append("appid", appid);
  callBuyItem.append("market_name", market_name);
  callBuyItem.append("amount", amount);
  callBuyItem.append("currencyid", SettingsInjections.getUsedCurrency());
  callBuyItem.append("price", price);
  callBuyItem.append("agree_stamp", agree_stamp);
  callBuyItem.append("privateMode", !(privateModeState === false));

  return callBuyItem.POST().then((resp) => {
    LogStream("APICALLDEBUG").info(`MarketBuyItem(${appid}:${market_name}, ${amount} * ${price}) - got response:`, resp);
    return resp;
  });
}


//  AssetAPI request  action=cln_market_buy_exact&token=xsaffasfa&appid=666
export function MarketBuyExactItem(appid, exactOrderId, market_name, amount, price, agree_stamp) {
  let callBuyItem = new TradeAPICall("cln_market_buy_exact");
  const privateModeState = utils_api.getStored(["MarketApp", "privateTradingExactState"]);

  callBuyItem.setRetryPolicy(3, 1000, 2000);
  callBuyItem.appendTransactId();
  callBuyItem.append("appid", appid);
  callBuyItem.append("exactOrderId", exactOrderId);
  callBuyItem.append("market_name", market_name);
  callBuyItem.append("amount", amount);
  callBuyItem.append("currencyid", SettingsInjections.getUsedCurrency());
  callBuyItem.append("price", price);
  callBuyItem.append("agree_stamp", agree_stamp);
  callBuyItem.append("privateMode", !(privateModeState === false));

  return callBuyItem.POST().then((resp) => {
    LogStream("APICALLDEBUG").info(`MarketBuyExactItem(${appid}:${market_name}, ${amount} * ${price}) - got response:`, resp);
    return resp;
  });
}


//  AssetAPI request  action=cln_books&token=xsaffasfa&appid=666
// market_name
export function MarketBooks(appid, hashname, count = 10, skip = 0) {
  let callBooks = new TradeAPICall("cln_books");
  callBooks.setRetryPolicy(3, 500, 1500);
  callBooks.append("appid", appid);
  callBooks.append("market_name", hashname);
  callBooks.append("count", count);
  callBooks.append("skip", skip);

  return callBooks.POST().then((resp) => {
    LogStream("APICALLDEBUG").info(`MarketBooks(${appid}:${hashname}) - got response:`, resp);
    return resp;
  });
}


//  AssetAPI request  action=cln_books_brief&token=xsaffasfa&appid=666
// market_name
export function MarketBooksBrief(appid, hashname) {
  let callBriefBooks = new TradeAPICall("cln_books_brief");
  callBriefBooks.setRetryPolicy(3, 500, 1500);
  callBriefBooks.append("appid", appid);
  callBriefBooks.append("market_name", hashname);

  return callBriefBooks.POST().then((resp) => {
    LogStream("APICALLDEBUG").info(`MarketBooksBrief(${appid}:${hashname}) - got response:`, resp);
    return resp;
  });
}


//  AssetAPI request  action=cln_get_pair_stat&token=xsaffasfa&appid=666
// market_name
// appid
export function MarketGetPairStat(appid, hashname) {
  let callGetPairStat = new TradeAPICall("cln_get_pair_stat");
  callGetPairStat.setRetryPolicy(3, 500, 1500);
  callGetPairStat.append("appid", appid);
  callGetPairStat.append("market_name", hashname);
  callGetPairStat.append("currencyid", SettingsInjections.getUsedCurrency());

  return callGetPairStat.POST().then((resp) => {
    LogStream("APICALLDEBUG").info(`MarketGetPairStat(${appid}:${hashname}) - got response:`, resp);
    return resp;
  });
}


//  AssetAPI request  action=cln_market_get_asset_class&token=xsaffasfa
// appid=666
// name=Goose

//  "result"."asset_class".{}
function MarketGetClassByHashname(appid, hashname) {
  let callGetAssetClass = new TradeAPICall("cln_market_get_asset_class");

  callGetAssetClass.setRetryPolicy(5, 100, 500);
  callGetAssetClass.append("appid", appid);
  callGetAssetClass.append("name", hashname);

  return callGetAssetClass.POST().then((resp) => {
    LogStream("APICALLDEBUG").info(`MarketGetClassByHashname(${appid}:${hashname}) - got response:`, resp);
    return resp.asset_class;
  });
}

// appid, contextid, assetid, amount, price, wished, agree_stamp
export function MarketMakeAuction(appid, contextid, assetid, amount, price, wished, agree_stamp, end_time, price_step) {
  let callMakeAuction = new TradeAPICall("cln_make_auction");
  const privateModeState = utils_api.getStored(["MarketApp", "privateTradingState"]);

  callMakeAuction.setRetryPolicy(3, 1000, 2000);
  callMakeAuction.appendTransactId();

  callMakeAuction.append("appid", appid);
  callMakeAuction.append("contextid", contextid);
  callMakeAuction.append("assetid", assetid);
  callMakeAuction.append("amount", amount);
  callMakeAuction.append("currencyid", SettingsInjections.getUsedCurrency());
  callMakeAuction.append("price", price);
  callMakeAuction.append("seller_should_get", wished);
  callMakeAuction.append("agree_stamp", agree_stamp);
  callMakeAuction.append("privateMode", !(privateModeState === false));

  callMakeAuction.append("end_time", end_time);
  callMakeAuction.append("price_step", price_step);

  return callMakeAuction.POST().then((resp) => {
    LogStream("APICALLDEBUG").info(`MarketSellItem(appid:${appid}, (ctx:${contextid}/asset:${assetid} * amount:${amount}) <=> (price_step:${price_step}%end_time:${end_time})) - got response:`, resp);
    return resp;
  });
}


////////////////////////////////////////////
class ClassByHashnameCache extends DatCache {
  constructor() {
    super({ name: "classhash", timeoutMin: 30 });
  }


  fetch(key, [appid, hashname]) {
    return MarketGetClassByHashname(appid, hashname);
  }
}


export const ClassByHashnameResolver = new ClassByHashnameCache();
