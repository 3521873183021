import G_marketConfigStorage from 'components/globalMarketConfigStorage';
import MoneyUtil from 'components/moneyUtil';
import { _I_ } from 'components/translate';
import { IconGlyphicon } from 'generics/icons';
import { SettingsInjections } from 'generics/settingsInjections';
import { classMixer } from 'generics/utils';
import React from 'react';
import { MarketStore } from 'stores';
import { ViewAmountInput, ViewCheckbox } from "./commonAssets";

export default class ViewItemSellEditor extends React.Component {
  constructor(props) {
    super(props);

    const sellPriceMin = G_marketConfigStorage.getPriceMin();
    const sellPriceMax = G_marketConfigStorage.getPriceMax();

    const userCurrStr = MoneyUtil.formatCurr(SettingsInjections.getUsedCurrency());
    const initialPrice = MoneyUtil.parseMoney(this.props.initialPrice);
    const price = Math.max(sellPriceMin, initialPrice || 10000);

    const priceText = MoneyUtil.formatMoney(price);
    this.currentLocale = SettingsInjections.getUsedLocale();

    // Auction form
    const { minEndDate: endDateNow } = G_marketConfigStorage.getAuctionParams();

    this.state = {
      agree: false,
      sellPriceMin,
      sellPriceMax,
      price,
      priceText,
      amountText: "1",
      sellAmount: 1,
      totalAmount: 0,
      instances: [],
      userCurrStr,
      // Auction form
      endDate: endDateNow,
      priceStep: 1000,
      priceStepText: "0.10"
    }

    this.onAgreeClick = () => { this.setState({ "agree": (this.state.agree ? false : Date.now()) }); };
    this.onPriceChange = (event) => this.processPriceChange(event.target.value, false);
    this.onPriceWheel = (event) => this.handlePriceWheel(event, false);
    this.onBlurPrice = (event) => this.handleBlurPrice(event, false);

    // Auction form
    this.onPriceStepChange = (event) => this.processPriceChange(event.target.value, true);
    this.onPriceStepWheel = (event) => this.handlePriceWheel(event, true);
    this.onBlurPriceStep = (event) => this.handleBlurPrice(event, true);
    this.onDateChange = (event) => this.processDateChange(event.target.value);
  }


  handleBlurPrice(e, isAuction) {
    const priceText = isAuction ? this.state.priceStepText : this.state.priceText;
    const fmtPriceText = MoneyUtil.formatMoney(isAuction ? this.state.priceStep : this.state.price);

    if (priceText != fmtPriceText) {
      const newValue = isAuction ? { priceStepText: fmtPriceText } : { priceText: fmtPriceText };
      this.setState(newValue);
    }
  }

  handleAmountBlur = (e) => {
    const amountText = `${this.state.sellAmount}`
    if (amountText == this.state.amountText) {
      return;
    }

    this.setState({ amountText });
  }


  handleAmountWheel = (e) => {
    const delta = Math.sign(e.deltaY) * (e.altKey ? 10 : 1);
    const newValue = this.state.sellAmount - delta;

    this.processAmountChange((newValue > 0) ? newValue : 0);
  }

  handleAmountChange = (event) => {
    this.processAmountChange(event.target.value);
  }

  handleFieldSelect = (e) => {
    e.target.select();
  };

  handlePriceWheel(e, isAuction) {
    const delta = 10000 * Math.sign(e.deltaY) * (e.altKey ? 10 : (e.shiftKey ? 0.1 : 1));
    const currentValue = isAuction ? this.state.priceStep : this.state.price;
    const newValue = MoneyUtil.formatMoney(currentValue - delta);

    if (+newValue >= 0)
      this.processPriceChange(newValue, isAuction);
  }

  processAmountChange(val) {
    let [, amountText] = (/^\s*([0-9]+)\s*$/).exec(val) || [];
    if (!amountText) {
      amountText = "";
    }

    if (amountText == this.state.amountText) {
      return;
    }

    let sellAmount = +amountText;
    if (sellAmount > this.props.totalAmount)
      return;

    if (sellAmount > 0 || !amountText) {
      this.setState({ sellAmount, amountText });
    }
  }


  processPriceChange(val, isAuction) {
    const [, hi, dot, lo] = ((/^\s*(\d*)(?:(\.)(\d*))?\s*$/).exec(val) || []);
    if ((!hi) && (!lo))
      return;

    const priceText = `${hi || ""}${(dot && ".") || ""}${lo || ""}`;
    const priceTextState = isAuction ? this.state.priceStepText : this.state.priceText;

    if (priceTextState === priceText)
      return;

    const price = MoneyUtil.parseMoney(priceText);

    const newState = isAuction ?
      { priceStep: price, priceStepText: priceText, agree: false } :
      { price, priceText, agree: false };

    if (isFinite(price) && price >= 0)
      this.setState(newState);
  }

  processDateChange(val) {
    this.setState({ endDate: val });
  }

  getFeeRatio() {
    const EXCHANGE_FEE = G_marketConfigStorage.getMarketFee();
    const marketFee = this.props.marketFee || 0;
    return (1 - EXCHANGE_FEE - marketFee)
  }


  getResult() {
    const { sellAmount, sellPriceMin, sellPriceMax, userCurrStr, agree } = this.state;
    const market = SettingsInjections.getUsedCurrency();
    const { isWealthyUser } = MarketStore.credentials;
    const notes = [];

    let isValid = true;
    let amount = Math.floor(sellAmount);

    if (isFinite(amount)) {
      if (amount < 1) {
        isValid = false;
        amount = NaN;
        notes.push(_I_("ItemBuyEditor.note.posAmount"));
      } else if (amount > 1000) {
        isValid = false;
        amount = NaN;
        notes.push(_I_("ItemBuyEditor.note.maxAmount"));
      }
    } else {
      isValid = false;
      amount = NaN;
      notes.push(_I_("ItemBuyEditor.note.invalidAmount"));
    }

    let price = Math.floor(this.state.price);
    let wished = Math.ceil(price * this.getFeeRatio());
    wished = Math.floor(wished / 100) * 100; // rounding to cents

    let total = (amount * price);
    let totalWished = (amount * wished);

    // Auction form
    let priceStep = Math.floor(this.state.priceStep / 100) * 100;

    let endDate = Date.parse(this.state.endDate);
    let nowDate = Date.now();

    if (isFinite(price) && isFinite(priceStep)) {
      if (price < sellPriceMin || priceStep < 0.01) {
        isValid = false;
        price = NaN;
        priceStep = NaN;
        notes.push(`${_I_("ItemSellEditor.note.minPrice")} ${MoneyUtil.formatMoney(sellPriceMin)} ${userCurrStr}`);
      } else {
        if (total > sellPriceMax || priceStep > sellPriceMax) {
          isValid = false;
          price = NaN;
          priceStep = NaN;
          notes.push(`${_I_("ItemSellEditor.note.maxTotals")} ${MoneyUtil.formatMoney(sellPriceMax)} ${userCurrStr}`);
        }

        if (parseFloat(this.state.priceStepText) > parseFloat(this.state.priceText)) {
          isValid = false;
          price = NaN;
          priceStep = NaN;
          notes.push(`${_I_("ItemSellEditor.note.maxTotals")} ${this.state.priceText}`);
        }

        const marketBalance = (this.props.balance[market] || 0);
        const { soft_limit, hard_limit, op_limit } = this.props.wlimits;

        if (op_limit && totalWished > (op_limit * 10000)) {
          isValid = false;
          price = NaN;
          notes.push(`${_I_("ItemSellEditor.note.maxPrice")} ${MoneyUtil.formatMoney(op_limit * 10000, market)}`);
        }

        const lim = Math.min(soft_limit || Infinity, hard_limit || Infinity);
        if (!isWealthyUser && lim && isFinite(lim) && (marketBalance + totalWished) > (lim * 10000)) {
          isValid = false;
          price = NaN;
          notes.push(`${_I_("ItemSellEditor.note.maxBalance")} ${MoneyUtil.formatMoney(lim * 10000, market)}`);
        }
      }
    } else {
      isValid = false;
      price = NaN;
      notes.push(_I_("ItemBuyEditor.note.invalidPrice"));
    }

    if (!endDate || endDate < nowDate) {
      isValid = false;
      notes.push(`Invalid end date`)
    }

    if (notes.length != 0)
      isValid = false;

    if (!isValid) {
      totalWished = NaN;
    }

    return { isValid, amount: sellAmount, price, wished, totalWished, agree, notes, priceStep, endDate };
  }

  renderSellButton(isValid, totalWished) {
    const { userCurrStr } = this.state;
    const disabled = !(isValid && this.state.agree);

    return (
      <div className={classMixer("button sell baloonOverride baloon baloonTop baloonCentered", disabled && "disabled")}
        onClick={this.props.processSellOrder}>
        {_I_("ItemSellEditor.label.submit")} {isValid && (
          <React.Fragment>
            {_I_("MarketDepthTotals.starting.sell")} {MoneyUtil.formatMoney(totalWished)} {userCurrStr}
          </React.Fragment>
        )}
      </div>
    );
  }

  render() {
    const { isValid, totalWished, notes } = this.getResult();
    const { priceText, amountText, agree, sellAmount, priceStepText, endDate } = this.state;
    const { totalAmount, auctionable } = this.props;

    const legalHost = SettingsInjections.getLegalHost();

    const market = SettingsInjections.getUsedCurrency();
    const { sellOrdersTotal, buyOrdersTotal } = this.props.orders;

    let marketBalance = (this.props.balance[market] || 0);
    // round balances down to nearest cent
    marketBalance = Math.floor((+marketBalance || 0) / 100) * 100;
    let termsUrl = `${legalHost}/${this.currentLocale.icon}/termsofservice`;

    const { minEndDate, maxEndDate } = G_marketConfigStorage.getAuctionParams();

    const danger = isValid ? null : (
      <div className="notes-row">
        {notes.map(
          (text, key) => <div key={key}><IconGlyphicon type="exclamation-sign" />&nbsp;{text}</div>
        )}
      </div>
    )

    return (
      <React.Fragment>
        <div className="editor-row">
          <React.Fragment>
            {_I_("ItemSellEditor.currentBalance.before")} {MoneyUtil.formatMoney(marketBalance, market)} {_I_("ItemSellEditor.currentBalance.after")}
          </React.Fragment>

          {(sellOrdersTotal > 0) &&
            <React.Fragment>
              <br />{_I_("ItemSellEditor.sellOrdersTotal.before")} {MoneyUtil.formatMoney(sellOrdersTotal, market)} {_I_("ItemSellEditor.sellOrdersTotal.after")}
            </React.Fragment>
          }

          {(buyOrdersTotal > 0) &&
            <React.Fragment>
              <br />{_I_("ItemSellEditor.buyOrdersTotal.before")} {MoneyUtil.formatMoney(buyOrdersTotal, market)} {_I_("ItemSellEditor.buyOrdersTotal.after")}
            </React.Fragment>
          }
        </div>

        <div className="editor-block">
          <div className="editor-row">
            <span className="label-column">{_I_("ItemSellEditor.label.price")}</span>
            <span className="icon-column"></span>
            <span className="input-column baloonOverride baloon baloonTop baloonCentered" onWheel={this.onPriceWheel}
              data-baloon={_I_("ItemSellEditor.label.wheelInput")}  >
              <input className="form-control input-sm" type="text"
                value={priceText}
                onFocus={this.handleFieldSelect}
                onBlur={this.onBlurPrice}
                onChange={this.onPriceChange} />
            </span>
          </div>

          <div className="editor-row">
            <span className="label-column">{_I_("ItemSellEditor.label.amount")}</span>
            <span className="icon-column"></span>
            <span className="input-column baloonOverride baloon baloonTop baloonCentered" onWheel={this.handleAmountWheel}
              data-baloon={_I_("ItemBuyEditor.label.wheelAmountInput")} >

              <ViewAmountInput
                amount={amountText}
                buyAmount={sellAmount}
                onBlur={(e) => this.handleAmountBlur(e)}
                onChange={(e) => this.handleAmountChange(e)}
                canBeLess={sellAmount > 1}
                canBeMore={sellAmount < totalAmount}
                onAmountChange={(v) => this.processAmountChange(v)}
              />
            </span>
          </div>

          <div className="editor-row">
            <span className="label-column">{_I_("ItemSellEditor.label.wished")}</span>
            <span className="icon-column"></span>
            <span className="input-column text-center">
              <input type="text" className="form-control input-sm" readOnly
                value={MoneyUtil.formatMoney(totalWished)} />
            </span>
          </div>

          {auctionable &&
            <React.Fragment>
              <div className="editor-row">
                <span className="label-column">{_I_("ItemSellEditor.label.priceStep")}</span>
                <span className="icon-column"></span>
                <span className="input-column"
                  onWheel={this.onPriceStepWheel}>
                  <input className="form-control input-sm" type="text"
                    value={priceStepText}
                    onBlur={this.onBlurPriceStep}
                    onChange={this.onPriceStepChange}
                  />
                </span>
              </div>

              <div className="editor-row">
                <span className="label-column">{_I_("ItemSellEditor.label.endDate")}</span>
                <span className="icon-column"></span>
                <span className="input-column text-center">
                  <input className="form-control input-sm"
                    type="date"
                    value={endDate}
                    onChange={this.onDateChange}
                    min={minEndDate}
                    max={maxEndDate}
                    />
                </span>
              </div>
            </React.Fragment>
          }
        </div>

        {danger}

        <div className="editor-row">
          <div className="agree-checkbox" onClick={this.onAgreeClick}>
            <ViewCheckbox checked={!!agree}>
              {_I_("ItemSellEditor.label.agree")}&nbsp;
              <a href={termsUrl} className="agree-link" target="_blank" rel="noopener noreferrer">
                {_I_("ItemBuyEditor.label.ToU")}
              </a>
            </ViewCheckbox>
          </div>
        </div>

        <div className="editor-row">
          {this.renderSellButton(isValid, totalWished)}
        </div>
      </React.Fragment>
    );
  }
}
