import { DataSource } from 'generics/utils';


export const staticConfigs = new DataSource({
  siteSettings: null,
  circuits: null,
  usedCircuit: null,
  usedCircuitName: null,
});


