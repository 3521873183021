import { ItemClassInfoResolver } from 'api/assetAPICall';
import { MarketCancelOrder } from 'api/marketAPICall';
import { MarketOrders, MarketTradeHistory } from 'api/ordersAPICall';
import { blockedPairsKey, blockedPairsSource, getBlockedPairAffectAfter, isPairBlocked, refreshBlockedPairs } from 'api/rpcAPICall';
import { ClassByHashnameResolver } from 'api/tradeAPICall';
import ApplicationEvents from 'components/applicationEvents';
import { fakeClassInfo, isExpired } from 'components/classInfoUtil';
import { ViewItemIconic } from 'components/commonAssets';
import { spawnDialogWindow } from 'components/dialogHolder';
import G_marketConfigStorage from 'components/globalMarketConfigStorage';
import MoneyUtil from 'components/moneyUtil';
import { _I_ } from 'components/translate';
import { TrivialDialog } from 'components/trivialDialog';
import { CloseButton } from 'generics/buttons';
import { LogStream } from 'generics/common';
import { SourceListener } from 'generics/dataBound';
import { IconFontAwesome } from 'generics/icons';
import { SettingsInjections } from 'generics/settingsInjections';
import { DataSource, PromiseDelay, classMixer, decoratorRateLimit } from 'generics/utils';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Scrollbar from "react-scrollbars-custom";
import { MarketStore, OrdersStore } from 'stores';
import ViewLegalFooter from "./legalFooter";

// LogStream.enable( "VIEWORDERSLIST" );
LogStream.enable("VIEWORDERSLISTERROR");

const loggerViewOrdersList = LogStream("VIEWORDERSLIST");
const loggerErrors = LogStream("VIEWORDERSLISTERROR");

const ORDERS_LOAD_DELAY_MS = 300;
const HISTORY_LOAD_DELAY_MS = 400;

const ordersByTime = (l, r) => (r.time - l.time);
const historyByTime = (l, r) => (r.doneTime && l.doneTime) ? (r.doneTime - l.doneTime) : (r.time - l.time);

export const ordersSummary = new DataSource({
  buyOrders: [],
  buyOrdersTotal: 0,
  sellOrders: [],
  sellOrdersTotal: 0
});

export const ordersHistory = new DataSource({
  list: [],
  stage: "dirty",
});

const reloadOrdersDelayed = decoratorRateLimit(
  ORDERS_LOAD_DELAY_MS
)(() => {
  return MarketOrders(

  ).catch((reason) => {
    reason = Object.assign({}, reason, { "caption": "Failed to load own orders data:" });
    ApplicationEvents.emit_async("EventLog.append", reason);
    return [];

  }).then((resp) => {
    loggerViewOrdersList.info("reloadOrdersDelayed -> ", resp);
    OrdersStore.setOrders(resp || []);
    setOrders(resp || []);
  });
});

function reducerOrdersTotal(accum, order) {
  const { normalPrice, localPrice, currency, amount } = order;
  const price = MoneyUtil.preferMoney(normalPrice, localPrice, currency, currency);
  return accum + (price * (amount || 0))
}

function setOrders(orders) {
  const buyOrders = orders.filter(it => (it.type == 'BUY')).sort(ordersByTime);
  const buyOrdersTotal = buyOrders.reduce(reducerOrdersTotal, 0);

  const sellOrders = orders.filter(it => (it.type == 'SELL')).sort(ordersByTime);
  const sellOrdersTotal = sellOrders.reduce(reducerOrdersTotal, 0);

  ordersSummary.update({
    buyOrders,
    buyOrdersTotal,
    sellOrders,
    sellOrdersTotal
  });
}

function onTickerEvent(args, eventParams, details) {
  const topic = details.decodedTopic;
  const ownerUserId = `${MarketStore.userId}`;

  if (!topic)
    return;

  if ((topic.eventType != "book") && (topic.eventType != "deal"))
    return;

  if ((topic.buyerUserId != ownerUserId) && (topic.sellerUserId != ownerUserId))
    return;

  loggerViewOrdersList.info(`onTickerEvent update due to ${details.topic}`);
  ApplicationEvents.emit_async("market.ordersUpdate");
}

ApplicationEvents.on("market.ordersUpdate", reloadOrdersDelayed);
ApplicationEvents.on("market.notify.received", onTickerEvent);

function setHistory(history, stage) {
  const changed = { stage };
  if (history)
    changed.list = history.sort(historyByTime)
  ordersHistory.update(changed);
}

function reloadHistory() {
  setHistory(null, "pending");
  return MarketTradeHistory().then((resp) => {
    loggerViewOrdersList.info("loadOrdersHistory -> ", resp);
    setHistory([...resp], "done");

  }).catch((reason) => {
    reason = Object.assign({}, reason, { "caption": "Failed to load orders history data:", });
    ApplicationEvents.emit_async("EventLog.append", reason);
    setHistory([], "fail");
  });
}

function spawnCancelOrderModal(order) {
  spawnDialogWindow(ViewCancelOrderItemDialog, {
    order
  });
}

function shortHistory(history) {
  let sellClosedOrders = [];
  let buyClosedOrders = [];

  if (history.list.length) {
    sellClosedOrders = history.list.filter((order) => {
      if ((order.type == 'SELL')) {
        order.CLOSED = true;
        return true;
      }

      return false;
    });

    buyClosedOrders = history.list.filter((order) => {
      if ((order.type == 'BUY')) {
        order.CLOSED = true;
        return true;
      }
      return false;
    });
  }

  return { sellClosedOrders, buyClosedOrders };
}


function viewOrderItem(order) {
  ApplicationEvents.emit_async("market.setCurrentAssetByHash", order.appId, order.market);
}

export class ViewChangeDisplayedOrdersContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ordersState: "OPENED"
    };
  }

  componentDidMount() {
    const { stage } = ordersSummary.get();
    if ((stage == "dirty") || (stage == "fail")) {
      reloadHistory();
    }
  }

  changeDisplayedOrdersState = (ordersState) => {
    if (!["OPENED", "CLOSED"].includes(ordersState) || ordersState === this.state.ordersState) {
      return;
    }
    if (ordersState == "CLOSED") {
      const { stage } = ordersHistory.get();
      if ((stage == "dirty") || (stage == "fail")) {
        reloadHistory();
      }
    }
    this.setState({ ordersState });
    this.props.setHistoryType(ordersState);
  }

  render() {
    const { ordersState } = this.state;
    return (
      <div className="stateSwitch">
        <div className={classMixer('switchItem', (ordersState === "OPENED") && "active")}
          onClick={() => {
            this.changeDisplayedOrdersState("OPENED");
          }}>
          {_I_(`OrdersListContainer.Opened.Orders`)}
        </div>
        <div className={classMixer('switchItem', (ordersState === "CLOSED") && "active")}
          onClick={() => {
            this.changeDisplayedOrdersState("CLOSED");
          }}>
          {_I_(`OrdersListContainer.Closed.Orders`)}
        </div>
      </div>
    );
  }

}

export const ViewOrdersListContainer = observer(
  class ViewOrdersListContainer extends React.Component {
    constructor(props) {
      super(props);

      const market = SettingsInjections.getUsedCurrency();

      this.state = {
        market,
        activeTab: "sellOrders",
        expandedBlock: null,
        stateOrders: "BUY"
      };
    }

    componentDidMount() {
      ApplicationEvents.on("market.ordersUpdate", this.handleUpdate); // mark history dirty
      reloadOrdersDelayed();
      reloadHistory();

      reaction(
        () => [ MarketStore.credentials ],
        () => {
          reloadOrdersDelayed();
          this.handleUpdate();
        }
      )
      reaction(
        () => [ OrdersStore.orders ],
        () => {
          this.handleUpdate();
          this.setCurrentAuction()
        }
      )
    }

    componentWillUnmount() {
      ApplicationEvents.off("market.ordersUpdate", this.handleUpdate);
    }

    setCurrentAuction() {
      OrdersStore.orders.forEach(order => {
        if (order.auction && order.type == 'BUY')
          ApplicationEvents.emit_async("market.setCurrentAuctionByHash", order.appId, order.market);
      })
    }

    handleUpdate = () => {
      setHistory(null, "dirty");

      const { stateOrders } = this.state;

      if (stateOrders == "SELL")
        PromiseDelay(HISTORY_LOAD_DELAY_MS).then(reloadHistory);
    }

    changeDisplayedOrdersState = (stateOrders) => {
      if (!["BUY", "SELL"].includes(stateOrders) || stateOrders === this.state.stateOrders) {
        return;
      }

      const { stage } = ordersHistory.get();
      if (stage == "dirty" || stage == "fail")
        reloadHistory();

      this.setState({ stateOrders });
    }

    render() {
      const { activeTab } = this.state;
      const { credentials: creds } = MarketStore;

      const limitedAccount = (creds.need2step && !creds.have2step) ||
        (creds.needEmailVerification && !creds.haveEmailVerification);


      return (
        <SourceListener orders={ordersSummary} history={ordersHistory}>
          {({ orders, history }) => {
            const { sellOrders, buyOrders } = orders;
            const { sellClosedOrders, buyClosedOrders } = shortHistory(history);

            let sellCombinedOrders = [...sellOrders, ...sellClosedOrders];
            let buyCombinedOrders = [...buyOrders, ...buyClosedOrders];

            return (
              <React.Fragment>
                <Scrollbar
                  ref={this.props.scrollbar}
                  id="content"
                  style={{ display: this.props.hidden ? "none" : "" }}
                  defaultStyles={false}
                  scrollX={false}>
                  <div className="appScreen" screen="wallet">
                    <div className="screenBody">
                      <div className="ordersHistory">
                        <ViewOrdersListTab
                          orderType="buy"
                          orders={(this.state.stateOrders === "BUY") ? buyCombinedOrders : sellCombinedOrders}
                          active={activeTab === "buyOrders"}
                          title={<div className="title"></div>}
                          limitedAccount={limitedAccount}
                          scrollbar={this.props.scrollbar}
                          hidden={this.props.hidden}
                          onStateChange={this.changeDisplayedOrdersState}
                          state={this.state.stateOrders}
                        />
                      </div>
                    </div>
                    <ViewLegalFooter />
                  </div>
                </Scrollbar>
              </React.Fragment>
            );
          }}
        </SourceListener>
      );
    }
  }
)

function OrderEntryBasicInfo({ order, blockedAfter }) {
  const orderStamp = new Date(order.time * 1000).toLocaleString();
  const orderDoneStamp = !!order.doneTime && new Date(order.doneTime * 1000).toLocaleString();
  const haveBlockSchedule = blockedAfter != 0;
  const pairIsBlocked = isPairBlocked(blockedAfter);
  return (
    <>
      <div className="id">
        #{order.id}{!!order.mid && `/${order.mid}`}
      </div>
      <div className="date">{_I_("ViewOrdersEntry.order.issued")} {orderStamp}</div>
      {!!orderDoneStamp &&
        <div className="date">{_I_("ViewOrdersEntry.order.executed")} {orderDoneStamp}</div>
      }
      {haveBlockSchedule && (
        pairIsBlocked ?
          (<div className="entry-blocked">{_I_(`ViewOrdersEntry.order.pairIsBlocked`)}</div>) :
          (<div className="entry-blocked">
            {_I_(`ViewOrdersEntry.order.pairWillBlockAfter`)} {new Date(blockedAfter).toLocaleString()}
          </div>)
      )}
    </>
  );
}

function OrderEntryNotes({ order }) {
  const { currency, amount, doneAmount } = order;
  const price = MoneyUtil.preferMoney(order.normalPrice, order.localPrice, currency, currency);
  const orderPrice = MoneyUtil.formatMoney(price, currency);
  const orderSum = MoneyUtil.formatMoney(price * amount, currency);
  const orderDoneSum = MoneyUtil.formatMoney(price * doneAmount, currency);

  return (
    <>
      {(!doneAmount && amount > 0) &&
        <div key="amount" className="price">
          <div className="amount">{amount}</div>
          <div className="itemPrice">{orderPrice}</div>
          <div className="totalPrice">{orderSum}</div>
        </div>
      }
      {(doneAmount > 0) &&
        <div key="amount" className="price">
          <div className="amount">{doneAmount}</div>
          <div className="itemPrice">{orderPrice}</div>
          <div className="totalPrice">{orderDoneSum}</div>
        </div>
      }
    </>
  );
}

function isCanView(classInfo, blockedAfter) {
  const expired = isExpired(classInfo);
  const pairIsBlocked = isPairBlocked(blockedAfter);
  const canView = (!expired && (classInfo.marketable || classInfo.auctionable) && !pairIsBlocked);

  return canView;
}

function OrderEntryIcon({ order, classInfo, blockedAfter }) {
  const canView = isCanView(classInfo, blockedAfter);
  const handleView = canView ? () => viewOrderItem(order) : undefined;

  return (<ViewItemIconic classInfo={classInfo} onClick={handleView} />);
}

function OrderEntryActions({ order, classInfo, limitedAccount, blockedAfter, state }) {
  const expired = isExpired(classInfo);
  const pairIsBlocked = isPairBlocked(blockedAfter);
  const canView = isCanView(classInfo, blockedAfter);
  const handleView = canView ? () => viewOrderItem(order) : undefined;

  const canCancel = (
    !expired && !pairIsBlocked && !limitedAccount &&
    !order.CLOSED && !classInfo.auctionable &&
    (classInfo.marketable || classInfo.IS_UNRESOLVED)
  );
  const handleCancel = canCancel ? () => spawnCancelOrderModal(order) : undefined;

  return (
    <div className="controls">
      {(!!handleView) &&
        <div className="button cancel" onClick={handleView}>
          {_I_("ViewOrdersEntry.order.view")}
        </div>
      }
      {(!!handleCancel) &&
        <div className="button cancel" onClick={handleCancel}>
          {_I_("ViewOrdersEntry.order.cancel")}
        </div>
      }
    </div>
  );
}

class ViewOrdersEntry extends React.Component {
  constructor(props) {
    super(props);

    const { appId, market: marketName } = this.props.order;
    const pairKey = blockedPairsKey({ appId, marketName });
    this.state = { "classInfo": fakeClassInfo("pending", marketName), pairKey };
  }


  resolveOrderClass(order) {
    const appId = order.appId;
    const marketName = order.market;
    const pairKey = blockedPairsKey({ appId, marketName });

    refreshBlockedPairs(appId, marketName);

    ClassByHashnameResolver.resolve(
      appId, marketName
    ).then(
      (assetclass) => ItemClassInfoResolver.resolve(appId, assetclass)
    ).then(
      (classInfo) => this.setState({ classInfo, pairKey })
    ).catch(  // it's possible to have outdated orders in history
      (reason) => {
        this.setState({ "classInfo": fakeClassInfo("broken", marketName), pairKey })
        loggerErrors.error("ViewOrdersEntry#resolveOrderClass(", appId, marketName, ") - Failed (", reason, ")");
      }
    );
  }

  UNSAFE_componentWillMount() {
    this.resolveOrderClass(this.props.order);
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.order == nextProps.order)
      return;

    this.resolveOrderClass(nextProps.order)
  }


  render() {
    const { classInfo, pairKey } = this.state;
    const { order, orderType, limitedAccount, state } = this.props;
    const nameTextColor = classInfo.name_color ? { "color": '#' + classInfo.name_color } : {};

    return (
      <SourceListener blockedPairs={blockedPairsSource}>{
        ({ blockedPairs }) => {
          const blockedAfter = getBlockedPairAffectAfter(blockedPairs[pairKey]);

          return (
            <div className="historyTableItem">

              <OrderEntryIcon classInfo={classInfo} order={order}
                blockedAfter={blockedAfter} />

              <div className="entryBody">

                <div className='entryNotes'>
                  <div className="commodityName" style={nameTextColor}>{classInfo.name}</div>
                  <OrderEntryNotes order={order} />
                </div>

                <div className='orderInfo'>
                  <OrderEntryBasicInfo order={order}
                                       blockedAfter={blockedAfter} />

                  <OrderEntryActions order={order} classInfo={classInfo}
                                     limitedAccount={limitedAccount} orderType={orderType}
                                     blockedAfter={blockedAfter} state={state} />
                </div>
              </div>
            </div>
          );
        }
      }</SourceListener>
    );
  }
}

export class ViewOrdersListTab extends React.Component {
  constructor(props) {
    super(props);
    this._animated = false;

    this.state = {
      "pageSize": 15,
    };

    this.handleExpandClick = () => {
      this.props.onExpandClick(!this.props.expanded);
    };

    this.handleAnimationStart = () => {
      this._animated = true;
    };
    this.handleAnimationDone = () => {
      this._animated = false;

      if (this.props.scrollbar.current)
        this.props.scrollbar.current.update();
    };

    this.handleTableScroll = (scrollValues, scrollBarRef) =>
      this.loadPageIfNeeded(scrollValues, scrollBarRef);
  }

  renderNoOrders() {
    return (
      <div className="noOrders">
        {_I_("ViewOrdersList.empty.label")}
      </div>
    );
  }

  loadPageIfNeeded(scrollValues, scrollbar) {
    const pageSize = 15;
    const { orders } = this.props;

    scrollValues = scrollValues || scrollbar.current.getScrollValues();

    if (scrollValues.clientHeight && (scrollValues.scrollHeight - scrollValues.scrollTop < 1.5 * scrollValues.clientHeight))
      if (this.state.pageSize <= orders.length)
        this.setState({ "pageSize": this.state.pageSize + pageSize })
  }

  renderStateSwitch() {
    const { state } = this.props;

    return (
      <>
        <div
          className={classMixer('switchItem', (state === "BUY") && "active")}
          onClick={() => this.props.onStateChange("BUY")}
        >
          {_I_("OrdersListContainer.Tab.buyOrders")}
        </div>
        <div
          className={classMixer('switchItem', (state === "SELL") && "active")}
          onClick={() => this.props.onStateChange("SELL")}
        >
          {_I_("OrdersListContainer.Tab.sellOrders")}
        </div>
      </>
    );
  }

  render() {
    const appIds = G_marketConfigStorage.getAppIdList();
    const { state, orderType, limitedAccount, hidden, scrollbar } = this.props;
    let { orders } = this.props;

    const filteredOrders = orders.filter(o => appIds.includes(o.appId.toString()));

    const pageSize = Math.min(this.state.pageSize, filteredOrders.length);
    const pageOrders = filteredOrders.slice(0, pageSize);

    return (
      <div className="ordersBlock collapse-exit-done expand-enter-done">
        <div className="title">{_I_("Orders.title.header")}</div>
        <div className="stateSwitch">
          {this.renderStateSwitch()}
        </div>
        {filteredOrders.length > 0 ? (
          <Scrollbar
            className="historyTableHolder"
            ref={scrollbar}
            style={{ display: hidden ? "none" : "" }}
            scrollX={false}
            onScrollStop={this.handleTableScroll}
          >
            {pageOrders.map((order) => (
              <ViewOrdersEntry
                key={order.id}
                order={order}
                orderType={orderType}
                limitedAccount={limitedAccount}
                state={state}
              />
            ))}
          </Scrollbar>
        ) : (
          this.renderNoOrders()
        )}
      </div>
    )
  }
}

class ViewCancelOrderItemDialog extends React.Component {
  doCancelOrder = () => {
    spawnCancelOrderItemProcessingModal([this.props.order]);
    this.props.removeModal();
  };

  doCancelAllOrder = (orders) => {
    const { sellOrders, buyOrders } = orders;
    let cancelOrders = [];

    const ordersSummaryType = sellOrders.concat(buyOrders);

    ordersSummaryType.forEach((order) => {
      if (order.market === this.props.order.market && order.type === this.props.order.type) {
        cancelOrders.push(order);
      };
    });

    spawnCancelOrderItemProcessingModal(cancelOrders);
    this.props.removeModal();
  };

  onBackdropTouch() {
    this.props.removeModal();
  }

  render() {
    return (
      <>
        <div className="header">
          <div className="title">
            {_I_("CancelOrderDialog.heading.youSure")}
          </div>
          <CloseButton onClick={this.props.removeModal} />
        </div>
        <div className="body-panel">
          <div className="text-center">
            <div className="image">
              <div className="settingsName">
                <b>{_I_("CancelOrderDialog.body.youSure")}</b>
              </div>
            </div>
          </div>
          <div className="well">
            <div className="buyOrder" onClick={this.doCancelOrder}>
              <IconFontAwesome type="check" />&nbsp;
              {_I_("CancelOrderDialog.button.cancel")}
            </div>
            <SourceListener orders={ordersSummary}>
              {
                ({ orders }) => {
                  return (
                    <div className="buyOrder" onClick={() => this.doCancelAllOrder(orders)}>
                      <IconFontAwesome type="check" />&nbsp;
                      {_I_("CancelAllOrderDialog.button.cancel")}
                    </div>
                  );
                }
              }
            </SourceListener>
            <div className="buyOrder" onClick={this.props.removeModal}>
              <IconFontAwesome type="remove" />&nbsp;{_I_("CancelOrderDialog.button.noCancel")}
            </div>
          </div>
        </div>
      </>
    );
  }
};

class ViewCancelOrderItemProcessingDialog extends React.Component {
  componentDidMount() {
    const orders = this.props.order;

    Promise.all(orders.map(
      (order) => MarketCancelOrder(
        order.pairId, order.id
      )
    )).then((resp) => {
      loggerViewOrdersList.info("cancelOrderItem -> ", resp);
    }).catch((reason) => {
      loggerErrors.error("cancelOrderItem - Failed (", reason, ")");
      spawnCancelOrderErrorModal();
    }).then(() => {
      ApplicationEvents.emit_async("market.updateInventory");
      ApplicationEvents.emit_async("market.ordersUpdate");
    }).then(() => {
      this.props.removeModal();
    });
  }

  render() {
    return (
      <div className="panel panel-info panel-sm view-is-processing-dialog">
        <div className="panel-heading">
          {_I_("CancelOrderDialog.heading.processing")}
        </div>

        <div className="panel-body">
          <div className="text-center">
            <IconFontAwesome type="spinner" size="3x" pulse fixedWidth />&nbsp;
          </div>
          <div className="text-center">
            <b>{_I_("CancelOrderDialog.body.processing")}</b>
          </div>
        </div>
      </div>
    );
  }
};

function spawnCancelOrderItemProcessingModal(order) {
  spawnDialogWindow(ViewCancelOrderItemProcessingDialog, {
    order
  });
}

class ViewCancelOrderErrorDialog extends React.Component {
  onBackdropTouch() {
    this.props.removeModal();
  }

  render() {
    const { removeModal } = this.props;
    return (<TrivialDialog
      prefixL10N={`CancelOrderErrorModal`}
      panelType="warning"
      onCloseButton={removeModal}
      onCancelButton={removeModal}
      bodyIcon={<IconFontAwesome type="exclamation-triangle" size="3x" fixedWidth />}
    />);
  }
};

function spawnCancelOrderErrorModal() {
  spawnDialogWindow(ViewCancelOrderErrorDialog, {});
}