import { AuthByRefreshToken } from 'api/rolesAPICall';
import { utils_api } from 'generics/common';
import { getStartArgs, resetQuery } from 'generics/queryArgs.js';
import $ from 'jquery';
import 'jquery.cookie';

const TOKEN_KEY = ["MarketApp", "auth", "tokenPair"];

export function loadTokenTuple() {
  const stored = utils_api.getStored(TOKEN_KEY);
  if (stored && ("object" === typeof stored)) {
    const { token = null, gseaToken = null, steamAppId = null, steamId = null } = stored;
    if (!!token)
      return { token, gseaToken, steamAppId, steamId };
  }
  return null;
}

export function loadCookieTokenTuple() {
  const token = $.cookie('yutoken');
  if (!!token)
    return { token, };
  return null;
}

export function storeToken(token, gseaToken, steamAppId, steamId) {
  utils_api.setStored(TOKEN_KEY, { token, gseaToken: gseaToken || token, steamAppId, steamId });
  utils_api.sheduleReload();
}

export function updateToken(token, gseaToken) {
  let stored = utils_api.getStored(TOKEN_KEY);

  if (stored && ("object" === typeof stored)) {
    stored.token = token;
    stored.gseaToken = gseaToken || token;
  }
  else
    stored = { token, gseaToken };

  utils_api.setStored(TOKEN_KEY, stored);
}

export function resetToken() {
  utils_api.setStored(TOKEN_KEY, { token: null, gseaToken: null });
}

export function detectOldInterface(legacyUrl) {
  const interfaceOldVersion = $.cookie('interfaceOldVersion');

  if (interfaceOldVersion === "true" && legacyUrl !== undefined) {
    document.location.assign(legacyUrl);
    return true;
  }

  return false;
}

export function detectSsoLanding() {
  const { status } = getStartArgs();

  if (status && ((+status) != 200))
    return false;

  const { refresh_token } = getStartArgs();

  if (refresh_token) {
    AuthByRefreshToken(refresh_token).then((creds) => {
      const { jwt } = creds;

      if (jwt) {
        const { app_id: steamAppId, steam_id: steamId } = getStartArgs();
        storeToken(jwt, null, steamAppId, steamId);
        resetQuery();
        return true;
      }
    });
  }

  return false;
}
