import { MarketBuyExactItem, MarketBuyItem } from 'api/tradeAPICall';
import { cachedBalance } from 'api/walletAPICall';
import ApplicationEvents from 'components/applicationEvents';
import { spawnDialogWindow } from 'components/dialogHolder';
import ViewItemBuyEditor from 'components/itemBuyEditor';
import { ViewItemMarketDepthOwnWarning } from 'components/itemMarketDepth';
import { spawnAuctionFinishedErrorModal, spawnOnBuyReserveFailDialog, spawnWrongBidPriceErrorModal } from 'components/messageBoxes';
import ViewPriceHistoryGraph from 'components/priceHistoryGraph';
import { _I_ } from 'components/translate';
import { CloseButton } from 'generics/buttons';
import { LogStream } from 'generics/common';
import { dataBound } from 'generics/dataBound';
import React from 'react';
import ReactGA from 'react-ga';
import { ViewItemCard } from "./commonAssets";

class ConfirmBuyDialogBody extends React.Component {
  constructor(props) {
    super(props);

    this.processBuyOrder = () => this.placeOrder();
  }

  componentDidMount() {
    const { classInfo, appid } = this.props;
    ReactGA.pageview('item/' + appid + '/' + encodeURI(classInfo.market_hash_name) + '/buy');
  }

  placeOrder() {
    const { isValid, amount, price, agree } = this.buyEditor.getResult();

    if (!isValid || !agree) {
      return;
    }

    const { hash_name, appid, classInfo, exactOrderId } = this.props;

    LogStream("VIEWTRADEDIALOG").info("placeOrder", { appid, amount, price, agree });

    this.placeOrderInternal(appid, hash_name, amount, price, agree, exactOrderId)
      .then(response => this.handleBuyResponse(appid, hash_name, classInfo, amount, price, response))
      .catch(reject => this.handleBuyError(appid, hash_name, classInfo, amount, price, reject))
      .finally(() => {
        ApplicationEvents.emit_async("market.updateInventory");
        ApplicationEvents.emit_async("market.ordersUpdate");
        ApplicationEvents.emit_async("market.updateItem", appid, hash_name);
      });

    ViewBuyProcessingDialog.spawnProcessingModal(appid, hash_name);
    this.props.removeModal(); // close self
  }

  placeOrderInternal(appid, hash_name, amount, price, agree, exactOrderId, classInfo) {
    return exactOrderId
      ? MarketBuyExactItem(appid, exactOrderId, hash_name, amount, price, agree)
      : MarketBuyItem(appid, hash_name, amount, price, agree)
  }

  handleBuyResponse(appid, hash_name, classInfo, amount, price, resp) {
    LogStream("VIEWTRADEDIALOG").info("buy order placed -> ", JSON.stringify(resp, null, "  "));

    ReactGA.event({
      category: 'Order',
      action: 'buy'
    });

    ApplicationEvents.emit_async("EventLog.append", {
      "type": "log",
      "caption": "Trade (BUY) Item",
      "message": "Order issued successfully, please check your orders page later",
      "details": {
        "response": resp,
        "marketHashName": hash_name,
        amount,
        price,
        "appId": appid
      }
    });
  }

  handleBuyError(appid, hash_name, classInfo, amount, price, reason) {
    const errorType = (((reason || {}).details || {}).response || {}).error;
    const modalErrors = ['SETUNOWNED_ACCEPT_FAIL', 'CANCELED_ON_AUCTION_PRICE_REQUIREMENTS', 'CANCELED_ON_AUCTION_FINISHED'];

    if (modalErrors.includes(errorType)) {
      LogStream("VIEWTRADEDIALOG").error("placeOrder - Failed (", reason, ")");

      if (errorType === 'SETUNOWNED_ACCEPT_FAIL') {
        spawnOnBuyReserveFailDialog(appid, hash_name, classInfo, amount, price);
      } else if (errorType === 'CANCELED_ON_AUCTION_PRICE_REQUIREMENTS') {
        spawnWrongBidPriceErrorModal(appid, hash_name, classInfo);
      } else if (errorType === 'CANCELED_ON_AUCTION_FINISHED') {
        spawnAuctionFinishedErrorModal(appid, hash_name, classInfo);
      }
    }

    reason = Object.assign({}, reason, {
      "caption": "Trade (BUY) Item Error",
      "message": ("Failed to buy item:\n" + reason.message)
    });

    LogStream("VIEWTRADEDIALOG").error("placeOrder - Failed (", reason, ")");
    ApplicationEvents.emit_async("EventLog.append", reason);
  }


  render() {
    const { classInfo, appid, auction } = this.props;
    const { auctionable } = classInfo;
    return (
      <React.Fragment>
        <div className="body">
          <ViewItemCard classInfo={classInfo} appId={appid} />
          <div className="view-confirm-dialog-summary">
            <div className="title" title="gear">
              {_I_(auctionable ? "ItemMarketDetails.bid" : "ConfirmBuyDialog.title")}
            </div>
            <div>
              <ViewItemMarketDepthOwnWarning type="sell" haveOwnOrders={this.props.haveOwnOrders} />
            </div>
            <div className="view-trade-editor">
              <ViewItemBuyEditor
                auctionable={auctionable}
                auction={auction}
                initialPrice={this.props.initialPrice}
                processBuyOrder={this.processBuyOrder}
                balance={this.props.balance}
                ref={(buyEditor) => this.buyEditor = buyEditor}
              />
            </div>
          </div>
        </div>
        {!auctionable &&
          <div className="view-graph-overlay">
            <div className="graphContainer">
              <ViewPriceHistoryGraph
                market_name={this.props.hash_name}
                appid={this.props.appid}
              />
            </div>
          </div>
        }
      </React.Fragment >
    );
  }
}


const Bound_ConfirmBuyDialogBody = dataBound(ConfirmBuyDialogBody, {
  "balance": cachedBalance
});


class ViewConfirmBuyDialog extends React.Component {
  onBackdropTouch() {
    this.props.removeModal();
  }

  render() {
    return (
      <>
        <div className="header">
          <CloseButton onClick={this.props.removeModal} />
        </div>
        <Bound_ConfirmBuyDialogBody  {...this.props} />
      </>
    )
  }
}


class ViewBuyProcessingDialog extends React.Component {
  constructor(props) {
    super(props);

    this.refreshCurrentAsset = this.refreshCurrentAsset.bind(this);
  }


  static spawnProcessingModal(appId, hashName) {
    spawnDialogWindow(ViewBuyProcessingDialog, {
      appId, hashName
    });
  }


  onBackdropTouch() {
    // this.props.removeModal();
  }


  componentDidMount() {
    ApplicationEvents.on("market.inventoryUpdateCompleted", this.refreshCurrentAsset);
  }


  componentWillUnmount() {
    ApplicationEvents.off("market.inventoryUpdateCompleted", this.refreshCurrentAsset);
  }


  refreshCurrentAsset() {
    const { hashName, appId } = this.props;

    ApplicationEvents.emit_async("market.setCurrentAssetByHash", appId, hashName);
    this.props.removeModal();
  }


  render() {
    return (
      <div className="body">
        <div className="text-center">
        </div>
        <div className="text-center">
          <b>{_I_("BuyProcessingDialog.pleaseWait")}</b>
        </div>
      </div>
    );
  }

}


export default function spawnConfirmDialogModal(initialPrice, appid, hash_name, classInfo, haveOwnOrders, auction, exactOrderId) {
  spawnDialogWindow(ViewConfirmBuyDialog, {
    initialPrice, appid, hash_name, classInfo, haveOwnOrders, auction, exactOrderId
  });
}
