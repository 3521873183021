import { isPairBlocked } from 'api/rpcAPICall';
import { isCountryDenied } from 'components/classInfoUtil';
import { _I_ } from 'components/translate';
import { IconGlyphicon } from 'generics/icons';
import moment from 'moment';
import React from 'react';
import { CalendarAuction } from "./marketIcons";

function FraudWarnings({ classInfo }) {
  const { fraudwarnings } = classInfo;
  if (fraudwarnings && fraudwarnings.length) {
    return (
      <span>
        {fraudwarnings.map(
          (warnText, key) => (<div className="fraud-warnings" key={key}>{warnText}</div>)
        )}
      </span>
    );
  }

  return false;
}

function RestrictionNonMarketable({ classInfo }) {
  return (!(classInfo.marketable || classInfo.auctionable) &&
    <div className="restriction" >
      <IconGlyphicon type="warning-sign" />
      {" "}
      {_I_("ViewItemDetailed.restriction.nonMarketable")}
    </div>
  );
}

function RestrictionBlockedAfter({ blockedAfter }) {
  if (blockedAfter == 0) {
    return false;
  }

  if (isPairBlocked(blockedAfter)) {
    return (
      <div className="restriction" >
        <IconGlyphicon type="warning-sign" />
        {" "}
        {_I_("ViewItemDetailed.restriction.blockedPair")}
      </div>
    )
  }

  const stamp = new Date(blockedAfter).toLocaleString();
  return (
    <div className="restriction" >
      <IconGlyphicon type="warning-sign" />
      {" "}
      {_I_("ViewItemDetailed.restriction.blockedAfter")}
      {" "}
      {stamp}
    </div>
  )
}

function RestrictionExpiresAt({ classInfo }) {
  const expireAt = +classInfo.expireAt;
  const expireAtStamp = (+expireAt > 0) && (new Date(1000 * expireAt)).toLocaleString();

  return (!!expireAtStamp &&
    <div className="restriction" >
      <IconGlyphicon type="fire" />
      {" "}{_I_("ViewItemDetailed.restriction.expiresAt1")}
      {" "}{expireAtStamp}
      {" "}{_I_("ViewItemDetailed.restriction.expiresAt2")}
    </div>
  );
}

function RestrictionEndedAt({ brief }) {
  const endedAt = brief.auction?.end_time;
  const endedAtStamp = (+endedAt > 0) && moment(1000 * endedAt);
  const isAuctionEnded = (endedAt || 0) * 1000 < Date.now();

  return (!!endedAtStamp &&
    <div className="restriction" >
      {_I_(isAuctionEnded ? "ViewItemSearch.title.finished" : "ViewItemDetailed.restriction.endedAt")}
      <div className='endedDate'>
        <CalendarAuction />
        <span className='date'>
          {endedAtStamp.format('DD.MM.YYYY')}
        </span>
        <span className='time'>
          {endedAtStamp.format('HH:mm')}
        </span>
      </div>
    </div>
  );
}

function RestrictionCountryDeny({ classInfo }) {
  const countryDenied = isCountryDenied(classInfo);

  return (countryDenied &&
    <div className="restriction" >
      <IconGlyphicon type="warning-sign" />
      {" "}
      {_I_("ViewItemDetailed.restriction.unclearStatus")}
    </div>
  );
}

function DelayTrade({ brief }) {
  const tradeDelaySec = brief?.tradeDelaySec;
  const delayTrade = (+tradeDelaySec > 0) && moment(1000 * tradeDelaySec).format('HH:mm');

  return (delayTrade &&
    <div className="restriction" >
      <IconGlyphicon type="fire" />
      {" "}
      {_I_("ViewItemDetailed.restriction.delayTrade")}
      {" "}
      {delayTrade}
    </div>
  );
}

export default class ViewAssetWarnings extends React.Component {
  render() {
    const { classInfo, blockedAfter, brief } = this.props;

    return (
      <div className="well well-sm text-warning WarningsPanel">
        <FraudWarnings classInfo={classInfo} />
        <RestrictionNonMarketable classInfo={classInfo} />
        <RestrictionBlockedAfter blockedAfter={blockedAfter} />
        <RestrictionExpiresAt classInfo={classInfo} />
        <RestrictionCountryDeny classInfo={classInfo} />
        <RestrictionEndedAt brief={brief} />
        <DelayTrade brief={brief} />
      </div>
    )
  }
}