import G_marketConfigStorage from 'components/globalMarketConfigStorage';
import MoneyUtil from 'components/moneyUtil';
import { _I_ } from 'components/translate';
import { IconGlyphicon } from 'generics/icons';
import { SettingsInjections } from 'generics/settingsInjections';
import React from 'react';
import { CoinsGrayIcon, PixGrayIcon } from "../generics/marketIcons";
import { getBidIndex, isOwnBid } from './briefBookLoader';

export class ViewItemMarketDepthTotals extends React.Component {
  render() {
    const { total, type, orders, bids, auctionSalt } = this.props;
    const marketMode = SettingsInjections.getMode();
    const marketCoins = marketMode == 'pixstorm' ? <PixGrayIcon /> : <CoinsGrayIcon />;

    if (!orders.length) {
      let noOrdersText = "";

      if (bids)
        noOrdersText = "MarketDepthTotals.noOrders.auction";
      else if (type == "sell")
        noOrdersText = "MarketDepthTotals.noOrders.sell";
      else
        noOrdersText = "MarketDepthTotals.noOrders.buy";

      return (
        <div className="noOrders">{_I_(noOrdersText)}</div>
      );
    }

    let userBidText = null;

    if (bids && bids.length) {
      const userBidIndex = getBidIndex(bids, auctionSalt);
      const userBid = userBidIndex !== -1 ? bids[userBidIndex].price : null;

      if (userBid)
        userBidText = <div>
          {_I_("ItemMarketDetails.yourBid")}
          <div className="promotion price">
            {userBid} {marketCoins}
          </div>
          {_I_("ItemMarketDetails.yourBid.win")}
        </div>;
    }

    let starting = "";
    let andMore = "";
    let prevew = "";

    if (type == "sell") {
      starting = _I_("MarketDepthTotals.starting.sell");
      andMore = _I_("MarketDepthTotals.andMore.buy");
      prevew = _I_("MarketDepthTotals.prevew.buy");
    } else {
      starting = _I_("MarketDepthTotals.starting.buy");
      andMore = _I_("MarketDepthTotals.andMore.sell");
      prevew = _I_("MarketDepthTotals.prevew.sell");
    }

    return (
      <React.Fragment>
        <div>
          {prevew}
          <div className="promotion count">
            {total}
          </div>
          {starting}
          <div className="promotion price">
            {orders[0].price} {marketCoins}
          </div>
          {andMore}
        </div>
        {userBidText}
      </React.Fragment>
    );
  }
}


export class ViewItemMarketDepthTable extends React.Component {
  constructor(props) {
    super(props);

    const market = MoneyUtil.formatCurr(SettingsInjections.getUsedCurrency());
    const hashToken = G_marketConfigStorage.getUserToken();
    const auctionSalt = this.props.auctionSalt;

    this.state = { market, hashToken, auctionSalt };
  }

  renderAuction(clipped) {
    const { auctionSalt } = this.state;

    return (
      <div className="table">
        <div className="tableWrapper">
          <div className="row headerRow">
            <div className="cell price">{_I_("MarketDepthTable.caption.price")} ({this.state.market})</div>
          </div>

          {clipped.map(
            ({ price, count: hash }) => {
              const isOwn = isOwnBid(auctionSalt, hash);

              return (
                <div className="row" key={price}>
                  <div className="cell price">
                    {price} {isOwn ? `(${_I_("ItemMarketDetails.yourBid.short")})` : null}
                  </div>
                </div>
              )
            }
          )}
        </div>
      </div>
    );
  }

  renderMarket(clipped) {
    return (
      <div className="table">
        <div className="tableWrapper">
          <div className="row headerRow">
            <div className="cell price">{_I_("MarketDepthTable.caption.price")} ({this.state.market})</div>
            <div className="cell count">{_I_("MarketDepthTable.caption.quantity")}</div>
          </div>

          {clipped.map(
            ({ price, count }) => (
              <div className="row" key={price}>
                <div className="cell price">{price}</div>
                <div className="cell count">{count}</div>
              </div>
            )
          )}
        </div>
      </div>
    );
  }

  render() {
    const { orders, total, lines, type, auctionable } = this.props;

    if (!orders.length)
      return (<div className="promotion price" />);

    const clipped = orders.slice(0, lines);
    const clippedSum = clipped.reduce((a, v) => (a + v.count), 0);
    if (clippedSum < total) {
      const ellipsis = (type == "sell") ? "+" : "-";
      const last = clipped.pop();
      clipped.push({
        price: `${last.price} ${ellipsis}`,
        count: (total - clippedSum + last.count)
      });
    }

    const clippedTable = auctionable ? this.renderAuction(clipped) : this.renderMarket(clipped);

    return clippedTable;
  }
}


export class ViewItemMarketDepthOwnWarning extends React.Component {
  render() {
    if (!this.props.haveOwnOrders) {
      return null;
    }

    const pending = _I_(
      (this.props.type == "sell") ? "MarketDepthOwnWarning.sell" : "MarketDepthOwnWarning.buy"
    );
    return (<div className="alert alert-warning">
      <IconGlyphicon type="warning-sign" />&nbsp;{pending}
    </div>);
  }
}
