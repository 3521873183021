
import { fetchExtendedTags } from 'components/classInfoUtil';
import { hangleIngamePreview } from 'components/hooks/ingamePreviewHook';
import { _I_ } from 'components/translate';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ViewInGameIcon } from 'generics/marketIcons';

const HOOKED_TAGS = {
  inGamePreview: true,
}


function getActiveTags(classInfo) {
  const tags = fetchExtendedTags(
    classInfo
  ).filter(
    it => (it && HOOKED_TAGS[it.category])
  ).map(
    it => ({ [it.category]: true })
  );

  return Object.assign({}, ...tags);
}


export class ShareActionBar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      copyUrlClicked: false
    }
  }

  copyItemUrl = () => {
    this.setState({ copyUrlClicked: true });
    setTimeout(() => this.setState({ copyUrlClicked: false }), 400);
  }

  render() {

    const { classInfo, appId } = this.props;

    const urlItem = window.location.origin + window.location.pathname + "?n=" + encodeURIComponent(classInfo.market_hash_name) + "&viewitem=&a=" + appId;

    return (
      <React.Fragment>
        <CopyToClipboard text={urlItem}>
          <img className="ingamePreview" src="images/icons/icon_link.png"
            onClick={() => { this.copyItemUrl(); }}
          />
        </CopyToClipboard>
        {this.state.copyUrlClicked && (
          <div className="copyMsg">
            <div className="poligon"></div>
            <div className="textMsg" >
              {_I_("ActionBar.action.previewCopied")}
            </div>
          </div>
        )
        }
      </React.Fragment>
    );
  }
}

export class ViewInGamePanel extends React.Component {

  render() {

    const { classInfo, appId } = this.props;

    const {
      inGamePreview,
    } = getActiveTags(classInfo);

    return (<div className="text">
      {inGamePreview && (
        <div className="ingamePreviewText"
          onClick={() => hangleIngamePreview(appId, classInfo)}>
          <ViewInGameIcon />
          <span className="previewText">{_I_("ActionBar.action.ingamePreview")}</span>
        </div>
      )}
    </div>);
  }
}