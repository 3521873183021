import { utils_api } from 'generics/common.js';
import { storeCurrentApp } from 'generics/routed.js';
import { makeAutoObservable } from "mobx";

class MarketStore {
  page = "market";
  currentAppId = null;

  credentials = {};

  leftPanelOpened = false;

  filterTags = {};
  filterText = "";

  constructor() {
    makeAutoObservable(this)
  }

  setCurrentAppId(appId) {
    this.currentAppId = appId;
    storeCurrentApp(appId);

    this.clearFilterTags();
    this.clearFilterText();
  }

  setPage(page) {
    this.page = page;
  }

  toggleLeftPanel() {
    this.leftPanelOpened = !this.leftPanelOpened;
  }

  setFilterTags(tags, merge) {
    let selectedTagsMap = Object.assign({}, tags || {});

    if (merge && Object.keys(this.filterTags).length) {
      let newTags = Object.assign({}, this.filterTags);

      Object.keys(selectedTagsMap).forEach((tag) => {
        const tagCat = tag.split(":", 1).shift();

        Object.keys(newTags).filter((tag) => {
          return tag.split(":", 1).shift() === tagCat;
        }).forEach((tag) => {
          delete newTags[tag];
        });

        let newTag = {};
        newTag[tag] = selectedTagsMap[tag];
        Object.assign(newTags, newTag);
      })

      selectedTagsMap = newTags;
    }

    const strTags = Object.keys(selectedTagsMap).sort().join("\0");
    const strStateTags = Object.keys(this.filterTags).sort().join("\0");

    if (strTags != strStateTags)
      this.filterTags = selectedTagsMap;
  }

  clearFilterTags() {
    this.filterTags = {};
  }

  setFilterText(text) {
    this.filterText = text;
  }

  clearFilterText() {
    this.filterText = "";
  }

  get token() {
    return this.credentials?.token || null;
  }

  get userCountry() {
    return this.credentials?.country || null;
  }

  get userId() {
    return this.credentials?.userId || null;
  }

  setCredentials(creds) {
    const newCreds = utils_api.frozenMerge(
      { token: '', userId: 0, nick: '' },
      Object.assign( {}, creds )
    );

    this.credentials = newCreds;
  }
}

export default new MarketStore();