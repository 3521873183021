import { MarketMakeAuction, MarketSellItem } from 'api/tradeAPICall';
import { cachedBalance } from 'api/walletAPICall';
import ApplicationEvents from 'components/applicationEvents';
import { spawnDialogWindow } from 'components/dialogHolder';
import { ViewItemMarketDepthOwnWarning } from 'components/itemMarketDepth';
import ViewItemSellEditor from 'components/itemSellEditor';
import { spawnDoubleSellErrorModal } from 'components/messageBoxes';
import { ordersSummary } from 'components/orderAssets';
import ViewPriceHistoryGraph from 'components/priceHistoryGraph';
import { _I_ } from 'components/translate';
import { walletLimits } from 'components/walletLimitsController';
import { CloseButton } from 'generics/buttons';
import { LogStream } from 'generics/common';
import { dataBound } from 'generics/dataBound';
import React from 'react';
import ReactGA from 'react-ga';
import { ViewItemCard } from "./commonAssets";

class ConfirmSellDialogBody extends React.Component {
  constructor(props) {
    super(props);

    this.processSellOrder = () => this.placeOrder();
  }

  componentDidMount() {
    const { classInfo, appid } = this.props;
    ReactGA.pageview('item/' + appid + '/' + encodeURI(classInfo.market_hash_name) + '/sell');
  }

  placeOrder() {
    const {
      isValid,
      amount: totalAmount,
      price,
      wished,
      agree,
      priceStep,
      endDate
    } = this.sellEditor.getResult();

    if ((!isValid) || (!agree))
      return;

    const { hash_name, asssid, ctxid, appid, classInfo, instances } = this.props;
    const { auctionable } = classInfo;

    LogStream("VIEWTRADEDIALOG").info("placeOrder", appid, ctxid, asssid, totalAmount, price, wished, agree);

    let allAmount = totalAmount;

    Promise.all(instances.map(
      (instance) => {
        if (allAmount == 0) {
          return true;
        }

        let instanceAmount;

        if (instance.amount == undefined)
          instanceAmount = 1;
        else
          instanceAmount = instance.amount;

        if (instanceAmount > allAmount) {
          instanceAmount = allAmount;
        }

        allAmount -= instanceAmount;

        if (auctionable) {
          return MarketMakeAuction(
            appid, ctxid, instance.id, instanceAmount, price, wished, agree, endDate / 1000,
            priceStep
          )
        } else {
          return MarketSellItem(
            appid, ctxid, instance.id, instanceAmount, price, wished, agree
          )
        }
      })
    ).then((resp) => {
      LogStream("VIEWTRADEDIALOG").info("item sold -> ", JSON.stringify(resp, null, "  "));

      ReactGA.event({
        category: 'Order',
        action: 'sell'
      });

      ApplicationEvents.emit_async("EventLog.append", {
        "type": "log",
        "caption": "Trade (SELL) Item",
        "message": "Order issued successfully, please check your orders page later",
        "details": {
          "response": resp,
          "marketHashName": hash_name,
          price,
          wished,
          "assetId": asssid,
          "appId": appid,
          "ctxId": ctxid
        }
      });

      /* get created auction ID */
      let auctionId = null;
      let auctionResult = null;

      if (auctionable) {
        auctionResult = resp[0] || null;

        if (auctionResult?.success)
          auctionId = auctionResult?.auction;
      }

      ApplicationEvents.emit_async("market.sellComplete", auctionId);
    }).catch((reason) => {
      reason = Object.assign({}, reason,
        {
          "caption": "Trade (SELL) Item Error",
          "message": ("Failed to sell item:\n" + reason.message)
        });

      const details = ((reason || {}).details || {});
      const code = ((details.response || details.result || {}).error || "");

      if (code == "INVENTORY_FAIL_TO_GET_ASSETS_CLASS") {
        ApplicationEvents.emit_async("market.doubleSellError");
      } else {
        LogStream("VIEWTRADEDIALOG").error("clickSell - Failed (", reason, ")");
        ApplicationEvents.emit_async("EventLog.append", reason);
      }

      ApplicationEvents.emit_async("market.sellComplete");
      // return Promise.reject( reason );
    }).finally(() => {
      ApplicationEvents.emit_async("market.updateInventory");
      ApplicationEvents.emit_async("market.ordersUpdate");
    });

    spawnSellProcessingModal(appid, hash_name);
    this.props.removeModal(); // close self
  }


  render() {
    const { classInfo, appid } = this.props;
    const { auctionable } = classInfo;

    return (
      <React.Fragment>
        <div className="body">
          <ViewItemCard classInfo={classInfo} appId={appid} />
          <div className="view-confirm-dialog-summary">
            <div className="title" title="gear">
              {_I_(auctionable ? "ItemMarketDetails.makeAuction" : "SellDialog.title")}
            </div>
            <div>
              <ViewItemMarketDepthOwnWarning type="buy" haveOwnOrders={this.props.haveOwnOrders} />
            </div>
            <div className="view-trade-editor">
              <ViewItemSellEditor
                marketFee={this.props.classInfo.market_fee}
                initialPrice={this.props.initialPrice}
                processSellOrder={this.processSellOrder}
                ref={(sellEditor) => this.sellEditor = sellEditor}
                balance={this.props.balance}
                wlimits={this.props.wlimits}
                orders={this.props.orders}
                totalAmount={this.props.totalAmount}
                instances={this.props.instances}
                auctionable={auctionable}
              />
            </div>
          </div>
        </div>
        {!auctionable &&
          <div className="view-graph-overlay">
            <div className="graphContainer">
              <ViewPriceHistoryGraph
                market_name={this.props.hash_name}
                appid={this.props.appid}
              />
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}


// ViewItemSellEditor
const Bound_ConfirmSellDialogBody = dataBound(ConfirmSellDialogBody, {
  "balance": cachedBalance,
  "wlimits": walletLimits,
  "orders": ordersSummary,
});


class ViewConfirmSellDialog extends React.Component {
  onBackdropTouch() {
    this.props.removeModal();
  }

  render() {
    return (
      <React.Fragment>
        <div className="header">
          <CloseButton onClick={this.props.removeModal} />
        </div>
        <Bound_ConfirmSellDialogBody {...this.props} />
      </React.Fragment>
    );
  }
}


class ViewSellProcessingDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      'doubleSell': false,
      'hash_name': null
    };

    this.refreshCurrentAsset = this.refreshCurrentAsset.bind(this);
    this.doubleSellError = () => this.setState({ "doubleSell": true });
    this.onBriefUpdated = (market_name) => this.briefUpdated(market_name);
  }

  onBackdropTouch() {
    // this.props.removeModal();
  }

  componentDidMount() {
    ApplicationEvents.on("market.sellComplete", this.refreshCurrentAsset);
    ApplicationEvents.on("market.doubleSellError", this.doubleSellError);
  }

  componentWillUnmount() {
    ApplicationEvents.off("market.sellComplete", this.refreshCurrentAsset);
    ApplicationEvents.off("market.doubleSellError", this.doubleSellError);
  }

  briefUpdated(market_name) {
    const { hash_name } = this.state;

    if (market_name === hash_name) {
      this.props.removeModal();
      ApplicationEvents.off("market.briefUpdated", this.onBriefUpdated);
    }
  }

  refreshCurrentAsset(sell_hashname) {
    const { hashName, appId } = this.props;
    const currentHashName = sell_hashname ? sell_hashname : hashName;

    this.setState({ hash_name: currentHashName });

    ApplicationEvents.on("market.briefUpdated", this.onBriefUpdated);
    ApplicationEvents.emit_async("market.setCurrentAssetByHash", appId, currentHashName);

    if (this.state.doubleSell)
      spawnDoubleSellErrorModal();
  }


  render() {
    return (
      <div className="body">
        <div className="text-center">
          <div className="loader">
            <div className="spinner" />
          </div>&nbsp;
        </div>
        <div className="text-center">
          <b>{_I_("SellProcessingDialog.pleaseWait")}</b>
        </div>
      </div>
    );
  }
}


function spawnSellProcessingModal(appId, hashName) {
  spawnDialogWindow(ViewSellProcessingDialog, {
    appId, hashName
  });
}


export default function spawnConfirmSellModal(props) {
  spawnDialogWindow(ViewConfirmSellDialog, {
    ...props
  })
}
