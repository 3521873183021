import * as Sentry from "@sentry/react";
import { LocalLoadFile } from 'api/basicApi';
import GaijinToolPanel from 'components/gaijinToolPanel';
import registerTranslations from 'components/locale';
import { staticConfigs } from 'components/setup/configSources';
import { _I_ } from 'components/translate';
import { LogStream, sanitizeEvent, utils_api } from 'generics/common';
import { SourceListener } from 'generics/dataBound';
import { IconFontAwesome } from 'generics/icons';
import { findLocaleCode, findLocaleIcon, getDefaultLocale } from 'generics/localeUtils';
import { getStartArgs } from 'generics/queryArgs.js';
import { SettingsInjections } from 'generics/settingsInjections';
import React from 'react';
import ReactGA from 'react-ga';

registerTranslations();

LogStream.enable("CONFIG");
const loggerConfig = LogStream("CONFIG")


function loadSiteSettings() {
  return LocalLoadFile(
    "config/settings.json"
  ).catch((error) => Promise.reject("settings"));
}


function loadDevSiteSettings() {
  return LocalLoadFile(
    "config/dev_settings.json"
  ).catch((error) => ({}));  // It's normal if there no such file
}


function setupSiteSettings() {
  return Promise.all([
    loadSiteSettings(),
    loadDevSiteSettings(),

  ]).then(([mainSiteSettings, devSiteSettings]) => {
    const siteSettings = Object.assign({}, mainSiteSettings, devSiteSettings);
    if (!siteSettings.ssoUrl)
      siteSettings.devMode = true;

    staticConfigs.update({ siteSettings });

    SettingsInjections._devMode = siteSettings.devMode;

    if (typeof siteSettings.sentryUrl != undefined)
      SettingsInjections._sentryUrl = siteSettings.sentryUrl;

    loggerConfig.info(`siteSettings loaded successfully${siteSettings.devMode ? ", devmode set" : ""}`);
    return !!siteSettings.devMode;
  });
}


function loadCircuits() {
  return LocalLoadFile(
    "config/circuits.json"
  ).catch((error) => Promise.reject("circuits"));
}


function loadDevCircuits() {
  return LocalLoadFile(
    "config/dev_circuits.json"
  ).catch((error) => ({}));  // It's normal if there no such file
}


function setupCircuits() {
  return Promise.all([
    loadCircuits(),
    loadDevCircuits(),

  ]).then(([mainCircuits, devCircuits,]) => {
    const circuits = Object.assign({}, mainCircuits, devCircuits)

    let usedCircuitName = utils_api.getStored(["MarketApp", "LRU", "circuit"], "internal");

    if (!circuits[usedCircuitName]) {
      usedCircuitName = "internal";
      if (!circuits[usedCircuitName]) {
        usedCircuitName = Object.keys(circuits)[0];
      }

      utils_api.setStored(["MarketApp", "LRU", "circuit"], usedCircuitName);
    }

    const usedCircuit = circuits[usedCircuitName];

    SettingsInjections._circuit = usedCircuit;
    SettingsInjections._circuitName = usedCircuitName;

    staticConfigs.update({ circuits, usedCircuit, usedCircuitName });
    loggerConfig.info(`circuits loaded successfully: running on ${usedCircuitName}`);
    return usedCircuitName;
  });
}


export function setupUsedLocale() {
  let { skin_lang: icon } = getStartArgs();

  const marketMode = SettingsInjections.getMode();
  if (marketMode == 'pixstorm')
    icon = 'ru';

  const lang = findLocaleIcon(icon);
  if (lang)
    utils_api.setStored(["MarketApp", "LRU", "locale"], lang.code);

  const selectedLocale = findLocaleCode(utils_api.getStored(["MarketApp", "LRU", "locale"]));
  if (selectedLocale && selectedLocale.enabled) {
    SettingsInjections._Locale = selectedLocale;
    loggerConfig.info(`usedLocale loaded successfully: ${selectedLocale.code}`);
  } else {
    SettingsInjections._Locale = getDefaultLocale();
    loggerConfig.info(`usedLocale defaulted to: ${SettingsInjections._Locale.code}`);
  }

  return SettingsInjections._Locale;
}

function setupAnalytics() {
  loggerConfig.info(`google analytics initialize`);
  ReactGA.initialize([{
    trackingId: process.env.REACT_APP_GA_TRACKING_ID || null
  }]);
}

function setupSentry() {
  if (!SettingsInjections._sentryUrl)
    return;

  loggerConfig.info(`sentry initialize`);

  Sentry.init({
    dsn: SettingsInjections._sentryUrl,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: SettingsInjections._devMode ? 1.0 : 0.2,
    normalizeDepth: 6,
    beforeSend(event) {
      sanitizeEvent(event);
      return event;
    },
  });

  Sentry.setTag("circuit", SettingsInjections._circuitName);
}

function loadStaticConfigs() {
  return Promise.all([
    setupSiteSettings(),
    setupCircuits()
  ]).then(([devMode, usedCircuitName, usedLocale]) => {
    loggerConfig.info(`static configs initialization finished`);

    setupSentry();
    setupUsedLocale();
    setupAnalytics();
  });
}


function StaticConfigError({ error }) {
  return <GaijinToolPanel type="danger"
    caption={_I_("StaticConfigLoader.caption.error")} >
    <div className="text-center">
      <div className="alert alert-danger">
        <h3><IconFontAwesome type="exclamation-triangle" size="3x" fixedWidth /></h3>
        {_I_(`StaticConfigLoader.error.${error}`)}
      </div>
    </div>
  </GaijinToolPanel>;
}


function StaticConfigWaitbox() {
  return <GaijinToolPanel caption={_I_("StaticConfigLoader.caption.init")} >
    <div className="text-center">
      <h3>{_I_("StaticConfigLoader.text.init")}</h3>
      <div className="loader">
        <div className="spinner" />
      </div>
    </div>
  </GaijinToolPanel>;
}


export class StaticConfigLoader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stage: "pending",
    };
  }


  componentDidMount() {
    loadStaticConfigs().then(
      () => { this.setState({ stage: "done" }) },
      (error) => {
        loggerConfig.warning("StaticConfigLoader error", error);
        this.setState({ stage: error });
      });
  }


  render() {
    const { stage } = this.state;
    switch (stage) {
      case "done":
        return (<SourceListener conf={staticConfigs} >{
          ({ conf }) => (this.props.children(conf))
        }</SourceListener>);

      case "pending":
        return (<StaticConfigWaitbox />);

      default:
        return (<StaticConfigError error={stage} />);
    }
  }
}

