import { GenericAPICall } from 'api/genericAPICall';
import { LogStream } from 'generics/common';
import { SettingsInjections } from 'generics/settingsInjections';

////////////////////////////////////////////
// class MarketAPICall
class MarketAPICall  extends GenericAPICall {
  normalizeResponse( resp )  {
    return resp.result || resp.response;
  }


  getInterfaceUrl()  {
    return SettingsInjections.getUsedCircuit().market_server;
  }
};


////////////////////////////////////////////
//  AssetAPI request  action=cancel_order&token=xsaffasfa&appid=666
export function MarketCancelOrder ( pairId, orderId )
{
  let callCancelOrder = new MarketAPICall("cancel_order");
  callCancelOrder.setRetryPolicy( 3, 500, 1500 );
  callCancelOrder.appendTransactId();
  callCancelOrder.append("pairId", pairId );
  callCancelOrder.append("orderId", orderId );

  return callCancelOrder.POST().then( (resp) =>  {
      LogStream( "APICALLDEBUG" ).info(`MarketCancelOrder() - got response:`, resp);
      return resp;
    });
}

