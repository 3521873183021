import { MarketGetAppTags } from 'api/ordersAPICall';
import { ViewAppIdIcon } from 'components/commonAssets';
import { Dropdown, DropdownAction, FilterCheckbox } from 'components/generics';
import G_marketConfigStorage from 'components/globalMarketConfigStorage';
import { _I_ } from 'components/translate';
import { LogStream } from 'generics/common';
import { classMixer } from 'generics/utils';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Scrollbar from "react-scrollbars-custom";
import { MarketStore } from 'stores';

export const ViewMarketAppFilterEditor = observer(
  class ViewMarketAppFilterEditor extends React.Component {
    renderFullAppControl(projectSelectOptions) {
      const appId = MarketStore.currentAppId;
      const currentLocaleCaption = <img src={`images/icons/app_list.png`} />

      const items = projectSelectOptions.map((item) =>
        <DropdownAction
          key={`app_${item.value}`}
          onClick={() => MarketStore.setCurrentAppId(item.value)}
          active={appId == item.value}
        >
          {item.label}
        </DropdownAction>
      );

      return (
        <div className="MarketAppFilter">
          <Dropdown caption={currentLocaleCaption} mixClasses="noCaret">
            {items}
          </Dropdown>

          {appId && (
            <React.Fragment>
              <div className="gameBtn">
                <ViewAppIdIcon appId={appId} compact />
              </div>
            </React.Fragment>
          )}
        </div>
      )
    }

    renderAppList() {
      const isSimple = this.props.simple;

      const appId = MarketStore.currentAppId;
      const onSwitchClick = (appId) => {
        MarketStore.setCurrentAppId(appId)

        if (this.props.onUpdate)
          this.props.onUpdate();
      };

      let defaultOption = { value: null, label: _I_("AppFilterEditor.label.allFilter") };

      const appList = this.props.appIds.map((appId) => G_marketConfigStorage.resolveAppId(appId));
      const projectSelectOptions = [defaultOption].concat(appList.map((item) => ({ value: item.appid, label: item.name })));

      if (appId) {
        defaultOption = projectSelectOptions.find(item => item.value === appId);
      }

      return (
        <React.Fragment>
          {isSimple && appList.map((item) => (
            <div className="gameBtn" key={item.appid} onClick={() => onSwitchClick(item.appid)}>
              <ViewAppIdIcon appId={item.appid} compact />
            </div>
          ))}

          {!isSimple && this.renderFullAppControl(projectSelectOptions)}
        </React.Fragment>
      );
    }

    render() {
      return (
        this.renderAppList()
      );
    }
  }
)

export class ViewMarketTagsIcon extends React.Component {
  render() {
    return (
      <img
        className="marketFilterIcon"
        src={`images/icons/filter.png`}
        onClick={() => this.props.onClickIcon()}
      />
    );
  }
}

export const ViewMarketTagsFilters = observer(
  class ViewMarketTagsFilters extends React.Component {
    render() {
      const { hiddenPage } = this.props;

      return (
        <ViewMarketTagsFiltersEditor
          appIds={this.props.appIds}
          locale={this.props.locale}
          hiddenPage={hiddenPage}
        />
      );
    }
  }
)

export const ViewMarketTagsFiltersEditor = observer(
  class ViewMarketTagsFiltersEditor extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        "tagmap": []
      };

      reaction(
        () => [MarketStore.currentAppId, this.props.locale],
        () => {
          this.loadTags(MarketStore.currentAppId)
        }
      )
    }

    componentDidMount() {
      this.loadTags(MarketStore.currentAppId);
    }

    composeTagmap(tags) {
      let res = [];

      const sortTag = (a, b) => {
        if (a.sort < b.sort) return -1;
        if (a.sort > b.sort) return 1;
        return 0;
      };

      Object.keys(tags).forEach((tagGroup) => {
        const { loc, sortingIndex, values } = tags[tagGroup];
        if (!loc) return;
        let catLoc = { name: tagGroup, loc, tags: [], collapsed: true, sort: sortingIndex };

        Object.keys(values).forEach((tagName) => {
          const { loc, color, sortingIndex } = values[tagName];
          if (!loc) return;
          catLoc.tags.push({ name: tagGroup + ":" + tagName, loc, color, sort: sortingIndex });
        });

        catLoc.tags = catLoc.tags.sort(sortTag);
        res.push(catLoc);
      });

      return this.tagRender(res.sort(sortTag)).filter(x => (!!x.tags.length));
    }


    loadTags(appId) {
      if (!appId) {
        this.setState({ "tagmap": [] });
        return;
      }

      MarketGetAppTags(
        appId
      ).then((tags) => {
        LogStream("VIEWMARKETEDITEMSLIST").info("MarketGetAppTags -> ", tags);
        this.setState({ "tagmap": this.composeTagmap(tags) });

      }).catch((reason) => {
        this.setState({ "tagmap": [] });
        LogStream("VIEWMARKETEDITEMSLIST").error("MarketGetAppTags - Failed (", reason, ")");

      });
    }

    renderTagCategory(category) {
      const collapsed = category.collapsed;

      return (
        <div key={category.name} className={classMixer("appid-filter-tag-category", collapsed && "collapsed")}>
          <div className="appid-filter-tag-category-name" onClick={() => this.collapseCategory(category.name)}>
            <div className="title">{category.loc || "---"}</div>
            <img className="iconExpander" src={`images/icons/arrow.png`} />
          </div>
          <div className="appid-filter-tag-category-list">
            {category.tags.map((tag) => this.renderTagEntry(tag))}
          </div>
        </div>
      )
    }

    collapseCategory(category_name) {
      let tagmap = this.state.tagmap;

      tagmap = tagmap.map((category) => {
        if (category.name == category_name)
          category.collapsed = !category.collapsed

        return category
      })

      this.setState({ tagmap });
    }

    renderAppOptions() {
      let tagmap = this.state.tagmap;

      return (
        <div className="appid-filter-menu">
          <div className="appid-filter-menu-list">
            {tagmap.map((category) => this.renderTagCategory(category))}
          </div>
        </div>
      );
    }

    renderTagEntry({ loc, name, color }) {
      const checked = MarketStore.filterTags[name];
      const onToggleTag = () => {
        const newTags = Object.assign({}, MarketStore.filterTags);
        if (checked)
          delete newTags[name];
        else
          newTags[name] = { loc, name, color };

        MarketStore.setFilterTags(newTags);
      };

      const style = color ? { color: `#${color}` } : {};

      return (
        <div className="appid-filter-tag-entry" key={name} onClick={onToggleTag}>
          <FilterCheckbox checked={!!checked} classMixer="appid-filter-checkbox" />
          <div className="filterText" style={style}>{loc}</div>
        </div>
      );
    }

    tagRender(tagmap) {
      const firstTag = tagmap[0];
      firstTag.collapsed = false;
      tagmap[0] = firstTag;

      return tagmap;
    }

    sidebarTagsRender(openFilters) {
      return (
        <div className="sidebar-tags-filters-title">
          {!!this.state.tagmap.length && (
            <div className="gameSelection">
              <ViewMarketTagsIcon onClickIcon={() => MarketStore.toggleLeftPanel()} />
              <div className={classMixer("filterLabel", MarketStore.leftPanelOpened && "opened")}>{_I_("AppFilterEditor.label.byTags")}</div>
            </div>
          )}
          {(!openFilters) && (
            <React.Fragment>
              <div className={classMixer("filterMessage", MarketStore.leftPanelOpened && "opened")}>{_I_("AppFilterEditor.message.filter")}</div>
              <div className={classMixer("gameMessage", MarketStore.leftPanelOpened && "opened")}>{_I_("AppFilterEditor.message.game")}</div>
            </React.Fragment>
          )}
        </div>
      )
    }

    render() {
      const appList = this.props.appIds.map((appId) => G_marketConfigStorage.resolveAppId(appId));
      const openFilters = MarketStore.currentAppId && MarketStore.page != "market";

      const onSwitchClick = (appId) => {
        MarketStore.setCurrentAppId(appId)

        if(MarketStore.page !== 'inventory')
          MarketStore.setPage("marketSell");
      };

      if(this.props.hiddenPage)
        return null;

      return (
        <React.Fragment>
          {this.sidebarTagsRender(openFilters)}
          {openFilters && (
            <div className={classMixer("tagsFiltersPanel", this.props.hiddenPage, MarketStore.leftPanelOpened && "opened")}>
              <div className="sidebar-tags-filters">
                <Scrollbar defaultStyles={false} scrollX={false}>
                  {this.renderAppOptions()}
                </Scrollbar>
              </div>
            </div>
          )}
          {(!openFilters) && (
            <div className={classMixer("gameBtns", MarketStore.leftPanelOpened && "opened")}>
              {appList.map((item) => (
                <div className="gameBtn" key={item.appid} onClick={() => onSwitchClick(item.appid)}>
                  <ViewAppIdIcon appId={item.appid} compact />
                </div>
              ))}
            </div>
          )}
        </React.Fragment>
      )
    }
  }
)

export const ViewMarketTagsTerms = observer(
  class ViewMarketTagsTerms extends React.Component {
    renderTagsTerms() {
      if (!MarketStore.currentAppId)
        return [];

      const tags = Object.values(MarketStore.filterTags);
      if (!tags.length)
        return [];

      const tagmap = {};
      tags.forEach(({ loc, name, color }) => {
        const [, category, tagname] = (/^([^:]+)(:.*)?$/ig).exec(name);
        if (!tagname)
          return;

        if (!tagmap[category]) {
          tagmap[category] = { name: category, loc: "", rtags: [] };
        }

        tagmap[category].rtags.push({ loc, name, color });
      });


      return Object.values(tagmap).map(({ name, loc, rtags }) => {
        const newTags = Object.assign({}, MarketStore.filterTags);

        const items = rtags.map(({ loc, name, color }, idx) => {
          delete newTags[name];
          return (<span className="subcap" key={`t${name}`}>{loc}</span>);
        });

        const onToggleTag = () => MarketStore.setFilterTags(newTags);

        return (
          <div key={`tag_${name}`} variant="default"
            className="view-marketed-search-term-button"
            onClick={onToggleTag}
          >
            <div className="caption">{loc}{items}</div>
            <div className="remove" />
          </div>
        )
      });
    }

    renderRemoveFilters() {
      if (MarketStore.currentAppId) {
        const onClear = () => MarketStore.clearFilterTags();
        return (
          <div className="removeFilters" onClick={onClear} >
            <div className="caption">{_I_("AppFilterEditor.label.clearFilter")}</div>
            <div className="remove" />
          </div>
        );
      } else {
        return null;
      }
    }

    render() {
      const terms = this.renderTagsTerms();

      return (
        <div className="ViewItemSearchTerms">
          {!!terms.length && this.renderRemoveFilters()}
          {terms}
        </div>
      );
    }
  }
)