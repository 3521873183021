
//import { DataSource } from 'generics/utils';
import React from 'react';

////////////////////////////////////////////
// class dataBound - pubsub generic
export function dataBound(OriginalComponent, sources) {

  const DataBound = class extends React.Component {
    constructor(props)  {
      super(props);

      const srcList = Object.entries( sources );
      const dataList = srcList.map( ([domain, src])  =>  ({ [domain]: src.get() }) );
      this.state = Object.assign({}, ...dataList);

      this.listeners = srcList.map( ([domain, src])  =>  {
        const handler = (val, prev) => this.setState({ [domain]: src.get() });
        return { domain, src, handler };
      });
    }


    componentDidMount() {
      const newStates = this.listeners.map( ({ domain, src, handler })  =>  {
          src.on( handler );
          return { [domain]: src.get() };
        });
      this.setState(Object.assign({}, ...newStates));
    }


    componentWillUnmount() {
      this.listeners.map( ({ domain, src, handler })  =>  src.off( handler ) );
    }


    render() {
      return <OriginalComponent {...this.props} {...this.state} />;
    }
  };

  DataBound.displayName = `Bound_${OriginalComponent.displayName || OriginalComponent.name || 'Component'}`;
  return DataBound;

}



////////////////////////////////////////////
// class SourceListener - pubsub advanced, using render hijackin
export class SourceListener extends React.Component {
  constructor(props)  {
    super(props);

    const {  children, ...sources  } = this.props;

    const srcList = Object.entries( sources );
    const dataList = srcList.map( ([domain, src])  =>  ({ [domain]: src.get() }) );
    this.state = Object.assign({}, ...dataList);

    this.listeners = srcList.map( ([domain, src])  =>  {
      const handler = (val, prev) => this.setState({ [domain]: src.get() });
      return { domain, src, handler };
    });
  }


  componentDidMount() {
    const newStates = this.listeners.map( ({ domain, src, handler })  =>  {
        src.on( handler );
        return { [domain]: src.get() };
      });
    this.setState(Object.assign({}, ...newStates));
  }


  componentWillUnmount() {
    this.listeners.map( ({ domain, src, handler })  =>  src.off( handler ) );
  }


  render() {
    const {  children  } = this.props;

    return children && children(this.state);
  }
};



export function __test__()  {
  /*const ds = new DataSource({ xxx: 1 });

  const EasyC = dataBound( class DataUser {

    render()  {
      return <div onClick={ ()  => this.props.domain.set({ xxx: this.props.domain.get().xxx + 1 })}>
          {this.props.domain.get().xxx}
        </div>;
    }
  },
  {
    domain:ds
  });*/
}
