import { AuthCheckUserAuth } from 'api/rolesAPICall';
import AuthInfoIcon from 'components/authInfoIcon';
import React from 'react';



//  simple control loading user's credentials via market proxy using provided token
//  provides input line and button with state? if token is ok - allow click to apply
export class AuthTokenChecker extends React.Component  {

  constructor(props) {
    super(props);

    this.state = {
      token: this.props.token||"",
    };

    this.umounted = false;
  }


  onEditValue = (event) =>  {
    this.loadCredentials(event.target.value);
  };


  componentDidMount()  {
    this.loadCredentials( this.props.token );
  }


  componentWillUnmount()  {
    this.umounted = true;
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    if ( this.props.token == nextProps.token )
      return;

    this.loadCredentials( nextProps.token );
  }


  loadCredentials( token )  {
    if ( this.umounted )
      return;

    if ( !token )  {
      this.setState({  token:"", creds: null,  error:null  });
      return;
    }

    this.setState( (prevState, props) =>  {

      AuthCheckUserAuth(
          token

        ).then((creds)  =>  {
          return {  creds,  error:null  };

        }).catch((err)  =>  {
          const error = ((err.details || {}).response||{}).error || "UNKNOWN";
          return {  creds: null,  error  };

        }).then((update)  =>  {
          if ( this.umounted )
            return;

          this.setState( (checkState, checkProps) =>  {
            if ( checkState.token == token )  {
              return update;
            }  else  {
              return {};
            }
          });
        });

      return {  token:token,  creds: null,  error:null  };
    });
  }


  render() {
    if ( this.props.readOnly && (!this.props.token) )
      return null;

    return (
        <div className="well well-sm">
          <label>{this.props.children}</label>

          <div className="form-horizontal">
            <div className="form-group form-group-sm">

              <div className="col-sm-9">
                <input  type="text"  className="form-control"
                    readOnly={this.props.readOnly}
                    value={this.state.token}
                    onChange={this.onEditValue}
                  />
              </div>

              <AuthInfoIcon
                    error={this.state.error}
                    creds={this.state.creds}
                    onClick={() => this.props.onClick(this.state.token)}
                    mixClasses="col-sm-3 btn-sm"
                  />

            </div>
          </div>
        </div>
    );
  }

}

