import { MarketSearch } from 'api/ordersAPICall';
import ApplicationEvents from 'components/applicationEvents';
import { BriefBookLoaderAsync, getBidIndex } from "components/briefBookLoader";
import { ViewAppIdIcon } from 'components/commonAssets';
import G_marketConfigStorage from 'components/globalMarketConfigStorage';
import { ViewMarketAppFilterEditor, ViewMarketTagsTerms } from 'components/marketAppFilterEditor';
import { ViewMarketSortOrder } from 'components/marketSortSelector';
import MoneyUtil from 'components/moneyUtil';
import { _I_ } from 'components/translate';
import { LogStream } from 'generics/common';
import { loadCurrentApp } from 'generics/routed.js';
import { SettingsInjections } from 'generics/settingsInjections';
import { classMixer } from 'generics/utils';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import Scrollbar from "react-scrollbars-custom";
import { MarketStore } from 'stores';
import { ItemsContentPreloader } from './generics';

export class ViewMarketedItemPrice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      'isOwn': false,
      'ownBidPrice': false
    };

    this.hashToken = G_marketConfigStorage.getUserToken();
    this.updateItem = (appid, hashname) => {
      if (appid && appid != this.props.item.appid)
        return false;

      if (hashname && hashname != this.props.item.hash_name)
        return false;

      this.updateContexts();
    }
  }

  componentDidMount() {
    ApplicationEvents.on("market.updateItem", (appid, hash_name) => this.updateItem(appid, hash_name));
    this.updateItem();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.item.price != this.props.item.price || prevProps.item.buy_price != this.props.item.buy_price) {
      this.updateItem();
    }
  }

  componentWillUnmount() {
    ApplicationEvents.off("market.updateItem", (appid, hash_name) => this.updateItem(appid, hash_name));
  }

  async updateContexts() {
    const { appid, hash_name } = this.props.item;

    if (this.props.isAuction) {
      try {
        const books = await BriefBookLoaderAsync(appid, hash_name);
        const salt = books?.auction?.salt;
        const preferPrice = this.getPreferPrice();

        const ownBidIndex = getBidIndex(books?.ordersBuy, salt);
        const haveOwnBid = ownBidIndex !== -1;
        const ownBid = haveOwnBid && books?.ordersBuy[ownBidIndex];

        const isPriceSameOwnBid = haveOwnBid && (ownBid.rawPrice >= preferPrice - books.auction.priceStep);

        this.setState({ isOwn: isPriceSameOwnBid, ownBidPrice: isPriceSameOwnBid && (ownBid.rawPrice * 10000) });
      } catch (reason) {
        const reasonException = {
          ...reason,
          caption: "Books Error",
          message: `Failed to get books:\n ${reason.message}`
        };
        LogStream("MARKETAPP").error("MarketBooksBrief - Failed (", reasonException, ")");
      }
    }
  }

  getHavePrice() {
    const price = this.getPrice();
    return !!((price > 0) && (this.props.item.depth > 0 || this.props.item.buy_depth > 0));
  }

  getPrice() {
    if (this.props.option == "auction")
      return this.state.ownBidPrice || this.props.item.price || this.props.item.buy_price;
    else if (this.props.option == "buy")
      return this.props.item.buy_price;
    else
      return this.props.item.price;
  }

  getPreferPrice() {
    const curr = SettingsInjections.getUsedCurrency();
    const price = this.getPrice();
    return MoneyUtil.preferMoney(price, null, curr, curr);
  }

  render() {

    const { item, option, isAuction } = this.props;
    const { isOwn } = this.state;
    const havePrice = this.getHavePrice();
    const marketMode = SettingsInjections.getMode();

    if (!havePrice)
      return null;

    let bidType = classMixer("bid", isOwn && "winningBid");

    let lowestPrice = classMixer("lowestPrice",
      (option == "buy" || item.totalAmount) && "buyItem", isAuction && "buyAction");

    let messagePrice = (option == "buy" || item.totalAmount) ?
      _I_(`ViewMarketedItem.beforePrice`) : _I_(`ViewMarketedItem.lovestPrice`);

    let auctionMessage = !!isOwn ? _I_(`ViewMarketedItem.yourBid`) : _I_(`ViewMarketedItem.bid`);
    let preferPrice = this.getPreferPrice();

    return (
      <div className={lowestPrice}>
        {!isAuction && messagePrice}
        {isAuction && auctionMessage}
        <div className={bidType}>
          <span className="price" title={_I_('ViewMarketedItem.SellingFrom')}>
            {MoneyUtil.formatMoney(preferPrice)}
          </span>
          <img className="currency" src={`images/icons/coins${marketMode == 'pixstorm' ? '_pix' : ''}_${!!isOwn ? 'winning' : 'gray'}.png`} />
        </div>
      </div>
    )
  }
}

const ViewMarketedItemAmount = ({ item, option, noBuyOrders }) => {

  const depthCount = (option == "buy") ? item.buy_depth : item.depth;
  const classNameCount = (option == "buy" || item.totalAmount) ? "count buyItem" : "count";
  const auctionEnded = item?.auction_end_time;
  const auctionable = item.classInfo?.auctionable || false;

  if (depthCount || item.totalAmount) {
    let icon = !!auctionEnded ? "auction_buy.png" :
      ((option == "buy" || item.totalAmount) ? "order_buy.png" : "order_sell.png");

    return (
      <React.Fragment>
        {item.totalAmount && (
          <span className="countItems">
            {item.totalAmount}
          </span>
        )}
        {(!auctionable && !noBuyOrders) && (depthCount ?
          (
            <div className={classNameCount}>
              <img className="currency" src={`images/icons/${icon}`} />
              <span className="countAmount">
                {depthCount}
              </span>
            </div>
          ) : (
            <span className="noBuyText">
              {_I_("ViewMarketedItem.NoBuyText")}
            </span>
          )
        )}
      </React.Fragment>
    )
  }

  return (
    <span className="noBuyText">
      {_I_("ViewMarketedItem.SoldOutText")}
    </span>
  )
}

export class ViewMarketedItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      auctionEnded: false
    }

    this.auctionEndTimer = null;
    this.onTickerEvent = (args, eventParams, details) => this.processTickerEvent(details);
  }

  tradeItem() {
    const item = this.props.item;
    LogStream("VIEWUSERINVENTORY").info(`tradeItem(${item.appid}, ${item.hash_name}) `);
    ApplicationEvents.emit_async("market.setCurrentAssetByHash", item.appid, item.hash_name);
  }

  processTickerEvent(details) {
    const topic = details.decodedTopic;

    if (!topic)
      return;

    const { eventType, appId, hashname } = topic;

    if (appId && appId != this.props.item.appid)
      return;

    if (hashname && hashname != this.props.item.hash_name)
      return;

    if (eventType == "auction_ended")
      this.setState({ "auctionEnded": true });

    ApplicationEvents.emit_async("market.updateItem", appId, hashname);
  }

  componentDidMount() {
    ApplicationEvents.on("market.notify.received", this.onTickerEvent);
  }

  componentWillUnmount() {
    ApplicationEvents.off("market.notify.received", this.onTickerEvent);

    if (this.auctionEndTimer)
      clearTimeout(this.auctionEndTimer);
  }

  render() {
    if (this.state.auctionEnded)
      return null;

    const { item, handler, option } = this.props;
    const iconStyle = item.color ? { "backgroundColor": `#${item.color}` } : {};

    const auctionEnded = item?.auction_end_time;
    const isAuction = !!auctionEnded;
    const endedAtStamp = (+auctionEnded > 0) && moment(1000 * auctionEnded).format('DD.MM.YYYY HH:mm');

    let lotMeta = classMixer(this.props.styleItem, item.totalAmount && "inventory");

    let tradableAfter = item.totalAmount ? item?.classInfo?.tradable_after : null;
    let tradableAfterDate = tradableAfter ? new Date(tradableAfter) : null;
    let tradableAfterStr = tradableAfterDate ? moment(tradableAfterDate).format("DD/MM/YYYY HH:mm") : null;

    if (isAuction && (+auctionEnded > 0)) {
      let auctionEndedDelay = auctionEnded * 1000 - Date.now();

      if (auctionEndedDelay > 0) {
        this.auctionEndTimer = setTimeout(() => {
          this.setState({ "auctionEnded": true });
        }, auctionEndedDelay);
      }
    }

    return (
      <div className={lotMeta} onClick={handler}>
        <div className="rarity" style={iconStyle} />

        {isAuction && (
          <div className="auctionRarity" />
        )}

        <div className="lotContent">
          <div className="lotOver">
            <img className="main" key={item.icon} src={item.icon}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "images/broken_item.png";
              }} />
          </div>
        </div>
        <div className="lotMeta">
          <div className="projectBlock">
            <ViewAppIdIcon appId={item.appid} compact />
          </div>
          <div className="name">{item.name}</div>
        </div>
        <div className="lotMetaDetailed">
          <ViewMarketedItemAmount item={item} option={option} noBuyOrders={!!tradableAfter} />
          {tradableAfterStr ? (
            <span className="noBuyText">
              {_I_("ViewMarketedItem.tradableAfter")} {tradableAfterStr}
            </span>
          ) : (
            <ViewMarketedItemPrice item={item} option={option} isAuction={isAuction} />
          )}

          {isAuction && (
            <div className="auction_ends">
              <img className="currency" src={`images/icons/auction_yellow.png`} /> {endedAtStamp}
            </div>
          )}
        </div>
      </div>
    );
  }
};

export class ViewMarketedItemsList extends React.Component {

  selectItem(item, type) {
    ApplicationEvents.emit_async("market.setCurrentAssetByHash", item.appid, item.hash_name, type);
  }

  styleActive(item) {
    let highlight = "lot";
    if (!item)
      highlight += " disabled";

    return highlight
  }

  render() {
    const indexShift = this.props.indexShift;
    const { items, options, isLoading } = this.props;

    if (isLoading)
      return <ItemsContentPreloader />

    if (!items || items.length == 0) {
      return <div className="nothingFound">{_I_("ViewItemSearch.stub.empty")}</div>;
    }

    return (
      <div className="lots grid">
        {
          items.map((item, key) => (
            <ViewMarketedItem
              key={`i_${indexShift}_${key}`}
              handler={() => this.selectItem(item, true)}
              item={item}
              styleItem={this.styleActive(item)}
              option={options}
            />
          ))
        }
      </div>
    );
  }
};

export const ViewItemSearch = observer(
  class ViewItemSearch extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        foundItems: [],
        lastFound: 0,
        skip: 0,
        words: "[]",
        sortOrder: "",
        pagesize: 0,
        preloading: true
      };

      reaction(
        () => [MarketStore.currentAppId, MarketStore.filterTags],
        () => {
          this.setState({ "skip": 0 });
          this.searchItems();
        }
      )

      reaction(
        () => MarketStore.filterText,
        () => {
          this.onNewSearch();
        }
      )

      this.handleTableScroll = (scrollValues, scrollBarRef) => this.loadPageIfNeeded(scrollValues, scrollBarRef);
    }

    searchItems() {
      this.pageSize().then(() => {
        const extraElementCount = 1; // Number of elements to get to determine if there is a next page
        const words = JSON.parse(this.state.words);

        if (words.length && words[0])
          words[0] = '"' + words[0].replace(/"/g, "") + '"';

        const apps = [MarketStore.currentAppId].filter((it) => +it > 0);
        const tags = MarketStore.filterTags;
        const fetchCount = this.state.pagesize + extraElementCount;
        const foundItems = this.state.foundItems;
        const skip = this.state.skip;

        MarketSearch(
          words, apps, tags,
          skip, fetchCount,
          this.state.sortOrder,
          this.props.options
        ).then((assets) => {
          LogStream("VIEWMARKETEDITEMSLIST").info("searchItems -> ", assets);

          const allItems = (skip > 0) ?
            foundItems.concat(assets.slice(1, assets.length)) : assets;

          this.setState({ "foundItems": allItems, "lastFound": assets.length, "preloading": false });

          return allItems;

        }).catch((reason) => {
          this.setState({ "foundItems": [], "lastFound": 0 });

          reason = Object.assign({}, reason,
            {
              "caption": "Search Item Error",
              "message": ("Failed to search market for items:\n" +
                reason.message)
            });

          LogStream("VIEWMARKETEDITEMSLIST").error("searchItems - Failed (", reason, ")");
          ApplicationEvents.emit_async("EventLog.append", reason);
        });
      });
    }

    onNewSearch() {
      const filterText = MarketStore.filterText;
      const words = filterText.split(/\s/).filter((w) => !!w);
      const wordsStr = JSON.stringify(words);
      if (wordsStr == this.state.words)
        return; // no updates

      this.setState({ "skip": 0, "words": wordsStr });
    }

    componentDidMount() {
      const appIds = (this.props.appIds || []).map(it => +it).filter(it => it > 0);
      const lruAppId = +(loadCurrentApp());
      const currentAppId = (appIds.length == 1) ? appIds[0] : lruAppId;

      if ((currentAppId > 0) && (appIds.indexOf(currentAppId) >= 0)) {
        MarketStore.setCurrentAppId(currentAppId);
        return;
      }

      this.searchItems();
    }

    loadPageIfNeeded(scrollValues, scrollbar) {
      scrollValues = scrollValues || scrollbar.current.getScrollValues();

      if (scrollValues.clientHeight && (scrollValues.scrollHeight - scrollValues.scrollTop < 1.5 * scrollValues.clientHeight))
        if (!this.state.lastFound || this.state.lastFound > this.state.pagesize)
          this.setState({ "skip": this.state.skip + this.state.pagesize })
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.options != this.props.options) {
        this.setState({ "skip": 0 })
      }

      if (prevProps.options != this.props.options)
        this.setState({ "preloading": true });

      if (
        (prevState.skip != this.state.skip) ||
        (prevState.words != this.state.words) ||
        (prevState.sortOrder != this.state.sortOrder) ||
        (prevProps.options != this.props.options) ||
        (prevProps.locale != this.props.locale)
      ) {
        this.searchItems();
      }
    }

    toggleSortOrder(sortOrder) {
      if (sortOrder == this.state.sortOrder) {
        return; // no updates
      }

      this.setState({ "skip": 0, sortOrder })
    }

    renderAppFilter() {
      return (
        <ViewMarketAppFilterEditor
          appIds={this.props.appIds}
        />
      )
    }

    async pageSize() {
      let screenWidth = this.props.scrollbar?.current?.clientWidth || 17;
      const lotWidth = Math.trunc((screenWidth * 17) / 100);
      let pagesizeContent = Math.trunc((screenWidth / lotWidth) * 3);
      await this.setState({ "pagesize": pagesizeContent });
    }

    render() {
      const { currentAsset, options } = this.props;

      return (
        <React.Fragment>
          <Scrollbar ref={this.props.scrollbar} id="content"
            style={{ display: this.props.hidden ? "none" : "" }} defaultStyles={false}
            scrollX={false} onScrollStop={this.handleTableScroll}>
            <div className="appScreen" screen="market">
              <div className="screenBody">
                <div className="controls">
                  <div className="left">
                    {this.renderAppFilter()}
                  </div>
                  <div className="center">
                  </div>
                  <div className="right sorting-wrapper">
                    <ViewMarketSortOrder
                      sortOrder={this.state.sortOrder}
                      onChange={(o) => this.toggleSortOrder(o)}
                      locale={this.props.locale}
                    />
                  </div>
                  <ViewMarketTagsTerms
                    onUpdate={() => this.toggleAppFilter()}
                  />
                </div>
                <ViewMarketedItemsList
                  items={this.state.foundItems}
                  indexShift={this.state.skip}
                  pagesize={this.state.pagesize}
                  currentAsset={currentAsset}
                  options={options}
                  isLoading={this.state.preloading}
                  scrollbar={this.props.scrollbar}
                />
              </div>
            </div>
          </Scrollbar>
        </React.Fragment>
      );
    }
  }
)