import { storeToken } from 'api/authentic';
import AppConfigsMenu from 'components/appConfigsMenu';
import ApplicationEvents from 'components/applicationEvents';
import { AuthTokenChecker } from 'components/setup/authTokenChecker';
import { BootButton } from 'generics/buttons';
import { IconFontAwesome } from 'generics/icons';



function handleRelogin()  {
  ApplicationEvents.emit_async("auth.goToRelogin", "dev.relogin");
}


export function DevAuthenticationDialog(  props  )  {
  return (
    <div className="dev-dialog panel panel-default">
      <div className="panel-body">

        <div className="well well-sm">
          <label><u className="text-danger">DEVMODE:</u> Cirquit select</label>
          <AppConfigsMenu dropup />
        </div>

        <div className="well well-sm text-center">
          <BootButton onClick={handleRelogin} block>
            <IconFontAwesome type="user" />&nbsp;Login via SSO
          </BootButton>
        </div>

        <AuthTokenChecker readOnly
            token={props.stealAdmToken}
            onClick={storeToken}
          >
          <u className="text-danger">DEVMODE:</u> Charadmin token
        </AuthTokenChecker>

        <AuthTokenChecker
            token={props.lastUsedToken}
            onClick={storeToken}
          >
          <u className="text-danger">DEVMODE:</u> User supplied token
        </AuthTokenChecker>

        {!!props.onClose &&
          <div className="well well-sm text-center">
            <BootButton onClick={props.onClose} block>
              <IconFontAwesome type="remove" />&nbsp;Close
            </BootButton>
          </div>}

      </div>
    </div>
  );
}

