
import ApplicationEvents from 'components/applicationEvents';
import MoneyUtil from 'components/moneyUtil';
import { _I_ } from 'components/translate';
import { LogStream } from 'generics/common';
import {AuctionBid, GJN} from 'generics/marketIcons';
import React from 'react';

LogStream.enable("VIEWTRADEDIALOG");

function formatPreffered(normalPrice, localPrice, currency)  {
  return MoneyUtil.formatMoney(
    MoneyUtil.preferMoney(normalPrice, localPrice, currency),
    currency
  );
}

class DealEventTickerEntry  extends React.Component  {
  render()  {
    const  {  normalPrice,  localPrice,  buyer,  seller  } = this.props;
    const price = formatPreffered(normalPrice, localPrice);
    const title = `${buyer} ${_I_('DealEventTickerEntry.purchasedFrom')} ${seller} ${_I_("DealEventTickerEntry.for")} ${price}`;
    return (
      <div className="ticker" title={title}>
        <img className='icon' src="images/icons/order_done.png" />
        <div className='price'>{price}</div>
        <GJN />
        <div className='nick'>{buyer}</div>
        <div className='tradeMessage'>{_I_("DealEventTickerEntry.purchasedFrom")}</div>
        <div className='nick'>{seller}</div>
      </div>
    );
  }
}

class BookSellTickerEntry  extends React.Component  {
  render()  {
    const  {  normalPrice,  localPrice,  userNick,  } = this.props;
    const price = formatPreffered(normalPrice, localPrice);
    const title = `${userNick} ${_I_("BookSellTickerEntry.listedItem")} ${price}`;
    return (
      <div className="ticker" title={title}>
        <img className='icon' src="images/icons/order_buy.png" />
        <div className='price'>{price}</div>
        <GJN />
        <div className='nick'>{userNick}</div>
        <div className='tradeMessage'>{_I_("BookSellTickerEntry.listedItem")}</div>
      </div>
    );
  }
}

class BookBuyTickerEntry  extends React.Component  {
  render()  {
    const  {  normalPrice,  localPrice,  userNick,  amount,  } = this.props;
    const price = formatPreffered(normalPrice, localPrice);
    const title = `${userNick} ${_I_("BookBuyTickerEntry.created")} ${amount} ${_I_("BookBuyTickerEntry.for")} ${price}`;
    return (
      <div className="ticker" title={title}>
        <img className='icon' src="images/icons/order_sell.png" />
        <div className='price'>{price}</div>
        <GJN />
        <div className='nick'>{userNick}</div>
        <div className='tradeMessage'>{_I_("BookBuyTickerEntry.created")} {amount} {_I_("BookBuyTickerEntry.for")}</div>
      </div>
    );
  }
}

class BookAuctionTickerEntry  extends React.Component  {
  render()  {
    const  {  normalPrice,  localPrice,  userNick,  } = this.props;
    const price = formatPreffered(normalPrice, localPrice);
    const title = `${price} ${userNick}`
    return (
      <div className="ticker" title={title}>
        <AuctionBid />
        <div className='price'>{price}</div>
        <GJN />
        <div className='nick'>{userNick}</div>
      </div>
    );
  }
}

export default class ViewTradeTickerLog extends React.Component  {
  constructor(props)  {
    super(props);
    const tickerEvents = Array( this.props.limitTickerLog );
    tickerEvents[0] = (<div className="ticker message"><b>{_I_("ViewTradeTickerLog.waiting")}</b></div>);
    this.state = { tickerEvents };

    this.onTickerEvent = (...rest) => this.onTickerEvent__(...rest);
  }

  static processBookEventRecord(bookRecord)  {
    const logType = bookRecord.lt;

    if (logType == 'SET')  {
      const orderType = bookRecord.ot;
      if (orderType == 'SELL')
        return (<BookSellTickerEntry normalPrice={bookRecord.normalPrice}
                                     localPrice={bookRecord.localPrice}
                                     currency={bookRecord.currency}
                                     userNick={bookRecord.user.nick}  />);
      if (orderType == 'BUY')
        return <BookBuyTickerEntry  normalPrice={bookRecord.normalPrice}
                                    localPrice={bookRecord.localPrice}
                                    amount={bookRecord.amount}
                                    currency={bookRecord.currency}
                                    userNick={bookRecord.user.nick}  />;
    }
  }

  static processBidEventRecord(bookRecord, updateTradeState)  {
    const logType = bookRecord.lt;
    updateTradeState(true);

    if (logType == 'SET')  {
      return (
        <BookAuctionTickerEntry  normalPrice={bookRecord.normalPrice}
                                 localPrice={bookRecord.localPrice}
                                 currency={bookRecord.currency}
                                 userNick={bookRecord.user.nick}  />
      );
    }
  }

  onTickerEvent__( args, kwargs, details )  {
    const topic = details.decodedTopic;
    if ( !topic )                                         return;
    if ( this.props.market_name != topic.hashname )       return;
    if ( this.props.appid != topic.appId )                return;
    let events = [];
    if (topic.eventType == "deal")
      events.push(
        <DealEventTickerEntry normalPrice={kwargs.normalPrice}
                              localPrice={kwargs.localPrice}
                              currency={kwargs.currency}
                              buyer={kwargs.buyer}
                              seller={kwargs.seller}  />
      );

    if (topic.eventType === "book") {
      const bookRecord = kwargs.changes;

      events.push(
        ...(bookRecord || []).map(record => {
          return this.props.auction
            ? ViewTradeTickerLog.processBidEventRecord(record, this.props.updateTradeState)
            : ViewTradeTickerLog.processBookEventRecord(record);
        })
      );
    }

    events = events.filter( (e) => !!e );
    if ( events.length )
      this.setState(
        (prevState, props) => ({
          "tickerEvents" : ([ ...prevState.tickerEvents,
            ...events ]).slice(-props.limitTickerLog)
        })
      );
  }
  componentDidMount()  {
    ApplicationEvents.on("market.notify.received", this.onTickerEvent );
  }
  componentWillUnmount()  {
    ApplicationEvents.off("market.notify.received", this.onTickerEvent );
  }
  render()  {
    const fn = (entry, key) => (
      <div className="view-market-ticker-entry" key={`i${key}`}>
        <img className="subtract" src="images/icons/Subtract.png" />
        {entry}
      </div>);
    const items = this.state.tickerEvents.map( fn );
    return (<div className="view-market-ticker-root">{items.reverse()}</div>);
  }
}