import { utils_api } from 'generics/common.js';

export class SettingsInjections  {

  static getUsedCircuit()  {
    return utils_api.frozenMerge( SettingsInjections._circuit );
  }


  static getUsedCircuitName()  {
    return SettingsInjections._circuitName;
  }

  static getDevMode()  {
    return SettingsInjections._devMode;
  }

  static getUsedLocale()  {
    //  HINT: its defaulting, not merging!
    return utils_api.frozenMerge( SettingsInjections._Locale ||
                                  {  name : "english",  code : "en_US",  icon : "en",  enabled : true  });
  }

  static getUsedCurrency() {
    return "gjn";
  }

  static setCredentials( creds )  {
    SettingsInjections._creds = Object.assign( {}, creds );
  }

  static getVersion()  {
    return "@@BUILDSTAMPMARK@@";
  }

  static getMode() {
    return process.env.REACT_APP_MARKET_MODE || 'default';
  }

  static getLoginHost() {
    return process.env.REACT_APP_LOGIN_HOST || '';
  }

  static getStoreHost() {
    return process.env.REACT_APP_STORE_HOST || '';
  }

  static getLegalHost() {
    return process.env.REACT_APP_LEGAL_HOST || '';
  }
};


