import { ViewItemIconic } from 'components/commonAssets';
import { spawnDialogWindow } from 'components/dialogHolder';
import MoneyUtil from 'components/moneyUtil';
import { _I_ } from 'components/translate';
import { TrivialDialog } from 'components/trivialDialog';
import { IconFontAwesome } from 'generics/icons';
import { SettingsInjections } from 'generics/settingsInjections';
import React from 'react';

class ViewDoubleSellErrorDialog extends React.Component {
  onBackdropTouch() {
    this.props.removeModal();
  }


  render() {
    const { removeModal, } = this.props;
    return (
      <TrivialDialog
        prefixL10N={`DoubleSellErrorModal`}
        panelType="warning"
        onCloseButton={removeModal}
        onOkButton={removeModal}
        bodyIcon={<IconFontAwesome type="exclamation-triangle" size="3x" fixedWidth />}
        mixClasses={["view-messagebox-dialog"]}
      />
    );
  }
}

class ViewOnBuyReserveFailDialog extends React.Component {
  onBackdropTouch() {
    this.props.removeModal();
  }


  render() {
    const { removeModal, amount, price, class_info } = this.props;

    const market = SettingsInjections.getUsedCurrency();
    const priceText = MoneyUtil.formatMoney(price, market);
    const totalText = MoneyUtil.formatMoney(amount * price, market);

    return (
      <TrivialDialog
        prefixL10N={`OnBuyReserveFailModal`}
        panelType="warning"
        onCloseButton={removeModal}
        onOkButton={removeModal}
        bodyIcon={<IconFontAwesome type="exclamation-triangle" size="3x" fixedWidth />}
        mixClasses={["view-messagebox-dialog"]}
      >
        <ViewItemIconic classInfo={class_info} />
        <div>
          {_I_("OnBuyReserveFailModal.youNeed1")}
          <b> {amount} x {priceText} = {totalText} </b> {_I_("OnBuyReserveFailModal.youNeed2")}
        </div>
        <div>
          {_I_("OnBuyReserveFailModal.tryRefresh")}
        </div>
      </TrivialDialog>
    );
  }
}

class ViewWrongBidPriceErrorModal extends React.Component {
  onBackdropTouch() {
    this.props.removeModal();
  }


  render() {
    const { removeModal, class_info } = this.props;

    return (
      <TrivialDialog
        prefixL10N={`DoubleRateErrorModal`}
        panelType="warning"
        onCloseButton={removeModal}
        onOkButton={removeModal}
        bodyIcon={<IconFontAwesome type="exclamation-triangle" size="3x" fixedWidth />}
        mixClasses={["view-messagebox-dialog"]}
      >
        <ViewItemIconic classInfo={class_info} />
      </TrivialDialog>
    );
  }
}

class ViewAuctionFinishedErrorModal extends React.Component {
  onBackdropTouch() {
    this.props.removeModal();
  }

  render() {
    const { removeModal, class_info } = this.props;

    return (
      <TrivialDialog
        prefixL10N={`AuctionFinishedErrorModal`}
        panelType="warning"
        onCloseButton={removeModal}
        onOkButton={removeModal}
        bodyIcon={<IconFontAwesome type="exclamation-triangle" size="3x" fixedWidth />}
        mixClasses={["view-messagebox-dialog"]}
      >
        <ViewItemIconic classInfo={class_info} />
      </TrivialDialog>
    );
  }
}

export function spawnDoubleSellErrorModal() {
  spawnDialogWindow(ViewDoubleSellErrorDialog, {});
}


export function spawnOnBuyReserveFailDialog(appid, hash_name, class_info, amount, price) {
  spawnDialogWindow(ViewOnBuyReserveFailDialog, {
    appid, hash_name, class_info, amount, price,
  });
}

export function spawnWrongBidPriceErrorModal(appid, hash_name, class_info) {
  spawnDialogWindow(ViewWrongBidPriceErrorModal, {
    appid, hash_name, class_info,
  });
}


export function spawnAuctionFinishedErrorModal(appid, hash_name, class_info) {
  spawnDialogWindow(ViewAuctionFinishedErrorModal, {
    appid, hash_name, class_info,
  });
}

