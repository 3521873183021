import { staticConfigs } from 'components/setup/configSources';
import { BootButton } from 'generics/buttons';
import { utils_api } from 'generics/common';
import { SourceListener } from 'generics/dataBound';
import { classMixer } from 'generics/utils';
import React from 'react';

function setUsedCircuit( name )  {
  utils_api.setStored( ["MarketApp", "LRU", "circuit"], name );
  utils_api.sheduleReload();
}


function AppConfigsSelectorEntry({ val, name, active })  {
  const title = JSON.stringify(val, null, '  ');
  return (<li   title={title}
                className={classMixer(active && "active")}
                onClick={() => setUsedCircuit(name)}
              >
            <a>{name}</a>
          </li>);
}


export default class AppConfigsMenu extends React.Component  {
  constructor(props)  {
    super(props);
    this.state = { open : false };
  }


  toggleOpen = ()  =>  {
    this.setState({ open: !this.state.open });
  }


  render()  {
    const dropdownClasses = classMixer(
        "dropdown",  this.props.dropup && "dropup",  this.state.open && "open"
      );

    return (<SourceListener conf={staticConfigs} >{
          ({conf:{  circuits,  usedCircuitName,  siteSettings  }}) => {
              if ( !siteSettings.devMode )
                return null;
              const entries = Object.entries(circuits);
              if ( entries.length < 2 )
                return null;

              return (
                  <div  className={dropdownClasses} >
                    <BootButton block mixClasses="dropdown-toggle"
                                onClick={this.toggleOpen}
                        >
                        Config&nbsp;[{usedCircuitName}]
                        &nbsp;<span className="caret" />
                    </BootButton>
                    <ul className="dropdown-menu">{
                      entries.map(
                        ([name, val]) =>  (<AppConfigsSelectorEntry
                          key={name}
                          val={val}
                          name={name}
                          active={name == usedCircuitName} />)
                      )
                    }</ul>
                  </div>
                );
          }
        }</SourceListener>);
  }
}

