import { cachedBalance, WalletCheckExpiration } from 'api/walletAPICall';
import ApplicationEvents from 'components/applicationEvents';
import MoneyUtil from 'components/moneyUtil';
import { SourceListener } from 'generics/dataBound';
import { reaction } from "mobx";
import { observer } from 'mobx-react';
import React from 'react';
import { MarketStore } from 'stores';

const WalletEventsSentry = observer(
  class WalletEventsSentry extends React.Component {
    constructor(props) {
      super(props);

      this.timer = null;
      this.again = false;
      this.rateTimeoutMs = 10000;

      this.onTickerEvent = (...rest) => this.onTickerEvent__(...rest);
      this.onUpdateEvent = () => this.schedule(true);
      this.onOrdersUpdateEvent = () => this.schedule(false);
    }

    componentDidMount() {
      ApplicationEvents.on("market.notify.received", this.onTickerEvent);
      ApplicationEvents.on("market.buyCoins", this.onUpdateEvent);
      ApplicationEvents.on("market.ordersUpdate", this.onOrdersUpdateEvent);

      this.schedule(true);

      reaction(
        () => [MarketStore.credentials],
        () => {
          this.onUpdateEvent();
        }
      )
    }

    componentWillUnmount() {
      ApplicationEvents.off("market.notify.received", this.onTickerEvent);
      ApplicationEvents.off("market.buyCoins", this.onUpdateEvent);
      ApplicationEvents.off("market.ordersUpdate", this.onOrdersUpdateEvent);
    }

    onTickerEvent__(args, kwargs, details) {
      const { eventType, buyerUserId, sellerUserId } = ((details || {}).decodedTopic || {});
      const ownerUserId = MarketStore.userId;

      if (eventType == "deal") {
        if (ownerUserId == buyerUserId || ownerUserId == sellerUserId) {
          this.schedule(false);
        }
      }
    }

    onTimerEnd() {
      this.timer = null;  // timer fired, ratelimiting period clear
      if (this.again) {
        // reschedule due to requested under timer
        this.schedule(false);
      }
    }

    schedule(forced) {
      if (this.timer) {
        // got requested under ratelimiting timer
        if (!forced) {
          this.again = true;
          return;
        }
        clearTimeout(this.timer);
        this.timer = null
      }

      this.again = false;  // shouldn't repeat request at the end of ratelimiting period
      this.timer = setTimeout(evt => this.onTimerEnd(), this.rateTimeoutMs);

      WalletCheckExpiration(true);
    }

    render() {
      return null;
    }
  }
)

export class WalletEntry extends React.Component {
  render() {
    const { currency, balance } = this.props;
    // round balances down to nearest cent
    const floorBalance = Math.floor((+balance || 0) / 100) * 100;
    const isSimple = this.props.simple;

    return (
      <React.Fragment>
        <div className="amount">
          {MoneyUtil.formatMoney(floorBalance)}
        </div>
        {!isSimple &&
          <div className="currency">
            {MoneyUtil.formatCurr(currency)}
          </div>
        }
      </React.Fragment>
    );
  }
}

export class WalletEntryList extends React.Component {
  render() {
    const balanceEntries = Object.entries(this.props.balances);
    if (balanceEntries.length == 0)
      return <WalletEntry currency={null} balance={0} />;

    return <span>{balanceEntries.map(
      ([currency, balance], idx) => <WalletEntry key={currency} currency={currency} balance={balance} {...this.props} />
    )}</span>;
  }
}

export default class WalletWidgetIcon extends React.Component {
  render() {
    return (
      <SourceListener balances={cachedBalance}>
        {(sources) => (
          <React.Fragment>
            <WalletEntryList simple balances={sources.balances} />
            <WalletEventsSentry />
          </React.Fragment>
        )}
      </SourceListener>
    );
  }
}