
export const TooltipIcon = () => {
  return(
    <svg className='tooltipIcon' width="14" height="7" viewBox="0 0 14 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 7L7.07772 7L0.15544 7L5.56531 0.747876C6.36291 -0.173901 7.79253 -0.1739 8.59013 0.747877L14 7Z" fill="#FFDA7C" />
    </svg>
  )
}

export const ViewInGameIcon = () => {
  return(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 7C6.85714 7 4.57143 11.2857 4 12.7143C4.57143 14.1429 7.42857 17 12 17C16.5714 17 19.4286 14.1429 20 12.7143C19.4286 11.2857 17.1429 7 12 7ZM12 8C7.5 8 5.5 11.4286 5 12.5714C5.5 13.7143 8 16 12 16C16 16 18.5 13.7143 19 12.5714C18.5 11.4286 16.5 8 12 8ZM12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" fill="white"/>
    </svg>
  )
}

export const ExpandIcon = () => {
  return(
    <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path fill="currentColor"
            d="M500 224H376c-13.3 0-24-10.7-24-24V76c0-6.6 5.4-12 12-12h24c6.6 0 12 5.4 12 12v100h100c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12zm-340-24V76c0-6.6-5.4-12-12-12h-24c-6.6 0-12 5.4-12 12v100H12c-6.6 0-12 5.4-12 12v24c0 6.6 5.4 12 12 12h124c13.3 0 24-10.7 24-24zm0 236V312c0-13.3-10.7-24-24-24H12c-6.6 0-12 5.4-12 12v24c0 6.6 5.4 12 12 12h100v100c0 6.6 5.4 12 12 12h24c6.6 0 12-5.4 12-12zm240 0V336h100c6.6 0 12-5.4 12-12v-24c0-6.6-5.4-12-12-12H376c-13.3 0-24 10.7-24 24v124c0 6.6 5.4 12 12 12h24c6.6 0 12-5.4 12-12z" />
    </svg>
  )
}

export const CollapsIcon = () => {
  return(
    <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path fill="currentColor"
            d="M0 212V88c0-13.3 10.7-24 24-24h124c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12H48v100c0 6.6-5.4 12-12 12H12c-6.6 0-12-5.4-12-12zM352 76v24c0 6.6 5.4 12 12 12h100v100c0 6.6 5.4 12 12 12h24c6.6 0 12-5.4 12-12V88c0-13.3-10.7-24-24-24H364c-6.6 0-12 5.4-12 12zm148 212h-24c-6.6 0-12 5.4-12 12v100H364c-6.6 0-12 5.4-12 12v24c0 6.6 5.4 12 12 12h124c13.3 0 24-10.7 24-24V300c0-6.6-5.4-12-12-12zM160 436v-24c0-6.6-5.4-12-12-12H48V300c0-6.6-5.4-12-12-12H12c-6.6 0-12 5.4-12 12v124c0 13.3 10.7 24 24 24h124c6.6 0 12-5.4 12-12z" />
    </svg>
  )
}

export const CloseSquareIcon = () => {
  return(
    <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path fill="currentColor" d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm0 394c0 3.3-2.7 6-6 6H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h404c3.3 0 6 2.7 6 6v340zM356.5 194.6L295.1 256l61.4 61.4c4.6 4.6 4.6 12.1 0 16.8l-22.3 22.3c-4.6 4.6-12.1 4.6-16.8 0L256 295.1l-61.4 61.4c-4.6 4.6-12.1 4.6-16.8 0l-22.3-22.3c-4.6-4.6-4.6-12.1 0-16.8l61.4-61.4-61.4-61.4c-4.6-4.6-4.6-12.1 0-16.8l22.3-22.3c4.6-4.6 12.1-4.6 16.8 0l61.4 61.4 61.4-61.4c4.6-4.6 12.1-4.6 16.8 0l22.3 22.3c4.7 4.6 4.7 12.1 0 16.8z" />
    </svg>
  )
}

export const CloseIcon = () => {
  return(
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4" d="M15.2717 0.220703L17.7804 2.72938L11.5091 9.00063L17.7373 15.2285L15.2282 17.7376L9.0002 11.5095L2.7288 17.7809L0.219727 15.2719L6.49129 9.00063L0.262822 2.77217L2.7715 0.26349L9.0002 6.49186L15.2717 0.220703Z" fill="white" />
    </svg>
  )
}

export const FilterToggler = () => {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M7 2L7 14L13 8L7 2Z" fill="white" fillOpacity="0.5"/>
    </svg>
  )
}

export const AuctionBid = () => {
  return(
    <svg className='icon' xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.41764 0.623995L6.75915 0.965507C6.79155 0.997902 6.81773 1.03596 6.83641 1.07779L6.97413 1.38627L4.71006 3.65033L4.40159 3.51261C4.35976 3.49394 4.3217 3.46775 4.2893 3.43536L3.94779 3.09384C3.80135 2.9474 3.80135 2.70996 3.94779 2.56351L5.88731 0.623995C6.03376 0.477548 6.27119 0.477549 6.41764 0.623995ZM5.02732 3.86341L5.45587 4.29196L0.46292 8.51677C0.125557 8.74955 -0.102053 9.57171 0.42435 10.0981C0.950754 10.6245 1.75056 10.3745 2.0057 10.0595L6.2305 5.06659L6.69941 5.5355L8.8593 3.37561L8.00336 2.51967L5.86293 4.6601L5.86268 4.65985L8.00311 2.51942L7.18721 1.70352L5.02732 3.86341ZM9.19188 3.57337L6.89716 5.86808L7.01017 6.12119C7.02885 6.16302 7.05503 6.20108 7.08743 6.23348L7.42894 6.57499C7.57538 6.72144 7.81282 6.72144 7.95927 6.57499L9.89879 4.63547C10.0452 4.48902 10.0452 4.25159 9.89879 4.10514L9.55727 3.76363C9.52488 3.73123 9.48682 3.70505 9.44499 3.68637L9.19188 3.57337Z" fill="#FFDA7C"/>
      <path d="M6 10.125C6 9.71079 6.33579 9.375 6.75 9.375H10.5C10.9142 9.375 11.25 9.71079 11.25 10.125V10.5H6V10.125Z" fill="#FFDA7C"/>
      <path d="M6.75 9C6.75 8.58579 7.08579 8.25 7.5 8.25H9.75C10.1642 8.25 10.5 8.58579 10.5 9H6.75Z" fill="#FFDA7C"/>
    </svg>
  )
}

export const PixGrayIcon = () => {
  return(
    <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 3H10V13H8V16H6V13H4V16H2V13H0V3H2V0H4V3H6V0H8V3ZM2 5H8V11H2V5Z" fill="white" fillOpacity="0.5"/>
    </svg>
  )
}

export const CoinsGrayIcon = () => {
  return(
    <img src={`images/icons/coins_gray.png`} />
  )
}

export const AuctionEdit = ({size}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 25" fill="none">
      <g clipPath="url(#clip0_3616_215643)">
        <path fillRule="evenodd" clipRule="evenodd" d="M20.3555 8.8698L19.6027 8.11696C19.5451 8.05937 19.4774 8.01282 19.4031 7.97962L18.8433 7.72969L14.2757 12.2973L14.5256 12.857C14.5588 12.9314 14.6054 12.9991 14.663 13.0567L15.4158 13.8095C15.6762 14.0698 16.0983 14.0698 16.3586 13.8095L20.3555 9.81261C20.6159 9.55226 20.6159 9.13015 20.3555 8.8698ZM13.9282 11.702L12.961 10.7348L4.5114 20.7207C4.00112 21.3507 2.40151 21.8506 1.3487 20.7978C0.295894 19.745 0.751115 18.1007 1.42584 17.6351L11.4117 9.1855L10.5246 8.29836L14.8444 3.97858L16.5057 5.63994L18.248 7.38218L13.9282 11.702ZM14.466 3.41412L14.1664 2.74294C14.1332 2.66856 14.0866 2.60091 14.029 2.54332L13.2762 1.79048C13.0158 1.53013 12.5937 1.53013 12.3334 1.79048L8.3365 5.78736C8.07615 6.04771 8.07615 6.46982 8.3365 6.73017L9.08934 7.48301C9.14693 7.5406 9.21458 7.58715 9.28896 7.62036L9.96014 7.92002L14.466 3.41412Z" fill="#FFDA7C"/>
        <path d="M12.5 20.6834C12.5 19.9471 13.097 19.3501 13.8333 19.3501H21.6667C22.403 19.3501 23 19.9471 23 20.6834V21.6001H12.5V20.6834Z" fill="#FFDA7C"/>
        <path d="M14 18.4334C14 17.6971 14.597 17.1001 15.3333 17.1001H20.1667C20.903 17.1001 21.5 17.6971 21.5 18.4334V18.6001H14V18.4334Z" fill="#FFDA7C"/>
      </g>
      <defs>
        <clipPath id="clip0_3616_215643">
          <rect width="24" height="24" fill="white" transform="translate(0.5 0.600098)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export const WinningBid = () => {
  return (
    <svg className='icon' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.55626 0.832238L9.01161 1.28759C9.05481 1.33078 9.08972 1.38152 9.11462 1.4373L9.29808 1.84821L6.27911 4.86718L5.8682 4.68373C5.81242 4.65882 5.76168 4.62391 5.71848 4.58072L5.26313 4.12537C5.06787 3.93011 5.06787 3.61352 5.26313 3.41826L7.84916 0.832237C8.04442 0.636975 8.361 0.636976 8.55626 0.832238ZM6.70222 5.15118L7.27394 5.7229L0.616413 11.3562C0.166596 11.6666 -0.136884 12.7628 0.564987 13.4647C1.26686 14.1665 2.33326 13.8332 2.67345 13.4132L8.30674 6.75571L8.93199 7.38097L11.8118 4.50111L10.6702 3.35948L10.6702 3.35947L9.58207 2.27133L6.70222 5.15118ZM12.2554 4.76463L9.19551 7.82456L9.34593 8.16149C9.37084 8.21726 9.40575 8.26801 9.44895 8.3112L9.90429 8.76655C10.0996 8.96181 10.4161 8.96181 10.6114 8.76655L13.1974 6.18052C13.3927 5.98526 13.3927 5.66868 13.1974 5.47342L12.7421 5.01807C12.6989 4.97488 12.6481 4.93996 12.5924 4.91506L12.2554 4.76463Z" fill="#31E3EE"/>
      <path d="M8 13.5C8 12.9477 8.44772 12.5 9 12.5H14C14.5523 12.5 15 12.9477 15 13.5V14H8V13.5Z" fill="#31E3EE"/>
      <path d="M9 12C9 11.4477 9.44772 11 10 11H13C13.5523 11 14 11.4477 14 12H9Z" fill="#31E3EE"/>
    </svg>
  )
}

export const Outbid = () => {
  return (
    <svg className='icon' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.55626 0.832238L9.01161 1.28759C9.05481 1.33078 9.08972 1.38152 9.11462 1.4373L9.29808 1.84821L6.27911 4.86718L5.8682 4.68373C5.81242 4.65882 5.76168 4.62391 5.71848 4.58072L5.26313 4.12537C5.06787 3.93011 5.06787 3.61352 5.26313 3.41826L7.84916 0.832237C8.04442 0.636975 8.361 0.636976 8.55626 0.832238ZM6.70222 5.15118L7.27394 5.7229L0.616413 11.3562C0.166596 11.6666 -0.136884 12.7628 0.564987 13.4647C1.26686 14.1665 2.33326 13.8332 2.67345 13.4132L8.30674 6.75571L8.93199 7.38097L11.8118 4.50111L10.6702 3.35948L10.6702 3.35947L9.58207 2.27133L6.70222 5.15118ZM12.2554 4.76463L9.19551 7.82456L9.34593 8.16149C9.37084 8.21726 9.40575 8.26801 9.44895 8.3112L9.90429 8.76655C10.0996 8.96181 10.4161 8.96181 10.6114 8.76655L13.1974 6.18052C13.3927 5.98526 13.3927 5.66868 13.1974 5.47342L12.7421 5.01807C12.6989 4.97488 12.6481 4.93996 12.5924 4.91506L12.2554 4.76463Z" fill="#FF7D6B"/>
      <path d="M8 13.5C8 12.9477 8.44772 12.5 9 12.5H14C14.5523 12.5 15 12.9477 15 13.5V14H8V13.5Z" fill="#FF7D6B"/>
      <path d="M9 12C9 11.4477 9.44772 11 10 11H13C13.5523 11 14 11.4477 14 12H9Z" fill="#FF7D6B"/>
    </svg>
  )
}

export const OrderDone = () => {
  return (
    <svg className='icon' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M10.5 21L1.5 12L6 7.5L10.5 12L19.5 3L24 7.5L10.5 21Z" fill="url(#paint0_linear_3895_36799)"/>
      <defs>
        <linearGradient id="paint0_linear_3895_36799" x1="12" y1="3" x2="12" y2="22.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EED600"/>
          <stop offset="1" stopColor="#EE8100"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export const CalendarAuction = () => {
  return (
    <img src={`images/calendar.png`} />
  )
}

export const Amount = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
      <g opacity="0.3">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.5 2.4502H8C7.58579 2.4502 7.25 2.78598 7.25 3.2002V3.9502H13.25C14.0784 3.9502 14.75 4.62177 14.75 5.4502V11.4502H15.5C15.9142 11.4502 16.25 11.1144 16.25 10.7002V3.2002C16.25 2.78598 15.9142 2.4502 15.5 2.4502ZM14.75 12.2002V12.9502C14.75 13.7786 14.0784 14.4502 13.25 14.4502H12.5V15.2002C12.5 16.0286 11.8284 16.7002 11 16.7002H3.5C2.67157 16.7002 2 16.0286 2 15.2002V7.7002C2 6.87177 2.67157 6.2002 3.5 6.2002H4.25V5.4502C4.25 4.62177 4.92157 3.9502 5.75 3.9502H6.5V3.2002C6.5 2.37177 7.17157 1.7002 8 1.7002H15.5C16.3284 1.7002 17 2.37177 17 3.2002V10.7002C17 11.5286 16.3284 12.2002 15.5 12.2002H14.75ZM5.75 4.7002H13.25C13.6642 4.7002 14 5.03598 14 5.4502V12.9502C14 13.3644 13.6642 13.7002 13.25 13.7002H12.5V7.7002C12.5 6.87177 11.8284 6.2002 11 6.2002H5V5.4502C5 5.03598 5.33579 4.7002 5.75 4.7002Z" fill="white"/>
      </g>
    </svg>
  )
}

export const StartingPrice = () => {
  return (
    <img src={`images/startingPrice.png`} />
  )
}

export const Timelapse = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
      <g opacity="0.3">
        <path fillRule="evenodd" clipRule="evenodd" d="M2 9.80029C2 5.65816 5.35786 2.30029 9.5 2.30029C13.6421 2.30029 17 5.65816 17 9.80029C17 13.9424 13.6421 17.3003 9.5 17.3003C5.35786 17.3003 2 13.9424 2 9.80029ZM9.5 3.80029V9.80029L14.3003 6.20008C15.0536 7.20291 15.5 8.44947 15.5 9.80029C15.5 13.114 12.8137 15.8003 9.5 15.8003C6.18629 15.8003 3.5 13.114 3.5 9.80029C3.5 6.48658 6.18629 3.80029 9.5 3.80029Z" fill="white"/>
      </g>
    </svg>
  )
}

export const GJN = () => {
  return (
    <svg className='currency' xmlns="http://www.w3.org/2000/svg" width="9" height="13" viewBox="0 0 9 13" fill="none">
      <path d="M8.25 3.3999V1.8999H5.25V0.399902H3.75V1.8999H0.75V10.8999H3.75V12.3999H5.25V10.8999H8.25V4.8999H3.75V7.8999H5.25V6.3999H6.75V9.3999H2.25V3.3999H8.25Z" fill="white" fillOpacity="0.7"/>
    </svg>
  )
}

export const GJN_GOLD = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none">
      <g opacity="0.5" clipPath="url(#clip0_3660_66055)">
        <path d="M7.5 3.75V2.25H4.5V0.75H3V2.25H0V11.25H3V12.75H4.5V11.25H7.5V5.25H3V8.25H4.5V6.75H6V9.75H1.5V3.75H7.5Z" fill="#FFDA7C"/>
      </g>
      <defs>
        <clipPath id="clip0_3660_66055">
          <rect width="7.5" height="12" fill="white" transform="translate(0 0.75)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export const GJN_USER_BID = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
      <g opacity="0.5" clipPath="url(#clip0_3660_66080)">
        <path d="M7.5 3V1.5H4.5V0H3V1.5H0V10.5H3V12H4.5V10.5H7.5V4.5H3V7.5H4.5V6H6V9H1.5V3H7.5Z" fill="black" fillOpacity="0.8"/>
      </g>
      <defs>
        <clipPath id="clip0_3660_66080">
          <rect width="7.5" height="12" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export const EventIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g opacity="0.7">
        <path d="M7 4L11 8L7 12" stroke="white" strokeWidth="2" strokeLinejoin="round"/>
      </g>
    </svg>
  )
}

export const NotificationsDisabled = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g opacity="0.5">
        <path d="M4 19V17H6V10C6 8.61667 6.41667 7.38733 7.25 6.312C8.08333 5.23733 9.16667 4.53333 10.5 4.2V3.5C10.5 3.08333 10.646 2.72933 10.938 2.438C11.2293 2.146 11.5833 2 12 2C12.4167 2 12.7707 2.146 13.062 2.438C13.354 2.72933 13.5 3.08333 13.5 3.5V4.2C14.8333 4.53333 15.9167 5.23733 16.75 6.312C17.5833 7.38733 18 8.61667 18 10V17H20V19H4ZM12 22C11.45 22 10.9793 21.8043 10.588 21.413C10.196 21.021 10 20.55 10 20H14C14 20.55 13.8043 21.021 13.413 21.413C13.021 21.8043 12.55 22 12 22Z" fill="white"/>
      </g>
    </svg>
  )
}

export const NotificationsEnabled = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g opacity="0.5">
        <path fillRule="evenodd" clipRule="evenodd" d="M4 17V19H20V17H18V10C18 8.61667 17.5833 7.38733 16.75 6.312C15.9167 5.23733 14.8333 4.53333 13.5 4.2V3.5C13.5 3.08333 13.354 2.72933 13.062 2.438C12.7707 2.146 12.4167 2 12 2C11.5833 2 11.2293 2.146 10.938 2.438C10.646 2.72933 10.5 3.08333 10.5 3.5V4.2C9.16667 4.53333 8.08333 5.23733 7.25 6.312C6.41667 7.38733 6 8.61667 6 10V17H4ZM10.588 21.413C10.9793 21.8043 11.45 22 12 22C12.55 22 13.021 21.8043 13.413 21.413C13.8043 21.021 14 20.55 14 20H10C10 20.55 10.196 21.021 10.588 21.413ZM8 12.5L11.5 16L16.5 11L15 9.5L11.5 13L9.5 11L8 12.5Z" fill="white"/>
      </g>
    </svg>
  )
}

export const Arrow = () => {
  return (
    <svg className="arrowIcon" xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8" fill="none">
      <path d="M16 8L8 8L-3.49691e-07 8L6.58579 1.41421C7.36684 0.633163 8.63316 0.633165 9.41421 1.41421L16 8Z" fill="#2E3238"/>
    </svg>
  )
}

export const BidCanceled = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.55627 0.832238L9.01161 1.28759C9.05481 1.33078 9.08972 1.38152 9.11462 1.4373L9.29808 1.84821L6.27911 4.86718L5.8682 4.68373C5.81242 4.65882 5.76168 4.62391 5.71848 4.58072L5.26313 4.12537C5.06787 3.93011 5.06787 3.61352 5.26313 3.41826L7.84916 0.832237C8.04442 0.636975 8.361 0.636976 8.55627 0.832238ZM6.70222 5.15118L7.27394 5.7229L0.616414 11.3562C0.166596 11.6666 -0.136884 12.7628 0.564987 13.4647C1.26686 14.1665 2.33326 13.8332 2.67345 13.4132L8.30674 6.75571L8.93199 7.38097L11.8118 4.50111L10.6702 3.35948L10.6702 3.35947L9.58208 2.27133L6.70222 5.15118ZM12.2554 4.76463L9.19551 7.82456L9.34594 8.16149C9.37084 8.21726 9.40575 8.26801 9.44895 8.3112L9.90429 8.76655C10.0996 8.96181 10.4161 8.96181 10.6114 8.76655L13.1974 6.18052C13.3927 5.98526 13.3927 5.66868 13.1974 5.47342L12.7421 5.01807C12.6989 4.97488 12.6481 4.93996 12.5924 4.91506L12.2554 4.76463Z" fill="white" fillOpacity="0.5"/>
      <path d="M8 13.5C8 12.9477 8.44772 12.5 9 12.5H14C14.5523 12.5 15 12.9477 15 13.5V14H8V13.5Z" fill="white" fillOpacity="0.5"/>
      <path d="M9 12C9 11.4477 9.44772 11 10 11H13C13.5523 11 14 11.4477 14 12H9Z" fill="white" fillOpacity="0.5"/>
    </svg>
  )
}

export const OrderBuy = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M10 15L10 11C10 11 6.5 11 5 12C2.5 13.6667 1.5 16 1.5 16C1.5 16 1.49996 13 2.99998 10C5.25 5.5 10 5 10 5L10 1L16 8L10 15Z" fill="url(#paint0_linear_3966_40637)"/>
      <defs>
        <linearGradient id="paint0_linear_3966_40637" x1="2" y1="8" x2="16" y2="8" gradientUnits="userSpaceOnUse">
          <stop stopColor="#72FF70"/>
          <stop offset="1" stopColor="#5BDEDE"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export const OrderSell = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M1 9H5C5 9 5 5.5 4 4C2.33333 1.5 0 0.5 0 0.5C0 0.5 3.00001 0.499964 6 1.99998C10.5 4.25 11 9 11 9H15L8 15L1 9Z" fill="url(#paint0_linear_3966_40701)"/>
      <defs>
        <linearGradient id="paint0_linear_3966_40701" x1="8" y1="1" x2="8" y2="15" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF5C70"/>
          <stop offset="1" stopColor="#FF5925"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export const CancelBuy = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M10 15L10 11C10 11 6.5 11 5 12C2.5 13.6667 1.5 16 1.5 16C1.5 16 1.49996 13 2.99998 10C5.25 5.5 10 5 10 5L10 1L16 8L10 15Z" fill="white" fillOpacity="0.5"/>
    </svg>
  )
}

export const CancelSell = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M1 9H5C5 9 5 5.5 4 4C2.33333 1.5 0 0.5 0 0.5C0 0.5 3.00001 0.499964 6 1.99998C10.5 4.25 11 9 11 9H15L8 15L1 9Z" fill="white" fillOpacity="0.5"/>
    </svg>
  )
}