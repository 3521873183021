import React from "react";
import { SourceListener } from "../generics/dataBound";
import { blockedPairsSource, getBlockedPairAffectAfter } from "../api/rpcAPICall";
import ItemInfoBlock from "./itemInfoBlock";
import { ClassInfoTagList, ViewAmountBadge, ViewItemInfoIconic } from "./commonAssets";
import { ClassInfoDescriptions, ClassInfoDropDescriptions } from "./classInfoDescriptions";
import ViewAssetWarnings from "../generics/restriction";
import { ViewInGamePanel } from "./hooks/itemDetailedHooks";
import { LimitedAccountPanel } from "./credentialsIcon";
import MarketDetails from "./marketDetails";
import { useWarningState } from "../generics/marketHooks";

const ItemAssetPage = ({
                         pairKey,
                         classInfo,
                         appid,
                         brief,
                         exchangeWith,
                         droppedItem,
                         amount,
                         ctxid,
                         instances,
                         totalAmount
}) => {

  const { warningState } = useWarningState();

  return (
    <div className="screenBody">
      <SourceListener blockedPairs={blockedPairsSource}>{
        ({ blockedPairs }) => {
          const blockedAfter = getBlockedPairAffectAfter(blockedPairs[pairKey]);
          return (
            <div className="commodityCard">

              <ItemInfoBlock appid={appid} classInfo={classInfo} />

              <div className="commodityInfo">
                <ViewItemInfoIconic classInfo={classInfo} large>
                  <ViewAmountBadge amount={amount} showLowCounts />
                </ViewItemInfoIconic>

                <ClassInfoDropDescriptions
                  appid={appid}
                  descriptions={exchangeWith}
                  owner_descriptions={classInfo.owner_descriptions}
                  type="exchange_with"
                  parent_hashName={classInfo.market_hash_name}
                />

                <div className="summary">
                  <ClassInfoTagList classInfo={classInfo} appId={appid} />
                  <ViewAssetWarnings
                    classInfo={classInfo}
                    blockedAfter={blockedAfter}
                    brief={brief}
                  />
                  <ClassInfoDescriptions
                    appId={appid}
                    descriptions={classInfo.descriptions}
                    owner_descriptions={classInfo.owner_descriptions}
                    type="text"
                  />
                  <ViewInGamePanel classInfo={classInfo} appId={appid} />
                </div>
              </div>
              <div className="commodityContents">
                <ClassInfoDropDescriptions
                  appid={appid}
                  descriptions={droppedItem}
                  owner_descriptions={classInfo.owner_descriptions}
                  type="dropped_item"
                  parent_hashName={classInfo.market_hash_name}
                />
                <ClassInfoDropDescriptions
                  appid={appid}
                  descriptions={classInfo.descriptions}
                  owner_descriptions={classInfo.owner_descriptions}
                  type="dropped_bundle"
                  parent_hashName={classInfo.market_hash_name}
                />
              </div>
              <LimitedAccountPanel warningState={warningState} />

              <MarketDetails
                classInfo={classInfo}
                appid={appid}
                ctxid={ctxid}
                brief={brief}
                instances={instances}
                totalAmount={totalAmount}
                blockedAfter={blockedAfter}
              />
            </div>
          );
        }
      }
      </SourceListener>
    </div>
  )
}

export default ItemAssetPage;