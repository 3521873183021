
////////////////////////////////////////////
// function makeFrozen - merge list of objects making nonmutable one
export const makeFrozen = (...r) => Object.freeze(Object.assign({}, ...r));


////////////////////////////////////////////
// function classMixer - merge list of non-falsy classes to a string
export const classMixer = (...r) => r.filter(Boolean).join(" ");


////////////////////////////////////////////
// function encodeUtf8 - convert  internal wide string to utf8 byte string
export const encodeUtf8 = (s) => unescape(encodeURIComponent(s));


////////////////////////////////////////////
// function decodeUtf8 - convert utf8 byte string to internal wide string
export const decodeUtf8 = (s) => decodeURIComponent(escape(s));


////////////////////////////////////////////
// function encodeUtf8 - convert  internal wide string to utf8 hex string
export const encodeUtf8Hex = (str) => {
  const tohex = (c) => `00${c.charCodeAt(0).toString(16)}`.substr(-2);
  return [...encodeUtf8(str)].map(tohex).join("").toUpperCase();
}


////////////////////////////////////////////
// function decodeUtf8 - convert utf8 hex string to internal wide string
export const decodeUtf8Hex = (hexstr) => {
  if (!hexstr) return null;
  const codes = hexstr.match(/([0-9a-f]{2})/ig).map(i => +`0x${i}`);
  return decodeUtf8(String.fromCharCode(...codes));
}


////////////////////////////////////////////
// class EventSource - pubsub generic
export class EventSource {
  constructor() {

    this._subs = {};
  }


  subscribers = (name) => {
    return (this._subs[name] || (this._subs[name] = []));
  }


  on = (name, cb) => {
    this.subscribers(name).push(cb);
  }


  off = (name, cb) => {
    const subs = this.subscribers(name);
    const index = subs.indexOf(cb);
    if (index >= 0) {
      subs.splice(index, 1);
    }
  }


  emit = (name, ...args) => {
    const safecall = (cb) => {
      try {
        cb.apply(this, args);
      } catch (exc) {
        console.error("Exception caught while processing event '" + name + "'\n" + exc + "\n" + ((exc || {}).stack || ""), cb);
      }
    };

    this.subscribers(name).map(safecall);
  }


  emit_async = (...r) => {
    Promise.resolve().then(() => this.emit(...r));
  }
};


////////////////////////////////////////////
// class DataSource - data container with update subscription
export class DataSource {
  constructor(data) {
    this.es = new EventSource();
    this.data = makeFrozen(data);
  }


  get() {
    return this.data;
  }


  set(...props) {
    const prev = this.data;
    this.data = makeFrozen(...props);
    this.es.emit("set", this.data, prev);
  }


  update(...props) {
    this.set(this.data, ...props)
  }


  on(cb) {
    this.es.on("set", cb);
  }


  off(cb) {
    this.es.off("set", cb);
  }
};


////////////////////////////////////////////
// PromiseDelay - delay promise propagation
export function PromiseDelay(timeout, p) {
  return Promise.resolve(p).then(
    (val) => new Promise((resolve, reject) => (setTimeout(() => resolve(val), timeout))),
    (err) => new Promise((resolve, reject) => (setTimeout(() => reject(err), timeout)))
  );
}


////////////////////////////////////////////
// decoratorRateLimit - decorator for simple rate limiting by delaying and rescheduling
export function decoratorRateLimit(delay) {
  return (passenger) => {
    let reqPending = false;
    let reqNeedRescedule = false;

    const decorated = () => {
      if (reqPending) {
        reqNeedRescedule = true;
        return;
      }
      reqPending = true;

      PromiseDelay(delay).then(passenger).catch(() => true).then(() => {
        reqPending = false;
        if (reqNeedRescedule) {
          reqNeedRescedule = false;
          decorated();
        }
      });
    };
    return decorated;
  }
}


////////////////////////////////////////////
//  mkHashMap - construct map {hash(value):value} from list
export const mkHashMap = (hash, list) => Object.assign({},
  ...(list.map(
    it => ({ [hash(it)]: it })
  ))
);


////////////////////////////////////////////
//  randMinMax - return int via range [min, max]
export const randMinMax = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}


////////////////////////////////////////////
//  objectMap - get value previously stored via setStored
export const objectMap = (obj, cb) => {
  return Object.entries(obj).map(([k, v]) => cb(v, k, obj));
}

