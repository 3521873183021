import { MarketCancelOrder } from 'api/marketAPICall';
import { MarketOrders } from 'api/ordersAPICall';
import MoneyUtil from 'components/moneyUtil';
import { _I_ } from 'components/translate';
import { BootButton } from 'generics/buttons';
import { LogStream } from 'generics/common';
import { SourceListener } from 'generics/dataBound';
import { DataSource, PromiseDelay } from 'generics/utils';
import React from 'react';

const logger =  LogStream("CANCELALLORDERS");
// LogStream.enable( "CANCELALLORDERS" );

const loggerErrors =  LogStream("CANCELALLORDERSERROR");
LogStream.enable( "CANCELALLORDERSERROR" );

export const ordersList = new DataSource({
    buyOrders:[],
    buyOrdersTotal:0,
    sellOrders:[],
    sellOrdersTotal:0,
  });


function reducerOrdersTotal( accum, order )  {
  const  {  normalPrice,  localPrice,  currency,  amount } = order;
  const price = MoneyUtil.preferMoney(normalPrice, localPrice, currency, currency);
  return accum + (price * (amount||0))
}


const  ordersByTime = (l,r) => (r.time - l.time);


function setOrders(orders)  {

  const buyOrders = orders.filter( it => (it.type == 'BUY') ).sort(ordersByTime);
  const buyOrdersTotal = buyOrders.reduce( reducerOrdersTotal, 0 );

  const sellOrders = orders.filter( it => (it.type == 'SELL') ).sort(ordersByTime);
  const sellOrdersTotal = sellOrders.reduce( reducerOrdersTotal, 0 );

  ordersList.update({
      buyOrders,
      buyOrdersTotal,
      sellOrders,
      sellOrdersTotal
    });
}


ordersList.on(orders  =>  {
    logger.debug( "ordersList(", orders, ")" );
  });


export class CancelOrdersPanel extends React.Component  {
  constructor(props)  {
    super(props);
    this.state = {
        stage : "loading",

        processedBuy : 0,
        processedSell : 0,

        errors : "",
      };
  }


  componentDidMount()  {
    PromiseDelay(
      300,
      MarketOrders()

    ).catch(  (reason) =>  {
      loggerErrors.error("Failed to load own orders data:", reason)
      return [];

    }).then( (resp) =>  {
      logger.info( "CancelOrdersPanel:orders loaded -> ", resp );
      resp = resp||[];
      setOrders(resp);
      return resp.length;

    }).then( (count) =>  {
      if ( count )  {
        this.setState({"stage":"loaded"});
      }  else  {
        this.setState({"stage":"none"});
      }

    });
  }


  componentWillUnmount()  {
  }


  onCancelError = ( current, err )  =>  {
    loggerErrors.warning( "Failed MarketCancelOrder(",current.pairId, current.id, " -> ", err );

    this.setState((prevState, props)  =>  {
      const errors = [
          prevState.errors,
          _I_("CancelOrdersPanel.cancelError"),
          `${current.id}`,
          current.mid ? " / "+current.mid : "",
        ].join("");
      return { errors };
    });
  }


  countCancelStep = ( current, resp )  =>  {
    loggerErrors.info( "MarketCancelOrder(",current.pairId, current.id, " -> ", resp );

    this.setState((prevState, props)  =>  {
      if (current.type=="BUY")  {
        return {processedBuy: 1+prevState.processedBuy}
      }  else  {
        return {processedSell: 1+prevState.processedSell}
      }
    });
  }


  doCancelStep = ([ current, ...rest ])  =>  {
    return MarketCancelOrder(
        current.pairId, current.id

      ).catch( err  =>  {
        this.onCancelError( current, err );
        return err;

      }).then( resp  =>  {
        this.countCancelStep( current, resp )
        return PromiseDelay(300);
      }).then( ()  =>  {
        if ( rest.length )  {
          return this.doCancelStep(rest);
        }  else  {
          return true;
        }

      });
  }


  doCancelOrders = () =>  {
    this.setState({"stage":"cancelling"});
    const { buyOrders, sellOrders } = ordersList.get();
    this.doCancelStep(
        [ ...buyOrders, ...sellOrders ]
      ).then( () => {
        this.setState({"stage":"done"});
      });
  }


  render()  {
    const  { stage, processedBuy, processedSell, errors }  =  this.state;
    switch ( stage )  {
      case "loading":
        return (<CancelOrdersLoading stage={stage} />);

      case "loaded":
        return (<SourceListener orders={ordersList} >{({orders,}) => (
            <CancelOrdersLoaded stage={stage} orders={orders}
                                doCancelOrders={this.doCancelOrders} />
          )}</SourceListener>);

      case "cancelling":
        return (<SourceListener orders={ordersList} >{({orders,}) => (
            <CancelOrdersCancelling stage={stage} orders={orders}
                                    processedBuy={processedBuy}
                                    processedSell={processedSell} />
          )}</SourceListener>);

      case "done":
        return (<CancelOrdersDone stage={stage} errors={errors} />);

      default:
        return null; // all other cases
    }
  }
};

function PricePad({ price, market="gjn" })  {
  return (<i>{!!price && MoneyUtil.formatMoney(price, market)}</i>);
}


function CancelOrdersLoading({ stage })  {
  return (
      <div className="alert alert-primary" >
        <div className="text-center">
        {_I_("CancelOrdersPanel.loading")}
        </div>
      </div>
    );
}

function CancelOrdersDone({ stage, errors })  {
  return (
      <div className="alert alert-primary" >
        <div className="text-center">
        {_I_("CancelOrdersPanel.done")}
        {(!!errors)&&(
          <pre>
            {_I_("CancelOrdersPanel.errors")}
            {errors}
          </pre>
        )}
        </div>
      </div>
    );
}

function CancelOrdersLoaded({ stage, orders, doCancelOrders })  {
  const  { sellOrders, sellOrdersTotal, buyOrders, buyOrdersTotal }  =  orders;

  const haveBuy = !!(buyOrders && buyOrders.length);
  const haveSell = !!(sellOrders && sellOrders.length);
  return (
      <div className="alert alert-primary" >
        <div className="text-center">
          {_I_("CancelOrdersPanel.loaded.youHave")}
          {haveSell && (<span className="text-info">
            {sellOrders.length}
            {_I_("CancelOrdersPanel.loaded.sellOrders")}
            <PricePad price={sellOrdersTotal} />
          </span>)}

          {(haveSell && haveBuy) &&  _I_("CancelOrdersPanel.loaded.and")}

          {haveBuy && (<span className="text-info">
            {buyOrders.length}
            {_I_("CancelOrdersPanel.loaded.buyOrders")}
            <PricePad price={buyOrdersTotal} />
          </span>)}

          {_I_("CancelOrdersPanel.loaded.shouldCancel")}
        </div>
        <div className="text-center">
          <BootButton  variant="success" block onClick={doCancelOrders} >
            {_I_("CancelOrdersPanel.loaded.doCancel")}
          </BootButton>
        </div>
      </div>
    );
}

function CancelOrdersCancelling({ stage, orders, processedBuy, processedSell })  {
  const  { sellOrders, buyOrders }  =  orders;

  const haveSell = !!(sellOrders && sellOrders.length);
  const haveBuy = !!(buyOrders && buyOrders.length);
  return (
    <div className="alert alert-primary" >
      <div className="text-center">
        {_I_("CancelOrdersPanel.cancelling.processing")}
      </div>

      {haveBuy && (<div className="text-center">
          <span className="text-info">
            {processedBuy}
            {_I_("CancelOrdersPanel.cancelling.outOf")}
            {buyOrders.length}
          </span>
          {_I_("CancelOrdersPanel.cancelling.buyOrders")}
        </div>)}

      {haveSell && (<div className="text-center">
          <span className="text-info">
            {processedSell}
            {_I_("CancelOrdersPanel.cancelling.outOf")}
            {sellOrders.length}
          </span>
          {_I_("CancelOrdersPanel.cancelling.sellOrders")}
        </div>)}
    </div>);
}
