export const plural = (number, words) => {
  return Math.abs(parseInt(number, 10)) === 1 ? words[0] : words[1];
};

export const locale = {
  "ViewItemPreviewSidePane.quantity.inventory": " ",
  "ViewItemPreviewSidePane.quantity.ordersInventory": " items in the inventory",
  "ViewItemPreviewSidePane.quantity.count": " on sale",
  "ViewItemPreviewSidePane.quantity.countBuy": " buy orders",
  "ViewItemPreviewSidePane.quantity.items": " items",
  "ViewMarketedItem.SoldOutText": "sold out",
  "ViewMarketedItem.SoldOutTip": "No sell orders",
  "ViewMarketedItem.NoBuyText": "no buy orders",
  "ViewMarketedItem.SellingFrom": "Selling from...",
  "ViewMarketedItem.SellingMore": " or more",
  "ViewMarketedItem.BuyingMore": " or lower",
  "ViewMarketedItem.tradableAfter": "will become available for sale after",
  "ViewItemSearch.heading.showAll": "Showing all listings",
  "ViewItemSearch.heading.orders": "My orders",
  "ViewItemSearch.heading.marketSell": "Sell orders",
  "ViewItemSearch.heading.marketBuy": "Buy orders",
  "ViewItemSearch.heading.marketAuctions": "Auctions",
  "ViewItemSearch.title.marketAuction": "Auction",
  "ViewItemSearch.title.ending": "Until the end",
  "ViewItemSearch.title.finished": "Auction finished",
  "ViewItemSearch.heading.settings": "Settings",
  "ViewMarketedItem.button.more": "More details",
  "ViewItemSearch.heading.resultsFor": "Showing results for:",
  "ViewItemSearch.stub.empty": "Empty search result",
  "ViewItemSearch.heading.tooltip": "Your bet has been outbid",
  "ViewItemSearch.heading.tooltip.win": "Your bid won",
  "ViewItemPopup.committed.SELL": "Sale completed",
  "ViewItemPopup.committed.BUY": "Purchase completed",
  "ViewItemPopup.committed.BID": "Bid placed",
  "ViewItemPopup.application.BUY": "Buy order placed",
  "ViewItemPopup.application.SELL": "Sale request created",
  "ViewItemPopup.application.BID": "Bid made",
  "ViewItemPopup.canceled.BUY": "Buy order cancelled",
  "ViewItemPopup.canceled.SELL": "Sale request cancelled",
  "ViewItemPopup.canceled.BID": "Bid cancelled",
  "ViewItemPopup.showAll.button": "View all",
  "ViewItemAssets.readAll.button": "Mark all viewed",
  "ViewItemPopup.heading.noHistory": "No notifications yet",
  "ViewItemPopup.heading.unseenHistory": "There are no new notifications yet",
  "ViewItemSearch.termsPlaceholder": "Search terms here",
  "ViewItemSearch.label.search": "Search for items",
  "ViewMarketedItem.lovestPrice": "starting at ",
  "ViewMarketedItem.beforePrice": "under ",
  "ViewMarketedItem.yourBid": "your bid ",
  "ViewMarketedItem.bid": "bid ",
  "ViewItemSearch.heading.exchange": "Home",
  "ViewItemSearch.heading.light": "Light",
  "ViewItemSearch.heading.dark": "Dark",
  "ViewItemSearch.filteringAnnounce": "In order to filter orders you must select a game",
  "ViewItemSearch.ordersBuy": "BUY",
  "ViewItemSearch.ordersSell": "SELL",
  "ViewItemSearch.ordersOpen": "View full history",
  "MarketApp.Navbar.inventoryLink": "My inventory",
  "MarketApp.Navbar.ordersLink": "My wallet",
  "MarketApp.Navbar.marketLink": "Market",
  "MarketApp.Navbar.settingsLink": "Settings",
  "MarketApp.Navbar.replenishLink": "Replenish balance",
  "MarketApp.Navbar.balanceHistoryLink": "Balance history",
  "MarketApp.Navbar.buyCoinsLink": "Buy GaijinCoins",
  "MarketApp.Navbar.profileLink": "Profile",
  "MarketApp.Navbar.reloginLink": "Relogin or change account",
  "MarketApp.Navbar.toOldVersion": "Go to the legacy version",
  "MarketApp.Navbar.linkFeedback": "Leave Feedback",
  "ViewTradeDialog.caption": "Trade",
  "ViewTradeDialog.button.back": "Back",
  "NotAllowedMessage.caption": "Not allowed",
  "NotAllowedMessage.body.h1": "Access to marketplace",
  "NotAllowedMessage.body.h3": "currently not allowed for your account",
  "NotAllowedMessage.relogin": " ",
  "CountryDeniedMessage.caption": "Not allowed",
  "CountryDeniedMessage.body.notAllowed": "Access to marketplace is not allowed",
  "CountryDeniedMessage.body.unclearStatus": "Due to the unclear legal status of market transactions in your country, access to the marketplace is disabled",
  "LandingMessage.caption": "Authentication",
  "LandingMessage.body": "Finishing landing...",
  "TokenExpiredMessage.caption": "Authentication expired",
  "TokenExpiredMessage.body.h1": "Authentication expired",
  "TokenExpiredMessage.body.h3": "There's not much we can do with this, but you can still",
  "TokenExpiredMessage.relogin": "Relogin...",
  "AuthErrorMessage.caption": "Authentication failed",
  "AuthErrorMessage.body.h1": "Failed to check authentication",
  "AuthErrorMessage.body.code": "Code:",
  "AuthErrorMessage.body.desc": "There's not much we can do with this, but you can still",
  "AuthErrorMessage.relogin": "Relogin...",
  "AuthorizationRequiredMessage.caption": "Authentication required",
  "AuthorizationRequiredMessage.body.h3.1": "You are about to enter",
  "AuthorizationRequiredMessage.body.h3.2": "Gaijin Marketplace",
  "AuthorizationRequiredMessage.body.h3.3": "But you need to be authorized to proceed further",
  "AuthorizationRequiredMessage.login": "Sign-in with Gaijin.Net",
  "CheckingAuthMessage.caption": "Authentication",
  "CheckingAuthMessage.body": "Checking authentication, please be patient",
  "CancelOrdersPanel.cancelError": "\nFailed to cancel order ",
  "CancelOrdersPanel.loading": "Checking active orders",
  "CancelOrdersPanel.done": "Operation finished.",
  "CancelOrdersPanel.errors": "Recieved some errors: \n",
  "CancelOrdersPanel.loaded.youHave": "Currently you have ",
  "CancelOrdersPanel.loaded.sellOrders": " sell order(s) totalling  ",
  "CancelOrdersPanel.loaded.buyOrders": " buy order(s) totalling  ",
  "CancelOrdersPanel.loaded.and": " and ",
  "CancelOrdersPanel.loaded.shouldCancel": " and you have to cancel them all. ",
  "CancelOrdersPanel.loaded.doCancel": "Cancel orders",
  "CancelOrdersPanel.cancelling.processing": "Processing",
  "CancelOrdersPanel.cancelling.outOf": " out of ",
  "CancelOrdersPanel.cancelling.sellOrders": " sell orders",
  "CancelOrdersPanel.cancelling.buyOrders": " buy orders",
  "ClassInfoDescriptions.dropped_item.block_label": "Contains one of the following:",
  "ClassInfoDescriptions.dropped_item.stage-unknown": "Unknown",
  "ClassInfoDescriptions.dropped_item.stage-pending": "...loading",
  "ClassInfoDescriptions.text.block_label": "",
  "ClassInfoDescriptions.dropped_bundle.block_label": " ",
  "ClassInfoDescriptions.buttonLabel.top": "Show",
  "ClassInfoDescriptions.buttonLabel.hide": "Hide",
  "ClassInfoDescriptions.buttonLabel.bottom": "all",
  "ClassInfoDescriptions.dropped_text.description": "Description",
  "ClassInfoUtil.fake_name.pending": "Pending",
  "ClassInfoUtil.fake_name.broken": "Deleted item",
  "ClassInfoUtil.SynthTag.marketable1": "Marketable",
  "ClassInfoUtil.SynthTag.marketable0": "Non-marketable",
  "ClassInfoUtil.SynthTag.tradable1": "Tradable",
  "ClassInfoUtil.SynthTag.tradable0": "Non-tradable",
  "ClassInfoUtil.SynthTag.commodity1": "Commodity",
  "ClassInfoUtil.SynthTag.commodity0": "Non-commodity",
  "ConfirmBuyDialog.title": "Place buy order",
  "BuyProcessingDialog.title": "Processing...",
  "BuyProcessingDialog.pleaseWait": "Processing, please wait",
  "ProcessingDialog.notItem": "The requested item is not found on the market or is not available for trading",
  "SellDialog.title": "Put an item up for sale",
  "SellProcessingDialog.title": "Processing...",
  "SellProcessingDialog.pleaseWait": "Processing, please wait",
  "LimitedAccountPanel.2step.reason": "You need to configure two-factor authorization",
  "LimitedAccountPanel.2step.link": "More about two-factor authorization",
  "LimitedAccountPanel.2step.href": "https://support.gaijin.net/hc/en-us/articles/203623622-How-to-set-up-the-Two-Step-Authorization-Google-Authenticator-",
  "LimitedAccountPanel.email.reason": "You need to confirm your email address",
  "LimitedAccountPanel.email.link": "Proceed to profile",
  "LimitedAccountPanel.2step.toPlug": "Enable",
  "LimitedAccountPanel.email.href": "https://store.gaijin.net/user.php",
  "LimitedAccountPanel.title": "Your account is limited due to:",
  "LimitedAccountPanel.conclusion": "After changing the security settings, you should log out and log in to make the changes take effect",
  "LimitedAccountPanel.relogin": "Relogin...",
  "EmailVerificationStateIcon.success": "Confirmed account, email verified",
  "EmailVerificationStateIcon.warning": "Unconfirmed account, email not verified",
  "EmailVerificationStateIcon.danger": "Unconfirmed account, email verification REQUIRED",
  "TwoFactorStateIcon.success": "Account secured with two-factor authorization",
  "TwoFactorStateIcon.warning": "Insecure account, two-factor authorization disabled",
  "TwoFactorStateIcon.danger": "Two-factor authorization REQUIRED",
  "ViewInventoryTextFilter.common.label": "Looking for a specific item?",
  "ItemBuyEditor.label.wheelAmountInput": "You can use mouse wheel to change amount,\nalt+wheel = 10x speed",
  "ItemBuyEditor.label.wheelInput": "You can use mouse wheel to change price,\nalt+wheel = 10x speed,\nshift+wheel=0.1x speed",
  "ItemBuyEditor.note.posAmount": "Amount value should be positive",
  "ItemBuyEditor.note.maxAmount": "Amount value is over maximum on 1000 items",
  "ItemBuyEditor.note.invalidAmount": "Invalid amount value",
  "ItemBuyEditor.note.minPrice": "The minimum price is",
  "ItemBuyEditor.note.maxTotal": "Order total is over maximum of",
  "ItemBuyEditor.note.invalidPrice": "Invalid price value",
  "ItemBuyEditor.note.mustAgree": "Must agree to Terms Of Use",
  "ItemBuyEditor.note.insufficentFunds0": "Insufficent funds, you need more",
  "ItemBuyEditor.note.insufficentFunds1": "for this order",
  "ItemBuyEditor.label.buyCouns": "Buy",
  "ItemBuyEditor.label.submit": "Place order",
  "ItemBuyEditor.label.price": "Price per item:",
  "ItemBuyEditor.label.amount": "Amount:",
  "ItemBuyEditor.label.totalPrice": "Total price (includes fees):",
  "ItemBuyEditor.label.agree": "I agree to Gaijin Store",
  "ItemBuyEditor.label.ToU": "Terms of Use",
  "MarketDepthTotals.noOrders.sell": "No sell orders",
  "MarketDepthTotals.noOrders.buy": "No buy orders",
  "MarketDepthTotals.starting.sell": " for ",
  "MarketDepthTotals.starting.buy": " from ",
  "MarketDepthTotals.andMore.sell": " or below",
  "MarketDepthTotals.andMore.buy": " or higher",
  "MarketDepthTotals.prevew.sell": "Buying: ",
  "MarketDepthTotals.prevew.buy": "On sale: ",
  "MarketDepthOwnWarning.sell": "You have pending sale orders",
  "MarketDepthOwnWarning.buy": "You have pending buy orders",
  "MarketDepthTable.caption.price": "Price",
  "MarketDepthTable.caption.quantity": "Quantity",
  "MarketDepthTable.ellipsis.sell": "and more",
  "MarketDepthTable.ellipsis.buy": "and lower",
  "ItemMarketDetails.buyButton": "BUY",
  "ItemMarketDetails.sellButton": "SELL",
  "ItemMarketDetails.nothingToSellButton": "Nothing to sell",
  "ItemMarketDetails.makeAuction": "Make auction",
  "ItemMarketDetails.makeBid": "Make bid",
  "ItemMarketDetails.bid": "Bid",
  "ItemMarketDetails.yourBid": "Your bid:",
  "ItemMarketDetails.notifications.yourBid": "Your bid",
  "ItemMarketDetails.yourBid.short": "your",
  "ItemMarketDetails.yourBid.win": "wins",
  "ItemMarketDetails.yourBid.loose": "loses",
  "ItemSellEditor.label.priceStep": "Price step",
  "ItemSellEditor.label.endDate": "End date",
  "limitedAccountMessage.requires": "Requires:",
  "limitedAccountMessage.2step": "two-step protection",
  "limitedAccountMessage.verifiedEmail": "verified email",
  "limitedAccountMessage.and": " and ",
  "ItemSellEditor.label.wheelInput": "You can use mouse wheel to change price,\nalt+wheel = 10x speed,\nshift+wheel=0.1x speed",
  "ItemSellEditor.note.minPrice": "The minimum price is",
  "ItemSellEditor.note.maxPrice": "Maximum sell price alowed",
  "ItemSellEditor.note.maxTotals": "Order totals is over maximum of",
  "ItemSellEditor.note.maxBalance": "Total balance should not exceed",
  "ItemSellEditor.note.mustAgree": "Must agree to Terms Of Use",
  "ItemSellEditor.label.price": "Buyer pays (includes fees):",
  "ItemSellEditor.label.wished": "You receive, after fees charged:",
  "ItemSellEditor.label.agree": "I agree to Gaijin Store",
  "ItemSellEditor.label.ToU": "Terms of Use",
  "ItemSellEditor.label.submit": "Sale",
  "ItemSellEditor.currentBalance.before": "Currently you have",
  "ItemSellEditor.currentBalance.after": "in your balance",
  "ItemSellEditor.sellOrdersTotal.before": "plus",
  "ItemSellEditor.sellOrdersTotal.after": "accumulated in sell orders",
  "ItemSellEditor.buyOrdersTotal.before": "and",
  "ItemSellEditor.buyOrdersTotal.after": "accumulated in buy orders",
  "ItemSellEditor.label.amount": "How many do you want to sell:",
  "AppFilterEditor.label.byTags": "Filter",
  "AppFilterEditor.label.clearFilter": "Clear filter",
  "AppFilterEditor.label.byTitles": "Filter by titles",
  "AppFilterEditor.label.allFilter": "All Games",
  "AppFilterEditor.label.authenticity": "Authenticity",
  "AppFilterEditor.message.filter": "Filters are unique for each game.",
  "AppFilterEditor.message.game": "Choose a game:",
  "ViewItemDetailed.restriction.amount.items": " items",
  "ViewItemDetailed.restriction.nonMarketable": "Сan't be sold",
  "ViewItemDetailed.restriction.blockedAfter": "Will lock at",
  "ViewItemDetailed.restriction.blockedPair": "Trade locked",
  "ViewItemDetailed.restriction.expiresAt1": "Will disappear after",
  "ViewItemDetailed.restriction.expiresAt2": "",
  "ViewItemDetailed.restriction.endedAt": "End of auction",
  "ViewItemDetailed.restriction.amount": "Lot contains",
  "ViewItemDetailed.restriction.pieces": "pcs.",
  "ViewItemDetailed.restriction.startPrice": "Starting price",
  "ViewItemDetailed.restriction.days": "d",
  "ViewItemDetailed.restriction.hours": "hrs",
  "ViewItemDetailed.restriction.minutes": "min",
  "ViewItemDetailed.restriction.leadingBids": "leading bids",
  "ViewItemDetailed.restriction.noBids": "There are no bids yet. Place your first bid in this auction!",
  "ViewItemDetailed.restriction.delayTrade": "Once purchased, the item will be available for sale through",
  "ViewItemDetailed.restriction.unclearStatus": "Due to the unclear legal status of trophy cases in your country, all transactions with trophies and keys are blocked for your account.",
  "MarketSortOrder.label.name": "Name",
  "MarketSortOrder.hint.newDesc": "New ones first",
  "MarketSortOrder.hint.newAsc": "Old ones first",
  "MarketSortOrder.label.price": "Price",
  "MarketSortOrder.hint.priceAsc": "Low to high cost",
  "MarketSortOrder.hint.priceDesc": "High to low cost",
  "MarketSortOrder.label.amount": "Quantity",
  "MarketSortOrder.hint.amountAsc": "Low to high amounts",
  "MarketSortOrder.hint.amountDesc": "High to low amounts",
  "MarketSortOrder.label.popular": "Popular",
  "MarketSortOrder.label.popularItems": "Popular items",
  "DoubleSellErrorModal.heading": "Error",
  "DoubleSellErrorModal.prompt": "Failed to sell current item",
  "DoubleSellErrorModal.explanation": "Failed to place sell order, possible causes - item was sold or consumed ingame\nTry refreshing page",
  "DoubleSellErrorModal.okButton": "OK",
  "OnBuyReserveFailModal.heading": "Error",
  "OnBuyReserveFailModal.prompt": "Failed to place buy order",
  "OnBuyReserveFailModal.explanation": "Failed to issue a buy order, unable to reserve money from wallet.",
  "OnBuyReserveFailModal.youNeed1": "You need at least",
  "OnBuyReserveFailModal.youNeed2": "for this operation",
  "OnBuyReserveFailModal.tryRefresh": "Try refreshing page and ensure you have sufficent funds",
  "OnBuyReserveFailModal.okButton": "OK",
  "DoubleRateErrorModal.heading": "Error",
  "DoubleRateErrorModal.prompt": "An error occurred while placing a bid",
  "DoubleRateErrorModal.explanation": "Please try to bid later",
  "DoubleRateErrorModal.okButton": "OK",
  "AuctionFinishedErrorModal.heading": "Error",
  "AuctionFinishedErrorModal.prompt": "An error occurred while placing a bid",
  "AuctionFinishedErrorModal.explanation": "The auction was completed",
  "AuctionFinishedErrorModal.okButton": "OK",
  "CancelOrderErrorModal.heading": "Error",
  "CancelOrderErrorModal.prompt": "Failed to cancel order",
  "CancelOrderErrorModal.explanation": "Possible reasons are network problems or the order is updating right now.\nTry again a bit later.",
  "CancelOrderErrorModal.cancelButton": "Got it",
  "OrdersListContainer.Tab.sellOrders": "Sell orders",
  "OrdersListContainer.Tab.buyOrders": "Buy orders",
  "OrdersListContainer.Tab.historyList": "Orders history",
  "ViewOrdersEntry.amounts.remain": "Remain",
  "ViewOrdersEntry.amounts.done": "Done",
  "ViewOrdersEntry.order.order": "Order",
  "ViewOrdersEntry.order.issued": "Issued",
  "ViewOrdersEntry.order.executed": "Executed",
  "ViewOrdersEntry.order.pairIsBlocked": "Trade locked",
  "ViewOrdersEntry.order.pairWillBlockAfter": "Will lock at",
  "ViewOrdersEntry.order.BUY": "BUY",
  "ViewOrdersEntry.order.SELL": "SELL",
  "ViewOrdersEntry.order.view": "View",
  "ViewOrdersEntry.order.cancel": "Cancel",
  "ViewOrdersList.empty.label": "Nothing here",
  "CancelOrderDialog.heading.youSure": "Are you sure?",
  "CancelOrderDialog.body.youSure": "Are you sure to cancel order?",
  "CancelOrderDialog.button.cancel": "Yes, I want to cancel order",
  "CancelAllOrderDialog.button.cancel": "Yes for everyone",
  "CancelOrderDialog.button.noCancel": "No",
  "CancelOrderDialog.heading.processing": "Processing...",
  "CancelOrderDialog.body.processing": "Processing, please wait",
  "ProfileMenu.MenuItem.Settings": "Settings",
  "ProfileMenu.MenuItem.GaijinAccount": "Profile Gaijin.net",
  "ProfileMenu.MenuItem.AccountSwitch": "Change account",
  "ProfileMenu.MenuItem.Signout": "Exit",
  "ProfileMenu.MenuItem.nick": "Your nickname",
  "WalletBlock.balance.text": "Balance:",
  "WalletBlock.balance.buyCurrency": "Buy GJN",
  "SecurityBlock.message.title": "Two-factor authorization",
  "SecurityBlock.emailVerified.title": "Email",
  "OrdersHistory.title.buy": "Buy orders",
  "OrdersHistory.title.sell": "Sell orders",
  "NotificationsAssets.title": "Notifications",
  "NotificationsAssets.title.today": "Today",
  "NotificationsAssets.title.historyFromLastWeek": "In the last seven days",
  "NotificationsAssets.title.historyOlder": "Earlier",
  "LimitedAccountPanel.2step.pleas": "Protect your account to gain access to trading on the exchange.",
  "LimitedAccountPanel.2step.protected": "Your account is protected, you have access to trade on the exchange.",
  "LimitedAccountPanel.email.pleas": "Confirm it to access trading on the exchange.",
  "LimitedAccountPanel.email.protected": "Your e-mail address is confirmed, you have access to trade on the exchange.",
  "OrdersListContainer.Opened.Orders": "Opened",
  "OrdersListContainer.Closed.Orders": "Closed",
  "ViewOrdersList.empty.labelCollapse": "Collapse",
  "ViewOrdersList.empty.labelExpand": "Expand",
  "OrdersGraph.plot.title": "Buy/Sell orders",
  "OrdersGraph.fmt.offers.sell": "sell offers\n<br/>price",
  "OrdersGraph.fmt.andMore.sell": "or lower",
  "OrdersGraph.fmt.offers.buy": "buy offers\n<br/>price",
  "Orders.title.header": "offers",
  "OrdersGraph.fmt.andMore.buy": "or higher",
  "PriceHistoryGraph.plot.title": "Price history:",
  "PriceHistoryGraph.highlighter.format": "At <strong>%s</strong><br /> price <strong>%s</strong><br /> amount <strong>%s</strong><br />",
  "PriceHistoryGraph.plot.shortRange": "Short range",
  "PriceHistoryGraph.plot.wholeRange": "Whole time",
  "DealEventTickerEntry.purchasedFrom": "purchased this item from",
  "DealEventTickerEntry.for": "for",
  "BookSellTickerEntry.listedItem": "sale request",
  "BookBuyTickerEntry.created": "created",
  "BookBuyTickerEntry.for": "purchase order",
  "BookAuctionTickerEntry.created": "made a bet",
  "ViewTradeTickerLog.waiting": "Waiting for new activity...",
  "ViewTradeTickerLog.ticker": "Real time trading...",
  "ViewUserInventory.common.emptyInventory": " ",
  "ViewItemPreviewSidePane.UnselectButton.label": " ",
  "ViewItemPreviewSidePane.TradeButton.label": " ",
  "ViewItemPreviewSidePane.ViewButton.label": " ",
  "ViewItemPreviewSidePane.QuickSellButton.label": " ",
  "ViewInventoryTagCloud.tagTitle.itemsOf": " ",
  "ViewInventoryTagCloud.tagTitle.varietiesMarkedAs": " ",
  "ViewItemSearch.heading.inventory": "My items",
  "WalletBlock.circleSmall.settings": "Settings",
  "WalletBlock.circleSmall.exit": "Exit",
  "UserSettingsDialog.language": "Language",
  "UserSettingsDialog.user_settings": "User settings",
  "UserSettingsDialog.settings": "Settings",
  "UserSettingsDialog.save_settings": "Save settings",
  "UserSettingsDialog.privacySettings": "Trading",
  "UserSettingsDialog.privacySettingsPublic": "Public",
  "UserSettingsDialog.privacySettingsPrivate": "Private",
  "UserSettingsDialog.interface": "Interface",
  "UserSettingsDialog.interfaceOld": "Legacy",
  "UserSettingsDialog.interfaceNew": "Modern",
  "WalletLimitsIcon.soft_limit": "GJN coins limit exceeded.\nReplenish functions are limited.",
  "WalletLimitsIcon.hard_limit": "GJN coins limit exceeded.\nSell and replenish functions are limited.",
  "ActionBar.action.ingamePreview": "View in game",
  "ActionBar.action.previewCopied": "Link copied!",
  "ActionBar.action.previewShare": "Share",
  "IngamePreview.ask.heading": "View this in game?",
  "IngamePreview.ask.prompt": "You are about to view this item in game",
  "IngamePreview.ask.explanation": "Make sure the game client is running under your account",
  "IngamePreview.ask.cancelButton": "No",
  "IngamePreview.done.heading": "Done",
  "IngamePreview.done.prompt": "",
  "IngamePreview.done.explanation": "Successfully sent",
  "IngamePreview.done.okButton": "Ok",
  "IngamePreview.fail.heading": "Failed",
  "IngamePreview.fail.prompt": "",
  "IngamePreview.fail.explanation": "Could not contact the game client",
  "IngamePreview.fail.cancelButton": "Ok",
  "IngamePreview.pending.heading": "Just a second...",
  "IngamePreview.pending.prompt": "Notifying your game client",
  "IngamePreview.pending.explanation": "",
  "IngamePreview.ask.okButton": "Sure, show me",
  "StaticConfigLoader.caption.error": "Initialization error",
  "StaticConfigLoader.caption.init": "Initialization",
  "StaticConfigLoader.text.init": "Initializing application...",
  "StaticConfigLoader.error.settings": "Unable to load site settings",
  "StaticConfigLoader.error.circuits": "Unable to load services config",
  "ControlButton.close": "Close",
  "Exit": "Change account",
  "Legal.TermsConditions": "Terms and Conditions",
  "Legal.TermsService": "Terms of Service",
  "Legal.PrivacyPolicy": "Privacy Policy",
  "Legal.CookiesPolicy": "Cookies Policy",
  "Legal.TradePolicy": "Trade Policy",
  "Legal.TermsConditions.linck": "https://legal.gaijin.net/en/",
  "Legal.TermsService.linck": "https://legal.gaijin.net/en/termsofservice/",
  "Legal.PrivacyPolicy.linck": "https://legal.gaijin.net/en/privacypolicy/",
  "Legal.CookiesPolicy.linck": "https://legal.gaijin.net/en/cookie/",
  "Legal.TradePolicy.linck": "https://legal.gaijin.net/en/tradepolicy/",
  "Legal.Copyright": "Gaijin Network Ltd. All trademarks, logos and brand names are the property of their respective owners.",
  "MarketApp.Navbar.HeadTitle": "Gaijin.Net",
  "MarketApp.Navbar.HeadSubTitle": "Market"
};
