export const plural = (number, words) => {
  return Math.abs(parseInt(number, 10)) === 1 ? words[0] : words[1];
};

export const locale = {
  "ViewItemPreviewSidePane.quantity.inventory": " ",
  "ViewItemPreviewSidePane.quantity.ordersInventory": " Oggetti nell'inventario",
  "ViewItemPreviewSidePane.quantity.count": " on sale",
  "ViewItemPreviewSidePane.quantity.countBuy": " buy orders",
  "ViewItemPreviewSidePane.quantity.items": " Oggetti",
  "ViewMarketedItem.SoldOutText": "Esaurito",
  "ViewMarketedItem.SoldOutTip": "Nessun ordine di vendita",
  "ViewMarketedItem.NoBuyText": "no buy orders",
  "ViewMarketedItem.SellingFrom": "In vendita dal...",
  "ViewMarketedItem.SellingMore": " or more",
  "ViewMarketedItem.BuyingMore": " o più bassi",
  "ViewMarketedItem.tradableAfter": "sarà disponibile per l'acquisto tra",
  "ViewItemSearch.heading.showAll": "Mostra tutte le liste",
  "ViewItemSearch.heading.orders": "My orders",
  "ViewItemSearch.heading.marketSell": "Sell orders",
  "ViewItemSearch.heading.marketBuy": "Buy orders",
  "ViewItemSearch.heading.marketAuctions": "Aste",
  "ViewItemSearch.title.marketAuction": "Asta",
  "ViewItemSearch.title.ending": "Fino alla fine",
  "ViewItemSearch.title.finished": "Asta finita",
  "ViewItemSearch.heading.settings": "Settings",
  "ViewMarketedItem.button.more": "More details",
  "ViewItemSearch.heading.resultsFor": "Mostra risultati per:",
  "ViewItemSearch.stub.empty": "Svuota risultati ricerca",
  "ViewItemSearch.heading.tooltip": "La tua offerta è stata superata",
  "ViewItemSearch.heading.tooltip.win": "La tua offerta ha vinto",
  "ViewItemPopup.committed.SELL": "Vendita completata",
  "ViewItemPopup.committed.BUY": "Acquisto completato",
  "ViewItemPopup.committed.BID": "Offerta effettuata",
  "ViewItemPopup.application.BUY": "Ordine di acquisto creato",
  "ViewItemPopup.application.SELL": "Richiesta di vendita creata",
  "ViewItemPopup.application.BID": "Offerta fatta",
  "ViewItemPopup.canceled.BUY": "Ordine di acquisto annullato",
  "ViewItemPopup.canceled.SELL": "Richiesta di vendita annullata",
  "ViewItemPopup.canceled.BID": "Offerta annullata",
  "ViewItemPopup.showAll.button": "Vedi tutto",
  "ViewItemAssets.readAll.button": "Segna tutto come visto",
  "ViewItemPopup.heading.noHistory": "Ancora nessuna notifica",
  "ViewItemPopup.heading.unseenHistory": "Nessuna nuova notifica",
  "ViewItemSearch.termsPlaceholder": "Cerca i termini qui",
  "ViewItemSearch.label.search": "Ricerca oggetti",
  "ViewMarketedItem.lovestPrice": "starting at ",
  "ViewMarketedItem.beforePrice": "under ",
  "ViewMarketedItem.yourBid": "la tua offerta ",
  "ViewMarketedItem.bid": "offerta ",
  "ViewItemSearch.heading.exchange": "Home",
  "ViewItemSearch.heading.light": "Light",
  "ViewItemSearch.heading.dark": "Dark",
  "ViewItemSearch.filteringAnnounce": "In order to filter orders you must select a game",
  "ViewItemSearch.ordersBuy": "BUY",
  "ViewItemSearch.ordersSell": "SELL",
  "ViewItemSearch.ordersOpen": "Visualizza cronologia completa",
  "MarketApp.Navbar.inventoryLink": "Inventario",
  "MarketApp.Navbar.ordersLink": "I miei ordini",
  "MarketApp.Navbar.marketLink": "Mercato",
  "MarketApp.Navbar.settingsLink": "Impostazioni",
  "MarketApp.Navbar.replenishLink": "Rifornisci il bilancio",
  "MarketApp.Navbar.balanceHistoryLink": "Storico del bilancio",
  "MarketApp.Navbar.buyCoinsLink": "Compra GaijinCoins",
  "MarketApp.Navbar.profileLink": "Profilo",
  "MarketApp.Navbar.reloginLink": "Rilogga o cambia account",
  "MarketApp.Navbar.toOldVersion": "Vai alla versione più vecchia",
  "MarketApp.Navbar.linkFeedback": "Lascia un commento",
  "ViewTradeDialog.caption": "Scambia",
  "ViewTradeDialog.button.back": "Indietro",
  "NotAllowedMessage.caption": "Non permesso",
  "NotAllowedMessage.body.h1": "Accesso al mercato",
  "NotAllowedMessage.body.h3": "al momento non permesso sul tuo account",
  "NotAllowedMessage.relogin": "Cambia account",
  "CountryDeniedMessage.caption": "Non permesso",
  "CountryDeniedMessage.body.notAllowed": "L'accesso al mercato non è permesso",
  "CountryDeniedMessage.body.unclearStatus": "A causa di uno status legale del tuo Paese riguardanti le transazioni nel mercato, l'accesso al mercato è disabilitato",
  "LandingMessage.caption": "Autenticazione",
  "LandingMessage.body": "Completamento sbarco...",
  "TokenExpiredMessage.caption": "Autenticazione scaduta",
  "TokenExpiredMessage.body.h1": "Autenticazione scaduta",
  "TokenExpiredMessage.body.h3": "Non c'è molto che possiamo fare con questo, ma puoi sempre provare",
  "TokenExpiredMessage.relogin": "Rilogga...",
  "AuthErrorMessage.caption": "Autenticazione fallita",
  "AuthErrorMessage.body.h1": "Controllo autenticazione fallito",
  "AuthErrorMessage.body.code": "Codice:",
  "AuthErrorMessage.body.desc": "Non c'è molto che possiamo fare con questo, ma puoi sempre provare",
  "AuthErrorMessage.relogin": "Rilogga...",
  "AuthorizationRequiredMessage.caption": "Autenticazione richiesta",
  "AuthorizationRequiredMessage.body.h3.1": "Stai per entrare",
  "AuthorizationRequiredMessage.body.h3.2": "Mercato Gaijin",
  "AuthorizationRequiredMessage.body.h3.3": "Devi essere autorizzato per procedere",
  "AuthorizationRequiredMessage.login": "Entra con Gaijin.net",
  "CheckingAuthMessage.caption": "Autenticazione",
  "CheckingAuthMessage.body": "Autenticazione in controllo, si prega di attendere",
  "CancelOrdersPanel.cancelError": "\nCancellazione ordine fallita ",
  "CancelOrdersPanel.loading": "Controllo ordini attivi",
  "CancelOrdersPanel.done": "Operazione terminata.",
  "CancelOrdersPanel.errors": "Qualche errore: \n",
  "CancelOrdersPanel.loaded.youHave": "Attualmente hai ",
  "CancelOrdersPanel.loaded.sellOrders": " ordini di vendita in totale ",
  "CancelOrdersPanel.loaded.buyOrders": " ordini d'acquisto in totale ",
  "CancelOrdersPanel.loaded.and": " e ",
  "CancelOrdersPanel.loaded.shouldCancel": " e devi cancellarli tutti. ",
  "CancelOrdersPanel.loaded.doCancel": "Cancella ordini",
  "CancelOrdersPanel.cancelling.processing": "In processo",
  "CancelOrdersPanel.cancelling.outOf": " di ",
  "CancelOrdersPanel.cancelling.sellOrders": " ordini di vendita",
  "CancelOrdersPanel.cancelling.buyOrders": " ordini d'acquisto",
  "ClassInfoDescriptions.dropped_item.block_label": "Contiene uno dei seguenti:",
  "ClassInfoDescriptions.dropped_item.stage-unknown": "Sconosciuto",
  "ClassInfoDescriptions.dropped_item.stage-pending": "...caricamento",
  "ClassInfoDescriptions.text.block_label": "",
  "ClassInfoDescriptions.dropped_bundle.block_label": " ",
  "ClassInfoDescriptions.buttonLabel.top": "Show",
  "ClassInfoDescriptions.buttonLabel.hide": "Hide",
  "ClassInfoDescriptions.buttonLabel.bottom": "all",
  "ClassInfoDescriptions.dropped_text.description": "Description",
  "ClassInfoUtil.fake_name.pending": " ",
  "ClassInfoUtil.fake_name.broken": " ",
  "ClassInfoUtil.SynthTag.marketable1": " ",
  "ClassInfoUtil.SynthTag.marketable0": " ",
  "ClassInfoUtil.SynthTag.tradable1": " ",
  "ClassInfoUtil.SynthTag.tradable0": " ",
  "ClassInfoUtil.SynthTag.commodity1": " ",
  "ClassInfoUtil.SynthTag.commodity0": " ",
  "ConfirmBuyDialog.title": "Piazza ordine d'acquisto",
  "BuyProcessingDialog.title": "In processo...",
  "BuyProcessingDialog.pleaseWait": "In processo, attendere",
  "ProcessingDialog.notItem": "L'articolo richiesto non è stato trovato sul mercato o non è disponibile per il commercio",
  "SellDialog.title": "Metti un oggetto in vendita",
  "SellProcessingDialog.title": "In processo...",
  "SellProcessingDialog.pleaseWait": "In processo, attendere",
  "LimitedAccountPanel.2step.reason": "Devi configurare l'autorizzazione a due fattori",
  "LimitedAccountPanel.2step.link": "Informazioni sull'autenticazione a due fattori",
  "LimitedAccountPanel.2step.href": "https://support.gaijin.net/hc/en-us/articles/203623622-How-to-set-up-the-Two-Step-Authorization-Google-Authenticator-",
  "LimitedAccountPanel.email.reason": "Devi confermare il tuo indirizzo e-mail",
  "LimitedAccountPanel.email.link": "Vai al profilo",
  "LimitedAccountPanel.2step.toPlug": "Attiva",
  "LimitedAccountPanel.email.href": "https://store.gaijin.net/user.php",
  "LimitedAccountPanel.title": "Il tuo account è limitato a causa di:",
  "LimitedAccountPanel.conclusion": "Dopo aver modificato le impostazioni di sicurezza, devi effettuare il logout e accedere di nuovo per applicare le modifiche",
  "LimitedAccountPanel.relogin": "Rilogga...",
  "EmailVerificationStateIcon.success": "Account confermato, e-mail verificata",
  "EmailVerificationStateIcon.warning": "Account non confermato, email non verificata",
  "EmailVerificationStateIcon.danger": "Account non confermato, verifica email RICHIESTA",
  "TwoFactorStateIcon.success": "Account protetto con autenticazione a due fattori",
  "TwoFactorStateIcon.warning": "Account non assicurato, autorizzazione in due fattori disabilitata",
  "TwoFactorStateIcon.danger": "Autorizzazione in due fattori RICHIESTA",
  "ViewInventoryTextFilter.common.label": "Per filtrare l'inventario devi selezionare un gioco",
  "ItemBuyEditor.label.wheelAmountInput": "Puoi usare la rotella del mouse per aumentare il quantitativo,\nalt+rotella = velocità 10x",
  "ItemBuyEditor.label.wheelInput": "Puoi utilizzare la rotella del mouse per aumentare il prezzo,\nalt+rotella = velocità 10x,\nshift+rotella=velocità 0.1x",
  "ItemBuyEditor.note.posAmount": "L'ammontare deve essere positivo",
  "ItemBuyEditor.note.maxAmount": "Il valore è oltre il massimo di 1000 oggetti",
  "ItemBuyEditor.note.invalidAmount": "Valore quantitativo invalido",
  "ItemBuyEditor.note.minPrice": "Il prezzo minimo è",
  "ItemBuyEditor.note.maxTotal": "Gli ordini totali sono oltre il massimo di",
  "ItemBuyEditor.note.invalidPrice": "Prezzo non valido",
  "ItemBuyEditor.note.mustAgree": "Devi accettare i Termini di Utilizzo",
  "ItemBuyEditor.note.insufficentFunds0": "Fondi insufficienti, ne servono di più",
  "ItemBuyEditor.note.insufficentFunds1": "per questo ordine",
  "ItemBuyEditor.label.buyCouns": "Compra",
  "ItemBuyEditor.label.submit": "Piazza un ordine",
  "ItemBuyEditor.label.price": "Prezzo da pagare (per oggetto):",
  "ItemBuyEditor.label.amount": "Quanti ne vuoi acquistare:",
  "ItemBuyEditor.label.totalPrice": "Prezzo totale (tasse incluse):",
  "ItemBuyEditor.label.agree": "Acconsento al Negozio Gaijin",
  "ItemBuyEditor.label.ToU": "Termini di Utilizzo",
  "MarketDepthTotals.noOrders.sell": "Nessun ordine di vendita",
  "MarketDepthTotals.noOrders.buy": "Nessun ordine d'acquisto",
  "MarketDepthTotals.starting.sell": " for ",
  "MarketDepthTotals.starting.buy": " from ",
  "MarketDepthTotals.andMore.sell": " and below",
  "MarketDepthTotals.andMore.buy": " and higher",
  "MarketDepthTotals.prevew.sell": "Buying: ",
  "MarketDepthTotals.prevew.buy": "On sale: ",
  "MarketDepthOwnWarning.sell": "Hai degli ordini di vendita pendenti",
  "MarketDepthOwnWarning.buy": "Hai degli ordini d'acquisto pendenti",
  "MarketDepthTable.caption.price": "Prezzo",
  "MarketDepthTable.caption.quantity": "Quantità",
  "MarketDepthTable.ellipsis.sell": "ed altro",
  "MarketDepthTable.ellipsis.buy": "e più bassi",
  "ItemMarketDetails.buyButton": "COMPRA",
  "ItemMarketDetails.sellButton": "VENDI",
  "ItemMarketDetails.nothingToSellButton": "Niente da vendere",
  "ItemMarketDetails.makeAuction": "Creare asta",
  "ItemMarketDetails.makeBid": "Fare un'offerta",
  "ItemMarketDetails.bid": "Offerta",
  "ItemMarketDetails.yourBid": "La tua offerta:",
  "ItemMarketDetails.notifications.yourBid": "La tua offerta",
  "ItemMarketDetails.yourBid.short": "tuo",
  "ItemMarketDetails.yourBid.win": "vince",
  "ItemMarketDetails.yourBid.loose": "perde",
  "ItemSellEditor.label.priceStep": "Passo di prezzo",
  "ItemSellEditor.label.endDate": "Data di fine",
  "limitedAccountMessage.requires": "Richiede:",
  "limitedAccountMessage.2step": "protezione in due fasi",
  "limitedAccountMessage.verifiedEmail": "email verificata",
  "limitedAccountMessage.and": " e ",
  "ItemSellEditor.label.wheelInput": "Puoi utilizzare la rotella del mouse per aumentare il prezzo,\nalt+rotella = velocità 10x,\nshift+rotella=velocità 0.1x",
  "ItemSellEditor.note.minPrice": "Il prezzo minimo è",
  "ItemSellEditor.note.maxPrice": "Prezzo massimo consentito",
  "ItemSellEditor.note.maxTotals": "Gli ordini totali sono oltre il massimo di",
  "ItemSellEditor.note.maxBalance": "Il bilancio totale non può eccedere",
  "ItemSellEditor.note.mustAgree": "Devi accettare i Termini di Utilizzo",
  "ItemSellEditor.label.price": "L'acquirente paga (tasse incluse):",
  "ItemSellEditor.label.wished": "Ricevi, dopo la decurtazione tasse:",
  "ItemSellEditor.label.agree": "Acconsento al Negozio Gaijin",
  "ItemSellEditor.label.ToU": "Termini di Utilizzo",
  "ItemSellEditor.label.submit": "OK, metti in vendita",
  "ItemSellEditor.currentBalance.before": "Attualmente hai",
  "ItemSellEditor.currentBalance.after": "sul tuo bilancio",
  "ItemSellEditor.sellOrdersTotal.before": "più",
  "ItemSellEditor.sellOrdersTotal.after": "ordini di vendita accumulati",
  "ItemSellEditor.buyOrdersTotal.before": "e",
  "ItemSellEditor.buyOrdersTotal.after": "ordini d'acquisto accumulati",
  "ItemSellEditor.label.amount": "Quanti ne vuoi vendere:",
  "AppFilterEditor.label.byTags": "Filter",
  "AppFilterEditor.label.clearFilter": "Resetta filtri",
  "AppFilterEditor.label.byTitles": "Filtra per titoli",
  "AppFilterEditor.label.allFilter": "Tutti i giochi",
  "AppFilterEditor.label.authenticity": "Autenticità",
  "AppFilterEditor.message.filter": "I filtri sono unici per ogni gioco.",
  "AppFilterEditor.message.game": "Scegli un gioco:",
  "ViewItemDetailed.restriction.amount.items": " items",
  "ViewItemDetailed.restriction.nonMarketable": "Non può essere venduto",
  "ViewItemDetailed.restriction.blockedAfter": "Verrà bloccato tra",
  "ViewItemDetailed.restriction.blockedPair": "Scambio bloccato",
  "ViewItemDetailed.restriction.expiresAt1": "Scomparirà dopo",
  "ViewItemDetailed.restriction.expiresAt2": "",
  "ViewItemDetailed.restriction.endedAt": "End of auction",
  "ViewItemDetailed.restriction.amount": "Lot contains",
  "ViewItemDetailed.restriction.pieces": "pcs.",
  "ViewItemDetailed.restriction.startPrice": "Starting price",
  "ViewItemDetailed.restriction.days": "d",
  "ViewItemDetailed.restriction.hours": "hrs",
  "ViewItemDetailed.restriction.minutes": "min",
  "ViewItemDetailed.restriction.leadingBids": "leading bids",
  "ViewItemDetailed.restriction.noBids": "There are no bids yet. Place your first bid in this auction!",
  "ViewItemDetailed.restriction.delayTrade": "Once purchased, the item will be available for sale through",
  "ViewItemDetailed.restriction.unclearStatus": "A causa di uno status legale non chiaro del trofeo nel tuo paese, tutte le transazioni con trofei e chiavi sono bloccati sul tuo account.",
  "MarketSortOrder.label.name": "Nome",
  "MarketSortOrder.hint.newDesc": "New ones first",
  "MarketSortOrder.hint.newAsc": "Old ones first",
  "MarketSortOrder.label.price": "Prezzo",
  "MarketSortOrder.hint.priceAsc": "Costo: dal più basso",
  "MarketSortOrder.hint.priceDesc": "Dal prezzo più alto al più basso",
  "MarketSortOrder.label.amount": "Quantità",
  "MarketSortOrder.hint.amountAsc": "Prezzo: dal più basso",
  "MarketSortOrder.hint.amountDesc": "Dal prezzo più alto al più basso",
  "MarketSortOrder.label.popular": "Popolari",
  "MarketSortOrder.label.popularItems": "Popular items",
  "DoubleSellErrorModal.heading": "Errore",
  "DoubleSellErrorModal.prompt": "Vendita oggetto fallita",
  "DoubleSellErrorModal.explanation": "Non è stato possibile piazzare l'ordine di vendita, causa possibile - oggetto venduto o utilizzato in gioco\nProva ad aggiornare la pagina",
  "DoubleSellErrorModal.okButton": "OK",
  "OnBuyReserveFailModal.heading": "Errore",
  "OnBuyReserveFailModal.prompt": "Inserimento ordine d'acquisto fallito",
  "OnBuyReserveFailModal.explanation": "Non è stato possibile piazzare l'ordine di d'acquisto, mancanza di fondi nel bilancio.",
  "OnBuyReserveFailModal.youNeed1": "Ti serve almeno",
  "OnBuyReserveFailModal.youNeed2": "per questa operazione",
  "OnBuyReserveFailModal.tryRefresh": "Prova ad aggiornare la pagina ed assicurati di avere i fondi necessari",
  "OnBuyReserveFailModal.okButton": "OK",
  "DoubleRateErrorModal.heading": "Error",
  "DoubleRateErrorModal.prompt": "An error occurred while placing a bid",
  "DoubleRateErrorModal.explanation": "Please try to bid later",
  "DoubleRateErrorModal.okButton": "OK",
  "AuctionFinishedErrorModal.heading": "Error",
  "AuctionFinishedErrorModal.prompt": "An error occurred while placing a bid",
  "AuctionFinishedErrorModal.explanation": "The auction was completed",
  "AuctionFinishedErrorModal.okButton": "OK",
  "CancelOrderErrorModal.heading": "Errore",
  "CancelOrderErrorModal.prompt": "Cancellazione ordine fallita",
  "CancelOrderErrorModal.explanation": "Potrebbe essere un problema di connessione o di aggiornamento dell'ordine.\nRiprova tra un po'.",
  "CancelOrderErrorModal.cancelButton": "Preso",
  "OrdersListContainer.Tab.sellOrders": "Ordini di vendita",
  "OrdersListContainer.Tab.buyOrders": "Ordini d'acquisto",
  "OrdersListContainer.Tab.historyList": "Storico ordini",
  "ViewOrdersEntry.amounts.remain": "Restanti",
  "ViewOrdersEntry.amounts.done": "Fatto",
  "ViewOrdersEntry.order.order": "Ordine",
  "ViewOrdersEntry.order.issued": "Emesso",
  "ViewOrdersEntry.order.executed": "Eseguito",
  "ViewOrdersEntry.order.pairIsBlocked": "Scambio bloccato",
  "ViewOrdersEntry.order.pairWillBlockAfter": "Verrà bloccato tra",
  "ViewOrdersEntry.order.BUY": "COMPRA",
  "ViewOrdersEntry.order.SELL": "VENDI",
  "ViewOrdersEntry.order.view": "Guarda",
  "ViewOrdersEntry.order.cancel": "Annulla",
  "ViewOrdersList.empty.label": "Non c'è nulla",
  "CancelOrderDialog.heading.youSure": "Sei sicuro?",
  "CancelOrderDialog.body.youSure": "Sei sicuro di voler annullare l'ordine?",
  "CancelOrderDialog.button.cancel": "Si, voglio annullare l'ordine",
  "CancelAllOrderDialog.button.cancel": "Yes for everyone",
  "CancelOrderDialog.button.noCancel": "No",
  "CancelOrderDialog.heading.processing": "In processo...",
  "CancelOrderDialog.body.processing": "In processo, attendere",
  "ProfileMenu.MenuItem.Settings": "Impostazioni",
  "ProfileMenu.MenuItem.GaijinAccount": "Profile Gaijin.net",
  "ProfileMenu.MenuItem.AccountSwitch": "Change account",
  "ProfileMenu.MenuItem.Signout": "Esci",
  "ProfileMenu.MenuItem.nick": "Your nickname",
  "WalletBlock.balance.text": "Saldo:",
  "WalletBlock.balance.buyCurrency": "Buy GJN",
  "SecurityBlock.message.title": "Autenticazione a due fattori",
  "SecurityBlock.emailVerified.title": "Email",
  "OrdersHistory.title.buy": "Buy orders",
  "OrdersHistory.title.sell": "Sell orders",
  "NotificationsAssets.title": "Notifiche",
  "NotificationsAssets.title.today": "Oggi",
  "NotificationsAssets.title.historyFromLastWeek": "Negli ultimi sette giorni",
  "NotificationsAssets.title.historyOlder": "Precedentemente",
  "LimitedAccountPanel.2step.pleas": "Protect your account to gain access to trading on the exchange.",
  "LimitedAccountPanel.2step.protected": "Il tuo account è protetto, hai accesso alle transazioni.",
  "LimitedAccountPanel.email.pleas": "Confirm it to access trading on the exchange.",
  "LimitedAccountPanel.email.protected": "Your e-mail address is confirmed, you have access to trade on the exchange.",
  "OrdersListContainer.Opened.Orders": "Aperti",
  "OrdersListContainer.Closed.Orders": "Chiusi",
  "ViewOrdersList.empty.labelCollapse": "Collapse",
  "ViewOrdersList.empty.labelExpand": "Expand",
  "OrdersGraph.plot.title": "Ordini di acquisto/vendita",
  "OrdersGraph.fmt.offers.sell": "offerte di vendita\n<br/>prezzo",
  "OrdersGraph.fmt.andMore.sell": "o più bassi",
  "OrdersGraph.fmt.offers.buy": "ordini d'acquisto\n<br/>prezzo",
  "Orders.title.header": "ordini",
  "OrdersGraph.fmt.andMore.buy": "o più alto",
  "PriceHistoryGraph.plot.title": "Cronologia prezzi:",
  "PriceHistoryGraph.highlighter.format": "Al <strong>%s</strong><br /> prezzo <strong>%s</strong><br /> di <strong>%s</strong><br />",
  "PriceHistoryGraph.plot.shortRange": "A breve termine",
  "PriceHistoryGraph.plot.wholeRange": "Sempre",
  "DealEventTickerEntry.purchasedFrom": "oggetto acquistato da",
  "DealEventTickerEntry.for": "per",
  "BookSellTickerEntry.listedItem": "sale request",
  "BookBuyTickerEntry.created": "creato",
  "BookBuyTickerEntry.for": "purchase order",
  "BookAuctionTickerEntry.created": "made a bet",
  "ViewTradeTickerLog.waiting": "In attesa di una nuova attività...",
  "ViewTradeTickerLog.ticker": "Real time trading...",
  "ViewUserInventory.common.emptyInventory": "Al momento il tuo inventario è vuoto",
  "ViewItemPreviewSidePane.UnselectButton.label": "Filtri",
  "ViewItemPreviewSidePane.TradeButton.label": "Compra/Vendi",
  "ViewItemPreviewSidePane.ViewButton.label": "Vedi",
  "ViewItemPreviewSidePane.QuickSellButton.label": "Vendita rapida",
  "ViewInventoryTagCloud.tagTitle.itemsOf": "oggetti per",
  "ViewInventoryTagCloud.tagTitle.varietiesMarkedAs": "tipi contrassegnati come",
  "ViewItemSearch.heading.inventory": "My items",
  "WalletBlock.circleSmall.settings": "Impostazioni",
  "WalletBlock.circleSmall.exit": "Esci",
  "UserSettingsDialog.language": "Language",
  "UserSettingsDialog.user_settings": "User settings",
  "UserSettingsDialog.settings": "Settings",
  "UserSettingsDialog.save_settings": "Save settings",
  "UserSettingsDialog.privacySettings": "Trading",
  "UserSettingsDialog.privacySettingsPublic": "Public",
  "UserSettingsDialog.privacySettingsPrivate": "Private",
  "UserSettingsDialog.interface": "Interface",
  "UserSettingsDialog.interfaceOld": "Legacy",
  "UserSettingsDialog.interfaceNew": "Modern",
  "WalletLimitsIcon.soft_limit": "Limite GJN coins oltrepassato.\nFunzioni di rifornimento limitate.",
  "WalletLimitsIcon.hard_limit": "Limite GJN coins oltrepassato.\nFunzioni di Vendita e rifornimento limitate.",
  "ActionBar.action.ingamePreview": "Vedi in gioco",
  "ActionBar.action.previewCopied": "Link copied!",
  "ActionBar.action.previewShare": "Share",
  "IngamePreview.ask.heading": "Vuoi vederlo in gioco?",
  "IngamePreview.ask.prompt": "Stai per vedere questo oggetto in gioco",
  "IngamePreview.ask.explanation": "Make sure the game client is running under your account",
  "IngamePreview.ask.cancelButton": "No",
  "IngamePreview.done.heading": "Fatto",
  "IngamePreview.done.prompt": "",
  "IngamePreview.done.explanation": "Inviato con successo",
  "IngamePreview.done.okButton": "Ok",
  "IngamePreview.fail.heading": "Fallito",
  "IngamePreview.fail.prompt": "",
  "IngamePreview.fail.explanation": "Non deve contattare il client di gioco",
  "IngamePreview.fail.cancelButton": "Ok",
  "IngamePreview.pending.heading": "Giusto un secondo...",
  "IngamePreview.pending.prompt": "Notifica in gioco",
  "IngamePreview.pending.explanation": "",
  "IngamePreview.ask.okButton": "Certo, mostrami",
  "StaticConfigLoader.caption.error": "Errore Inizializzazione",
  "StaticConfigLoader.caption.init": "Inizializzazione",
  "StaticConfigLoader.text.init": "Inizializzazione applicazione...",
  "StaticConfigLoader.error.settings": "Impossibile caricare le impostazioni del sito",
  "StaticConfigLoader.error.circuits": "Impossibile caricare le configurazioni dei servizi",
  "ControlButton.close": "Close",
  "Exit": " ",
  "Legal.TermsConditions": "Termini e condizioni",
  "Legal.TermsService": "Termini servizio",
  "Legal.PrivacyPolicy": "Politica privacy",
  "Legal.CookiesPolicy": "Politica cookie",
  "Legal.TradePolicy": "Politica commerciale",
  "Legal.TermsConditions.linck": "https://legal.gaijin.net/it/",
  "Legal.TermsService.linck": "https://legal.gaijin.net/it/termsofservice/",
  "Legal.PrivacyPolicy.linck": "https://legal.gaijin.net/it/privacypolicy/",
  "Legal.CookiesPolicy.linck": "https://legal.gaijin.net/it/cookie/",
  "Legal.TradePolicy.linck": "https://legal.gaijin.net/it/tradepolicy/",
  "Legal.Copyright": "Gaijin Network Ltd. Tutti i marchi,loghi e nomi delle marche appartengono ai loro rispettivi proprietari.",
  "MarketApp.Navbar.HeadTitle": "Gaijin.Net",
  "MarketApp.Navbar.HeadSubTitle": "Market",
};