import { DropdownAction } from 'components/generics';
import { IconFontAwesome, IconGlyphicon } from 'generics/icons';
import React from 'react';
import ReactDOM from 'react-dom';

export class NavbarNav extends React.Component {
  render() {
    const classes = [
      "nav navbar-nav",
      (this.props.left && "navbar-left"),
      (this.props.right && "navbar-right")
    ];

    return (
      <ul className={classes.join(" ")}>{this.props.children}</ul>
    )
  }
}

export class NavbarNavLink extends React.Component {
  render() {
    return (
      <DropdownAction {...this.props} />
    )
  }
}

export class NavbarNavFontAwesomeLink extends React.Component {
  //  navbar link button component with icon and text

  render() {
    const { icon, children, className, ...passthrough } = this.props;
    return (
      <NavbarNavLink {...passthrough}  >
        <IconFontAwesome type={icon} />&nbsp;{children}
      </NavbarNavLink>
    );
  }
}

export class NavbarNavGlyphiconLink extends React.Component {
  //  navbar link button component with icon and text

  render() {
    const { icon, children, ...passthrough } = this.props;
    return (
      <NavbarNavLink {...passthrough}  >
        <IconGlyphicon type={icon} />&nbsp;{children}
      </NavbarNavLink>
    )
  }
}

export class NavbarNavIconLink extends React.Component {
  render() {
    const { icon, children, ...passthrough } = this.props;
    return (
      <NavbarNavLink {...passthrough}  >
        <img className="navbar-link-icon" src={`images/icons/${icon}.png`} /> {children && <div>{children}</div>}
      </NavbarNavLink>
    )
  }
}

export class NavbarNavDropdown extends React.Component {
  //  navbar dropdown menu button component
  constructor(props) {
    super(props);

    this.state = { open: false };
    this.handleDocumentClick = (evt) => {
      const dropdownRoot = ReactDOM.findDOMNode(this.dropdownRoot);

      if (!dropdownRoot.contains(evt.target)) {
        this.setState({ open: false });
      }
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  render() {
    const li_classes = (["dropdown",
      (this.state.open && "open"),
      (this.props.active && "active"),
      (this.props.disabled && "disabled"),
      this.props.mixClasses
    ]).join(" ");

    return (
      <li className={li_classes} role="button"
        onClick={() => this.setState({ open: !this.state.open })}
        ref={(r) => (this.dropdownRoot = r)}
      >
        <a className="dropdown-toggle"
          title={this.props.title}
        >
          {this.props.caption}&nbsp;<span className="caret" />
        </a>

        <ul className="dropdown-menu">
          {this.props.children}
        </ul>
      </li>
    );
  }
}

export class NavbarNavDropdownAction extends React.Component {
  //  navbar dropdown menu entry component

  render() {
    const li_classes = (["",
      (this.props.active && "active"),
      (this.props.disabled && "disabled"),
      (this.props.hidden && "hidden"),
      this.props.mixClasses
    ]).join(" ");

    const a_props = {
      onClick: this.props.onClick,
      title: this.props.title,
      href: this.props.href,
      target: (this.props.newtab && "_blank"),
      rel: (this.props.newtab && "noopener noreferrer"),
    };

    return (
      <li className={li_classes} role="button">
        <a {...a_props}>
          {this.props.children}
        </a>
      </li>
    );
  }
}

export class NavbarText extends React.Component {
  //  navbar links group component
  render() {
    const classes = ["nav navbar-text",
      (this.props.left && "navbar-left"),
      (this.props.right && "navbar-right")]
    return (<ul className={classes.join(" ")}  >{this.props.children}</ul>);
  }
}