export const plural = (number, words) => {
  return Math.abs(parseInt(number, 10)) === 1 ? words[0] : words[1];
};

export const locale = {
  "ViewItemPreviewSidePane.quantity.inventory": " ",
  "ViewItemPreviewSidePane.quantity.ordersInventory": " Gegenstände im Inventar",
  "ViewItemPreviewSidePane.quantity.count": " zum Verkauf",
  "ViewItemPreviewSidePane.quantity.countBuy": " Bestellungen kaufen",
  "ViewItemPreviewSidePane.quantity.items": " Gegenstände",
  "ViewMarketedItem.SoldOutText": "Ausverkauft",
  "ViewMarketedItem.SoldOutTip": "Keine Verkaufsaufträge",
  "ViewMarketedItem.NoBuyText": "keine Bestellungen",
  "ViewMarketedItem.SellingFrom": "Verkauf durch...",
  "ViewMarketedItem.SellingMore": " oder mehr",
  "ViewMarketedItem.BuyingMore": " oder weniger",
  "ViewMarketedItem.tradableAfter": "wird verfügbar sein nach",
  "ViewItemSearch.heading.showAll": "Alle Listen anzeigen",
  "ViewItemSearch.heading.orders": "Meine Bestellungen",
  "ViewItemSearch.heading.marketSell": "Verkäufe",
  "ViewItemSearch.heading.marketBuy": "Bestellungen",
  "ViewItemSearch.heading.marketAuctions": "Auktionen",
  "ViewItemSearch.title.marketAuction": "Auktion",
  "ViewItemSearch.title.ending": "Bis zum Ende",
  "ViewItemSearch.title.finished": "Auktion beendet",
  "ViewItemSearch.heading.settings": "Einstellungen",
  "ViewMarketedItem.button.more": "Mehr Details",
  "ViewItemSearch.heading.resultsFor": "Ergebnisse anzeigen:",
  "ViewItemSearch.stub.empty": "Leeres Suchergebnis",
  "ViewItemSearch.heading.tooltip": "Ihr Gebot wurde überboten",
  "ViewItemSearch.heading.tooltip.win": "Ihr Gebot hat gewonnen",
  "ViewItemPopup.committed.SELL": "Verkauf abgeschlossen",
  "ViewItemPopup.committed.BUY": "Kauf abgeschlossen",
  "ViewItemPopup.committed.BID": "Gebot abgegeben",
  "ViewItemPopup.application.BUY": "Kaufauftrag erstellt",
  "ViewItemPopup.application.SELL": "Verkaufsanfrage erstellt",
  "ViewItemPopup.application.BID": "Gebot abgegeben",
  "ViewItemPopup.canceled.BUY": "Kaufauftrag storniert",
  "ViewItemPopup.canceled.SELL": "Verkaufsanfrage storniert",
  "ViewItemPopup.canceled.BID": "Gebot storniert",
  "ViewItemPopup.showAll.button": "Alle anzeigen",
  "ViewItemAssets.readAll.button": "Alle als angesehen markieren",
  "ViewItemPopup.heading.noHistory": "Noch keine Benachrichtigungen",
  "ViewItemPopup.heading.unseenHistory": "Keine neuen Benachrichtigungen",
  "ViewItemSearch.termsPlaceholder": "Hier Begriffe suchen",
  "ViewItemSearch.label.search": "Nach Items suchen",
  "ViewMarketedItem.lovestPrice": "von ",
  "ViewMarketedItem.beforePrice": "vor ",
  "ViewMarketedItem.yourBid": "ihr Gebot ",
  "ViewMarketedItem.bid": "gebot ",
  "ViewItemSearch.heading.exchange": "Home",
  "ViewItemSearch.heading.light": "Light",
  "ViewItemSearch.heading.dark": "Dark",
  "ViewItemSearch.filteringAnnounce": "um deine Bestellungen zu filtern musst du ein Spiel auswählen",
  "ViewItemSearch.ordersBuy": "KAUFEN",
  "ViewItemSearch.ordersSell": "VERKAUFEN",
  "ViewItemSearch.ordersOpen": "Vollen Verlauf ansehen",
  "MarketApp.Navbar.inventoryLink": "Inventar",
  "MarketApp.Navbar.ordersLink": "Meine Bestellungen",
  "MarketApp.Navbar.marketLink": "Market",
  "MarketApp.Navbar.settingsLink": "Einstellungen",
  "MarketApp.Navbar.replenishLink": "Guthaben auffüllen",
  "MarketApp.Navbar.balanceHistoryLink": "Guthabenverlauf",
  "MarketApp.Navbar.buyCoinsLink": "GaijinCoins kaufen",
  "MarketApp.Navbar.profileLink": "Profil",
  "MarketApp.Navbar.reloginLink": "Neu einloggen oder Konto wechseln",
  "MarketApp.Navbar.toOldVersion": "Zur alten Version wechseln",
  "MarketApp.Navbar.linkFeedback": "Feedback hinterlassen",
  "ViewTradeDialog.caption": "Handel",
  "ViewTradeDialog.button.back": "Zurück",
  "NotAllowedMessage.caption": "Nicht gestattet",
  "NotAllowedMessage.body.h1": "Zugang zum Marktplatz",
  "NotAllowedMessage.body.h3": "momentan nicht für ihr Konto gestattet",
  "NotAllowedMessage.relogin": "Konto wechseln",
  "CountryDeniedMessage.caption": "Nicht gestattet",
  "CountryDeniedMessage.body.notAllowed": "Zugang zum Marktplatz ist nicht gestattet",
  "CountryDeniedMessage.body.unclearStatus": "Aufgrund der unklaren rechtlichen Lage in deinem Land ist der Zugang zum Marktplatz deaktiviert",
  "LandingMessage.caption": "Authentifizierung",
  "LandingMessage.body": "Landung abschließen...",
  "TokenExpiredMessage.caption": "Authentifizierung abgelaufen",
  "TokenExpiredMessage.body.h1": "Authentifizierung abgelaufen",
  "TokenExpiredMessage.body.h3": "Wir können nicht viel damit anfangen, aber du kannst immer noch",
  "TokenExpiredMessage.relogin": "Re-Login...",
  "AuthErrorMessage.caption": "Authentifizierung gescheitert",
  "AuthErrorMessage.body.h1": "Überprüfung der Authentifizierung gescheitert",
  "AuthErrorMessage.body.code": "Code:",
  "AuthErrorMessage.body.desc": "Wir können nicht viel damit anfangen, aber du kannst immer noch",
  "AuthErrorMessage.relogin": "Re-Login...",
  "AuthorizationRequiredMessage.caption": "Authentifizierung benötigt",
  "AuthorizationRequiredMessage.body.h3.1": "Du betrittst jetzt",
  "AuthorizationRequiredMessage.body.h3.2": "Gaijin Marktplatz",
  "AuthorizationRequiredMessage.body.h3.3": "Du musst autorisiert sein um fortzufahren",
  "AuthorizationRequiredMessage.login": "Mit Gaijin-Net einloggen",
  "CheckingAuthMessage.caption": "Authentifizierung",
  "CheckingAuthMessage.body": "Überprüfung der Authentifizierung, bitte warten",
  "CancelOrdersPanel.cancelError": "\nStornierung der Bestellung abgebrochen ",
  "CancelOrdersPanel.loading": "Aktive Aufträge überprüfen",
  "CancelOrdersPanel.done": "Vorgang abgeschlossen.",
  "CancelOrdersPanel.errors": "Fehler aufgetreten: \n",
  "CancelOrdersPanel.loaded.youHave": "Im Moment hast du ",
  "CancelOrdersPanel.loaded.sellOrders": " Aufträge verkaufen von insgesamt ",
  "CancelOrdersPanel.loaded.buyOrders": " Aufträge kaufen von insgesamt ",
  "CancelOrdersPanel.loaded.and": " und ",
  "CancelOrdersPanel.loaded.shouldCancel": " und du musst sie alle abbrechen. ",
  "CancelOrdersPanel.loaded.doCancel": "Aufträge abbrechen",
  "CancelOrdersPanel.cancelling.processing": "Bearbeiten",
  "CancelOrdersPanel.cancelling.outOf": " nicht im ",
  "CancelOrdersPanel.cancelling.sellOrders": " Aufträge verkaufen",
  "CancelOrdersPanel.cancelling.buyOrders": " Aufträge kaufen",
  "ClassInfoDescriptions.dropped_item.block_label": "Enthält eines der Folgenden:",
  "ClassInfoDescriptions.dropped_item.stage-unknown": "Unbekannt",
  "ClassInfoDescriptions.dropped_item.stage-pending": "...wird geladen",
  "ClassInfoDescriptions.text.block_label": "",
  "ClassInfoDescriptions.dropped_bundle.block_label": " ",
  "ClassInfoDescriptions.buttonLabel.top": "Anzeigen",
  "ClassInfoDescriptions.buttonLabel.hide": "Verstecken",
  "ClassInfoDescriptions.buttonLabel.bottom": "alle",
  "ClassInfoDescriptions.dropped_text.description": "Description",
  "ClassInfoUtil.fake_name.pending": "Ausstehend",
  "ClassInfoUtil.fake_name.broken": "Gelöschtes Item",
  "ClassInfoUtil.SynthTag.marketable1": "Marktfähig",
  "ClassInfoUtil.SynthTag.marketable0": "Nicht marktfähig",
  "ClassInfoUtil.SynthTag.tradable1": "Handelbar",
  "ClassInfoUtil.SynthTag.tradable0": "Nicht handelbar",
  "ClassInfoUtil.SynthTag.commodity1": "Handeslware",
  "ClassInfoUtil.SynthTag.commodity0": "Keine Handelsware",
  "ConfirmBuyDialog.title": "Bestellung aufgeben",
  "BuyProcessingDialog.title": "Bearbeiten...",
  "BuyProcessingDialog.pleaseWait": "Bearbeiten, bitte warten",
  "ProcessingDialog.notItem": "Der angeforderte Artikel wurde auf dem Markt nicht gefunden oder ist nicht verfügbar zum Handel",
  "SellDialog.title": "Ein Item zum Verkauf platzieren",
  "SellProcessingDialog.title": "Bearbeiten...",
  "SellProcessingDialog.pleaseWait": "Bearbeiten, bitte warten",
  "LimitedAccountPanel.2step.reason": "Du musst die Zwei-Faktor-Authentifizierung aktivieren.",
  "LimitedAccountPanel.2step.link": "Mehr zur Zwei-Faktor-Authentifizierung",
  "LimitedAccountPanel.2step.href": "https://support.gaijin.net/hc/en-us/articles/203623622-How-to-set-up-the-Two-Step-Authorization-Google-Authenticator-",
  "LimitedAccountPanel.email.reason": "Du musst deine E-Mail-Adresse bestätigen.",
  "LimitedAccountPanel.email.link": "Zum Profil",
  "LimitedAccountPanel.2step.toPlug": "Aktivieren",
  "LimitedAccountPanel.email.href": "https://store.gaijin.net/user.php",
  "LimitedAccountPanel.title": "Dein Konto ist durch Folgendes limitiert:",
  "LimitedAccountPanel.conclusion": "Nachdem du deine Sicherheitseinstellungen geändert hast, solltest du dich ausloggen und erneut einloggen, damit die Änderungen gelten.",
  "LimitedAccountPanel.relogin": "Re-Login...",
  "EmailVerificationStateIcon.success": "Konto bestätigt, E-Mail-Adresse verifiziert",
  "EmailVerificationStateIcon.warning": "Konto nicht bestätigt, Email unverifiziert",
  "EmailVerificationStateIcon.danger": "Konto unbestätigt, Email Verifizierung BENÖTIGT",
  "TwoFactorStateIcon.success": "Konto mit Zwei-Faktor-Authentifizierung gesichert",
  "TwoFactorStateIcon.warning": "Ungesichertes Konto, Zwei-Faktor-Authentifizierung abgeschaltet",
  "TwoFactorStateIcon.danger": "Zwei-Faktor-Authentifizierung BENÖTIGT",
  "ViewInventoryTextFilter.common.label": "Um dein Inventar zu filtern, musst du ein Spiel auswählen.",
  "ItemBuyEditor.label.wheelAmountInput": "Du kannst das Mausrad benutzen um die Anzahl zu ändern,\nalt+Mausrad = Geschwindigkeit x10",
  "ItemBuyEditor.label.wheelInput": "Du kannst das Mausrad benutzen um den Preis zu ändern,\nalt+Mausrad = Geschwindigkeit x10,\nshift+Mausrad = Geschwindigkeit x0,1",
  "ItemBuyEditor.note.posAmount": "Der Wert sollte positiv sein",
  "ItemBuyEditor.note.maxAmount": "Der Wert ist über dem Maximum von 1000 Items",
  "ItemBuyEditor.note.invalidAmount": "Unzulässiger Wert",
  "ItemBuyEditor.note.minPrice": "Der minimale Preis ist",
  "ItemBuyEditor.note.maxTotal": "Die Auftragsanzahl ist über dem Maximum von",
  "ItemBuyEditor.note.invalidPrice": "Unzulässiger Wert",
  "ItemBuyEditor.note.mustAgree": "Du musst den Nutzungsbedingungen zustimmen",
  "ItemBuyEditor.note.insufficentFunds0": "Ungenügend Geldmittel, du brauchst mehr",
  "ItemBuyEditor.note.insufficentFunds1": "für diesen Auftrag",
  "ItemBuyEditor.label.buyCouns": "Kaufen",
  "ItemBuyEditor.label.submit": "Auftrag platzieren",
  "ItemBuyEditor.label.price": "Welchen Preis möchtest du zahlen (pro Item):",
  "ItemBuyEditor.label.amount": "Wie viel möchtest du kaufen:",
  "ItemBuyEditor.label.totalPrice": "Gesamter Preis (mit Gebühren):",
  "ItemBuyEditor.label.agree": "Ich stimme dem Gaijin-Shop zu",
  "ItemBuyEditor.label.ToU": "Nutzungsbedingungen",
  "MarketDepthTotals.noOrders.sell": "Keine Verkaufsaufträge",
  "MarketDepthTotals.noOrders.buy": "Keine Kaufaufträge",
  "MarketDepthTotals.starting.sell": " für ",
  "MarketDepthTotals.starting.buy": " von ",
  "MarketDepthTotals.andMore.sell": " und niedriger",
  "MarketDepthTotals.andMore.buy": " und höher",
  "MarketDepthTotals.prevew.sell": "Kauf: ",
  "MarketDepthTotals.prevew.buy": "Im Verkauf: ",
  "MarketDepthOwnWarning.sell": "Du hast ausstehende Verkaufsaufträge",
  "MarketDepthOwnWarning.buy": "Du hast ausstehende Kaufaufträge",
  "MarketDepthTable.caption.price": "Preis",
  "MarketDepthTable.caption.quantity": "Anzahl",
  "MarketDepthTable.ellipsis.sell": "und mehr",
  "MarketDepthTable.ellipsis.buy": "und weniger",
  "ItemMarketDetails.buyButton": "KAUFEN",
  "ItemMarketDetails.sellButton": "VERKAUFEN",
  "ItemMarketDetails.nothingToSellButton": "Nichts zu verkaufen",
  "ItemMarketDetails.makeAuction": "Auktion erstellen",
  "ItemMarketDetails.makeBid": "Gebot abgeben",
  "ItemMarketDetails.bid": "Gebot",
  "ItemMarketDetails.yourBid": "Ihr Gebot:",
  "ItemMarketDetails.notifications.yourBid": "Ihr Gebot",
  "ItemMarketDetails.yourBid.short": "Ihr",
  "ItemMarketDetails.yourBid.win": "gewinnt",
  "ItemMarketDetails.yourBid.loose": "verliert",
  "ItemSellEditor.label.priceStep": "Preisschritt",
  "ItemSellEditor.label.endDate": "Enddatum",
  "limitedAccountMessage.requires": "Erfordert:",
  "limitedAccountMessage.2step": "Absicherung in zwei Schritten",
  "limitedAccountMessage.verifiedEmail": "verifizierte Email",
  "limitedAccountMessage.and": " und ",
  "ItemSellEditor.label.wheelInput": "Du kannst das Mausrad benutzen um den Preis zu ändern,\nalt+Mausrad = Geschwindigkeit x10,\nshift+Mausrad = Geschwindigkeit x0,1",
  "ItemSellEditor.note.minPrice": "Der minimale Preis ist",
  "ItemSellEditor.note.maxPrice": "Der maximale Verkaufspreis, der erlaubt ist",
  "ItemSellEditor.note.maxTotals": "Die Auftragsanzahl ist über dem Maximum von",
  "ItemSellEditor.note.maxBalance": "Der Kontostand darf nicht überschreiten",
  "ItemSellEditor.note.mustAgree": "Du musst den Nutzungsbedingungen zustimmen",
  "ItemSellEditor.label.price": "Käufer zahlt (einschließlich Gebühren):",
  "ItemSellEditor.label.wished": "Du erhältst, nachdem Gebühren berechnet wurden:",
  "ItemSellEditor.label.agree": "Ich stimme dem Gaijin-Shop zu",
  "ItemSellEditor.label.ToU": "Nutzungsbedingungen",
  "ItemSellEditor.label.submit": "OK, für den Verkauf bereitstellen",
  "ItemSellEditor.currentBalance.before": "Im Moment hast du",
  "ItemSellEditor.currentBalance.after": "dein Kontostand beträgt",
  "ItemSellEditor.sellOrdersTotal.before": "plus",
  "ItemSellEditor.sellOrdersTotal.after": "angesammelte Verkaufsaufträge",
  "ItemSellEditor.buyOrdersTotal.before": "und",
  "ItemSellEditor.buyOrdersTotal.after": "angesammelte Kaufaufträge",
  "ItemSellEditor.label.amount": "Wie viel möchtest du verkaufen:",
  "AppFilterEditor.label.byTags": "Filter",
  "AppFilterEditor.label.clearFilter": "Filter zurücksetzen",
  "AppFilterEditor.label.byTitles": "Nach Titel filtern",
  "AppFilterEditor.label.allFilter": "Alle Spiele",
  "AppFilterEditor.label.authenticity": "Echtheit",
  "AppFilterEditor.message.filter": "Die Filter sind für jedes Spiel individuell.",
  "AppFilterEditor.message.game": "Wähle ein Spiel:",
  "ViewItemDetailed.restriction.amount.items": " Items",
  "ViewItemDetailed.restriction.nonMarketable": "Kann nicht verkauft werden",
  "ViewItemDetailed.restriction.blockedAfter": "Wird gesperrt",
  "ViewItemDetailed.restriction.blockedPair": "Handel gesperrt",
  "ViewItemDetailed.restriction.expiresAt1": "Wird verschwinden nach",
  "ViewItemDetailed.restriction.expiresAt2": "",
  "ViewItemDetailed.restriction.endedAt": "End of auction",
  "ViewItemDetailed.restriction.amount": "Lot contains",
  "ViewItemDetailed.restriction.pieces": "pcs.",
  "ViewItemDetailed.restriction.startPrice": "Starting price",
  "ViewItemDetailed.restriction.days": "d",
  "ViewItemDetailed.restriction.hours": "hrs",
  "ViewItemDetailed.restriction.minutes": "min",
  "ViewItemDetailed.restriction.leadingBids": "leading bids",
  "ViewItemDetailed.restriction.noBids": "There are no bids yet. Place your first bid in this auction!",
  "ViewItemDetailed.restriction.delayTrade": "Once purchased, the item will be available for sale through",
  "ViewItemDetailed.restriction.unclearStatus": "Aufgrund der unklaren rechtlichen Lage der Trophäentruhen in deinem Land sind alle Transaktionen mit Trophäen und Schlüsseln für dein Konto gesperrt.",
  "MarketSortOrder.label.name": "Name",
  "MarketSortOrder.hint.newDesc": "Neue zuerst",
  "MarketSortOrder.hint.newAsc": "Alte zuerst",
  "MarketSortOrder.label.price": "Preis",
  "MarketSortOrder.hint.priceAsc": "Kosten aufsteigend",
  "MarketSortOrder.hint.priceDesc": "Hohe bis niedrige Kosten",
  "MarketSortOrder.label.amount": "Anzahl",
  "MarketSortOrder.hint.amountAsc": "Betrag aufsteigend",
  "MarketSortOrder.hint.amountDesc": "Hohe bis niedrige Summe",
  "MarketSortOrder.label.popular": "Beliebt",
  "MarketSortOrder.label.popularItems": "Beliebte Items",
  "DoubleSellErrorModal.heading": "Fehler",
  "DoubleSellErrorModal.prompt": "Verkauf des aktuellen items gescheitert",
  "DoubleSellErrorModal.explanation": "Platzieren des Verkaufsauftrages gescheitert, einer der möglichen Gründe - Item wurde verkauft oder im Spiel verbraucht\nVersuche die Seite neu zu laden",
  "DoubleSellErrorModal.okButton": "OK",
  "OnBuyReserveFailModal.heading": "Fehler",
  "OnBuyReserveFailModal.prompt": "Kaufauftrag konnte nicht platziert werden",
  "OnBuyReserveFailModal.explanation": "Erstellung eines Kaufauftrags gescheitert, nicht möglich Geld vom Wallet zu reservieren.",
  "OnBuyReserveFailModal.youNeed1": "Du brauchst mindestens",
  "OnBuyReserveFailModal.youNeed2": "für diese Transaktion",
  "OnBuyReserveFailModal.tryRefresh": "Um die Seite neu zu laden bitte gehe sicher genug Mittel zu haben",
  "OnBuyReserveFailModal.okButton": "OK",
  "DoubleRateErrorModal.heading": "Error",
  "DoubleRateErrorModal.prompt": "An error occurred while placing a bid",
  "DoubleRateErrorModal.explanation": "Please try to bid later",
  "DoubleRateErrorModal.okButton": "OK",
  "AuctionFinishedErrorModal.heading": "Error",
  "AuctionFinishedErrorModal.prompt": "An error occurred while placing a bid",
  "AuctionFinishedErrorModal.explanation": "The auction was completed",
  "AuctionFinishedErrorModal.okButton": "OK",
  "CancelOrderErrorModal.heading": "Fehler",
  "CancelOrderErrorModal.prompt": "Stornierung der Bestellung abgebrochen",
  "CancelOrderErrorModal.explanation": "Mögliche Gründe sind Netzwerkprobleme oder du hast während der Bestellung aktualisiert.\nBitte etwas später erneut probieren.",
  "CancelOrderErrorModal.cancelButton": "Verstanden",
  "OrdersListContainer.Tab.sellOrders": "Aufträge verkaufen",
  "OrdersListContainer.Tab.buyOrders": "Aufträge kaufen",
  "OrdersListContainer.Tab.historyList": "Auftragsverlauf",
  "ViewOrdersEntry.amounts.remain": "Verblieben",
  "ViewOrdersEntry.amounts.done": "Fertig",
  "ViewOrdersEntry.order.order": "Auftrag",
  "ViewOrdersEntry.order.issued": "Ausgestellt",
  "ViewOrdersEntry.order.executed": "Ausgeführt",
  "ViewOrdersEntry.order.pairIsBlocked": "Handel gesperrt",
  "ViewOrdersEntry.order.pairWillBlockAfter": "Wird gesperrt",
  "ViewOrdersEntry.order.BUY": "KAUFEN",
  "ViewOrdersEntry.order.SELL": "VERKAUFEN",
  "ViewOrdersEntry.order.view": "Ansicht",
  "ViewOrdersEntry.order.cancel": "Abbrechen",
  "ViewOrdersList.empty.label": "Hier ist nichts",
  "CancelOrderDialog.heading.youSure": "Bist du sicher?",
  "CancelOrderDialog.body.youSure": "Bist du sicher den Auftrag abzubrechen?",
  "CancelOrderDialog.button.cancel": "Ja, du möchtest den Auftrag abbrechen",
  "CancelAllOrderDialog.button.cancel": "Yes for everyone",
  "CancelOrderDialog.button.noCancel": "Nein",
  "CancelOrderDialog.heading.processing": "Bearbeiten...",
  "CancelOrderDialog.body.processing": "Bearbeiten, bitte warten",
  "ProfileMenu.MenuItem.Settings": "Einstellungen",
  "ProfileMenu.MenuItem.GaijinAccount": "Gaijin.net-Profil",
  "ProfileMenu.MenuItem.AccountSwitch": "Konto wechseln",
  "ProfileMenu.MenuItem.Signout": "Verlassen",
  "ProfileMenu.MenuItem.nick": "Dein Nickname",
  "WalletBlock.balance.text": "Guthaben:",
  "WalletBlock.balance.buyCurrency": "GJN kaufen",
  "SecurityBlock.message.title": "Zwei-Faktor-Authentifizierung",
  "SecurityBlock.emailVerified.title": "E-Mail",
  "OrdersHistory.title.buy": "Bestellungen kaufen",
  "OrdersHistory.title.sell": "Verkaufen",
  "NotificationsAssets.title": "Benachrichtigungen",
  "NotificationsAssets.title.today": "Heute",
  "NotificationsAssets.title.historyFromLastWeek": "In den letzten sieben Tagen",
  "NotificationsAssets.title.historyOlder": "Früher",
  "LimitedAccountPanel.2step.pleas": "Schütze dein Konto um auf der Börse handeln zu können.",
  "LimitedAccountPanel.2step.protected": "Dein Konto ist geschützt, du kannst auf der Börse handeln.",
  "LimitedAccountPanel.email.pleas": "Bestätige um auf der Börse handeln zu können.",
  "LimitedAccountPanel.email.protected": "Deine E-Mailadresse wurde bestätigt, du kannst auf der Börse handeln.",
  "OrdersListContainer.Opened.Orders": "Aktiv",
  "OrdersListContainer.Closed.Orders": "Abgeschlossen",
  "ViewOrdersList.empty.labelCollapse": "Zusammenklappen",
  "ViewOrdersList.empty.labelExpand": "Ausklappen",
  "OrdersGraph.plot.title": "Käufe/Verkäufe",
  "OrdersGraph.fmt.offers.sell": "Aufträge verkaufen zum Preis von\n<br/>",
  "OrdersGraph.fmt.andMore.sell": "oder weniger",
  "OrdersGraph.fmt.offers.buy": "Aufträge kaufen zum Preis von\n<br/>",
  "Orders.title.header": "Aufträge",
  "OrdersGraph.fmt.andMore.buy": "der höher",
  "PriceHistoryGraph.plot.title": "Preisverlauf:",
  "PriceHistoryGraph.highlighter.format": "Bei <strong>%s</strong><br /> Preis <strong>%s</strong><br /> Summe <strong>%s</strong><br />",
  "PriceHistoryGraph.plot.shortRange": "Kurze Zeit",
  "PriceHistoryGraph.plot.wholeRange": "Gesamte Zeit",
  "DealEventTickerEntry.purchasedFrom": "dieses Item wurde gekauft von",
  "DealEventTickerEntry.for": "für",
  "BookSellTickerEntry.listedItem": "sale request",
  "BookBuyTickerEntry.created": "erstellt",
  "BookBuyTickerEntry.for": "purchase order",
  "BookAuctionTickerEntry.created": "made a bet",
  "ViewTradeTickerLog.waiting": "Warte auf neue Aktivität...",
  "ViewTradeTickerLog.ticker": "Real time trading...",
  "ViewUserInventory.common.emptyInventory": "Im Moment ist dein Inventar leer",
  "ViewItemPreviewSidePane.UnselectButton.label": "Filter",
  "ViewItemPreviewSidePane.TradeButton.label": "Verkaufen/Kaufen",
  "ViewItemPreviewSidePane.ViewButton.label": "Ansicht",
  "ViewItemPreviewSidePane.QuickSellButton.label": "Schnellverkauf",
  "ViewInventoryTagCloud.tagTitle.itemsOf": "Items von",
  "ViewInventoryTagCloud.tagTitle.varietiesMarkedAs": "Sorten vermerkt als",
  "ViewItemSearch.heading.inventory": "Meine Items",
  "WalletBlock.circleSmall.settings": "Einstellungen",
  "WalletBlock.circleSmall.exit": "Verlassen",
  "UserSettingsDialog.language": "Sprache",
  "UserSettingsDialog.user_settings": "Benutzereinstellungen",
  "UserSettingsDialog.settings": "Einstellungen",
  "UserSettingsDialog.save_settings": "Einstellungen speichern",
  "UserSettingsDialog.privacySettings": "Handel",
  "UserSettingsDialog.privacySettingsPublic": "Öffentlich",
  "UserSettingsDialog.privacySettingsPrivate": "Privat",
  "UserSettingsDialog.interface": "Interface",
  "UserSettingsDialog.interfaceOld": "Legacy",
  "UserSettingsDialog.interfaceNew": "Modern",
  "WalletLimitsIcon.soft_limit": "Beschränkung der GJN-Coins überschritten.\nDie Auffüllfunktionen sind beschränkt.",
  "WalletLimitsIcon.hard_limit": "Beschränkung der GJN-Coins überschritten.\nVerkauf und Auffüllfunktionen sind beschränkt.",
  "ActionBar.action.ingamePreview": "Im Spiel ansehen",
  "ActionBar.action.previewCopied": "Link kopiert!",
  "ActionBar.action.previewShare": "Teilen",
  "IngamePreview.ask.heading": "Im Spiel ansehen?",
  "IngamePreview.ask.prompt": "Du siehst dir das Item im Spiel an",
  "IngamePreview.ask.explanation": "Stell sicher, dass der Spiel-Client unter deinem Konto läuft.",
  "IngamePreview.ask.cancelButton": "Nein",
  "IngamePreview.done.heading": "Fertig",
  "IngamePreview.done.prompt": "",
  "IngamePreview.done.explanation": "Erfolgreich gesendet",
  "IngamePreview.done.okButton": "Ok",
  "IngamePreview.fail.heading": "Gescheitert",
  "IngamePreview.fail.prompt": "",
  "IngamePreview.fail.explanation": "Der Spielclient konnte nicht erreicht werden",
  "IngamePreview.fail.cancelButton": "Ok",
  "IngamePreview.pending.heading": "Nur eine Sekunde...",
  "IngamePreview.pending.prompt": "Deinen Spielclient anzeigen",
  "IngamePreview.pending.explanation": "",
  "IngamePreview.ask.okButton": "Sicher, zeig her",
  "StaticConfigLoader.caption.error": "Initialisierungsfehler",
  "StaticConfigLoader.caption.init": "Initialisierung",
  "StaticConfigLoader.text.init": "Initialisierungantrag...",
  "StaticConfigLoader.error.settings": "Nicht möglich die Seiteneinstellungen zu laden",
  "StaticConfigLoader.error.circuits": "Nicht möglich die Dienstleistungseinstellungen zu laden",
  "ControlButton.close": "Schließen",
  "Exit": " ",
  "Legal.TermsConditions": "Geschäftsbedingungen",
  "Legal.TermsService": "Nutzungsbedingungen",
  "Legal.PrivacyPolicy": "Datenschutzerklärung",
  "Legal.CookiesPolicy": "Cookie-Richtlinie",
  "Legal.TradePolicy": "Handelsrichtlinie",
  "Legal.TermsConditions.linck": "https://legal.gaijin.net/de/",
  "Legal.TermsService.linck": "https://legal.gaijin.net/de/termsofservice/",
  "Legal.PrivacyPolicy.linck": "https://legal.gaijin.net/de/privacypolicy/",
  "Legal.CookiesPolicy.linck": "https://legal.gaijin.net/de/cookie/",
  "Legal.TradePolicy.linck": "https://legal.gaijin.net/de/tradepolicy/",
  "Legal.Copyright": "Gaijin Network Ltd. Alle Markenzeichen, Logos und Markennamen sind das Eigentum ihrer jeweiligen Eigentümer.",
  "MarketApp.Navbar.HeadTitle": "Gaijin.Net",
  "MarketApp.Navbar.HeadSubTitle": "Market",
};