import React, { useState, useEffect, createContext, useContext, Fragment } from "react";
import { MarketBooks } from "../api/tradeAPICall";
import { LogStream } from "../generics/common";
import { ItemClassInfoResolver } from "../api/assetAPICall";
import { fakeClassInfo, isCountryDenied } from "./classInfoUtil";
import { ViewItemIconic } from "./commonAssets";
import { GJN } from "../generics/marketIcons";
import { ClassInfoDescriptions } from "./classInfoDescriptions";
import MoneyUtil from "./moneyUtil";
import {_I_} from "./translate";
import spawnConfirmDialogModal from "./confirmBuyDialog";


const ClassInfoContext = createContext(null);

const fetchMarketBooks = async (appid, market_name) => {
  try {
    const brief = await MarketBooks(appid, market_name);
    return { sellItems: brief.SELL || [], buyItems: brief.BUY || [] };
  } catch (reason) {
    handleMarketBooksError(reason);
    return { sellItems: [], buyItems: [] };
  }
};

const handleMarketBooksError = (reason) => {
  reason = Object.assign({}, reason, {
    "caption": "Books Brief Error",
    "message": "Failed to get books brief:\n" + reason.message
  });
  LogStream("MARKETAPP").error("MarketBooksBrief - Failed (", reason, ")");
};


const ClassInfoProvider = ({ children, appid, asset }) => {
  const [classInfo, setClassInfo] = useState(null);

  useEffect(() => {
    ItemClassInfoResolver.resolve(appid, asset)
      .then((classInfo) => setClassInfo(classInfo))
      .catch((reason) => {
        setClassInfo(fakeClassInfo("broken", asset));
      });
  }, [appid, asset]);

  return (
    <ClassInfoContext.Provider value={classInfo}>
      {children}
    </ClassInfoContext.Provider>
  );
};


const ViewItem = ({ item, appid, market_name, haveOwnSellOrders }) => {
  const classInfo = useContext(ClassInfoContext);

  const itemPrice = item.price ? MoneyUtil.formatMoney(item.price) : "";

  const clickBuy = () => {
    const countryDenied = isCountryDenied(classInfo);
    if (countryDenied)
      return;

    const initialBuyPrice = parseFloat(itemPrice);
    spawnConfirmDialogModal(initialBuyPrice, appid, market_name, classInfo, haveOwnSellOrders, false, item.id);
  }

  return (
    <div className="nonCommodityItem">
      <div className="item">
        {classInfo && (
          <Fragment>
            <ViewItemIconic classInfo={classInfo} />
            <div className="classInfoContent">
              <div className="itemInfo">
                <div className="name">{classInfo?.name}</div>
                <ClassInfoDescriptions
                  appId={appid}
                  descriptions={classInfo.descriptions}
                  owner_descriptions={classInfo.owner_descriptions}
                  type="text"
                />
              </div>
              <div className="event">
                <div className="price">
                  {itemPrice} <GJN />
                </div>

                <div onClick={() => clickBuy()} className="button buy">{_I_("ItemMarketDetails.buyButton")}</div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

const ViewNonCommodity = ({ appid, market_name, haveOwnSellOrders }) => {
  const [items, setItems] = useState({ sellItems: [], buyItems: [] });

  useEffect(() => {
    fetchMarketBooks(appid, market_name).then(setItems);
  }, [appid, market_name]);

  return (
    <div className="nonCommodityItemBlock">
      {items.sellItems.map((item) => (
        <ClassInfoProvider key={item.assetClass} appid={appid} asset={item.assetClass}>
          <ViewItem item={item} appid={appid} market_name={market_name} haveOwnSellOrders={haveOwnSellOrders} />
        </ClassInfoProvider>
      ))}
      {items.buyItems.map((item) => (
        <ClassInfoProvider key={item.assetClass} appid={appid} asset={item.assetClass}>
          <ViewItem item={item} appid={appid} />
        </ClassInfoProvider>
      ))}
    </div>
  );
};

export default ViewNonCommodity;