import { _I_ } from 'components/translate';
import { MarketStore } from 'stores';

export function cmpClassInfoTagValue(left, right) {
  if (left.internal_name == right.internal_name) return 0;
  if (left.internal_name < right.internal_name) return -1;
  return 1;
}

export function cmpClassInfoTagCategory(left, right) {
  if (left.category == right.category) return 0;
  if (left.category < right.category) return -1;
  return 1;
}

export function cmpClassInfoTagCategoryValue(left, right) {
  return cmpClassInfoTagCategory(left, right) || cmpClassInfoTagValue(left, right);
}

export function fetchExtendedTags(classInfo) {
  return [...(classInfo.tags || [])];
}

export function isExpired(classInfo) {
  if (classInfo.IS_UNRESOLVED)
    return false;

  if (!(classInfo.marketable || classInfo.auctionable))
    return true;

  const expireAt = +classInfo.expireAt;
  const expireAtStamp = (!!expireAt) && (expireAt > 0) && +(new Date(1000 * expireAt));

  if (expireAtStamp) {
    const ensureHidden = 5 * 60 * 1000;
    const now = Date.now();
    if ((expireAtStamp - ensureHidden) < now)
      return true;
  }

  return false;
}

export function isCountryDenied(classInfo) {
  const { userCountry } = MarketStore;
  const deniedCountriesTag = fetchExtendedTags(
    classInfo
  ).filter(
    tagDesc => tagDesc && tagDesc.category == 'countryDenyPurchase' && tagDesc.internal_name
  );
  const deniedCountries = deniedCountriesTag.length && deniedCountriesTag[0].internal_name.split('_');
  const countryDenied = deniedCountries && deniedCountries.filter(
    denyCountry => denyCountry && denyCountry.toUpperCase() == userCountry
  ).length;

  return !!countryDenied;
}

export function fakeClassInfo(reason, name) {

  let icon_url = null;

  if (reason == "pending")
    icon_url = "images/loader.gif";

  if (reason == "broken")
    icon_url = "images/broken_item.png";

  return {
    "name": `${_I_(`ClassInfoUtil.fake_name.${reason}`)}`,
    "background_color": "",
    "name_color": "",
    "icon_url": icon_url,
    "IS_UNRESOLVED": (reason == "broken"),
  };
}