import ApplicationEvents from "components/applicationEvents";
import { observer } from "mobx-react";
import { Fragment, useEffect, useState } from 'react';
import { NotificationContext } from "../../generics/marketHooks";
import { NavbarNavIconLink } from "../../generics/navbar";
import { classMixer } from "../../generics/utils";
import { HistoryStore, MarketStore } from "../../stores";
import NotificationPopup from './notificationPopup';

const NotificationButton = observer(() => {
  const [isShow, setIsShow] = useState(false);
  const [lastSeenTs, setLastSeenTs] = useState(null);
  const { unseenHistory } = HistoryStore;


  useEffect(() => {
    const latestTs = unseenHistory.reduce((maxTs, item) => Math.max(maxTs, item.ts), 0);

    if (latestTs > lastSeenTs) {
      setIsShow(true);
      setLastSeenTs(latestTs);
    }
  }, [unseenHistory, lastSeenTs]);

  const handleOutsideClick = (e) => {
    if (
      !e.target.closest('.notificationPopup') &&
      !e.target.closest('.sidebarButton notifications')
    ) {
      setIsShow(false);
    }
  };

  const onTickerEvent = (args, kwargs, details) => {
    const { eventType } = details?.decodedTopic;

    if (eventType == 'user_history' || eventType == 'auction_ended') {
      HistoryStore.loadUpdates(5);
    }
  }

  useEffect(() => {
    ApplicationEvents.on("market.notify.received", onTickerEvent);
    return () => ApplicationEvents.off("market.notify.received", onTickerEvent);
  }, []);

  useEffect(() => {
    if (isShow) {
      document.addEventListener('click', handleOutsideClick);
      return () => document.removeEventListener('click', handleOutsideClick);
    }
  }, [isShow]);

  const unseenHistoryLength = HistoryStore.unseenHistory.length;

  return (
    <NotificationContext.Provider value={{ isShow, setIsShow }}>
      <Fragment>
        <NavbarNavIconLink
          onClick={() => setIsShow(true)}
          mixClasses={classMixer("sidebarButton notifications", !!unseenHistoryLength && "counter" ,(MarketStore.page === "notifications") && "active")}
          icon="notifications"
        />

        {isShow && <NotificationPopup />}
      </Fragment>
    </NotificationContext.Provider>
  );
});

export default NotificationButton;