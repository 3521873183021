import axios from 'axios';
import ApplicationEvents from 'components/applicationEvents';
import { LogStream } from 'generics/common';
import { PromiseDelay, randMinMax } from 'generics/utils';

const loggerAPICallError = LogStream("APICALLERROR")
LogStream.enable("APICALLERROR");

const loggerAPICallGeneric = LogStream("APICALLGENERIC")
// LogStream.enable( "APICALLGENERIC" );

const loggerAPICallDebug = LogStream("APICALLDEBUG")
// LogStream.enable( "APICALLDEBUG" );

let rid = 0;

function encodeFromQuery(params) {
  return params.map(({ name, val }) => `${name}=${encodeURIComponent(val)}`).join('&');
}


function encodeRPCQuery(params) {
  const unwrapped = params.map(({ name, val }) => ({ [name]: val }));
  return JSON.stringify(Object.assign({}, ...unwrapped));
}


////////////////////////////////////////////
// class AjaxCall
export class AjaxCall {

  constructor(command) {
    this.__RID = ++rid;
    this.display = "<>";
    this.onFlight = false;
    this.retryPolicy = null;
    this.command = command;
    this.params = [];
    this.headers = {};
  }


  append(name, val) {
    var params = this.params.filter((v) => v.name != name);
    params.push({ name, val });

    if (name == "appid" && val != 1165)
      params = params.filter((v) => v.name != "app_perm");

    this.params = params;
    return this;
  }

  appendHeader(name, val) {
    this.headers[name] = val;
    return this;
  }

  appendTransactId() {
    return this.append(
      "transactid", randMinMax(1000000, 1000000000000000)
    ).append(
      "reqstamp", Date.now()
    );
  }


  __performRequest(type, url, data, headers) {
    const req = new Promise((resolveWith, rejectWith) => {

      axios({
        method: type,
        url, data, headers,
        responseType: 'json'
      })
        .then(response => {
          resolveWith(response.data);
        })
        .catch(error => {
          const { response } = error;
          rejectWith({
            type: "error",
            caption: "HTTP request failed",
            message: `HTTP request failed during command '${this.command}' execution`,
            details: {
              textStatus: error.message,
              errorThrown: error,
              statusCode: response ? response.status : 0,
              command: this.command,
              params: this.params
            }
          });
        });
    }).then(
      (resp) => this.normalizeResponse(resp)
    ).then(
      (resp) => this.checkErrors(resp)
    ).catch(
      (reason) => this.checkRetry(reason, type, url, data, headers)
    );

    return req;
  }


  __proceedWithRequest(type, url, data, headers) {
    this.display = `([${this.__RID}]'${url}':'${data}')`;

    if (this.onFlight)
      throw new Error(`Trying to reexecute AjaxCall to ${this.display}`);

    this.onFlight = true;

    loggerAPICallGeneric.info(`${this.display} going to be called`);

    const pending = this.preRequestHook().then(
      () => this.__performRequest(type, url, data, headers)
    );

    pending.then(
      (resp) => { loggerAPICallGeneric.info(`Request to ${this.display} succeed with response `, resp); },
      (reason) => {
        if (!reason || (reason?.isFatal !== false && reason?.details?.statusCode !== 503))
          loggerAPICallError.error(`Request to ${this.display} failed with reason `, reason);
      }
    );

    return pending;
  }


  GET() {
    const url = this.getInterfaceUrl() + "?" + encodeFromQuery(this.params);
    const postbody = undefined;
    const headers = this.headers;
    return this.__proceedWithRequest("GET", url, postbody, headers);
  }


  POST() {
    const url = this.getInterfaceUrl();
    const postbody = encodeFromQuery(this.params);
    const headers = this.headers;
    return this.__proceedWithRequest("POST", url, postbody, headers);
  }


  RPC() {
    const url = this.getInterfaceUrl();
    const postbody = encodeRPCQuery(this.params);
    const headers = this.headers;
    return this.__proceedWithRequest("POST", url, postbody, headers);
  }


  setCustomRetryPolicy(policy) {
    this.retryPolicy = policy;
  }


  setRetryPolicy(count, min_delay, max_delay) {
    this.retryPolicy = basicRetryPolicy(count, min_delay, max_delay);
  }


  preRequestHook() {
    return Promise.resolve(null); // noop
  }


  normalizeResponse(resp) {
    return resp; //  noop in generic
  }


  checkErrors(resp) {
    let isFatalError = true;
    let errorText = null;

    if (typeof resp == 'object') {
      if ("success" in resp) {
        if (resp.success === true)
          return resp;
        else if (resp.error == "AUTH_RESPONSE_STATUS_IS_LOGINERROR" || resp.error == "TOKEN_EXPIRED") {
          isFatalError = false;
          ApplicationEvents.emit_async("auth.tokenExpired");
        }
      } else if (!resp.error)
        return resp;

      // "success" field == false or "error" field was set
      errorText = (resp.error || "unknown");
    }
    else if (typeof resp == 'string' && resp.startsWith("!ERROR"))
      errorText = resp;
    else
      errorText = "unknown";

    return Promise.reject({
      "type": "error",
      "isFatal": isFatalError,
      "errorText": errorText,
      "caption": "Server returns error",
      "message": `Server returns error '${errorText}' during command '${this.command}' execution`,
      "details": {
        "response": resp,
        "command": this.command,
        "params": this.params
      }
    });
  }


  checkRetry(reason, type, url, data, headers) {
    if (!this.retryPolicy)
      return Promise.reject(reason);

    const delay = this.retryPolicy(reason);
    if (delay == null)
      return Promise.reject(reason);

    if (delay >= 0) {
      return PromiseDelay(delay).then(() => {
        this.onFlight = false;
        loggerAPICallDebug.warning("Performing RETRY for ", this.display);
        return this.__performRequest(type, url, data, headers);
      });
    }

    return Promise.reject(reason);
  }
}


////////////////////////////////////////////
// class LocalFileCall
// let callAppDesc = new LocalFileCall("config/appdesc.json");
class LocalFileCall extends AjaxCall {
  constructor(path) {
    super(path)
    this.path = path;
  }


  normalizeResponse(resp) {
    return resp;
  }


  getInterfaceUrl() {
    return this.path;
  }
};



export function LocalLoadFile(name) {
  let callLocalFile = new LocalFileCall(name);
  callLocalFile.append("vstamp", "@@BUILDSTAMPMARK@@");

  return callLocalFile.GET().then((resp) => {
    loggerAPICallDebug.info(`LocalLoadFile(${name}) - got response:`, resp);
    return resp;
  });
}


export function getHttpDetails(reason) { return ((reason || {}).details || {}); }


export function isHttpRetry(reason) {
  const details = getHttpDetails(reason);
  return (details.statusCode == 503) || (details.errorThrown == "RETRY");
}


function __retryLambda(count, A, B) {
  return (reason) => {
    if (isHttpRetry(reason) && (count > 0)) {
      return A + (B * (--count));
    }
  }
}

export function basicRetryPolicy(count, min_delay, max_delay) {
  count = count || 1;
  min_delay = min_delay || 100;
  max_delay = max_delay || min_delay;
  return __retryLambda(count, min_delay, (max_delay - min_delay) / ((count - 1) || 1))
}

