import { MarketNotifyGameViewItem } from 'api/gameNotifyAPICall';
import { ClassByHashnameResolver } from 'api/tradeAPICall';
import { ViewItemIconic } from 'components/commonAssets';
import { spawnDialogWindow } from 'components/dialogHolder';
import { TrivialDialog } from 'components/trivialDialog';
import { BootButton } from 'generics/buttons';
import { IconFontAwesome } from 'generics/icons';
import React from 'react';

export function hangleIngamePreview(appId, classInfo)  {
  spawnDialogWindow(ViewIngamePreviewDialog,  {
      appId, classInfo,
    });
}


class ViewIngamePreviewDialog extends React.Component  {
  constructor(props)  {
    super(props);

    this.state={
      stage:"ask",
    }
  }


  onBackdropTouch()  {
    const {  stage  } = this.state;
    if ( stage == "pending" )  {
      return;  // must wait till result
    }

    this.props.removeModal();
  }


  sendCommand = ()  =>  {
    const {  stage  } = this.state;
    if ( stage != "ask" )
      return;

    this.setState((prevState, props)  =>  {
      const  {  appId, classInfo  } = props;

      ClassByHashnameResolver.resolve(
          appId, classInfo.market_hash_name

        ).then(
          (assetClass) =>  MarketNotifyGameViewItem( appId, assetClass )

        ).then(
          ()  =>  this.setState({ stage:"done" }),
          ()  =>  this.setState({ stage:"fail" }),

        );

      return { stage:"pending" };
    });
  }


  render()  {
    const {  stage  } = this.state;
    const  {  classInfo,  removeModal  } = this.props;
    switch ( stage )  {
      case "ask"  :
        return  (
          <TrivialDialog
              prefixL10N={`IngamePreview.${stage}`}
              mixClasses={["ingamePreviewDialog"]}
              onCloseButton={removeModal}
              onOkButton={this.sendCommand}
              onCancelButton={removeModal}

              bodyIcon={<ViewItemIconic classInfo={classInfo} />}
              />);

      case "done"  :
        return  (
          <TrivialDialog
              prefixL10N={`IngamePreview.${stage}`}
              mixClasses={["ingamePreviewDialog"]}
              panelType="success"
              onCloseButton={removeModal}
              onOkButton={removeModal}
              />);

      case "fail"  :
        return  (
          <TrivialDialog
              prefixL10N={`IngamePreview.${stage}`}
              mixClasses={["ingamePreviewDialog"]}
              panelType="warning"
              onCloseButton={removeModal}
              onCancelButton={removeModal}
              />);

      case "pending"  :
        return  (
          <TrivialDialog
              prefixL10N={`IngamePreview.${stage}`}
              mixClasses={["ingamePreviewDialog"]}
              panelType="primary"
              bodyIcon={<IconFontAwesome type="cog" size="3x" spin fixedWidth />}
              />);
    }


    return (
        <div className="panel panel-info panel-sm">
          <div className="panel-body">
            <BootButton  variant="danger"  onClick={removeModal}  >
              <IconFontAwesome type="remove" />&nbsp;Broken dialog
            </BootButton>
          </div>
        </div>
      )
  }
};
