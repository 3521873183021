import { MarketGlobalInfo } from 'api/ordersAPICall';
import { LogStream } from 'generics/common';
import { SettingsInjections } from 'generics/settingsInjections';
import { objectMap } from 'generics/utils';
import moment from 'moment';

LogStream.enable("GLOBALMARKETCONFIGSTORAGE");

class GlobalMarketConfigStorage {
  constructor() {
    this.marketFee = 0;
    this.games = {};
    this.ready = false;
    this.priceMin = 0;
    this.priceMax = 0;
    this.marketUserToken = null;
    this.auction = {};
  }

  load() {
    return MarketGlobalInfo().then((resp) => {
      this.marketFee = resp.marketFee || 0;
      this.priceMin = resp.minPrice || 1000;
      this.priceMax = resp.maxPrice || 2000000000;
      this.marketUserToken = resp.marketUserToken || null;
      this.auction = resp.auction || {};

      this.games = {};

      const marketMode = SettingsInjections.getMode();
      const allowedGames = (marketMode == 'pixstorm') ? [ '1067' ] : null;

      objectMap(resp.games, (game) => {
        if (game.appid < 1)
          return;

        if (!game.briefName || !game.name || !game.icon || !game.desc)
          return;

        if (allowedGames && !allowedGames.includes(game.appid.toString()))
          return;

        this.games[game.appid] = game;
      });

      this.ready = true;
      LogStream("GLOBALMARKETCONFIGSTORAGE").info("load (", this, ")");
      return resp;
    });
  }


  check() {
    if (!this.ready)
      throw new Error("GlobalMarketConfigStorage was not loaded properly ");
  }


  unresolvedApp(appid) {
    return {
      "appid": appid,
      "briefName": "N/A",
      "name": "N/A",
      "desc": ["N/A"],
      "icon": "images/loader.gif",
      "unresolved": true
    };
  }

  resolveAppId(appid) {
    this.check();
    return this.games[appid] || this.unresolvedApp(appid);
  }


  getAppIdList() {
    this.check();
    return Object.keys(this.games);
  }


  getMarketFee() {
    this.check();
    return this.marketFee || 0;
  }

  getPriceMin() {
    this.check();
    return this.priceMin;
  }

  getPriceMax() {
    this.check();
    return this.priceMax;
  }

  getUserToken() {
    this.check();
    return this.marketUserToken;
  }

  getAuctionParams() {
    this.check();

    const { minDurationHours, maxDurationHours } = this.auction;

    const minEndDate = moment()
      .add(minDurationHours || 24, 'hours')
      .add(1, 'days')
      .format('YYYY-MM-DD')
      .toString();

    const maxEndDate = maxDurationHours ? moment()
      .add(maxDurationHours, 'hours')
      .subtract(1, 'days')
      .format('YYYY-MM-DD')
      .toString() : null;

    return {
      minEndDate, maxEndDate
    };
  }
};


let G_marketConfigStorage = new GlobalMarketConfigStorage(); // FIXME, should be separate component
export default G_marketConfigStorage;
