export const plural = (number, words) => {
  return Math.abs(parseInt(number, 10)) === 1 ? words[0] : words[1];
};

export const locale = {
  "ViewItemPreviewSidePane.quantity.inventory": " ",
  "ViewItemPreviewSidePane.quantity.ordersInventory": " položky v inventáři",
  "ViewItemPreviewSidePane.quantity.count": " v akci",
  "ViewItemPreviewSidePane.quantity.countBuy": " nákupní příkaz",
  "ViewItemPreviewSidePane.quantity.items": " položek",
  "ViewMarketedItem.SoldOutText": "vyprodáno",
  "ViewMarketedItem.SoldOutTip": "Žádný prodejní příkaz",
  "ViewMarketedItem.NoBuyText": "žádný nákupní příkaz",
  "ViewMarketedItem.SellingFrom": "V prodeji od...",
  "ViewMarketedItem.SellingMore": " nebo více",
  "ViewMarketedItem.BuyingMore": " nebo méně",
  "ViewMarketedItem.tradableAfter": "půjde koupit v",
  "ViewItemSearch.heading.showAll": "Zobrazení všech nabídek",
  "ViewItemSearch.heading.orders": "Moje příkazy",
  "ViewItemSearch.heading.marketSell": "Prodejní příkazy",
  "ViewItemSearch.heading.marketBuy": "Nákupní příkazy",
  "ViewItemSearch.heading.marketAuctions": "Aukce",
  "ViewItemSearch.title.marketAuction": "Aukce",
  "ViewItemSearch.title.ending": "Do konce",
  "ViewItemSearch.title.finished": "Aukce skončila",
  "ViewItemSearch.heading.settings": "Nastavení",
  "ViewMarketedItem.button.more": "Více detailů",
  "ViewItemSearch.heading.resultsFor": "Zobrazení výsledků pro:",
  "ViewItemSearch.stub.empty": "Žádné výsledky hledání",
  "ViewItemSearch.heading.tooltip": "Vaše nabídka byla přehozena",
  "ViewItemSearch.heading.tooltip.win": "Vaše nabídka vyhrála",
  "ViewItemPopup.committed.SELL": "Prodej dokončen",
  "ViewItemPopup.committed.BUY": "Nákup dokončen",
  "ViewItemPopup.committed.BID": "Nabídka podána",
  "ViewItemPopup.application.BUY": "Vytvořen nákupní příkaz",
  "ViewItemPopup.application.SELL": "Vytvořena žádost o prodej",
  "ViewItemPopup.application.BID": "Nabídka učiněna",
  "ViewItemPopup.canceled.BUY": "Nákupní příkaz zrušen",
  "ViewItemPopup.canceled.SELL": "Zrušena žádost o prodej",
  "ViewItemPopup.canceled.BID": "Nabídka zrušena",
  "ViewItemPopup.showAll.button": "Zobrazit vše",
  "ViewItemAssets.readAll.button": "Označit vše jako zobrazené",
  "ViewItemPopup.heading.noHistory": "Zatím žádná oznámení",
  "ViewItemPopup.heading.unseenHistory": "Žádná nová oznámení",
  "ViewItemSearch.termsPlaceholder": "Zde hledejte podmínky",
  "ViewItemSearch.label.search": "Hledat položky",
  "ViewMarketedItem.lovestPrice": "od ",
  "ViewMarketedItem.beforePrice": "před ",
  "ViewMarketedItem.yourBid": "vaše nabídka ",
  "ViewMarketedItem.bid": "nabídka ",
  "ViewItemSearch.heading.exchange": "Home",
  "ViewItemSearch.heading.light": "Světlý",
  "ViewItemSearch.heading.dark": "Tmavý",
  "ViewItemSearch.filteringAnnounce": "Pro filtrování příkazů musíte zvolit hru",
  "ViewItemSearch.ordersBuy": "KOUPIT",
  "ViewItemSearch.ordersSell": "PRODAT",
  "ViewItemSearch.ordersOpen": "Zobrazit celou historii",
  "MarketApp.Navbar.inventoryLink": "Inventář",
  "MarketApp.Navbar.ordersLink": "Moje objednávky",
  "MarketApp.Navbar.marketLink": "Trh",
  "MarketApp.Navbar.settingsLink": "Nastavení",
  "MarketApp.Navbar.replenishLink": "Doplnit zůstatek",
  "MarketApp.Navbar.balanceHistoryLink": "Historie zůstatku",
  "MarketApp.Navbar.buyCoinsLink": "Koupit GaijinCoins",
  "MarketApp.Navbar.profileLink": "Profil",
  "MarketApp.Navbar.reloginLink": "Odhlásit nebo změnit účet",
  "MarketApp.Navbar.toOldVersion": "Přejít na starší verzi",
  "MarketApp.Navbar.linkFeedback": "Zanechat názor",
  "ViewTradeDialog.caption": "Obchodovat",
  "ViewTradeDialog.button.back": "Zpět",
  "NotAllowedMessage.caption": "Není povoleno",
  "NotAllowedMessage.body.h1": "Přístup na Trh",
  "NotAllowedMessage.body.h3": "momentálně není vašemu účtu povoleno",
  "NotAllowedMessage.relogin": "Změnit účet",
  "CountryDeniedMessage.caption": "Není povoleno",
  "CountryDeniedMessage.body.notAllowed": "Přístup na Trh není povolen",
  "CountryDeniedMessage.body.unclearStatus": "Kvůli nejasné legislativě transakcí ve vaší zemi je přístup na Trh zamítnut",
  "LandingMessage.caption": "Ověření",
  "LandingMessage.body": "Dokončuji...",
  "TokenExpiredMessage.caption": "Ověření vypršelo",
  "TokenExpiredMessage.body.h1": "Ověření vypršelo",
  "TokenExpiredMessage.body.h3": "Moc s tím nenaděláme, ale stále můžete",
  "TokenExpiredMessage.relogin": "Znovu přihlásit...",
  "AuthErrorMessage.caption": "Ověření selhalo",
  "AuthErrorMessage.body.h1": "Nepodařilo se provést ověření",
  "AuthErrorMessage.body.code": "Kód:",
  "AuthErrorMessage.body.desc": "Moc s tím nenaděláme, ale stále můžete",
  "AuthErrorMessage.relogin": "Znovu přihlásit...",
  "AuthorizationRequiredMessage.caption": "Vyžadováno ověření",
  "AuthorizationRequiredMessage.body.h3.1": "Chystáte se vstoupit",
  "AuthorizationRequiredMessage.body.h3.2": "Gaijin Trh",
  "AuthorizationRequiredMessage.body.h3.3": "Pro další postup musíte být ověřeni",
  "AuthorizationRequiredMessage.login": "Příhlásit se s Gaijin.Net",
  "CheckingAuthMessage.caption": "Ověření",
  "CheckingAuthMessage.body": "Kontroluji ověření, chvilku strpení",
  "CancelOrdersPanel.cancelError": "\nPři rušení příkazu došlo k chybě ",
  "CancelOrdersPanel.loading": "Kontroluji aktivní příkazy",
  "CancelOrdersPanel.done": "Operace dokončena.",
  "CancelOrdersPanel.errors": "Došlo k chybám: \n",
  "CancelOrdersPanel.loaded.youHave": "Momentálně máte ",
  "CancelOrdersPanel.loaded.sellOrders": " prodejní příkaz(y) dohromady za ",
  "CancelOrdersPanel.loaded.buyOrders": " nákupní příkaz(y) dohromady za ",
  "CancelOrdersPanel.loaded.and": " a ",
  "CancelOrdersPanel.loaded.shouldCancel": " a musíte je všechny zrušit. ",
  "CancelOrdersPanel.loaded.doCancel": "Zrušit příkazy",
  "CancelOrdersPanel.cancelling.processing": "Provádím",
  "CancelOrdersPanel.cancelling.outOf": " z celkem ",
  "CancelOrdersPanel.cancelling.sellOrders": " prodejní příkazy",
  "CancelOrdersPanel.cancelling.buyOrders": " nákupní příkazy",
  "ClassInfoDescriptions.dropped_item.block_label": "Obsahuje jedno z tohoto:",
  "ClassInfoDescriptions.dropped_item.stage-unknown": "Neznámý",
  "ClassInfoDescriptions.dropped_item.stage-pending": "...nahrávám",
  "ClassInfoDescriptions.text.block_label": "",
  "ClassInfoDescriptions.dropped_bundle.block_label": "Obsahuje jeden z těchto předmětů:",
  "ClassInfoDescriptions.buttonLabel.top": "Zobrazit",
  "ClassInfoDescriptions.buttonLabel.hide": "Skrýt",
  "ClassInfoDescriptions.buttonLabel.bottom": "vše",
  "ClassInfoDescriptions.dropped_text.description": "Description",
  "ClassInfoUtil.fake_name.pending": "Čekání",
  "ClassInfoUtil.fake_name.broken": "Smazaná položka",
  "ClassInfoUtil.SynthTag.marketable1": "Komerční",
  "ClassInfoUtil.SynthTag.marketable0": "Nekomerční",
  "ClassInfoUtil.SynthTag.tradable1": "Obchodovatelné",
  "ClassInfoUtil.SynthTag.tradable0": "Neobchodovatelné",
  "ClassInfoUtil.SynthTag.commodity1": "Komodita",
  "ClassInfoUtil.SynthTag.commodity0": "Není komodita",
  "ConfirmBuyDialog.title": "Zadání příkazu ke koupi",
  "BuyProcessingDialog.title": "Provádím...",
  "BuyProcessingDialog.pleaseWait": "Provádím, čekejte prosím",
  "ProcessingDialog.notItem": "Požadovaná položka nebyla na trhu nalezena nebo není dostupná pro obchodování",
  "SellDialog.title": "Dát položku k prodeji",
  "SellProcessingDialog.title": "Provádím...",
  "SellProcessingDialog.pleaseWait": "Provádím, čekejte prosím",
  "LimitedAccountPanel.2step.reason": "Musíte nakonfigurovat dvoufázové ověření",
  "LimitedAccountPanel.2step.link": "Další informace o dvoufázovém ověření",
  "LimitedAccountPanel.2step.href": "https://support.gaijin.net/hc/en-us/articles/203623622-How-to-set-up-the-Two-Step-Authorization-Google-Authenticator-",
  "LimitedAccountPanel.email.reason": "Musíte potvrdit e-mailovou adresu",
  "LimitedAccountPanel.email.link": "Přejít k profilu",
  "LimitedAccountPanel.2step.toPlug": "Zapnout",
  "LimitedAccountPanel.email.href": "https://store.gaijin.net/user.php",
  "LimitedAccountPanel.title": "Váš účet je omezen kvůli:",
  "LimitedAccountPanel.conclusion": "Po změně bezpečnostních nastavení byste se měli odhlásit a přihlásit, aby se změny projevily",
  "LimitedAccountPanel.relogin": "Opětovné přihlášení...",
  "EmailVerificationStateIcon.success": "Potvrzený účet, ověřený e-mail",
  "EmailVerificationStateIcon.warning": "Nepotvrzený účet, neověřený e-mail",
  "EmailVerificationStateIcon.danger": "Nepotvrzený účet, ověřený e-mail VYŽADOVÁN",
  "TwoFactorStateIcon.success": "Účet je zabezpečen dvoufázovým ověřením",
  "TwoFactorStateIcon.warning": "Nezabezpečený účet, dvoufázové ověření vypnuto",
  "TwoFactorStateIcon.danger": "Je VYŽADOVÁNO dvoufázové ověření",
  "ViewInventoryTextFilter.common.label": "Abyste mohli filtrovat inventář, je třeba vybrat hru",
  "ItemBuyEditor.label.wheelAmountInput": "Pro změnu počtu lze použít kolečko myši,\nalt+kolečko = 10x rychlost",
  "ItemBuyEditor.label.wheelInput": "Pro změnu počtu lze použít kolečko myši,\nalt+kolečko = 10x rychlost,\nshift+kolečko=0.1x rychlost",
  "ItemBuyEditor.note.posAmount": "Hodnota množství by měla být plusová",
  "ItemBuyEditor.note.maxAmount": "Hodnota množství překračuje 1000 předmětů",
  "ItemBuyEditor.note.invalidAmount": "Nekorektní množství",
  "ItemBuyEditor.note.minPrice": "Minimální cena je",
  "ItemBuyEditor.note.maxTotal": "Celková hodnota příkazů překračuje",
  "ItemBuyEditor.note.invalidPrice": "Nekorektní cena",
  "ItemBuyEditor.note.mustAgree": "Nutno souhlasit s Podmínkami užití",
  "ItemBuyEditor.note.insufficentFunds0": "Nedostatek prostředků, potřebujete víc",
  "ItemBuyEditor.note.insufficentFunds1": "na tento příkaz",
  "ItemBuyEditor.label.buyCouns": "Koupit",
  "ItemBuyEditor.label.submit": "Provést příkaz",
  "ItemBuyEditor.label.price": "Co chcete zaplatit (za předmět):",
  "ItemBuyEditor.label.amount": "Množství určené ke koupi:",
  "ItemBuyEditor.label.totalPrice": "Celková cena (vč. poplatků):",
  "ItemBuyEditor.label.agree": "Souhlasím s Gaijin Store",
  "ItemBuyEditor.label.ToU": "Podmínky užití",
  "MarketDepthTotals.noOrders.sell": "Žádné příkazy k prodeji",
  "MarketDepthTotals.noOrders.buy": "Žádné příkazy k nákupu",
  "MarketDepthTotals.starting.sell": " pro ",
  "MarketDepthTotals.starting.buy": " od ",
  "MarketDepthTotals.andMore.sell": " a níže",
  "MarketDepthTotals.andMore.buy": " a výše",
  "MarketDepthTotals.prevew.sell": "Kupuji: ",
  "MarketDepthTotals.prevew.buy": "V akci: ",
  "MarketDepthOwnWarning.sell": "Čekají na vás prodejní příkazy",
  "MarketDepthOwnWarning.buy": "Čekají na vás nákupnípříkazy",
  "MarketDepthTable.caption.price": "Cena",
  "MarketDepthTable.caption.quantity": "Množství",
  "MarketDepthTable.ellipsis.sell": "a více",
  "MarketDepthTable.ellipsis.buy": "a méně",
  "ItemMarketDetails.buyButton": "KOUPIT",
  "ItemMarketDetails.sellButton": "PRODAT",
  "ItemMarketDetails.nothingToSellButton": "Nic k prodeji",
  "ItemMarketDetails.makeAuction": "Vytvořit aukci",
  "ItemMarketDetails.makeBid": "Přihazovat",
  "ItemMarketDetails.bid": "Nabídka",
  "ItemMarketDetails.yourBid": "Vaše nabídka:",
  "ItemMarketDetails.notifications.yourBid": "Vaše nabídka",
  "ItemMarketDetails.yourBid.short": "vaše",
  "ItemMarketDetails.yourBid.win": "vyhrává",
  "ItemMarketDetails.yourBid.loose": "prohrává",
  "ItemSellEditor.label.priceStep": "Krok ceny",
  "ItemSellEditor.label.endDate": "Datum ukončení",
  "limitedAccountMessage.requires": "Vyžaduje:",
  "limitedAccountMessage.2step": "dvoufázové ověření",
  "limitedAccountMessage.verifiedEmail": "potvrzený e-mail",
  "limitedAccountMessage.and": " a ",
  "ItemSellEditor.label.wheelInput": "Pro změnu počtu lze použít kolečko myši,\nalt+kolečko = 10x rychlost,\nshift+kolečko=0.1x rychlost",
  "ItemSellEditor.note.minPrice": "Minimální cena je",
  "ItemSellEditor.note.maxPrice": "Maximální povolená prodejní cena",
  "ItemSellEditor.note.maxTotals": "Celková částka příkazů přesahuje maximálních",
  "ItemSellEditor.note.maxBalance": "Celková částka by neměla přesáhnout",
  "ItemSellEditor.note.mustAgree": "Je nutné souhlasit s Podmínkami užití",
  "ItemSellEditor.label.price": "Kupující zaplatí (vč. poplatků):",
  "ItemSellEditor.label.wished": "Po očištění od poplatků dostanete:",
  "ItemSellEditor.label.agree": "Souhlasím s podmínkami Gaijin Store",
  "ItemSellEditor.label.ToU": "Podmínky užití",
  "ItemSellEditor.label.submit": "OK, dát k prodeji",
  "ItemSellEditor.currentBalance.before": "Momentálně máte",
  "ItemSellEditor.currentBalance.after": "ve své bilanci",
  "ItemSellEditor.sellOrdersTotal.before": "plus",
  "ItemSellEditor.sellOrdersTotal.after": "nashromážděno v prodejních příkazech",
  "ItemSellEditor.buyOrdersTotal.before": "a",
  "ItemSellEditor.buyOrdersTotal.after": "nashromážděno v nákupních příkazech",
  "ItemSellEditor.label.amount": "Množství určené k prodeji:",
  "AppFilterEditor.label.byTags": "Filter",
  "AppFilterEditor.label.clearFilter": "Vymazat filtr",
  "AppFilterEditor.label.byTitles": "Filtrovat pomocí názvů",
  "AppFilterEditor.label.allFilter": "Všechny hry",
  "AppFilterEditor.label.authenticity": "Autenticita",
  "AppFilterEditor.message.filter": "Filtry jsou zvlášť pro každou hru.",
  "AppFilterEditor.message.game": "Vybrat hru:",
  "ViewItemDetailed.restriction.amount.items": " položky",
  "ViewItemDetailed.restriction.nonMarketable": "Nelze prodat",
  "ViewItemDetailed.restriction.blockedAfter": "Uzamkne se v",
  "ViewItemDetailed.restriction.blockedPair": "Obchod uzamčen",
  "ViewItemDetailed.restriction.expiresAt1": "Zmizí po",
  "ViewItemDetailed.restriction.expiresAt2": "",
  "ViewItemDetailed.restriction.endedAt": "End of auction",
  "ViewItemDetailed.restriction.amount": "Lot contains",
  "ViewItemDetailed.restriction.pieces": "pcs.",
  "ViewItemDetailed.restriction.startPrice": "Starting price",
  "ViewItemDetailed.restriction.days": "d",
  "ViewItemDetailed.restriction.hours": "hrs",
  "ViewItemDetailed.restriction.minutes": "min",
  "ViewItemDetailed.restriction.leadingBids": "leading bids",
  "ViewItemDetailed.restriction.noBids": "There are no bids yet. Place your first bid in this auction!",
  "ViewItemDetailed.restriction.delayTrade": "Once purchased, the item will be available for sale through",
  "ViewItemDetailed.restriction.unclearStatus": "Vzhledem k nejasnému právnímu statusu beden s trofejemi ve vaší zemi jsou všechny transakce s trofejemi a klíči pro váš účet zablokovány.",
  "MarketSortOrder.label.name": "Název",
  "MarketSortOrder.hint.newDesc": "Nejdříve nejnovější",
  "MarketSortOrder.hint.newAsc": "Nejdříve nejstarší",
  "MarketSortOrder.label.price": "Cena",
  "MarketSortOrder.hint.priceAsc": "Cena od nejnižší",
  "MarketSortOrder.hint.priceDesc": "Cena od nejvyšší",
  "MarketSortOrder.label.amount": "Počet",
  "MarketSortOrder.hint.amountAsc": "Cena od nejnižší",
  "MarketSortOrder.hint.amountDesc": "Cena od nejvyšší",
  "MarketSortOrder.label.popular": "Oblíbené",
  "MarketSortOrder.label.popularItems": "Oblíbené položky",
  "DoubleSellErrorModal.heading": "Chyba",
  "DoubleSellErrorModal.prompt": "Při prodeji položky došlo k chybě",
  "DoubleSellErrorModal.explanation": "Nepodařilo se zadat prodejní příkaz, jedna z možných příčin - položka byla prodána nebo spotřebována ve hře\nZkuste obnovit stránku",
  "DoubleSellErrorModal.okButton": "OK",
  "OnBuyReserveFailModal.heading": "Chyba",
  "OnBuyReserveFailModal.prompt": "Nepodařilo se zadat nákupní příkaz",
  "OnBuyReserveFailModal.explanation": "Nepodařilo se vydat nákupní příkaz, nebylo možné rezervovat peníze z peněženky.",
  "OnBuyReserveFailModal.youNeed1": "Potřebujete alespoň",
  "OnBuyReserveFailModal.youNeed2": "pro tuto operaci",
  "OnBuyReserveFailModal.tryRefresh": "Zkuste obnovit stránku a ujistit se, že máte dostatek prostředků",
  "OnBuyReserveFailModal.okButton": "OK",
  "DoubleRateErrorModal.heading": "Error",
  "DoubleRateErrorModal.prompt": "An error occurred while placing a bid",
  "DoubleRateErrorModal.explanation": "Please try to bid later",
  "DoubleRateErrorModal.okButton": "OK",
  "AuctionFinishedErrorModal.heading": "Error",
  "AuctionFinishedErrorModal.prompt": "An error occurred while placing a bid",
  "AuctionFinishedErrorModal.explanation": "The auction was completed",
  "AuctionFinishedErrorModal.okButton": "OK",
  "CancelOrderErrorModal.heading": "Chyba",
  "CancelOrderErrorModal.prompt": "Chyba při rušení příkazu",
  "CancelOrderErrorModal.explanation": "Možnými příčinami jsou problémy se sítí v době aktualizace objednávky.\nZkuste to znovu o něco později.",
  "CancelOrderErrorModal.cancelButton": "Rozumím",
  "OrdersListContainer.Tab.sellOrders": "Prodejní přikazy",
  "OrdersListContainer.Tab.buyOrders": "Nákupní příkazy",
  "OrdersListContainer.Tab.historyList": "Historie příkazů",
  "ViewOrdersEntry.amounts.remain": "Zbývá",
  "ViewOrdersEntry.amounts.done": "Hotovo",
  "ViewOrdersEntry.order.order": "Příkaz",
  "ViewOrdersEntry.order.issued": "Vydáno",
  "ViewOrdersEntry.order.executed": "Provedeno",
  "ViewOrdersEntry.order.pairIsBlocked": "Obchod uzamčen",
  "ViewOrdersEntry.order.pairWillBlockAfter": "Bude uzamčen v",
  "ViewOrdersEntry.order.BUY": "KOUPIT",
  "ViewOrdersEntry.order.SELL": "PRODAT",
  "ViewOrdersEntry.order.view": "Zobrazit",
  "ViewOrdersEntry.order.cancel": "Zrušit",
  "ViewOrdersList.empty.label": "Zde nic není",
  "CancelOrderDialog.heading.youSure": "Jste si jisti?",
  "CancelOrderDialog.body.youSure": "Opravdu chcete zrušit příkaz?",
  "CancelOrderDialog.button.cancel": "Ano, chci zrušit příkaz",
  "CancelAllOrderDialog.button.cancel": "Ano pro všechno",
  "CancelOrderDialog.button.noCancel": "Ne",
  "CancelOrderDialog.heading.processing": "Provádím...",
  "CancelOrderDialog.body.processing": "Provádím, čekejte prosím",
  "ProfileMenu.MenuItem.Settings": "Nastavení",
  "ProfileMenu.MenuItem.GaijinAccount": "Profil Gaijin.net",
  "ProfileMenu.MenuItem.AccountSwitch": "Změnit účet",
  "ProfileMenu.MenuItem.Signout": "Ukončit",
  "ProfileMenu.MenuItem.nick": "Vaše přezdívka",
  "WalletBlock.balance.text": "Zůstatek:",
  "WalletBlock.balance.buyCurrency": "Koupit GJN",
  "SecurityBlock.message.title": "Dvoufázové ověření",
  "SecurityBlock.emailVerified.title": "E-mail",
  "OrdersHistory.title.buy": "Nákupní příkazyBuy orders",
  "OrdersHistory.title.sell": "Prodejní příkazy",
  "NotificationsAssets.title": "Upozornění",
  "NotificationsAssets.title.today": "Dnes",
  "NotificationsAssets.title.historyFromLastWeek": "Za posledních sedm dní",
  "NotificationsAssets.title.historyOlder": "Dříve",
  "LimitedAccountPanel.2step.pleas": "Chraňte svůj účet pro přístup k obchodování na Trhu.",
  "LimitedAccountPanel.2step.protected": "Váš účet je chráněný, máte přístup k obchodování na burze.",
  "LimitedAccountPanel.email.pleas": "Potvrďte, abyste získali přístup k obchodování na Trhu.",
  "LimitedAccountPanel.email.protected": "Vaše e-mailová adresa je potvrzena, máte přístup k obchodování na Trhu.",
  "OrdersListContainer.Opened.Orders": "Otevřené",
  "OrdersListContainer.Closed.Orders": "Zavřené",
  "ViewOrdersList.empty.labelCollapse": "Sbalit",
  "ViewOrdersList.empty.labelExpand": "Rozbalit",
  "OrdersGraph.plot.title": "Příkazy ke koupi/prodeji",
  "OrdersGraph.fmt.offers.sell": "prodejní příkazy\n<br/>cena",
  "OrdersGraph.fmt.andMore.sell": "nebo nižší",
  "OrdersGraph.fmt.offers.buy": "nákupní příkazy\n<br/>cena",
  "Orders.title.header": "objednávky",
  "OrdersGraph.fmt.andMore.buy": "nebo vyšší",
  "PriceHistoryGraph.plot.title": "Historie ceny:",
  "PriceHistoryGraph.highlighter.format": "Při <strong>%s</strong><br /> ceně <strong>%s</strong><br /> počet <strong>%s</strong><br />",
  "PriceHistoryGraph.plot.shortRange": "Krátkodobý",
  "PriceHistoryGraph.plot.wholeRange": "Za celou dobu",
  "DealEventTickerEntry.purchasedFrom": "tato položka koupena od",
  "DealEventTickerEntry.for": "pro",
  "BookSellTickerEntry.listedItem": "sale request",
  "BookBuyTickerEntry.created": "vytvořeny",
  "BookBuyTickerEntry.for": "purchase order",
  "BookAuctionTickerEntry.created": "made a bet",
  "ViewTradeTickerLog.waiting": "Čeká se na novou aktivitu...",
  "ViewTradeTickerLog.ticker": "Real time trading...",
  "ViewUserInventory.common.emptyInventory": "V tuto chvíli je váš inventář prázdný",
  "ViewItemPreviewSidePane.UnselectButton.label": "Filtry",
  "ViewItemPreviewSidePane.TradeButton.label": "Prodat/Koupit",
  "ViewItemPreviewSidePane.ViewButton.label": "Ukázat",
  "ViewItemPreviewSidePane.QuickSellButton.label": "Rychlý prodej",
  "ViewInventoryTagCloud.tagTitle.itemsOf": "položky",
  "ViewInventoryTagCloud.tagTitle.varietiesMarkedAs": "variace značené jako",
  "ViewItemSearch.heading.inventory": "Moje položky",
  "WalletBlock.circleSmall.settings": "Nastavení",
  "WalletBlock.circleSmall.exit": "Ukončit",
  "UserSettingsDialog.language": "Jazyk",
  "UserSettingsDialog.user_settings": "Uživatelské nastavení",
  "UserSettingsDialog.settings": "Nastavení",
  "UserSettingsDialog.save_settings": "Uložit nastavení",
  "UserSettingsDialog.privacySettings": "Obchodování",
  "UserSettingsDialog.privacySettingsPublic": "Veřejný",
  "UserSettingsDialog.privacySettingsPrivate": "Privátní",
  "UserSettingsDialog.interface": "Interface",
  "UserSettingsDialog.interfaceOld": "Legacy",
  "UserSettingsDialog.interfaceNew": "Modern",
  "WalletLimitsIcon.soft_limit": "Limit v GJN coins byl překonán.\nFunkce doplnění zůstatku jsou omezeny.",
  "WalletLimitsIcon.hard_limit": "Limit v GJN coins byl překonán.\nFunkce prodeje a doplnění zůstatku jsou omezeny.",
  "ActionBar.action.ingamePreview": "Zobrazit ve hře",
  "ActionBar.action.previewCopied": "Link zkopírován!",
  "ActionBar.action.previewShare": "Sdílet",
  "IngamePreview.ask.heading": "Zobrazit toto ve hře?",
  "IngamePreview.ask.prompt": "Chystáte se zobrazit tuto položku ve hře",
  "IngamePreview.ask.explanation": "Ujistěte se, že jste ve hře přihlášeni pod svým účtem",
  "IngamePreview.ask.cancelButton": "Ne",
  "IngamePreview.done.heading": "Hotovo",
  "IngamePreview.done.prompt": "",
  "IngamePreview.done.explanation": "Úspěšně posláno",
  "IngamePreview.done.okButton": "Ok",
  "IngamePreview.fail.heading": "Selhání",
  "IngamePreview.fail.prompt": "",
  "IngamePreview.fail.explanation": "Nepovedl se kontaktovat herní klient",
  "IngamePreview.fail.cancelButton": "Ok",
  "IngamePreview.pending.heading": "Chvilku strpení...",
  "IngamePreview.pending.prompt": "Komunikace se hrou",
  "IngamePreview.pending.explanation": "",
  "IngamePreview.ask.okButton": "Jistě, ukázat",
  "StaticConfigLoader.caption.error": "Chyba inicializace",
  "StaticConfigLoader.caption.init": "Inicializace",
  "StaticConfigLoader.text.init": "Inicializace aplikace...",
  "StaticConfigLoader.error.settings": "Nelze načíst nastavení stránky",
  "StaticConfigLoader.error.circuits": "Nelze načíst konfiguraci služeb",
  "ControlButton.close": "Zavřít",
  "Exit": " ",
  "Legal.TermsConditions": "Smluvní podmínky",
  "Legal.TermsService": "Podmínky používání služby",
  "Legal.PrivacyPolicy": "Zásady ochrany osobních údajů",
  "Legal.CookiesPolicy": "Zásady Používání Souborů Cookie",
  "Legal.TradePolicy": "Obchodní zásady",
  "Legal.TermsConditions.linck": "https://legal.gaijin.net/cs/",
  "Legal.TermsService.linck": "https://legal.gaijin.net/cs/termsofservice/",
  "Legal.PrivacyPolicy.linck": "https://legal.gaijin.net/cs/privacypolicy/",
  "Legal.CookiesPolicy.linck": "https://legal.gaijin.net/cs/cookie/",
  "Legal.TradePolicy.linck": "https://legal.gaijin.net/cs/tradepolicy/",
  "Legal.Copyright": "Gaijin Network Ltd. Všechny ochranné známky, loga a názvy značek jsou majetkem příslušných vlastníků.",
  "MarketApp.Navbar.HeadTitle": "Gaijin.Net",
  "MarketApp.Navbar.HeadSubTitle": "Market",
};