import { DatCache } from 'api/datCache';
import { GenericAPICall } from 'api/genericAPICall';
import { LogStream } from 'generics/common';
import { SettingsInjections } from 'generics/settingsInjections';

////////////////////////////////////////////
// class AssetAPICall
class AssetAPICall  extends GenericAPICall {
  normalizeResponse( resp )  {
    return resp.result || resp.response;
  }


  getInterfaceUrl()  {
    return SettingsInjections.getUsedCircuit().assetapi_server;
  }
};


////////////////////////////////////////////
//  AssetAPI request  action=GetContexts&token=xsaffasfa&appid=666
//  per-user*per-app list of available contexts
//    each context is a separate inventory container -
//    4ex: character backpack, his horse bags and a box with loot under bed
//    or if game supports multi-character accounts - it can be personal inventory per character
//    games use it for gameplay features
//
export function MarketGetContexts( appid )  {
  let callGetContexts = new AssetAPICall("GetContexts");
  callGetContexts.setRetryPolicy( 3, 500, 1500 );
  callGetContexts.append("appid", appid);

  return callGetContexts.POST().then( (resp, reject) =>  {
      LogStream( "APICALLDEBUG" ).info(`MarketGetContexts(${appid}) - got response:`, resp);
      return resp.contexts;
    }).catch( (reject) => {
      return false;
  });
}


//  AssetAPI request  action=GetContextContents&token=xsaffasfa&appid=666&contextid=34
//  return list of items in this particular contextid - i.e "what player have in a box with loot under bed"
export function MarketGetContextContents( appid, ctxid )  {
  let callGetContents = new AssetAPICall("GetContextContents");
  callGetContents.setRetryPolicy( 3, 500, 1500 );
  callGetContents.append("appid", appid);
  callGetContents.append("contextid", ctxid);

  return callGetContents.POST().then( (resp) =>  {
      LogStream( "APICALLDEBUG" ).info(`MarketGetContextContents(${appid}, ${ctxid}) - got response:`, resp);
      return resp.assets;
    });
}


//  AssetAPI request  action=GetAssetClassInfo&token=xsaffasfa&appid=666&language=en_US
// GetAssetClassInfo
// language="en_US"
// class_count=1
// class_name0="__itemdefid"
// class_value0=10000
//  {
//     success:true,
//     result:{
//       asset:{
//       }
//     }
//  }
function MarketGetAssetClassInfo( language, appid, itemClass )  {
  let callGetClassInfo = new AssetAPICall("GetAssetClassInfo");
  callGetClassInfo.setRetryPolicy( 10, 100, 2000 );
  callGetClassInfo.append("appid", appid);
  callGetClassInfo.append("language", language );

  let class_count = 0;
  itemClass.forEach(({name, value}) =>  {
    callGetClassInfo.append(`class_name${class_count}`,name);
    callGetClassInfo.append(`class_value${class_count}`,value);
    class_count++;
  });
  callGetClassInfo.append("class_count",class_count);

  return callGetClassInfo.POST().then( (resp) =>  {
      LogStream( "APICALLDEBUG" ).info('MarketGetAssetClassInfo(', appid,', ', itemClass, ') - got response:', resp);
      return resp.asset;
    });
}


////////////////////////////////////////////
class ItemClassInfoCache extends DatCache  {
  constructor()  {
    super({name:"classinfo", timeoutMin:10});
  }


  hash(req)  {
    return this.getClassSid( ...req );
  }


  getClassSid( appid, itemClass, hash_name )  {
    const language = SettingsInjections.getUsedLocale().code;
    const cid =  itemClass.map(  ({name, value}) => `${name}|${value}`  )
    return ([  `${language}`,  `${appid}`, hash_name || "", ...cid  ]).join("\0");
  }


  fetch( classid, [ appid, itemClass, hash_name ] )  {
    const language = SettingsInjections.getUsedLocale().code;
    return MarketGetAssetClassInfo(
          language, appid, itemClass
      ).then( asset => {

        if (hash_name != undefined)
          asset.market_hash_name = hash_name

        return { ...asset, classid };
      });
  }
}


export const ItemClassInfoResolver = new ItemClassInfoCache();


