import autobahn from 'autobahn';
import ApplicationEvents from 'components/applicationEvents';
import { LogStream } from 'generics/common';
import { decodeUtf8Hex, encodeUtf8Hex } from 'generics/utils';
import { MarketStore } from 'stores';

// LogStream.enable( "REALTIMETRANSPORT" );
const loggerRTT = LogStream("REALTIMETRANSPORT");


export class RealtimeTransport {
  constructor(url, realm) {
    this.url = url;
    this.realm = realm;
    this.connection = new autobahn.Connection({ url, realm });
    this.connection.onopen = this.onopen;
    this.connection.onclose = this.onclose;

    this.TAG = Symbol("RealtimeTransport relative");

    this.topics = {};
    this.seenIds = [];

    loggerRTT.info(`RealtimeTransport::constructor(${url}, ${realm})`);

    this.connection.open();
  }


  onopen = (session, details) => {
    loggerRTT.info("onopen : Connected");
    ApplicationEvents.emit_async("market.notify.connected");

    const ownerUserId = MarketStore.userId;
    if (ownerUserId > 0) {
      // "com.deal.log.app_%lld.market_%s.b%llu.s%llu";
      const buyerDealTopic = `com.deal.log...b${ownerUserId}.`
      const sellerDealTopic = `com.deal.log....s${ownerUserId}`
      const historyTopic = `com.user_history.${ownerUserId}`

      this.changeTopicSub("buyerDealTopic", buyerDealTopic);
      this.changeTopicSub("sellerDealTopic", sellerDealTopic);
      this.changeTopicSub("historyTopic", historyTopic);
    }

  }


  onclose = (reason, details) => {
    loggerRTT.info(`onclose : Disconnected (${reason})`);
    ApplicationEvents.emit_async("market.notify.disconnected", reason);
  }


  attach() {
    ApplicationEvents.on("market.setCurrentAssetByHash", this.setCurrentAsset);
    ApplicationEvents.on("market.setCurrentAuctionByHash", this.setCurrentAuction);
  }


  detach() {
    ApplicationEvents.off("market.setCurrentAssetByHash", this.setCurrentAsset);
    ApplicationEvents.off("market.setCurrentAuctionByHash", this.setCurrentAuction);

    Object.keys(this.topics).forEach((key) => {
      this.changeTopicSub(key, null);
    });
  }


  changeTopicSub(topicKey, newTopic) {
    const prevTopic = this.topics[topicKey];
    if (prevTopic == newTopic)
      return;

    this.topics[topicKey] = newTopic;

    if (prevTopic)
      this.unsubscribe(prevTopic, this.onReceived);

    if (newTopic)
      this.subscribe(newTopic, this.onReceived);
  }


  wasSeen(pubid) {
    if (this.seenIds.includes(pubid))
      return true;

    const BACKLOG = 128;
    this.seenIds = ([pubid, ...this.seenIds]).slice(0, BACKLOG);
    return false;
  }


  parseTopic(topic) {
    const topicRe = /^com\.(deal|book|user_history|auction_ended)(?:\.log\.app_([0-9]+)\.market_([0-9a-f]+)(?:\.b([0-9]+)\.s([0-9]+))?)?/ig;
    const decodable = topicRe.exec(topic);

    if (!decodable)
      return;

    return {
      eventType: decodable[1],
      appId: decodable[2] || null,
      hashname: decodeUtf8Hex(decodable[3] || null),
      buyerUserId: decodable[4] || null,
      sellerUserId: decodable[5] || null
    };
  }


  onReceived = (args, kwargs, details) => {
    if (this.wasSeen(details.publication)) {
      loggerRTT.info(" onReceived: dedupe cancel args ", args,
        " kwargs ", kwargs, " details ", details);

      return;
    }

    details.decodedTopic = this.parseTopic(details.topic);

    loggerRTT.info(" onReceived: emitting event args ", args,
      " kwargs ", kwargs, " details ", details);
    ApplicationEvents.emit_async("market.notify.received", args, kwargs, details);
  }


  setCurrentAsset = (appid, hash_name) => {
    let bookTopic = null;
    let dealTopic = null;

    if (appid && hash_name) {
      // "com.book.log.app_%lld.market_%s",
      // "com.deal.log.app_%lld.market_%s.b%llu.s%llu";

      const market_hash_name = encodeUtf8Hex(hash_name);
      bookTopic = `com.book.log.app_${appid}.market_${market_hash_name}`;
      dealTopic = `com.deal.log.app_${appid}.market_${market_hash_name}..`;
    }

    this.changeTopicSub("bookTopic", bookTopic);
    this.changeTopicSub("dealTopic", dealTopic);
  }

  // "com.auction_ended.log.app_%d.market_%s",
  setCurrentAuction = (appid, hash_name) => {
    let auctionEndedTopic = null;

    if (appid && hash_name) {
      const market_hash_name = encodeUtf8Hex(hash_name);
      auctionEndedTopic = `com.auction_ended.log.app_${appid}.market_${market_hash_name}`;
    }

    this.changeTopicSub(`auctionEndedTopic_${appid}_${hash_name}`, auctionEndedTopic);
  }

  // topic
  // cb = (args, kwargs, details)  => ()
  subscribe(topic, cb) {
    loggerRTT.info(`subscribe(${topic}) : Entering`);
    let session = this.connection.session;

    if (!(this.TAG in cb))
      cb[this.TAG] = {};

    if (topic in cb[this.TAG])
      throw new Error(`Cant't subscribe to '${topic}': Already subscribed`);

    session.subscribe(
      topic, cb, { match: 'wildcard' }
    ).then((sub) => {
      loggerRTT.info(`subscribe(${topic}) : subscribed to topic`);
      cb[this.TAG][topic] = sub;
      // console.error( "session.subscriptions", session.subscriptions );
    }).catch((err) => {
      loggerRTT.error(`subscribe(${topic}) : failed to subscribe to topic :`, err);
    });
  }


  unsubscribe(topic, cb) {
    loggerRTT.info(`unsubscribe(${topic}) : Entering`);
    let session = this.connection.session;

    if (!(this.TAG in cb))
      throw new Error(`Cant't unsubscribe from '${topic}': Not subscribed to anything`);

    if (!(topic in cb[this.TAG]))
      throw new Error(`Cant't unsubscribe from '${topic}': Not subscribed to topic`);

    const sub = cb[this.TAG][topic];
    delete cb[this.TAG][topic];

    session.unsubscribe(
      sub
    ).then((sub) => {
      loggerRTT.info(`unsubscribe(${topic}) : success`);
    }).catch((err) => {
      loggerRTT.error(`unsubscribe(${topic}) : failed :`, err);
    });
  }
}

