
import React from 'react';



export class IconGlyphicon  extends React.Component  {
  render()  {
    return <i className={`glyphicon glyphicon-${this.props.type}`} />;
  }
}


export class IconFontAwesome  extends React.Component  {

  combineClasses()  {
    const sizeMap = {  "2x" : "fa-2x",  "3x":"fa-3x",  "4x":"fa-4x",  "5x":"fa-5x"  };
    return  ([
          `fa fa-${this.props.type}`,
          sizeMap[this.props.size],
          (this.props.pulse && "fa-pulse"),
          (this.props.spin && "fa-spin"),
          ((this.props.fixedWidth||this.props.fw) && "fa-fw"),
          ...(this.props.mixClasses || [])
        ]).filter( x  => !!x ).join(" ");
  }


  render()  {
    return <i className={this.combineClasses()} style={this.props.style} title={this.props.title}/>;
  }
}


export class IconImage  extends React.Component  {
  render()  {
    if ( this.props.image )
      return <img src={`images/${this.props.image}`} />;

    return <img src={this.props.src} />;
  }
}


export class IconLanguage  extends React.Component  {
  render()  {
    const  { small, large,
             labelNative, labelEnglish,
             code } = this.props;

    const className=[ (   (small && "lang-xs")  ||
                          (large && "lang-lg")  ||
                          ("lang-sm")              ),
                      (   (labelNative && labelEnglish && "lang-lbl-full")  ||
                          (labelNative && "lang-lbl")                       ||
                          (labelEnglish && "lang-lbl-en")                   ||
                          ("")                                                  ),
                    ].join(" ");

    return <i className={className} lang={code} />
  }
}

