import ExchangeRates from 'components/walletExchangeRates';
import { SettingsInjections } from 'generics/settingsInjections';

const normalPriceCoeff = 10000; // FIXME

export default class MoneyUtil {

  static formatMoney( value, curr )  {
    if ( !isFinite(value) )
      return "";

    let sval = ""+(Math.round(value)+99);

    let sign = "";
    if (sval[0] == "-")
    {
      sign = "-";
      sval = sval.slice(1);
    }

    if ( sval.length <= 4 )
      sval = ("0000"+sval).slice(-5);

    const moneyString = sign+sval.slice(0, sval.length-4)+"."+sval.slice(-4,-2);
    const currString = curr ? " " + this.formatCurr(curr) : "";

    return moneyString + currString;
  }

  static preferMoney( normalPrice, localPrice, priceCurr, userCurr )
  {
    if (!userCurr)
      userCurr = SettingsInjections.getUsedCurrency();

    if (priceCurr == userCurr)
      if (localPrice)
        return localPrice;

    return ExchangeRates.sellRate("usd", userCurr, normalPrice / normalPriceCoeff);
  }

  static formatCurr( curr ) {
    if (curr != 'gjn')
      return curr;

    const marketMode = SettingsInjections.getMode();

    return marketMode == 'pixstorm' ? 'PIX' : 'GJN';
  }

  static parseMoney( value )  {
    value = value || "";
    if ( value == "" )
      return NaN;

    const m = (/^\s*(\d*)(?:\.(\d*))?\s*$/).exec( value );
    if ( ! (m && (m[1] || m[2]) ) )
      return NaN;

    const hi = (m[1] || "0");
    const lo = ( ( (m[2] || "0") + "0000" ).slice(0,2) + "0000"  ).slice(0,4);
    return parseInt( (hi+lo), 10 );
  }
};


