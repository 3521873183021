import { classMixer, EventSource } from 'generics/utils';
import React from 'react';
import Scrollbar from "react-scrollbars-custom";
import ViewLegalFooter from "./legalFooter";

const dialogEvents = new EventSource();

let modalNonce = 0;

export class DialogHolder extends React.Component {
  constructor(props) {
    super(props);

    this.onToggleOffOnEsc = (e) => { if ((e.key == "Esc") || (e.key == "Escape")) this.removeComponent(modalNonce); }

    this.state = {
      "stack": []
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onToggleOffOnEsc);
    dialogEvents.on("pushModal", this.pushComponent);
  }


  componentWillUnmount() {
    dialogEvents.off("pushModal", this.pushComponent);
    document.removeEventListener("keydown", this.onToggleOffOnEsc);
  }

  removeComponent(key) {
    this.setState((prevState, props) => {
      const stack = prevState.stack.filter((x) => (x.key != key));
      return { stack };
    });
  }


  pushComponent = ({ Modal, modalProps }) => {
    const key = ++modalNonce;

    const modalDesc = {
      key,
      Modal,
      modalProps: Object.assign({}, modalProps, {
        key,
        removeModal: () => this.removeComponent(key),
        ref: (r) => { modalDesc.mounted = r; }
      }),
    };

    this.setState((prevState, props) => ({ "stack": prevState.stack.concat(modalDesc) }));
  }


  onBackdropTouch = (event) => {
    event.stopPropagation();

    const { mounted } = (this.state.stack.slice(-1)[0] || {});
    mounted && mounted.onBackdropTouch();
  }

  render() {
    if (this.state.stack.length == 0)
      return null;

    return (
      <div id="modalsHolder" onClick={this.onBackdropTouch}  >
        {this.state.stack.map(
          ({ Modal, modalProps, key }) => <ModalHolderPlate key={key} Modal={Modal} modalProps={modalProps} />
        )}
      </div>
    );
  }
}


function onHolderPlateTouch(event) {
  event.stopPropagation();
}


function ModalHolderPlate({ Modal, modalProps }) {
  return (
    <div className="modalWindow" onClick={onHolderPlateTouch}>
      <Scrollbar defaultStyles={false} scrollX={false}>
        <div className={classMixer("content", modalProps.class || "")}>
          <Modal {...modalProps} />
        </div>
        <ViewLegalFooter />
      </Scrollbar>
    </div>
  );

}


export function spawnDialogWindow(Modal, modalProps = {}) {
  dialogEvents.emit("pushModal", { Modal, modalProps });
}

