import { _I_ } from 'components/translate';
import { IconGlyphicon } from 'generics/icons';
import { classMixer } from 'generics/utils';
import { observer } from 'mobx-react';
import React from 'react';
import ContentLoader from "react-content-loader";
import ReactDOM from 'react-dom';
import { MarketStore } from 'stores';

// LogStream.enable("GRAPHCONTROLLER");

export class PagerControl extends React.Component {

  renderPageButton(pageShift) {
    return (
      <li key={pageShift} className={(this.props.pageShift == pageShift) ? "active" : ""}>
        <a onClick={() => this.props.setPageShift(pageShift)} >
          {Math.floor(pageShift / this.props.pageSize) + 1}
        </a>
      </li>
    );
  }


  renderPageGap(key) {
    return (<li key={key} className="disabled"><a disabled >...</a></li>);
  }


  buildRange(start, stop) {
    let pager = [];
    for (let page = start; page <= stop; page++)
      pager.push(this.renderPageButton(page * this.props.pageSize));

    return pager;
  }


  buildPages() {
    const lastPage = (this.props.itemsCount > 0) ? Math.floor((this.props.itemsCount - 1) / this.props.pageSize) : 0;
    const curPage = (this.props.pageShift >= this.props.itemsCount) ?
      (lastPage) :
      Math.floor(this.props.pageShift / this.props.pageSize);

    if (lastPage < 11)
      return this.buildRange(0, lastPage);

    if (curPage < 6)
      return [
        ...this.buildRange(0, 7),
        this.renderPageGap("hiGap"),
        ...this.buildRange(lastPage - 1, lastPage)
      ];

    if ((lastPage - curPage) < 6)
      return [
        ...this.buildRange(0, 1),
        this.renderPageGap("lowGap"),
        ...this.buildRange(lastPage - 7, lastPage)
      ];

    return [
      ...this.buildRange(0, 1),
      this.renderPageGap("lowGap"),
      ...this.buildRange(curPage - 2, curPage + 2),
      this.renderPageGap("hiGap"),
      ...this.buildRange(lastPage - 1, lastPage)
    ];


  }


  render() {
    if ((!this.props.renderUseless) && (this.props.itemsCount < this.props.pageSize))
      return null;

    if ((!this.props.renderEmpty) && (this.props.itemsCount == 0))
      return null;

    return (
      <div className="text-center">
        <ul className="pagination">
          {this.buildPages()}
        </ul>
      </div>
    );
  }
}


export class SimplePagerView extends React.Component {
  render() {
    const { disableBack, disableForward,
      onClickBack, onClickForward,
      currentPosition, arrowsWithIndexes } = this.props;

    const classesBack = (disableBack && "invisible") || "";
    const classesFwd = (disableForward && "invisible") || "";

    const bwdText = (!!arrowsWithIndexes) && (!disableBack) && (currentPosition > 0) && ` ${currentPosition - 1}`;
    const fwdText = (!!arrowsWithIndexes) && (!disableForward) && (currentPosition >= 0) && `${currentPosition + 1} `;

    return (
      <div className="ViewItemSearchPager">
        <ul className="pagination">
          <li className={classesBack}>
            <a disabled={disableBack} onClick={onClickBack}  >
              <IconGlyphicon type="chevron-left" /><span>{bwdText}</span>
            </a>
          </li>

          <li>
            <a disabled ><span>{currentPosition}</span></a>
          </li>

          <li className={classesFwd}>
            <a disabled={disableForward} onClick={onClickForward}  >
              <span>{fwdText}</span><IconGlyphicon type="chevron-right" />
            </a>
          </li>
        </ul>
      </div>

    );
  }
}


export class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: false };
    this.onRootRef = (r) => (this.dropdownRoot = r);
    this.handleOnTriggerClick = () => this.setState({ open: !this.state.open });
    this.onDocumentClick = (event) => this.handleDocumentClick(event)
  }


  handleDocumentClick(event) {
    if (!this.dropdownRoot) return;
    const rootNode = ReactDOM.findDOMNode(this.dropdownRoot);
    if (!rootNode) return;
    if (!rootNode.contains(event.target)) {
      this.setState({ open: false });
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.onDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onDocumentClick);
  }

  render() {
    const rootClasses = classMixer("dropdown", this.state.open && "open",
      this.props.active && "active", this.props.disabled && "disabled",
      this.props.dropup && "dropup", this.props.mixClasses)

    const menuClasses = classMixer("dropdown-menu", this.props.alignAlt && "dropdown-menu-right");

    return (
      <div className={rootClasses} onClick={this.handleOnTriggerClick} role="button" ref={this.onRootRef}>
        <a className="dropdown-toggle" title={this.props.title}>
          {this.props.caption}
          <span className="caret" />
        </a>
        <ul className={menuClasses}>
          {this.props.children}
        </ul>
      </div>
    );
  }
};

export class DropdownAction extends React.Component {
  //  dropdown entry component
  render() {
    const rootClasses = classMixer(this.props.active && "active", this.props.disabled && "disabled",
      this.props.hidden && "hidden", this.props.mixClasses);

    const linkProps = {
      title: this.props.title,
      href: this.props.href,
      target: classMixer(this.props.newtab && "_blank"),
      rel: classMixer(this.props.newtab && "noopener noreferrer"),
    };

    return (
      <li className={rootClasses} role="button" onClick={this.props.onClick}>
        <a {...linkProps}>
          {this.props.children}
        </a>
      </li>
    );
  }
}

export const ViewTextFilter = observer(
  class ViewTextFilter extends React.Component {
    onTextFilterSend() {
      MarketStore.setFilterText(this.searchInput.value || "");
    }

    textFilterRef(searchInput) {
      this.searchInput = searchInput;
    }

    onTextFilterReset() {
      this.searchInput.value = "";
      MarketStore.clearFilterText();
    }

    render() {
      const classes = ["marketSearch", "left", ((this.props.hidden && "hidden") || "")].join(" ");
      return (
        <div className={classes}>
          <div className="controlItem searchBar">
            {MarketStore.filterText &&
              <img className="button reset" src={`images/icons/btn_reset.png`} onClick={() => this.onTextFilterReset()} />
            }

            <input type="text"
              id="searchInput"
              placeholder={_I_("ViewItemSearch.termsPlaceholder")}
              ref={(searchInput) => this.textFilterRef(searchInput)}
              onKeyUp={() => this.onTextFilterSend()}
            />

            <div className="button search" onClick={() => this.onTextFilterSend()}>
              <img src={`images/icons/search.png`} />
            </div>
          </div>
        </div>
      );
    }
  }
)

export class FilterCheckbox extends React.Component {
  render() {
    return (
      <div className={classMixer("our-checkbox", this.props.classMixer)}>
        <div className={classMixer("state", this.props.checked && "checked")} />
      </div>
    )
  }
}

export class ItemsContentPreloader extends React.Component {
  render() {
    const lotCount = 3;
    const lotWidth = 220;
    const effectiveWidth = lotCount * lotWidth;
    const effectiveHeight = 362;
    let lots = [];

    for (let i = 0; i < lotCount; i++)
      lots.push(<rect key={`fakeLot_${i}`} y="0" x={i * lotWidth} rx="5" ry="5" width="200" height="352" />);

    return (
      <div className="lots grid">
        <ContentLoader
          speed={2}
          width={effectiveWidth}
          height={effectiveHeight}
          viewBox={`0 0 ${effectiveWidth} ${effectiveHeight}`}
          backgroundColor="#272C35"
          foregroundColor="#363D46"
        >
          {lots}
        </ContentLoader>
      </div>
    );
  }
}